import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../../reducers/rootReducer';
import { bindActionCreators, Dispatch } from 'redux';
import { Grid, TextField, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, makeStyles, Typography, InputAdornment, IconButton } from '@material-ui/core';
import theme, { colorPalette } from '../../../assets/theme';
import { DesktopDatePicker, LocalizationProvider } from '@material-ui/pickers';
import DateFnsUtils from '@material-ui/pickers/adapter/date-fns';
import { authenticationTypeKey, authTypes, AWS_S3, AZURE_BLOB, datePickerConstants, FTP, SFTP, transferTypeKey } from '../../../utils/dfbConstants';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { frcJsonAttributes, frcJson, defaultErrorObject, errorObjectAttributes } from './fileRetrievalConfigurationJson';
import { saveFileRetrievalConfig, updateFileRetrievalConfig } from '../../../../src/actions/formatAction';
import { toast } from 'react-toastify';
import { transferTypeValues, scheduleTypes, statuses, lastRetrievalDate } from '../../../utils/dfbConstants';
import { getLabelProperties, getAuthTypeValues, frcJsonTrim, getRequestBody } from './fileRetrievalConfigurationUtill';
import { validateSourceFolder } from '../../../utils/fileTransferConfig/validateSourceFolder';
import { validateAuthenticationPassword } from '../../../utils/fileTransferConfig/validateAuthPassword';
import { validateAutherizationUserName } from '../../../utils/fileTransferConfig/validateAuthUserName';
import { nextRetrievalDateValidation } from '../../../utils/fileTransferConfig/validateNextRetrievalDate';
import { lastRetrievalDateValidation } from '../../../utils/fileTransferConfig/validateLastRetrievalDate';
import { groupNameValidation } from '../../../utils/fileTransferConfig/validateGroupName';
import { transferTypeValidation } from '../../../utils/fileTransferConfig/validateTransferType';
import { authTypeValidation } from '../../../utils/fileTransferConfig/validateAuthType';
import { sourceFilePrefixValidation } from '../../../utils/fileTransferConfig/validateSourceFilePrefix';
import { retryLimitValidation } from '../../../utils/fileTransferConfig/validateRetryLimit';
import { retryIntervalValidation } from '../../../utils/fileTransferConfig/validateRetryInterval';
import { sourceFileExtensionValidation } from '../../../utils/fileTransferConfig/validateSourceFileExtension';
import { sourceFileSuffixValidation } from '../../../utils/fileTransferConfig/validateSourceFileSuffix';
import { sourceFileDataMaskValidation } from '../../../utils/fileTransferConfig/validateSourceFileDataMask';
import { filePasswordValidation } from '../../../utils/fileTransferConfig/validateFilePassword';
import { hostNameValidation } from '../../../utils/fileTransferConfig/validateHostName';
import { portValidation } from '../../../utils/fileTransferConfig/validatePort';
import { sasUrlValidation } from '../../../utils/fileTransferConfig/validateSasUrl';
import { subFolderValidation } from '../../../utils/fileTransferConfig/validateSubfolder';
import { authKeyFilevalidation } from '../../../utils/fileTransferConfig/validateSshKeyFile';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import FileRetrievalDetailsComponent from './FileRetrievalDetailsComponent';
import { clientNameValidation } from '../../../utils/fileTransferConfig/validateClientName';
interface FileRetrievalConfigDialogProps {
  campaignFormat: any,
  open: boolean,
  fileRetrievalConfig: any,
  refreshFrcDialog: boolean,
  handleDialogClose: () => any,
  key: any,
  isViewOpen: boolean,
  handleEdit: () => any,
  handleResetPagination: () => any,
  setFrcExpand: (index: number) => void,
  handleFrcAccordion: (clientName: string, configId: number, fileRetrievalConfig: any) => void
}
const UnconnectedFileRetrievalConfigDialogContainer: React.FC<Props & FileRetrievalConfigDialogProps> = (props) => {

  const [open, setOpen] = React.useState(false);
  const [refresh, setRefresh] = React.useState(false);
  const [actionKeyLoader, setActionKeyLoader] = React.useState(false)
  const [openNextRetrievalDatePicker, setOpenNextRetrievalDatePicker] = React.useState(false);
  const [openLastRetrievalDatePicker, setOpenLastRetrievalDatePicker] = React.useState(false);
  const [frcDataNeedsToBeSend, setFrcDataNeedsToBeSend] = React.useState({ ...frcJson });
  const [errorObject, setErrorObject] = React.useState({ ...defaultErrorObject });
  const [authPassMask, setAuthPassMask] = React.useState(true);
  const [filePassMask, setFilePassMask] = React.useState(true);
  const [azureSubFolder, setAzureSubFolder] = React.useState('');
  const [initialTransferType, setInitialTransferType] = React.useState('');
  const [subFolderError, setSubfolderError] = React.useState('');
  const classes = useStyles();
  const defaultOption = "";

  const validateAuthUserName = validateAutherizationUserName(frcDataNeedsToBeSend, errorObject, props.messages);

  const validateAuthPassword = validateAuthenticationPassword(frcDataNeedsToBeSend, errorObject, props.messages);

  const sourceFolderValidation = validateSourceFolder(frcDataNeedsToBeSend, errorObject, props.messages);

  const validateNextRetrievalDate = nextRetrievalDateValidation(frcDataNeedsToBeSend, errorObject, props.messages);

  const validateLastRetrievalDate = lastRetrievalDateValidation(frcDataNeedsToBeSend, errorObject, props.messages);

  const validateGroupName = groupNameValidation(frcDataNeedsToBeSend, errorObject, props.messages);

  const validateTransferType = transferTypeValidation(frcDataNeedsToBeSend, errorObject, props.messages);

  const validateAuthType = authTypeValidation(frcDataNeedsToBeSend, errorObject, props.messages);

  const validateSourceFilePrefix = sourceFilePrefixValidation(frcDataNeedsToBeSend, errorObject, props.messages);

  const validateRetryLimit = retryLimitValidation(frcDataNeedsToBeSend, errorObject, props.messages);

  const validateRetryInterval = retryIntervalValidation(frcDataNeedsToBeSend, errorObject, props.messages);

  const validateSourceFileExtension = sourceFileExtensionValidation(frcDataNeedsToBeSend, errorObject, props.messages);

  const validateSourceFileSuffix = sourceFileSuffixValidation(frcDataNeedsToBeSend, errorObject, props.messages);

  const validateSourceFileDataMask = sourceFileDataMaskValidation(frcDataNeedsToBeSend, errorObject, props.messages);

  const validateFilePassword = filePasswordValidation(frcDataNeedsToBeSend, errorObject, props.messages);

  const validateHostName = hostNameValidation(frcDataNeedsToBeSend, errorObject, props.messages);

  const validatePort = portValidation(frcDataNeedsToBeSend, errorObject, props.messages);

  const validateSasURL = sasUrlValidation(frcDataNeedsToBeSend, errorObject, props.messages);

  const validateSubFolder = subFolderValidation(frcDataNeedsToBeSend, azureSubFolder, setSubfolderError, props.messages);

  const validateAuthKeyFile = authKeyFilevalidation(frcDataNeedsToBeSend, errorObject, props.messages);

  const validateClientName = clientNameValidation(frcDataNeedsToBeSend, errorObject, props.messages, props.campaignFormat.isGeneric);

  useEffect(() => {
    setErrorObject({ ...defaultErrorObject });
    setAzureSubFolder('');
    if (props.fileRetrievalConfig.configId != null) {
      setFrcDataNeedsToBeSend({ ...props.fileRetrievalConfig, sourceFolder: props.fileRetrievalConfig.transferType === AZURE_BLOB && props.fileRetrievalConfig.sourceFolder != null ? props.fileRetrievalConfig.sourceFolder.split("/")[0] : props.fileRetrievalConfig.sourceFolder });
      setInitialTransferType(props.fileRetrievalConfig.transferType);
      if (props.fileRetrievalConfig.transferType === AZURE_BLOB) {
        getAzureSubFolder(props.fileRetrievalConfig.sourceFolder);
      }
    }
    setAuthPassMask(true);
    setFilePassMask(true);
  }, [props.refreshFrcDialog])

  const handleClose = () => {
    setOpen(false);
  };
  const refreshForm = () => {
    setRefresh(!refresh);
  }
  const closeDialog = () => {
    props.handleDialogClose();
  }

  const handleAuthPassMask = () => {
    setAuthPassMask(!authPassMask);
  };

  const handleFilePassMask = () => {
    setFilePassMask(!filePassMask);
  };

  const getAzureSubFolder = (sourcefolder: string): string => {
    let subfolder: string = '';
    frcDataNeedsToBeSend.sourceFolder = sourcefolder != null ? sourcefolder.split("/")[0] : '';
    if (sourcefolder != null && sourcefolder.trim().length > 0 && sourcefolder.includes("/")) {
      let index: number = sourcefolder.indexOf("/");
      subfolder = sourcefolder.slice(index, sourcefolder.length);
      setAzureSubFolder(subfolder);
    }
    return subfolder;
  }

  const handleCalendarDialog = (isOpen: boolean, dateFieldLabel: string) => {
    if (dateFieldLabel === 'nextretrieval') {
      setOpenNextRetrievalDatePicker(isOpen);
      if (isOpen === false) {
        document.getElementById('next-retrieval-data')?.focus();
      }
      errorObject.nextRetrievalDate = '';
    }
    else {
      setOpenLastRetrievalDatePicker(isOpen);
      if (isOpen === false) {
        document.getElementById('last-retrieval-data')?.focus();
      }
      errorObject.lastRetrievalDate = '';
    }
    refreshForm();
  }

  const handleChangeOfInput = (key: keyof frcJsonAttributes, value: string) => {
    if (typeof frcDataNeedsToBeSend[key] === 'string' || typeof frcDataNeedsToBeSend[key] === 'number' || typeof frcDataNeedsToBeSend[key] === 'object') {
      let tempObj = { ...frcDataNeedsToBeSend, [key]: value };
      let prevTransferType = frcDataNeedsToBeSend.transferType;
      setFrcDataNeedsToBeSend(tempObj);
      if (key === transferTypeKey && (initialTransferType === '' || value === initialTransferType)) {
        setInitialTransferType(value);
      }
      if (key === transferTypeKey && prevTransferType !== value) {
        setErrorObject({ ...defaultErrorObject });
      }
      if (key === transferTypeKey && value === AZURE_BLOB) {
        let tempFrcObj = { ...frcDataNeedsToBeSend, [key]: value };
        tempFrcObj.authenticationType = authTypes.AUTH_SAS;
        setFrcDataNeedsToBeSend(tempFrcObj);
      }
      if (key === transferTypeKey && value === AWS_S3) {
        let tempFrcObj = { ...frcDataNeedsToBeSend, [key]: value };
        tempFrcObj.authenticationType = authTypes.AUTH_PASSWORD;
        setFrcDataNeedsToBeSend(tempFrcObj);
      }
      if (key === authenticationTypeKey && frcDataNeedsToBeSend.transferType === SFTP && frcDataNeedsToBeSend.authenticationType !== value) {
        let tempFrcObj = { ...frcDataNeedsToBeSend };
        let tempFrcErrorObject = { ...errorObject };
        tempFrcObj.authenticationType = value;
        tempFrcObj.authPassword = '';
        tempFrcErrorObject.authPassword = '';
        setErrorObject(tempFrcErrorObject);
        setFrcDataNeedsToBeSend(tempFrcObj);
      }
      if (key === transferTypeKey && initialTransferType !== '' && value !== initialTransferType) {
        let tempGroupName: string = frcDataNeedsToBeSend.groupName;
        let tempConfigId: number | null = frcDataNeedsToBeSend.configId;
        let tempClientName: string = frcDataNeedsToBeSend.clientName;
        let tempFrcObj = { ...frcJson };
        tempFrcObj.transferType = value;
        tempFrcObj.groupName = tempGroupName;
        tempFrcObj.configId = tempConfigId;
        tempFrcObj.clientName = tempClientName;
        if (key === transferTypeKey && value === AZURE_BLOB) {
          tempFrcObj.authenticationType = authTypes.AUTH_SAS;
        }
        if (key === transferTypeKey && value === AWS_S3) {
          tempFrcObj.authenticationType = authTypes.AUTH_PASSWORD;
        }
        setInitialTransferType(value);
        setAuthPassMask(true);
        setFilePassMask(true);
        setFrcDataNeedsToBeSend(tempFrcObj);
        setErrorObject({ ...defaultErrorObject });
      }
      if (props.fileRetrievalConfig.configId != null && key === transferTypeKey && props.fileRetrievalConfig.transferType === value) {
        setFrcDataNeedsToBeSend({ ...props.fileRetrievalConfig, sourceFolder: props.fileRetrievalConfig.transferType === AZURE_BLOB ? props.fileRetrievalConfig.sourceFolder.split("/")[0] : props.fileRetrievalConfig.sourceFolder });
        setInitialTransferType(props.fileRetrievalConfig.transferType);
        setErrorObject({ ...defaultErrorObject });
        if (props.fileRetrievalConfig.transferType === AZURE_BLOB && props.fileRetrievalConfig.transferType === value) {
          getAzureSubFolder(props.fileRetrievalConfig.sourceFolder);
        }
        setAuthPassMask(true);
        setFilePassMask(true);

      }
    }
  }

  const handleNextRetrievalDate = (nextRetrievalDate: Date | null, enteredDate: string | undefined) => {
    let tempObj = { ...frcDataNeedsToBeSend };
    if (enteredDate === undefined && nextRetrievalDate === null) {
      tempObj.nextRetrievalDate = null;
    } else if (nextRetrievalDate !== null && enteredDate === undefined) {
      tempObj.nextRetrievalDate = nextRetrievalDate;
    } else {
      tempObj.nextRetrievalDate = new Date(enteredDate !== undefined && enteredDate.trim().length === 10 ? enteredDate : 'Invalid Date');
    }
    setFrcDataNeedsToBeSend(tempObj);
  }

  const handleLastRetrievalDate = (lastRetrievalDate: Date | null, enteredDate: string | undefined) => {
    let tempObj = { ...frcDataNeedsToBeSend };
    if (enteredDate === undefined && lastRetrievalDate === null) {
      tempObj.lastRetrievalDate = null;
    } else if (lastRetrievalDate !== null && enteredDate === undefined) {
      tempObj.lastRetrievalDate = lastRetrievalDate;
    } else {
      tempObj.lastRetrievalDate = new Date(enteredDate !== undefined && enteredDate.trim().length === 10 ? enteredDate : 'Invalid Date');
    }
    setFrcDataNeedsToBeSend(tempObj);
  }

  const frcJsonTrimAll = () => {
    frcJsonTrim(frcDataNeedsToBeSend);
    if (frcDataNeedsToBeSend.configId == null) {
      frcDataNeedsToBeSend.lastRetrievalDate = new Date(Date.parse(lastRetrievalDate));
    }
    if (frcDataNeedsToBeSend.transferType === AZURE_BLOB) {
      frcDataNeedsToBeSend.sourceFolder = frcDataNeedsToBeSend.sourceFolder.concat(azureSubFolder);
      frcDataNeedsToBeSend.authenticationType = frcDataNeedsToBeSend.authenticationType.charAt(0);
    }
    if (frcDataNeedsToBeSend.transferType === AWS_S3) {
      frcDataNeedsToBeSend.hostName = frcDataNeedsToBeSend.hostName != null ? frcDataNeedsToBeSend.hostName.split("/")[0] : '';
    }
    frcDataNeedsToBeSend.updateBy = String(props.user?.email);
    if (!props.campaignFormat.isGeneric) {
      frcDataNeedsToBeSend.clientName = String(props.campaignFormat.client.clientName);
    }
  }
  const disableLabel = (label: string): boolean => {
    if (frcDataNeedsToBeSend.transferType === AZURE_BLOB && (label === props.messages.azureStorageAccountName || label === props.messages.azureContainerName)) {
      return true;
    } else {
      return false;
    }
  }

  const validateAllFields = (): boolean => {
    let errorCount: number = 0
    if (!validateGroupName()) {
      errorCount++;
    }
    if (!validateTransferType()) {
      errorCount++;
    }
    if (!validatePort()) {
      errorCount++;
    }
    if (!validateHostName()) {
      errorCount++;
    }
    if (frcDataNeedsToBeSend.transferType === AZURE_BLOB && !validateSasURL()) {
      errorCount++;
    }
    if (!validateAuthType()) {
      errorCount++;
    }
    if (!validateAuthUserName()) {
      errorCount++;
    }
    if (!validateAuthPassword()) {
      errorCount++;
    }
    if (!sourceFolderValidation()) {
      errorCount++;
    }
    if (!validateSourceFilePrefix()) {
      errorCount++;
    }
    if (!validateSourceFileSuffix()) {
      errorCount++;
    }
    if (!validateSubFolder()) {
      errorCount++;
    }
    if (!validateSourceFileDataMask()) {
      errorCount++;
    }
    if (!validateFilePassword()) {
      errorCount++;
    }
    if (!validateSourceFileExtension()) {
      errorCount++;
    }
    if (!validateAuthKeyFile()) {
      errorCount++;
    }
    if (!validateRetryLimit()) {
      errorCount++;
    }
    if (!validateRetryInterval()) {
      errorCount++;
    }
    if (!validateNextRetrievalDate()) {
      errorCount++;
    }
    if (!validateClientName()) {
      errorCount++;
    }
    if (!validateLastRetrievalDate() && props.fileRetrievalConfig.configId != null) {
      errorCount++;
    }
    if (errorCount > 0) {
      return false
    }
    return true;
  }
  const saveConfiguration = async () => {
    try {
      refreshForm();
      if (validateAllFields()) {
        frcJsonTrimAll();
        setActionKeyLoader(true);
        let result: any;
        if (frcDataNeedsToBeSend.configId == null) {
          result = await props.saveFileRetrievalConfig(props.campaignFormat.campaignFormatId, props.campaignFormat.campaignFormatMajorVersion, "FRC", getRequestBody(frcDataNeedsToBeSend));
          props.setFrcExpand(0);
          props.handleResetPagination();
        } else {
          result = await props.updateFileRetrievalConfig(frcDataNeedsToBeSend.configId, props.campaignFormat.campaignFormatId, props.campaignFormat.campaignFormatMajorVersion, getRequestBody(frcDataNeedsToBeSend))
          if (props.campaignFormat.isGeneric && result.success) {
            props.handleFrcAccordion(frcDataNeedsToBeSend.clientName, frcDataNeedsToBeSend.configId, result.data);
          }
        }
        if (result.success) {
          toast(<Typography style={{ color: 'black', opacity: 0.7 }}>{props.messages.ChangesSavedSuccess}</Typography>);
        }
        else {
          toast.error(<Typography style={{ color: 'white' }}>{props.messages.deleteDFBBusinessObjectFailureMessage}</Typography>);
        }
        setActionKeyLoader(false);
        props.handleDialogClose();
      }
    } catch (error) {
      console.log(error);
      toast.error(<Typography style={{ color: 'white' }}>{props.messages.deleteDFBBusinessObjectFailureMessage}</Typography>);
      setActionKeyLoader(false);
      props.handleDialogClose();
    }

  }

  const getPasswordTextField = (label: string, testId: string, flag: boolean, isRequired: boolean, value: string | null, frcFieldKey: keyof frcJsonAttributes, handleChange: (Key: keyof frcJsonAttributes, fieldValue: string) => void, handleFlag: () => void, handleOnBlur?: () => void) => {
    return (
      <TextField
        label={label}
        data-testid={testId}
        type={!flag ? 'text' : 'password'}
        value={value != null && value.length > 0 ? value : ''}
        required={isRequired}
        InputLabelProps={getInputLabelProps(value)}
        InputProps={{
          endAdornment:
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleFlag}
              >
                {!flag ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
        }}
        multiline={!flag}
        fullWidth
        onChange={(event) => {
          handleChange(frcFieldKey, event.target.value)
        }
        }
        onBlur={() => {
          if (handleOnBlur !== undefined) {
            handleOnBlur();
          }
          refreshForm();
        }}
      />
    )
  }

  const getInputLabelProps = (value: string | null) => {
    if (value != null && value.trim().length > 0) {
      return { shrink: true };
    } else {
      return {};
    }
  }

  const getTextField = (padding: Array<number>, label: string, testId: string, isRequired: boolean, value: string | null, frcFieldKey: keyof frcJsonAttributes, handleChange: (Key: keyof frcJsonAttributes, fieldValue: string) => void, handleOnBlur?: () => void) => {
    return (
      <TextField
        style={{ padding: theme.spacing(padding[0], padding[1], padding[2], padding[3]) }}
        label={label}
        placeholder={label}
        data-testid={testId}
        required={isRequired}
        size='small'
        fullWidth
        autoComplete={'off'}
        multiline
        disabled={disableLabel(label)}
        InputLabelProps={getInputLabelProps(value)}
        value={value != null && value.length > 0 ? value : ''}
        onChange={(event) => {
          handleChange(frcFieldKey, event.target.value);
        }}
        onBlur={() => {
          if (handleOnBlur !== undefined) {
            handleOnBlur();
            refreshForm();
          }
        }}
      />
    )
  }

  const getDateField = (open: boolean, onHandleCalenderDialog: (value: boolean, fieldLabel: string) => void, label: string, value: any, onChange: (pickedDate: Date | null, enteredDate: string | undefined) => void, disablePast: boolean, disableFuture: boolean, id: string, handleOnBlur: () => void, dateFieldLabel: string) => {
    return (
      <LocalizationProvider dateAdapter={DateFnsUtils} >
        <DesktopDatePicker
          inputFormat={datePickerConstants.UI_DATE_FORMAT}
          allowSameDateSelection={true}
          open={open}
          onClose={() => { onHandleCalenderDialog(false, dateFieldLabel) }}
          onOpen={() => { onHandleCalenderDialog(true, dateFieldLabel) }}
          label={label + ' *'}
          value={value}
          disablePast={disablePast}
          disableFuture={disableFuture}
          disableMaskedInput={true}
          onChange={(dateSelected, enteredDate) => { onChange(dateSelected, enteredDate) }}
          renderInput={props => <TextField id={id} data-testid={id} autoComplete="off" autoFocus={openNextRetrievalDatePicker} className={classes.warningStyles} onFocus={() => {
            onHandleCalenderDialog(false, dateFieldLabel)
          }}
            style={{ width: '100%' }} FormHelperTextProps={{ style: { display: 'none' } }} InputLabelProps={{
              style: { color: 'none' }
            }}
            onBlur={(event) => {
              handleOnBlur();
              refreshForm();
            }}
            {...props} />}
        />
      </LocalizationProvider>

    )
  }

  const displayErrorMessage = (key: keyof errorObjectAttributes) => {
    return (
      errorObject[key] != null && errorObject[key].length > 1 ?
        <Typography variant='caption' color='error'>
          {errorObject[key]}
        </Typography> : null
    )
  }
  const handleEdit = () => {
    props.handleEdit();
  };
  const handleClientName = (event: React.ChangeEvent<HTMLInputElement>) => {
    let tempObj = { ...frcDataNeedsToBeSend }
    tempObj.clientName = String(event?.target.value);
    setFrcDataNeedsToBeSend(tempObj);
  }

  return (
    <div>
      <Dialog
        open={props.open}
        keepMounted
        maxWidth={'md'}
        onClose={handleClose}
        aria-labelledby="file-retrieval-config-title"
        aria-describedby="file-retrieval-config-description"
        key={props.key}
      >
        <div className={classes.topStripBlue} />
        <DialogTitle>
          <Typography id="dialog-title" variant='h6' style={{ fontWeight: 'bold' }} data-testid='output-job-label'>
            {props.messages.fileRetrievalConfiguration}
          </Typography>
        </DialogTitle>
        <DialogContent>
          {!props.isViewOpen && <Grid container spacing={2}>
            <Grid item container direction="row" xs={12} justify='space-between' spacing={1}>
              <Grid item xs={6} style={{ padding: theme.spacing(0, 2.5, 0, 0) }}>
                {props.campaignFormat.isGeneric ?
                  <Grid>
                    <TextField
                      fullWidth
                      select
                      SelectProps={{ native: true }}
                      data-testid='client-name'
                      label={props.messages.frcClientNameLabel}
                      value={frcDataNeedsToBeSend.clientName}
                      required={true}
                      onChange={handleClientName}
                      onBlur={() => {
                        validateClientName();
                        refreshForm();
                      }}
                    >
                      <option value={defaultOption}>{defaultOption}</option>
                      {props.clients?.map((client: any) => (
                        <option id={client.clientId} key={client.clientId} value={client.clientName}>{client.clientName}</option>
                      ))}
                    </TextField>
                    {errorObject.clientName != null && errorObject.clientName.length > 1 &&
                      <Typography variant='caption' color='error'>
                        {errorObject.clientName}
                      </Typography>}
                  </Grid>
                  :
                  <TextField
                    fullWidth
                    data-testid='client-name'
                    label={props.messages.frcClientNameLabel}
                    defaultValue={(props.campaignFormat.client?.clientName) ? props.campaignFormat.client.clientName : ''}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                }
              </Grid>
              <Grid item xs={6} style={{ padding: theme.spacing(0, 0, 0, 2.5) }}>
                {getTextField([0.4, 0, 0, 0], props.messages.frcGroupNameLabel, "group-name", true, frcDataNeedsToBeSend.groupName, "groupName", handleChangeOfInput, validateGroupName)}
                {displayErrorMessage("groupName")}
              </Grid>
            </Grid>
            <Grid item container direction="row" xs={12} justify='space-between' spacing={1}>
              <Grid item xs={12}>
                <TextField
                  label={props.messages.Schedule}
                  placeholder={props.messages.Schedule}
                  SelectProps={{ native: true }}
                  InputLabelProps={getInputLabelProps(frcDataNeedsToBeSend.schedule)}
                  data-testid='schedule'
                  size='small'
                  fullWidth
                  select
                  multiline
                  value={frcDataNeedsToBeSend.schedule}
                  onChange={(event) => {
                    handleChangeOfInput('schedule', event.target.value);
                  }}
                >
                  {scheduleTypes.map((scheduleType) => (
                    <option key={scheduleType.schedule} value={scheduleType.scheduleAbbr}>{scheduleType.schedule}</option>
                  ))}
                </TextField>
              </Grid>
            </Grid>
            <Grid item container direction="row" xs={12} justify='space-between' spacing={1}>
              <Grid item xs={6} style={{ padding: theme.spacing(0, 2.5, 0, 0) }}>
                <TextField
                  label={props.messages.TransferType}
                  data-testid='transfer-type-select'
                  select
                  fullWidth
                  SelectProps={{ native: true }}
                  InputLabelProps={getInputLabelProps(frcDataNeedsToBeSend.transferType)}
                  required={true}
                  value={frcDataNeedsToBeSend.transferType}
                  onChange={(event) => {
                    handleChangeOfInput('transferType', event.target.value);
                  }}
                  onBlur={() => {
                    validateTransferType();
                    refreshForm();
                  }}
                >
                  {transferTypeValues.map((transferType) => (
                    <option key={transferType} value={transferType}>{transferType}</option>
                  ))}
                </TextField>
                {displayErrorMessage("transferType")}
              </Grid>
              <Grid item xs={6} style={{ padding: theme.spacing(0, 0, 0, 2.5) }}>
                <TextField
                  data-testid='authentication-type-select'
                  select={true}
                  fullWidth
                  required={true}
                  SelectProps={{ native: true }}
                  InputLabelProps={getInputLabelProps(frcDataNeedsToBeSend.authenticationType)}
                  InputProps={{ readOnly: frcDataNeedsToBeSend.transferType === AZURE_BLOB || frcDataNeedsToBeSend.transferType === AWS_S3 }}
                  label={props.messages.frcAuthenticationTypeLabel}
                  value={frcDataNeedsToBeSend.authenticationType != null && frcDataNeedsToBeSend.authenticationType.length > 0 ? frcDataNeedsToBeSend.authenticationType : ''}
                  onChange={(event) => { handleChangeOfInput('authenticationType', event.target.value) }}
                  onBlur={() => {
                    validateAuthType()
                    refreshForm();
                  }}
                >
                  {getAuthTypeValues(frcDataNeedsToBeSend.transferType).map((authType) => (
                    <option key={authType} value={authType.charAt(0)}>{authType}</option>
                  ))}
                </TextField>
                {displayErrorMessage("authenticationType")}
              </Grid>
            </Grid>
            <Grid item container direction="row" xs={12} justify='space-between' spacing={1}>
              <Grid item xs={6} style={{ padding: theme.spacing(0, 2.5, 0, 0) }}>
                {getTextField([0, 0, 0, 0], getLabelProperties(frcDataNeedsToBeSend.transferType).userNameLabel, "auth-user-name", true, frcDataNeedsToBeSend.authUserName, "authUserName", handleChangeOfInput, validateAuthUserName)}
                {displayErrorMessage("authUserName")}
              </Grid>
              {frcDataNeedsToBeSend.transferType !== AZURE_BLOB ? <Grid item xs={6} style={{ padding: theme.spacing(0, 0, 0, 2.5) }}>
                {frcDataNeedsToBeSend.transferType === SFTP && frcDataNeedsToBeSend.authenticationType === authTypes.AUTH_KEY ? <Grid>{getPasswordTextField(props.messages.SSHKey, "ssh-key-file", authPassMask, true, frcDataNeedsToBeSend.authKeyFileName, "authKeyFileName", handleChangeOfInput, handleAuthPassMask, validateAuthKeyFile)}
                  {displayErrorMessage("authKeyFileName")}
                </Grid> : <Grid>{getPasswordTextField(getLabelProperties(frcDataNeedsToBeSend.transferType).passwordLabel, "auth-password", authPassMask, true, frcDataNeedsToBeSend.authPassword, "authPassword", handleChangeOfInput, handleAuthPassMask, validateAuthPassword)}
                    {displayErrorMessage("authPassword")}
                  </Grid>}
              </Grid> : <Grid item xs={6} style={{ padding: theme.spacing(0, 0, 0, 2.5) }}>
                  {getTextField([0, 0, 0, 0], getLabelProperties(frcDataNeedsToBeSend.transferType).sourceFolderLabel, "subfolder", true, frcDataNeedsToBeSend.sourceFolder, "sourceFolder", handleChangeOfInput, sourceFolderValidation)}
                  {displayErrorMessage("sourceFolder")}
                </Grid>}
            </Grid>
            {frcDataNeedsToBeSend.transferType === FTP || frcDataNeedsToBeSend.transferType === SFTP ? <Grid item container direction="row" xs={12} justify='space-between' spacing={1}>
              <Grid item xs={6} style={{ padding: theme.spacing(0, 2.5, 0, 0) }}>
                {getTextField([0, 0, 0, 0], props.messages.Port, "port", true, String(frcDataNeedsToBeSend.port !== null ? frcDataNeedsToBeSend.port : ''), "port", handleChangeOfInput, validatePort)}
                {displayErrorMessage("port")}
              </Grid>
              <Grid item xs={6} style={{ padding: theme.spacing(0, 0, 0, 2.5) }}>
                {getTextField([0, 0, 0, 0], props.messages.HostName, "host-name", true, frcDataNeedsToBeSend.hostName, "hostName", handleChangeOfInput, validateHostName)}
                {displayErrorMessage("hostName")}
              </Grid>
            </Grid> : null}
            {frcDataNeedsToBeSend.transferType === AZURE_BLOB ? <Grid item container direction="row" xs={12} justify='space-between' spacing={1}>
              <Grid item xs={12}>
                {getTextField([0, 0, 0, 0], getLabelProperties(frcDataNeedsToBeSend.transferType).hostLabel, "sas-url", true, frcDataNeedsToBeSend.hostName, "hostName", handleChangeOfInput, validateSasURL)}
                {displayErrorMessage("hostName")}
              </Grid>
            </Grid> : null}
            {frcDataNeedsToBeSend.transferType === AWS_S3 ? <Grid item container direction="row" xs={12} justify='space-between' spacing={1}>
              <Grid item xs={12}>
                {getTextField([0, 0, 0, 0], getLabelProperties(frcDataNeedsToBeSend.transferType).hostLabel, "bucket-name", true, frcDataNeedsToBeSend.hostName != null ? frcDataNeedsToBeSend.hostName.split("/")[0] : "", "hostName", handleChangeOfInput, validateHostName)}
                {displayErrorMessage("hostName")}
              </Grid>
            </Grid> : null}
            <Grid item container direction="row" xs={12} justify='space-between' spacing={1}>
              {frcDataNeedsToBeSend.transferType !== AZURE_BLOB ? <Grid item xs={6} style={{ padding: theme.spacing(0, 2.5, 0, 0) }}>
                {getTextField([0, 0, 0, 0], getLabelProperties(frcDataNeedsToBeSend.transferType).sourceFolderLabel, "source-folder", true, frcDataNeedsToBeSend.sourceFolder, "sourceFolder", handleChangeOfInput, sourceFolderValidation)}
                {displayErrorMessage("sourceFolder")}
              </Grid> : <Grid item xs={6} style={{ padding: theme.spacing(0, 2.5, 0, 0) }}>
                  <TextField
                    data-testid='subfolder'
                    label={props.messages.azureSubFolderName}
                    value={azureSubFolder != '' ? azureSubFolder : ''}
                    required={false}
                    InputLabelProps={getInputLabelProps(azureSubFolder)}
                    fullWidth
                    multiline
                    onChange={(event) => {
                      setAzureSubFolder(event.target.value);
                    }}
                    onBlur={() => {
                      validateSubFolder();
                      refreshForm();
                    }}
                  />
                  {subFolderError != null && subFolderError.length > 1 &&
                    <Typography variant='caption' color='error'>
                      {subFolderError}
                    </Typography>}
                </Grid>}
              <Grid item xs={6} style={{ padding: theme.spacing(0, 0, 0, 2.5) }}>
                {getPasswordTextField(props.messages.frcFilePasswordLabel, "file-password", filePassMask, false, frcDataNeedsToBeSend.sourceFilePassword, "sourceFilePassword", handleChangeOfInput, handleFilePassMask, validateFilePassword)}
                {displayErrorMessage("sourceFilePassword")}
              </Grid>
            </Grid>
            <Grid item container direction="row" xs={12} justify='space-between' spacing={1}>
              <Grid item xs={6} style={{ padding: theme.spacing(0, 2.5, 0, 0) }}>
                {getTextField([0, 0, 0, 0], props.messages.frcSourceFilePrefixLabel, "source-file-prefix", true, frcDataNeedsToBeSend.sourceFileNamePrefix, "sourceFileNamePrefix", handleChangeOfInput, validateSourceFilePrefix)}
                {displayErrorMessage("sourceFileNamePrefix")}
              </Grid>
              <Grid item xs={6} style={{ padding: theme.spacing(0, 0, 0, 2.5) }}>
                {getTextField([0, 0, 0, 0], props.messages.frcSourceFileDateMaskLabel, "source-file-data-mask", false, frcDataNeedsToBeSend.sourceFileNameDatamask, "sourceFileNameDatamask", handleChangeOfInput, validateSourceFileDataMask)}
                {displayErrorMessage("sourceFileNameDatamask")}
              </Grid>
            </Grid>
            <Grid item container direction="row" xs={12} justify='space-between' spacing={1}>
              <Grid item xs={6} style={{ padding: theme.spacing(0, 2.5, 0, 0) }}>
                {getTextField([0, 0, 0, 0], props.messages.frcSourceFileSuffixLabel, "source-file-suffix", false, frcDataNeedsToBeSend.sourceFileNameSuffix, "sourceFileNameSuffix", handleChangeOfInput, validateSourceFileSuffix)}
                {displayErrorMessage("sourceFileNameSuffix")}
              </Grid>
              <Grid item xs={6} style={{ padding: theme.spacing(0, 0, 0, 2.5) }}>
                {getTextField([0, 0, 0, 0], props.messages.frcSourceFileExtensionLabel, "source-file-extension", true, frcDataNeedsToBeSend.sourceFileNameExtension, "sourceFileNameExtension", handleChangeOfInput, validateSourceFileExtension)}
                {displayErrorMessage("sourceFileNameExtension")}
              </Grid>
            </Grid>
            <Grid item container direction="row" xs={12} justify='space-between' spacing={1}>
              <Grid item xs={6} style={{ padding: theme.spacing(0, 2.5, 0, 0) }}>
                <ClickAwayListener onClickAway={() => { setOpenNextRetrievalDatePicker(false) }}>
                  <Grid>
                    {getDateField(openNextRetrievalDatePicker, handleCalendarDialog, props.messages.frcNextRetrievalLabel, frcDataNeedsToBeSend.nextRetrievalDate, handleNextRetrievalDate, true, false, 'next-retrieval-data', validateNextRetrievalDate, 'nextretrieval')}
                  </Grid>
                </ClickAwayListener>
                {displayErrorMessage("nextRetrievalDate")}
              </Grid>
              <Grid item xs={6} style={{ padding: theme.spacing(0, 0, 0, 2.5) }}>
                {getTextField([0.5, 0, 0, 0], props.messages.frcRetryLimitLabel, "retry-limit", true, frcDataNeedsToBeSend.retryLimit ? String(frcDataNeedsToBeSend.retryLimit) : null, "retryLimit", handleChangeOfInput, validateRetryLimit)}
                {displayErrorMessage("retryLimit")}
              </Grid>
            </Grid>
            <Grid item container direction="row" xs={12} justify='space-between' spacing={1}>
              <Grid item xs={6} style={{ padding: theme.spacing(0, 2.5, 0, 0) }}>
                {getTextField([0.5, 0, 0, 0], props.messages.frcRetryInterval, "retry-interval", true, frcDataNeedsToBeSend.retryInterval ? String(frcDataNeedsToBeSend.retryInterval) : null, "retryInterval", handleChangeOfInput, validateRetryInterval)}
                {displayErrorMessage("retryInterval")}
              </Grid>
              <Grid item xs={6} style={{ padding: theme.spacing(0, 0, 0, 2.5) }}>
                <TextField
                  style={{ padding: theme.spacing(0.5, 0, 0, 0) }}
                  label={'Status'}
                  placeholder={'Status'}
                  data-testid='status'
                  SelectProps={{ native: true }}
                  InputLabelProps={getInputLabelProps(frcDataNeedsToBeSend.active)}
                  size='small'
                  select
                  fullWidth
                  multiline
                  value={frcDataNeedsToBeSend.active}
                  onChange={(event) => {
                    handleChangeOfInput('active', event.target.value)
                  }}
                >
                  {statuses.map((status) => (
                    <option key={status.status} value={status.statusCode}>{status.status}</option>
                  ))}
                </TextField>
              </Grid>
              {props.fileRetrievalConfig.configId != null ? <Grid item xs={6} style={{ padding: theme.spacing(1, 2.5, 0, 0) }}>
                <ClickAwayListener onClickAway={() => { setOpenLastRetrievalDatePicker(false) }}>
                  <Grid>
                    {getDateField(openLastRetrievalDatePicker, handleCalendarDialog, props.messages.frcLastRetrievalLabel, frcDataNeedsToBeSend.lastRetrievalDate, handleLastRetrievalDate, false, true, 'last-retrieval-data', validateLastRetrievalDate, 'lastretrieval')}
                  </Grid>
                </ClickAwayListener>
                {displayErrorMessage("lastRetrievalDate")}
              </Grid> : null}
            </Grid>
          </Grid>}
          {
            props.isViewOpen && <FileRetrievalDetailsComponent fileRetrievalConfig={props.fileRetrievalConfig} />
          }
        </DialogContent>
        <DialogActions style={{ margin: theme.spacing(0, 2, 2, 2) }}>
          {actionKeyLoader ? <CircularProgress color='secondary' size={24} /> :
            <React.Fragment>
              <Button data-testid='frc-cancel-button' variant='contained' onClick={() => { closeDialog() }}>
                {'Cancel'}
              </Button>
              {!props.isViewOpen && <Button data-testid='frc-save-button' variant='contained' color='secondary' onClick={() => { saveConfiguration() }}>
                {'Save'}
              </Button>}
              {props.isViewOpen && <Button data-testid='frc-save-button' variant='contained' color='secondary' onClick={() => { handleEdit() }}>
                {'Edit'}
              </Button>}
            </React.Fragment>}
        </DialogActions>
      </Dialog>
    </div>
  );
}
const useStyles = makeStyles((theme) => ({
  dialogContainer: {
    minWidth: '900px'
  },
  topStripBlue: {
    background: colorPalette.primaryShade,
    height: '10px'
  },
  warningStyles: {
    "& .MuiFormLabel-root.Mui-error": {
      color: "#00497B !important"
    },
    "& .MuiInput-underline.Mui-error:after": {
      borderBottomColor: "black !important"
    },
    "& .MuiFormHelperText-root.Mui-error": {
      color: "orange !important"
    }
  }
}));

const mapStateToProps = (state: RootState) => ({
  user: state.user.user,
  messages: state.messageBundle.messages,
  clients: state.client.clients
})
const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators({
    saveFileRetrievalConfig,
    updateFileRetrievalConfig
  }, dispatch)
};

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;
export const FileRetrievalConfigDialogContainer = connect(mapStateToProps, mapDispatchToProps)(UnconnectedFileRetrievalConfigDialogContainer);