import { formatMessage } from './messagesUtils';
import * as validations from './additionalValidations'
import { dfbLineEndings } from './dfbConstants';

const DGID_FIELD_NAME = "DMD_UID_OUT";

interface FileErrorObj {
    numberOfRecords: number;
    validated: boolean;
    errors: string[];
}

interface FieldErrorObj {
    validated: boolean;
    errors: Array<{ rowNumber: number, fieldName: string, errorDescription: string }>;
}
export const percentage = (partialValue: number, totalValue: number) => {
    return Math.round((100 * partialValue) / totalValue);
}

export const isSubsetOf = (set: any[], subset: any[]) => {
    let count = 0;
    for (let i = 0; i < set.length; i++) {
        for (let j = 0; j < subset.length; j++) {
            if (set[i] !== undefined && subset[j].fieldName !== undefined) {
                if (subset[j].fieldName.trim() === set[i].trim()) {
                    count++
                }
            }
        }
    }
    if (count === subset.length) {
        return true
    } else {
        return false
    }
}
export const fieldValidator = (masterFields: any[], fileFields: any[],
    setFileProcessedPercentage: any, messageBundle: any) => {
    // set this to whatever number of items you can process at once
    return new Promise((reslove, reject) => {
        let errorObj: any = {
            numberOfRecords: 0,
            validated: true,
            errors: [],
            fieldErrors: [],
        };
        let recordCount = 0;
        let chunk = 100;
        let index = 1;
        let fields = fileFields[0];
        const dgidMap = new Map<string, number>();
        errorObj.numberOfRecords = fileFields.length - 1;
        const fileRecordObjArray: any[] = [];
        function doChunk() {
            try {
                let cnt = chunk;
                while (cnt-- && index < fileFields.length) {
                    if (!fileFields[index] || fileFields[index] + ''.trim().length < 1) {
                        index++;
                    }
                    else {
                        recordCount++;
                        let fieldValues = fileFields[index];
                        if (fieldValues.length !== fields.length) {
                            errorObj.validated = false;
                            //errorObj.errors.push(`Row ${index + 1} - Incorrect formatting. Check delimiters`);
                            errorObj.errors.push(formatMessage(messageBundle.validationIncorrectFormatError,
                                index + 1));
                        }
                        else {
                            let ignoreRow = false;
                            let tempErrorArray: Array<{ rowNumber: number, fieldName: string, errorDescription: string }> = [];
                            // process array[index] here
                            for (let i = 0; i < fields.length; i++) {
                                let masterField = masterFields.find(o => o.fieldName.toLowerCase().trim() === fields[i].toLowerCase().trim());
                                let fieldValue = fieldValues !== null ? fieldValues[i] : "";
                                if (masterField !== undefined) {
                                    switch (masterField.ignoreCode) {
                                        //Ignore entire record when the field is empty
                                        case -1:
                                            if (fieldValue && ('' + fieldValue).trim().length > 0) {
                                                let errorResult = validateField(masterField, fields[i], fieldValue, index, messageBundle, dgidMap)
                                                if (!errorResult.validated) {
                                                    tempErrorArray = tempErrorArray.concat(errorResult.errors)
                                                }
                                            }
                                            else {
                                                ignoreRow = true;
                                            }

                                            break;
                                        //Dont ignore
                                        case 0:
                                            let errorResult = validateField(masterField, fields[i], fieldValue, index, messageBundle, dgidMap)
                                            if (!errorResult.validated) {
                                                tempErrorArray = tempErrorArray.concat(errorResult.errors)
                                            }
                                            break;
                                        //Ignore only the field
                                        case 1:

                                            break;
                                        default:
                                            break;
                                    }
                                }
                            }
                            if (!ignoreRow) {
                                if (tempErrorArray.length > 0) {
                                    errorObj.validated = false;
                                }
                                errorObj.fieldErrors = errorObj.fieldErrors.concat(tempErrorArray);
                            }
                            else {
                                recordCount--;
                            }
                        }
                        ++index;

                    }
                }
                if (index < fileFields.length - 1) {
                    // set Timeout for async iteration
                    setFileProcessedPercentage(percentage(index, fileFields.length))
                    setTimeout(doChunk, 1);
                } else {
                    let size = 20000;
                    const chunkArr = chunkArray(fileRecordObjArray, size);
                    let requestSize = 10;
                    let chunkArr1 = chunkArray(chunkArr, requestSize);
                    setFileProcessedPercentage(0)
                    reslove({ errorObj: errorObj, chunkArr: chunkArr1, recordCount: recordCount })
                }
            } catch (error) {
                reject(error)
            }
        }
        if (isSubsetOf(fields, masterFields)) {
            doChunk();
        } else {
            errorObj.validated = false;
            //errorObj.errors.push(`Invalid file format`);
            errorObj.errors.push(formatMessage(messageBundle.validationFileFormatError));
            reslove({ errorObj: errorObj, recordCount: 0 })
        }


    })
}

function chunkArray(arr: any, chunkSize: number) {
    if (chunkSize <= 0) throw "Invalid chunk size";
    const R = [];
    for (let i = 0, len = arr.length; i < len; i += chunkSize)
        R.push(arr.slice(i, i + chunkSize));
    return R;
}

const validateField = (masterField: any, field: any, fieldValue: any, index: number, messageBundle: any, dgidMap: Map<string, number>) => {
    let errorObj: FieldErrorObj = {
        validated: true,
        errors: []
    }
    if (masterField !== undefined) {
        if (masterField.isMandatory) {
            if (fieldValue.trim() === "") {
                errorObj.validated = false;
                //errorObj.errors.push(`Row ${index + 1} - ${fields[i]} field is mandatory`);
                errorObj.errors.push({
                    rowNumber: index + 1,
                    fieldName: masterField.fieldName,
                    errorDescription: messageBundle.validationMandatoryFieldError
                });
            }
        }
        if (masterField.fieldLength !== null) {
            if (!(String(fieldValue).length <= Number(masterField.fieldLength))) {
                errorObj.validated = false;
                //errorObj.errors.push(`Row ${index + 1} - ${fields[i]} length should be less than or equels to ${masterField.fieldLength}`)
                errorObj.errors.push({
                    rowNumber: index + 1,
                    fieldName: masterField.fieldName,
                    errorDescription: formatMessage(messageBundle.validationFieldlengthError,
                        index + 1, field, masterField.fieldLength)
                });
            }
        }
        //check for unique validation, currently this is only for DGID, if needed extend it here for other fields
        if (masterField.fieldName === DGID_FIELD_NAME) {
            if (fieldValue != null && ('' + fieldValue).trim().length > 0) {
                if (dgidMap.has(fieldValue)) {
                    errorObj.validated = false;
                    //errorObj.errors.push(`Unique Validation failed : Row ${index + 1} - ${masterField.fieldName} is same as Row ${dgidMap.get(fieldValue)}`);
                    errorObj.errors.push({
                        rowNumber: index + 1,
                        fieldName: masterField.fieldName,
                        errorDescription: formatMessage(messageBundle.validationUniquenessError,
                            index + 1, masterField.fieldName, dgidMap.get(fieldValue))
                    });
                } else {
                    dgidMap.set(fieldValue, index + 1);
                }
            }
        }
        if (masterField.validationRegex !== null) {
            let re = new RegExp(masterField.validationRegex);
            if (fieldValue && !re.test(fieldValue)) {
                errorObj.validated = false;
                errorObj.errors.push({
                    rowNumber: index + 1,
                    fieldName: masterField.fieldName,
                    errorDescription: `Row ${index + 1} - ${masterField.validationErrorMessage}`
                });
            }
        } else if (masterField.fieldDataType.dataTypeName === 'String') {
            if (typeof fieldValue !== 'string') {
                errorObj.validated = false;
                //errorObj.errors.push(`Row ${index + 1} - ${fields[i]} should be type of string (${fieldValue})`)
                errorObj.errors.push({
                    rowNumber: index + 1,
                    fieldName: masterField.fieldName,
                    errorDescription: formatMessage(messageBundle.validationFieldTypeError,
                        index + 1, field, ' string ', fieldValue)
                });
            }
        } else if (masterField.fieldDataType.dataTypeName === 'Integer') {
            if (Number.isNaN(Number(fieldValue))) {
                errorObj.validated = false;
                //errorObj.errors.push(`Row ${index + 1} - ${fields[i]} should be type of number (${fieldValue})`)
                errorObj.errors.push({
                    rowNumber: index + 1,
                    fieldName: masterField.fieldName,
                    errorDescription: formatMessage(messageBundle.validationFieldTypeError,
                        index + 1, field, ' number ', fieldValue)
                });
            }
        } else if (masterField.additionalPropertyJson && masterField.additionalPropertyJson.validationFunction && fieldValue.length > 0) {
            type StatusKey = keyof typeof validations;
            let functionName: StatusKey = masterField.additionalPropertyJson.validationFunction as keyof typeof validations;
            let response = validations[functionName]({ f_id: masterField.additionalPropertyJson.fieldName, v: fieldValue, error: formatMessage(messageBundle.incorrectDateFormat, [masterField.additionalPropertyJson.format]) });
            if (!response.isValid) {
                errorObj.validated = false;
                errorObj.errors.push({
                    rowNumber: index + 1,
                    fieldName: masterField.fieldName,
                    errorDescription: `Row ${index + 1} - ${response.message}`
                });
            }
        }
    }
    return errorObj;
}

export const validateLineEnding = (campaignsData: any, separator: number) => {
    const recordSeparator: string = separator === 1 ? dfbLineEndings[0] : dfbLineEndings[1];
    const lineEnding: number = campaignsData.indexOf('\n');
    if ( campaignsData[lineEnding - 1] === '\r' && recordSeparator === dfbLineEndings[0]) {
        return true;
    } else if (campaignsData[lineEnding - 1] !== '\r' && recordSeparator === dfbLineEndings[1]) {
        return true;
    } else {
        return false;
    }
}