import { frcJsonAttributes, errorObjectAttributes } from '../../components/functional/fileTransferConfiguration/fileRetrievalConfigurationJson';

export function filePasswordValidation(frcDataNeedsToBeSend: frcJsonAttributes,
    errorObject: errorObjectAttributes, messages: any) {
    return (): boolean => {
        if (frcDataNeedsToBeSend.sourceFilePassword != null && frcDataNeedsToBeSend.sourceFilePassword.trim() !== '' && frcDataNeedsToBeSend.sourceFilePassword.trim().length > 20) {
            errorObject.sourceFilePassword = messages.exceededFieldLength.concat('20');
            return false;
        } else if (frcDataNeedsToBeSend.sourceFilePassword != null && frcDataNeedsToBeSend.sourceFilePassword.trim().length > 1 && frcDataNeedsToBeSend.sourceFilePassword.trim().indexOf(' ') !== -1) {
            errorObject.sourceFilePassword = messages.invalidFilePassword;
            return false;
        } else {
            errorObject.sourceFilePassword = '';
            return true;
        }
    }
}