import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { RootState } from '../../../reducers/rootReducer';
import { withAppShell } from '../../hoc/app_shell/withAppShell';
import MasterSlaveContainer from '../../ui/MasterSlaveContainer';
import TabTitleComponent from '../../ui/TabTitleComponent';
import { ReportDetailsComponent } from './ReportDetailsComponent';
import { ReportListComponent } from './ReportListComponent'

const ReportsComponent: React.FC<Props> = (props) => {
  sessionStorage.setItem('navIndex', '3');
  const [component, setComponent] = React.useState('campaign');

  useEffect(() => {
  }, [component])

  const setComponentName = (reportComponentName: string) => {
    setComponent(reportComponentName);
  }
  return (
    <React.Fragment>
      <TabTitleComponent tabTitle={props.messages.Reports}></TabTitleComponent>
      <MasterSlaveContainer
        listComponent={
          <ReportListComponent handleComponent={setComponentName} ></ReportListComponent>
        }
        detailsComponent={
          <ReportDetailsComponent componentName={component}></ReportDetailsComponent>
        }
      />
    </React.Fragment>
  )
}

const mapStateToProps = (state: RootState) => ({
  messages: state.messageBundle.messages
})
const mapDispatchToProps = (dispatch: Dispatch) => { return bindActionCreators({}, dispatch) };
type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;
export const ReportComponent = withAppShell(connect(mapStateToProps, mapDispatchToProps)(ReportsComponent), { enableNav: true })