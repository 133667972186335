import { toast } from "react-toastify";
import { Typography } from "@material-ui/core";
import React from "react";
import { TOAST_TYPE } from "../../utils/dfbConstants";

const toastMessage = (toastType: string, message: string) => {
  if (toastType === TOAST_TYPE.SUCCESS) {
    toast(<Typography style={{ color: "black", opacity: 0.7 }}>{message}</Typography>);
  } else {
    toast.error(<Typography style={{ color: "white" }}>{message}</Typography>);
  }
}

export default toastMessage;



