import { frcJsonAttributes, errorObjectAttributes } from '../../components/functional/fileTransferConfiguration/fileRetrievalConfigurationJson';
import { authTypes, AWS_S3 } from '../dfbConstants';

export function validateAutherizationUserName(frcDataNeedsToBeSend: frcJsonAttributes,
  errorObject: errorObjectAttributes, messages: any) {
  return (): boolean => {
    if (!frcDataNeedsToBeSend.authUserName || frcDataNeedsToBeSend.authUserName.trim().length < 1) {
      errorObject.authUserName = messages.FieldRequired;
    } else if (frcDataNeedsToBeSend.transferType === AWS_S3 && frcDataNeedsToBeSend.authenticationType === authTypes.AUTH_PASSWORD) {
      if (frcDataNeedsToBeSend.authUserName !== undefined) {
        const accessKeyRegExString = "^(?<![A-Z0-9])[A-Z0-9]{20}(?![A-Z0-9])$";
        const accessKeyRegEx = new RegExp(accessKeyRegExString);
        const match = accessKeyRegEx.exec(frcDataNeedsToBeSend.authUserName.trim());
        if (!match || frcDataNeedsToBeSend.authUserName.trim().length !== 20) {
          errorObject.authUserName = messages.invalidAccessKeyFormat;
          return false;
        } else {
          errorObject.authUserName = "";
          return true;
        }
      }
    } else {
      errorObject.authUserName = "";
      return true;
    }
    return false;
  };
}
