import { AnyAction } from "redux"
import { actionType } from "../actions/actionTypes";

type dfbBusinessObjectReducerState = {
    dfbBusinessObjectListFetchingInProgress: boolean,
    dfbBusinessObjectListFetchingSuccess: boolean,
    dfbBusinessObjectListFetchingFailure: boolean,
    dfbBusinessObjects: Array<any> | null,
    dfbBusinessObjectFetchingInProgress: boolean,
    dfbBusinessObjectFetchingSuccess: boolean,
    dfbBusinessObjectFetchingFailure: boolean,
    dfbBusinessObject: any,
    dfbBusinessObjectMetadata: any,
    dfbBusinessObjectDeleteInProgress: boolean,
    dfbBusinessObjectDeleteSuccess: boolean,
    dfbBusinessObjectDeleteFailure: boolean,
    fileProcessedPercentage: number,
    objectId: number | null,
    saveDFBBusinessObjectDataInProgress: boolean,
    saveDFBBusinessObjectDataSuccess: boolean,
    saveDFBBusinessObjectDataFailure: boolean,
    saveDFBBusinessObjectDataFailureMessage: string
}
const getInitialDFBBusinessObjectMetadata = (): Array<any> | null => {
    let dfbBusinessObjectMetadata = sessionStorage.getItem('dfbBusinessObjectMetadata')
    return dfbBusinessObjectMetadata ? JSON.parse(dfbBusinessObjectMetadata) : null
}
const initialState: dfbBusinessObjectReducerState = {
    dfbBusinessObjectListFetchingInProgress: false,
    dfbBusinessObjectListFetchingSuccess: false,
    dfbBusinessObjectListFetchingFailure: false,
    dfbBusinessObjects: null,
    dfbBusinessObjectFetchingInProgress: false,
    dfbBusinessObjectFetchingSuccess: false,
    dfbBusinessObjectFetchingFailure: false,
    dfbBusinessObject: undefined,
    dfbBusinessObjectMetadata: getInitialDFBBusinessObjectMetadata(),
    dfbBusinessObjectDeleteInProgress: false,
    dfbBusinessObjectDeleteSuccess: false,
    dfbBusinessObjectDeleteFailure: false,
    fileProcessedPercentage: 0,
    objectId: null,
    saveDFBBusinessObjectDataInProgress: false,
    saveDFBBusinessObjectDataSuccess: false,
    saveDFBBusinessObjectDataFailure: false,
    saveDFBBusinessObjectDataFailureMessage: '',
}

export function dfbBusinessObjectReducer(state = initialState, action: AnyAction): dfbBusinessObjectReducerState {
    switch (action.type) {
        case actionType.FETCHING_DFB_BUSINESS_OBJECT_LIST_IN_PROGRESS:
            return { ...state, dfbBusinessObjectListFetchingInProgress: true, dfbBusinessObjectListFetchingSuccess: false, dfbBusinessObjectListFetchingFailure: false };

        case actionType.FETCHING_DFB_BUSINESS_OBJECT_LIST_SUCCESS:
            return { ...state, dfbBusinessObjectListFetchingInProgress: false, dfbBusinessObjectListFetchingSuccess: true, dfbBusinessObjectListFetchingFailure: false, dfbBusinessObjects : action.payload };

        case actionType.FETCHING_DFB_BUSINESS_OBJECT_LIST_FAILURE:
            return { ...state, dfbBusinessObjectListFetchingInProgress: true, dfbBusinessObjectListFetchingSuccess: false, dfbBusinessObjectListFetchingFailure: false };

        case actionType.FETCHING_DFB_BUSINESS_OBJECT_IN_PROGRESS:
            return { ...state, dfbBusinessObjectFetchingInProgress: true, dfbBusinessObjectFetchingSuccess: false, dfbBusinessObjectFetchingFailure: false };

        case actionType.FETCHING_DFB_BUSINESS_OBJECT_SUCCESS:
            return { ...state, dfbBusinessObjectFetchingInProgress: false, dfbBusinessObjectFetchingSuccess: true, dfbBusinessObjectFetchingFailure: false, dfbBusinessObject: action.payload && action.payload.dfbBusinessObject ? action.payload.dfbBusinessObject : undefined, dfbBusinessObjectMetadata: action.payload && action.payload.metadata ? action.payload.metadata : undefined };

        case actionType.FETCHING_DFB_BUSINESS_OBJECT_FAILURE:
            return { ...state, dfbBusinessObjectFetchingInProgress: false, dfbBusinessObjectFetchingSuccess: false, dfbBusinessObjectFetchingFailure: true };

        case actionType.DELETE_DFB_BUSINESS_OBJECT_IN_PROGRESS:
            return { ...state, dfbBusinessObjectDeleteInProgress: true, dfbBusinessObjectDeleteSuccess: false, dfbBusinessObjectDeleteFailure: false };

        case actionType.DELETE_DFB_BUSINESS_OBJECT_SUCCESS:
            return { ...state, dfbBusinessObjectDeleteInProgress: false, dfbBusinessObjectDeleteSuccess: true, dfbBusinessObjectDeleteFailure: false };

        case actionType.DELETE_DFB_BUSINESS_OBJECT_FAILURE:
            return { ...state, dfbBusinessObjectDeleteInProgress: false, dfbBusinessObjectDeleteSuccess: false, dfbBusinessObjectDeleteFailure: true };
       
         case actionType.SET_FILE_PROCESSED_PERCENTAGE:
            return { ...state, fileProcessedPercentage: action.percentage };
        
        case actionType.SET_CAMPAIGN_ID:
            return { ...state, objectId: action.campaignId };

        case actionType.SAVE_DFB_BUSINESS_OBJECT_DATA_IN_PROGRESS:
             return { ...state, saveDFBBusinessObjectDataInProgress: true, saveDFBBusinessObjectDataFailure: false }
            
        case actionType.SAVE_DFB_BUSINESS_OBJECT_DATA_SUCCESS:
            return { ...state, saveDFBBusinessObjectDataInProgress: false, saveDFBBusinessObjectDataSuccess: true, saveDFBBusinessObjectDataFailure: false }
            
        case actionType.SAVE_DFB_BUSINESS_OBJECT_DATA_FAILURE:
            return { ...state, saveDFBBusinessObjectDataInProgress: false, saveDFBBusinessObjectDataFailure: true, saveDFBBusinessObjectDataFailureMessage: action.payload.errorMessage }
            
        case actionType.SAVE_DFB_BUSINESS_OBJECT_DATA_INITIAL_STATE:
            return { ...state, saveDFBBusinessObjectDataInProgress: false, saveDFBBusinessObjectDataSuccess: false, saveDFBBusinessObjectDataFailure: false }
        
        default:
            return state;
    }
}