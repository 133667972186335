import { Checkbox, FormControlLabel, Grid, GridSize, Typography } from "@material-ui/core";
import React from "react";

interface CustomCheckBoxProps {
    gridSize:GridSize,
    testId:string,
    label:string,
    value:string,
    name:any,
    isBoxChecked:boolean,
    index:number,
    onChange(event:React.ChangeEvent<HTMLInputElement>,index:number):void;
}

const CustomCheckBoxComponent: React.FC<CustomCheckBoxProps> = ({ gridSize,testId,label,value,name,isBoxChecked,index,onChange }) => {

    return (
        <Grid item container xs={gridSize} >
        <FormControlLabel
            data-testId={testId}
            label={<Typography variant='body1'>{label}</Typography>}
            value={value}
            name={JSON.stringify(name)}
            control={
                <Checkbox
                    style={{padding:'4px'}}
                    checked={isBoxChecked}
                    onChange={(event:any)=>{
                        onChange(event,index)
                    }}
                />
            }
        />
    </Grid>

    )
}


export default CustomCheckBoxComponent;