import { frcJsonAttributes, errorObjectAttributes } from '../../components/functional/fileTransferConfiguration/fileRetrievalConfigurationJson';

export function retryIntervalValidation(frcDataNeedsToBeSend: frcJsonAttributes,
    errorObject: errorObjectAttributes, messages: any) {
        return (): boolean => {
            const parsedRetryInterval = parseInt(String(frcDataNeedsToBeSend.retryInterval).trim());
            const RetryIntervalString = String(parsedRetryInterval);
            if (!frcDataNeedsToBeSend.retryInterval || frcDataNeedsToBeSend.retryInterval === "" || String(frcDataNeedsToBeSend.retryInterval).trim().length < 1) {
                errorObject.retryInterval = messages.FieldRequired;
                return false;
            } else if (String(frcDataNeedsToBeSend.retryInterval).trim() !== '0' && (RetryIntervalString === 'NaN' || !Number(frcDataNeedsToBeSend.retryInterval))) {
                errorObject.retryInterval = messages.numbersOnlyAllowed;
                return false;
            } else if (parsedRetryInterval > 90 || parsedRetryInterval < 5) {
                errorObject.retryInterval = messages.invalidRetryInterval;
                return false;
            } else {
                errorObject.retryInterval = '';
                return true;
            }
        }
}