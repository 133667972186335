import { Grid, makeStyles, Radio, RadioProps, TextField, Typography, withStyles } from '@material-ui/core';
import React, { useEffect } from 'react';
import theme from '../../assets/theme';


interface TabTitleComponentProps {
    tabTitle: string
}

const TabTitleComponent: React.FC<TabTitleComponentProps> = ({ tabTitle }) => {
    return (
        <Grid item container justify='flex-start' style={componentStyle.wrapperContainer}>
            <Typography variant='h5' color='primary' style={{ fontWeight: 'bold' }}>
                {tabTitle}
            </Typography>
        </Grid>

    )
}

const componentStyle = {
    wrapperContainer: {
        padding: theme.spacing(1, 0, 0, 0),
        flexGrow: 1,
        background: "#FAFDFF"
    }
}

export default TabTitleComponent;