import React from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../../reducers/rootReducer';
import { bindActionCreators, Dispatch } from 'redux';
import { Grid, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, makeStyles, Typography } from '@material-ui/core';
import theme, { colorPalette } from '../../../assets/theme';

interface ChartReportContainerDialogDialogProps {
    openDialog: boolean,
    handleCloseDialog(): void,
    chartReportHeader: React.ReactNode | React.ComponentType | Element,
    chartReportBody: React.ReactNode | React.ComponentType | Element,
    reportFetchingInProgress: boolean
}
const UnconnectedChartReportDialogContainer: React.FC<Props & ChartReportContainerDialogDialogProps | any> = ({ openDialog, handleCloseDialog, chartReportHeader, chartReportBody, reportFetchingInProgress }) => {

    const classes = useStyles();
    const closeReportDialog = () => {
        handleCloseDialog();
    }

    return (
        <div >
            <Dialog
                open={openDialog}
                keepMounted
                classes={{ paper: classes.dialogContainer }}
                aria-labelledby="report-title"
                aria-describedby="report-description"
            >
                <div className={classes.topStripBlue} />
                <DialogTitle>
                    <Grid data-testId='chart-report-header' container direction='row' md={12} justify='space-between'>
                        {chartReportHeader}
                    </Grid>
                </DialogTitle>
                <DialogContent>
                    {reportFetchingInProgress ? <Grid data-testId="chart-body" container direction='column' justify='center' alignContent='center' xs={12} style={{ height: '100%', paddingTop: 90 }}>
                        <Typography variant='body2'>
                            <CircularProgress />
                        </Typography>
                    </Grid> : chartReportBody}
                </DialogContent>
                <DialogActions style={{ margin: theme.spacing(0, 10.4, 2, 0) }}>
                    <React.Fragment>
                        <Button data-testid='report-cancel-button' variant='contained' color='secondary' onClick={closeReportDialog}>
                            {'Cancel'}
                        </Button>
                    </React.Fragment>
                </DialogActions>
            </Dialog>
        </div>
    );
}
const useStyles = makeStyles((theme) => ({
    dialogContainer: {
         minWidth: '1100px',
         minHeight: '400px'
    },
    topStripBlue: {
        background: colorPalette.primaryShade,
        height: '10px'
    },
}));

const mapStateToProps = (state: RootState) => ({
    user: state.user.user,
    messages: state.messageBundle.messages,
})
const mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators({
    }, dispatch)
};

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;
export const ChartReportDialogContainer = connect(mapStateToProps, mapDispatchToProps)(UnconnectedChartReportDialogContainer);