import { HTTP } from "../rest";
import { apiRoutes } from "../rest/APIRoutes";
import { actionType } from "./actionTypes";
import { store } from '../reducers';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import { ARCHIVED_STATUS_CODE, campaignFilterStatuses, formatTypes, sessionStorageLabels } from "../utils/dfbConstants";
import { decompress } from "compress-json";

export const fetchDFBBusinessObjectList = (queryString: string = '') => {
    return async (dispatch: (action: any) => void) => {
        try {
            dispatch(setDFBBusinessObjectListToState({ type: actionType.FETCHING_DFB_BUSINESS_OBJECT_LIST_IN_PROGRESS, payload: null }));
            let url = `${apiRoutes.campaignsURL}?${queryString}`;
            console.log("going to fetch campaign ....", url);
            if (sessionStorage.getItem('activeFormatType') == formatTypes.CAMPAIGN_TYPE && sessionStorage.getItem(sessionStorageLabels.FILTERED_CAMPAIGN_STATUS) != null && sessionStorage.getItem(sessionStorageLabels.FILTERED_CAMPAIGN_STATUS) == campaignFilterStatuses.ARCHIVED_STATUS) {
                url = `${url}&statusCode=${ARCHIVED_STATUS_CODE}`;
            }
            let result = await HTTP.get(url);
            let data = decompress(result.data);
            if (data !== undefined &&
                data.length > 0 &&
                data[0].campaignFormat.formatType.formatTypeAbbreviation === sessionStorage.getItem('activeFormatType')) {
                dispatch(setDFBBusinessObjectListToState({ type: actionType.FETCHING_DFB_BUSINESS_OBJECT_LIST_SUCCESS, payload: data }));
                return data;
            }
            else {
                if (data.length === 0) {
                    dispatch(setDFBBusinessObjectListToState({ type: actionType.FETCHING_DFB_BUSINESS_OBJECT_LIST_SUCCESS, payload: data }));
                    return data;
                }
            }
        } catch (error) {
            dispatch(setDFBBusinessObjectListToState({ type: actionType.FETCHING_DFB_BUSINESS_OBJECT_LIST_FAILURE, payload: null }));
            throw error;
        }
    }
}
const setDFBBusinessObjectListToState = ({ type, payload }: { type: string, payload: Array<any> | null }) => {
    return { type: type, payload: payload };
}
export const fetchDFBBusinessObjectWithData = (queryString: string = '', objectId: number) => {
    return async (dispatch: (action: any) => void) => {
        try {
            sessionStorage.setItem('activeObjectId', String(objectId));
            dispatch(setDFBBusinessObjectListToState({ type: actionType.FETCHING_DFB_BUSINESS_OBJECT_IN_PROGRESS, payload: null }));
            let url = `${apiRoutes.campaignsURL}?${queryString}`
            let result = await HTTP.get(url);
            if (result.data.dfbBusinessObject !== undefined &&
                result.data.dfbBusinessObject.campaignFormat.formatType.formatTypeAbbreviation === sessionStorage.getItem('activeFormatType') &&
                result.data.dfbBusinessObject.objectId === Number(sessionStorage.getItem('activeObjectId'))) {
                dispatch(setDFBBusinessObjectListToState({ type: actionType.FETCHING_DFB_BUSINESS_OBJECT_SUCCESS, payload: result.data }));
            }
        } catch (error) {
            dispatch(setDFBBusinessObjectToState({ type: actionType.FETCHING_DFB_BUSINESS_OBJECT_FAILURE, payload: null }));
            throw error;
        }
    }
}
const setDFBBusinessObjectToState = ({ type, payload }: { type: string, payload: any }) => {
    return { type: type, payload: payload };
}
export const deleteDFBBusinessObject = (queryString: string, dfbBusinessObject: any): any => {
    return async (dispatch: (action: any) => void) => {
        try {
            console.log('delete url', queryString);
            dispatch(setDFBBusinessObjectListToState({ type: actionType.DELETE_DFB_BUSINESS_OBJECT_IN_PROGRESS, payload: null }));
            let updateURL = `${apiRoutes.campaignsURL}?action=updateStatus&status=DIP&objectType=${dfbBusinessObject.campaignFormat.formatType.formatTypeAbbreviation}`;
            let response = await HTTP.put(updateURL, { object: dfbBusinessObject }, {});
            if (response.status === 200) {
                let url = `${apiRoutes.campaignsURL}?${queryString}`;
                HTTP.deleteAPI(url);
                sessionStorage.removeItem('objectId');
                dispatch(setDFBBusinessObjectListToState({ type: actionType.DELETE_DFB_BUSINESS_OBJECT_SUCCESS, payload: null }));
                return true;
            }
            return false;
        } catch (error) {
            dispatch(setDFBBusinessObjectToState({ type: actionType.DELETE_DFB_BUSINESS_OBJECT_FAILURE, payload: null }));
            return false;
        }
    }
}
export const deleteOutputFile = (queryString: string): any => {
    return async (dispatch: (action: any) => void) => {
        try {
            let url = `${apiRoutes.campaignsURL}?${queryString}`;
            let response = await HTTP.deleteAPI(url);
            if (response.status === 200) {
                dispatch(setDFBBusinessObjectListToState({ type: actionType.FETCHING_DFB_BUSINESS_OBJECT_SUCCESS, payload: response.data }));
                return true;
            }
            return false;
        } catch (error) {
            return false;
        }
    }
}
export const setDefaultDFBBusinessObject = () => {
    return async (dispatch: (action: any) => void) => {
        try {
            dispatch(setDFBBusinessObjectListToState({ type: actionType.FETCHING_DFB_BUSINESS_OBJECT_IN_PROGRESS, payload: null }));
            dispatch(setDFBBusinessObjectListToState({ type: actionType.FETCHING_DFB_BUSINESS_OBJECT_SUCCESS, payload: null }));
        } catch (error) {
            dispatch(setDFBBusinessObjectToState({ type: actionType.FETCHING_DFB_BUSINESS_OBJECT_FAILURE, payload: null }));
            throw error;
        }
    }
}

const setDFBBusinessObjectDataToState = ({ type, payload }: { type: string, payload: any | null }) => {
    return { type: type, payload: payload }
}
export const saveDFBBusinessObjectDataRequests = (dataNeedsToBeSend: any) => {
    return async (dispatch: (action: any) => void) => {
        try {
            let url = '';
            let t0 = performance.now()
            dispatch(setDFBBusinessObjectDataToState({ type: actionType.SAVE_DFB_BUSINESS_OBJECT_DATA_IN_PROGRESS, payload: null }));
            const token = localStorage.getItem('id_token');
            const config = {
                headers: {
                    Authorization: token,
                    'content-type': 'application/json'
                }
            }
            if (dataNeedsToBeSend.dfbBusinessObject.objectId !== undefined) {
                url = `${apiRoutes.campaignsURL}?action=signedURL&objectType=${dataNeedsToBeSend.objectType}&objectId=${dataNeedsToBeSend.dfbBusinessObject.objectId}`
            }
            else {
                url = `${apiRoutes.campaignsURL}?action=signedURL&objectType=${dataNeedsToBeSend.objectType}`
            }
            let result = await axios.post(url, { "objectKey": uuidv4() }, config);
            if (result.data.signedUrl) {
                console.log(result.data.signedUrl);
                const configS3 = {
                    headers: {
                        'content-type': 'application/json'
                    }
                }
                const response = await axios.put(result.data.signedUrl, dataNeedsToBeSend, configS3);
                console.log(response)
                dispatch(setDFBBusinessObjectDataToState({ type: actionType.SAVE_DFB_BUSINESS_OBJECT_DATA_SUCCESS, payload: null }));
                var t1 = performance.now()
                console.log("save campaign took " + (t1 - t0) + " milliseconds.")
                return { status: true };
            }
            else {
                dispatch(setDFBBusinessObjectDataToState({ type: actionType.SAVE_DFB_BUSINESS_OBJECT_DATA_FAILURE, payload: { errorMessage: result.data.message } }))
                if (result.data.errorCode === 'FileUploadFailed') {
                    return { status: false, message: result.data.message };
                }
            }
        } catch (error) {
            console.log(error)
            dispatch(setDFBBusinessObjectDataToState({ type: actionType.SAVE_DFB_BUSINESS_OBJECT_DATA_FAILURE, payload: { errorMessage: store.getState().messageBundle.messages.saveDFBBusinessObjectDataFailureMessage } }))
            return false;
        }
    };
};
const setFileProcessPercentage = ({ type, percentage }: { type: string, percentage: any | null }) => {
    return { type: type, percentage: percentage }
}
export const setFileProcessedPercentage = (percentage: any) => {
    return async (dispatch: (action: any) => void) => {
        dispatch(setFileProcessPercentage({ type: actionType.SET_FILE_PROCESSED_PERCENTAGE, percentage: percentage }));
    };
};
export const setSaveDFBBusinessObjectInitialState = () => {
    return async (dispatch: (action: any) => void) => {
        dispatch({ type: actionType.SAVE_DFB_BUSINESS_OBJECT_DATA_INITIAL_STATE });
    };
};
export let runLogHeaders = [
    {
        id: 'fileName',
        label: 'File Name',
        disableSort: true,
    },
    {
        id: 'recordCount',
        label: 'Record Count',
        disableSort: true
    },
    {
        id: 'logDate',
        label: 'Date Generated'
    },
    {
        id: 'action',
        label: 'Action',
        disableSort: true,
    },
]
export const IMMUTABLE_RUN_LOG_HEADERS = [
    {
        id: 'fileName',
        label: 'File Name',
        disableSort: true,
    },
    {
        id: 'recordCount',
        label: 'Record Count',
        disableSort: true
    },
    {
        id: 'logDate',
        label: 'Date Generated'
    },
    {
        id: 'action',
        label: 'Action',
        disableSort: true,
    },
]
export const getDFBBusinessObjectDetailsByBillCode = async (queryString: string) => {
    try {
        let url = `${apiRoutes.campaignsURL}?${queryString}`; //getBy=billCode&billCode=${billCode}
        let response = await HTTP.get(url);
        if (response.data !== null) {
            return response.data.campaign;
        } else {
            return null
        }

    } catch (error) {
        console.log(error)
        throw error;
    }
}
export const targetListsDisplayHeaders = [
    {
        id: 'fileName',
        label: 'File Name',
        disableSort: true,
    },
    {
        id: 'recordCount',
        label: 'Record Count'
    },
    {
        id: 'recordsInserted',
        label: 'Records Inserted'
    },
    {
        id: 'recordsUpdated',
        label: 'Records Updated'
    },
    {
        id: 'createdBy',
        label: 'Uploaded By',
        disableSort: true,
    },
    {
        id: 'createdDate',
        label: 'Upload Date'
    },
]
export const updateMetadata = async (queryString: any, body: any) => {
    try {
        let url = `${apiRoutes.campaignsURL}?${queryString}`;
        let response = await HTTP.put(url, body, {});
        if (response.data !== null) {
            return response.data;
        } else {
            return null
        }
    } catch (error) {
        console.log(error)
        return null;
    }
}
export const getTypeOfAction = (dataObject: any) => {
    return dataObject && dataObject.objectType === formatTypes.LICENSE_TYPE ? 'saveDFBBusinessObject' : 'saveCampaign';
}
export const saveDFBBusinessObject = (dataNeedsToBeSend: any) => {
    return async (dispatch: (action: any) => void) => {
        try {
            let url = `${apiRoutes.campaignsURL}?action=${getTypeOfAction(dataNeedsToBeSend)}&objectType=${dataNeedsToBeSend.objectType}`
            console.log("URL : ", url);
            dispatch(setDFBBusinessObjectDataToState({ type: actionType.SAVE_DFB_BUSINESS_OBJECT_DATA_IN_PROGRESS, payload: null }));
            let response = await HTTP.post(url, dataNeedsToBeSend, {});
            console.log("Response : ", response);
            dispatch(setDFBBusinessObjectDataToState({ type: actionType.SAVE_DFB_BUSINESS_OBJECT_DATA_SUCCESS, payload: null }));
            if (response.status === 200) {
                if (response.data.errorCode === 'duplicateBillcode') {
                    return { status: false, errorCode: response.data.errorCode };
                } else if (response.data.errorCode === 'salesForceError') {
                    return { status: false, errorCode: response.data.errorCode };
                } else if (response.data.errorCode === 'FileUploadFailed') {
                    return { status: false, errorCode: response.data.errorCode };
                }
                return { status: true };
            }
            else {
                return { status: false };
            }
        } catch (error) {
            console.log(error)
            dispatch(setDFBBusinessObjectDataToState({ type: actionType.SAVE_DFB_BUSINESS_OBJECT_DATA_FAILURE, payload: { errorMessage: store.getState().messageBundle.messages.saveDFBBusinessObjectDataFailureMessage } }))
            return { status: false, error: error };
        }
    }
}