import React from 'react';
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, makeStyles, Typography, TextField } from '@material-ui/core';
import { colorPalette } from '../../../assets/theme';
import messageBundle from '../../../assets/resource_bundle/messages.json';
import { formatMessage } from '../../../utils/messagesUtils';
interface DialogProps {
    handleClose(): void;
    open: boolean;
    onPrimaryButtonClick(changeComments: string): void;
    primaryButtonLoader: boolean;
    outPutFilesToBeDeleted: any;
    isEditConfirmDialogue:boolean;
}
const DeleteOutputFileAlertDialogContainer: React.FC<DialogProps> = ({ open, handleClose, onPrimaryButtonClick, primaryButtonLoader, outPutFilesToBeDeleted, isEditConfirmDialogue }) => {
    const classes = useStyles();
    const [changeComments, setChangeComments] = React.useState('');
    const [changeCommentsError, setChangeCommentsError] = React.useState('');
    const handleChangeOfComments = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setChangeComments(event.target.value);
        setChangeCommentsError('');
    }
    const handleOutputFileDelete = async () => {
        if (changeComments.trim().length > 0) {
            if (changeComments.trim().length <= 1000) {
                if(!isEditConfirmDialogue){
                await onPrimaryButtonClick(changeComments.trim());
                }else{
                    onPrimaryButtonClick(changeComments.trim());
                }
                setChangeComments('');
                setChangeCommentsError('');
            } else {
                let error = formatMessage(messageBundle.validationFieldlengthError,
                    1, '', 1000)
                setChangeCommentsError(error)
            }

        } else {
            setChangeCommentsError(messageBundle.FieldRequired)
        }
    }
    const onClickOfCancel = () => {
        setChangeComments('');
        setChangeCommentsError('');
        handleClose();
    }
    const getMessageWithFileNames = () => {
        if (outPutFilesToBeDeleted && outPutFilesToBeDeleted.length > 1) {
            let fileNameArray: Array<any> = [];
            for (let i = 0; i < outPutFilesToBeDeleted.length; i++) {
                if (i === outPutFilesToBeDeleted.length - 1) {
                    fileNameArray.push(<Typography variant='body2' >
                        {outPutFilesToBeDeleted[i].fileName} files will be deleted.
                    </Typography>)
                } else {
                    fileNameArray.push(<Typography variant='body2' >
                        {outPutFilesToBeDeleted[i].fileName},
                    </Typography>)
                }
            }
            return fileNameArray
        } else {
            if (outPutFilesToBeDeleted[0] !== undefined) {
                return <Typography variant='body2' >
                    {outPutFilesToBeDeleted[0].fileName} file will be deleted.
                </Typography>

            }
        }
    }
    const getSubmitOrDeleteButtons =()=>{
        if(isEditConfirmDialogue){
            return messageBundle.clickYes;
        }else{
            return messageBundle.delete;
        }
    }
    const getHeaderName =()=>{
        if(isEditConfirmDialogue){
            return <div style={{fontWeight:'bold'}}>{messageBundle.editCampaignMetaDataConfirm}</div>
        
        }else{
            return `${messageBundle.delete + " " + messageBundle.outputFile}`;
        }
    }
    return (
        <div>
            <Dialog
                open={open}
                onClose={onClickOfCancel}
                aria-labelledby="form-dialog-title"
                classes={{ paper: classes.dialogContainer }}
                disableBackdropClick>
                <div className={classes.topStripBlue} />
                <DialogTitle id="form-dialog-title" >{getHeaderName()}</DialogTitle>
                <DialogContent>

                   {!isEditConfirmDialogue? <Typography variant={'body1'}>
                        {getMessageWithFileNames()}
                    </Typography>:<Typography variant={'h6'}>{messageBundle.continueMessage}</Typography>}

                    <TextField
                        autoFocus
                        margin="dense"
                        data-testid="comments"
                        placeholder={isEditConfirmDialogue?messageBundle.commentSectionMessage: messageBundle.changeCommentsPlaceholder}
                        fullWidth
                        multiline
                        variant='outlined'
                        required
                        value={changeComments}
                        onChange={handleChangeOfComments}
                        rows={4}
                    />
                    {changeCommentsError.length > 0 ?
                        <Typography variant='caption' color='error'>
                            {changeCommentsError}
                        </Typography>
                        : null}
                </DialogContent>
                <DialogActions>
                    {primaryButtonLoader ?
                        <CircularProgress color='secondary' size={24} /> : <React.Fragment>
                            <Button onClick={onClickOfCancel} variant='contained' data-testid='cancel-button'>
                                {messageBundle.cancel}
                            </Button>
                            <Button onClick={handleOutputFileDelete} variant='contained' data-testid='confirm-button' color='secondary'>
                            {getSubmitOrDeleteButtons()}
                            </Button>
                        </React.Fragment>}
                </DialogActions>
            </Dialog>
        </div>
    );
}

export const useStyles = makeStyles((theme) => ({
    dialogContainer: {
        minWidth: '500px'
    },
    topStripBlue: {
        background: colorPalette.primaryShade,
        height: '10px'
    },
    headerName:{
        fontWeight:'bold',
    },
    headerFont:{
        fontSize:'22px'
    }
}));
export default DeleteOutputFileAlertDialogContainer;