import { Box, Button, CircularProgress, ClickAwayListener, createStyles, Divider, Grid, IconButton, InputAdornment, List, ListItem, ListItemIcon, ListItemText, MenuItem, Modal, Paper, Popper, TablePagination, TextField, Typography, withStyles } from "@material-ui/core";
import { ArrowDownward, ArrowUpward, Close, Group, Person, Search, Sort, SwapVert } from "@material-ui/icons";
import { Autocomplete, ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import React from "react";
import { ReactComponent as FilterSvg } from "../../assets/icons/Filter.svg";
import messages from "../../assets/resource_bundle/messages.json";
import theme, { colorPalette } from "../../assets/theme";
import FilterCheckBox from "./FilterCheckBox";
import "./ListPageWrapperStyle.css";
import PageWrapper from "./PageWrapper";
import { clearPaginationSessions, clearSortAndSearchSessions, getLastAction, resetListViewPagination } from "../../utils/paginationUtils";
import { ACTION_TYPE } from "../../utils/dfbConstants";

interface ListPageProps {
  data: Array<any>;
  rowsRenderer: (row: any) => JSX.Element;
  searchLabel: string;
  classes?: any;
  handleSearch: (searchText: string) => void;
  sortOptions?: Array<{ sortLabel?: string; sortOrder?:number; sortField?: string }>;
  handleSort?: (sortOptions: any) => void;
  handleListItemClick?: (listObject: any) => void;
  pagination?: boolean;
  listLoader: boolean;
  selectedItemFieldName: string;
  selectedItemFieldValue: any;
  refreshList?: boolean;
  revertListConfig?: boolean;
  setChipsData?: any;
  selectedUser?: string;
  selectedClients?: Array<any>;
  definedFilterClients?: Array<any>;
  getFilteredData?: (data: Array<any>) => void;
  dfbBusinessObjects?: any;
  definedFiltersUsers?: any;
  showFilter?: boolean;
  formatType?: string;
  searchCriteria: string;
  setSearchCriteria: (searchCriteria: string) => void;
  setRevertListConfig?: (revertListConfig: boolean) => void;
  handleCampaignsRadioFilter: () => void;
  detailsLoader: boolean;
  showLoader: boolean;
  isArchiveFilterSelected?: boolean;
  isActiveFilterSelected?: boolean;
  history: any;
  defaultSelect:() =>void
  getSortOption:()=>void
}
type ListPageState = {
  page: number;
  rowsPerPage: number;
  rows: Array<JSX.Element>;
  refresh: boolean;
  anchorEl: null | HTMLElement;
  sortOptions?: Array<{ sortLabel?: string; sortOrder?: number; sortField?: string }>;
  selectedSortOption: number | null;
  filterPopper: null | HTMLElement;
  filterByEntity: null | string;
  selectedClients?: Array<any>;
  tempSelectedUser: string;
  userOptions?: Array<any>;
};

class ListComponent extends React.Component<ListPageProps, ListPageState> {
  constructor(props: any) {
    super(props);
    this.state = {
      page: 0,
      rowsPerPage: 10,
      rows: [],
      refresh: false,
      anchorEl: null,
      sortOptions: this.props.sortOptions,
      selectedSortOption: null,
      filterPopper: null,
      filterByEntity: "client",
      selectedClients: [],
      tempSelectedUser: "",
      userOptions: [],
    };
  }

  generateDefaultSortOptions = () => {
    if (this.props.sortOptions) {
      let tempSortArray = [];
      for (let i = 0; i < this.props.sortOptions.length; i++) {
        let sortObj: any = {
          sortLabel: this.props.sortOptions[i].sortLabel,
          sortOrder: this.props.sortOptions[i].sortOrder?this.props.sortOptions[i].sortOrder:0,
          sortField: this.props.sortOptions[i].sortField,
        };
        if(sortObj.sortOrder !== 0){
          this.setState({selectedSortOption:i});
        }
        tempSortArray.push(sortObj);
      }

      return tempSortArray;
    }
  };

  componentDidMount = () => {
    let tempSortArray = this.generateDefaultSortOptions();
    let { page, rowsPerPage } = this.getPaginationSectionProperties();
    this.setState({ sortOptions: tempSortArray, page: page, rowsPerPage: rowsPerPage});
  };

  componentDidUpdate = (prevProps: any) => {
    if (prevProps.revertListConfig !== this.props.revertListConfig) {
      this.setState({selectedSortOption:null});
      let tempSortArray:any = this.generateDefaultSortOptions();
      let { page, rowsPerPage } = this.getPaginationSectionProperties();
      this.setState({ page: page, rowsPerPage: rowsPerPage, sortOptions: tempSortArray});
    }
    if (prevProps.refreshList !== this.props.refreshList) {
      let { page, rowsPerPage } = this.getPaginationSectionProperties();
      this.setState({ refresh: !this.state.refresh, page: page, rowsPerPage: rowsPerPage});
    }
    if (prevProps.listLoader !== this.props.listLoader) {
      this.setState({ anchorEl: null, filterPopper: null });
    }
  };

  handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    let listViewPagination = { page: newPage, rowsPerPage: this.state.rowsPerPage };
    sessionStorage.setItem('listViewPagination', JSON.stringify(listViewPagination));
    this.setState({ page: newPage });
    this.props.defaultSelect();
  };

  handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    let listViewPagination = { page: this.state.page, rowsPerPage: parseInt(event.target.value) };
    sessionStorage.setItem('listViewPagination', JSON.stringify(listViewPagination));
    this.setState({ rowsPerPage: parseInt(event.target.value, 10), page: 0 });
    this.props.defaultSelect();
  };

  handleSearch = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    this.props.handleSearch(event.target.value);
    this.setState({ page: 0 });
    this.props.setSearchCriteria(event.target.value);
    if(event.target.value.length < 1){
      if(sessionStorage.getItem('sortOptionDetails')!= null){
        sessionStorage.setItem('lastAction', ACTION_TYPE.SORT);
      }else{
        sessionStorage.removeItem('lastAction');
      }
      sessionStorage.removeItem('searchText');
    }else{
      sessionStorage.setItem('searchText',event.target.value);
      sessionStorage.setItem('lastAction', ACTION_TYPE.SEARCH);
    }
    
  };

  handleSortMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({ anchorEl: event.currentTarget, filterPopper: null });
  };

  handleSortMenuClose = () => {
    this.setState({ anchorEl: null });
  };

  handleFilterMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    const tempSelectedUser: string = sessionStorage.getItem(`selected${this.props.formatType}User`) || "";
    this.setState({ filterPopper: event.currentTarget, tempSelectedUser: tempSelectedUser, selectedClients: this.props.selectedClients });
  };

  handleFilterMenuClose = () => {
    this.setState({ filterPopper: null });
  };

  getSortIcon = (sortOptionsIndex: number) => {
    if (this.state.sortOptions) {
      if (this.state.sortOptions[sortOptionsIndex].sortOrder === 0) {
        return <SwapVert />;
      } else if (this.state.sortOptions[sortOptionsIndex].sortOrder === -1) {
        return <ArrowDownward color="secondary" />;
      } else if (this.state.sortOptions[sortOptionsIndex].sortOrder === 1) {
        return <ArrowUpward color="secondary" />;
      }
    }
  };

  handleFilterByEntity = (event: any, newFilterByEntity: any) => {
    if (newFilterByEntity != null) this.setState({ filterByEntity: newFilterByEntity });
  };

  setSelectedFilters = (selectedClients: any) => {
    this.setState({ selectedClients: selectedClients });
  };

  handleApplyFilters = () => {
    clearPaginationSessions();
    clearSortAndSearchSessions();
    this.props.getSortOption();
    this.props.setSearchCriteria("");
    let tempArray: Array<any> = [];
    this.state.selectedClients &&
      this.state.selectedClients.map((item: string, index: number) => {
        tempArray.push(item);
      });
    sessionStorage.setItem(`selected${this.props.formatType}Clients`, JSON.stringify(tempArray));
    sessionStorage.setItem(`selected${this.props.formatType}User`, this.state.tempSelectedUser);
    this.props.setChipsData();
    if (this.props.getFilteredData) {
      this.props.getFilteredData(this.props.dfbBusinessObjects ? this.props.dfbBusinessObjects : []);
    }
    if (this.props.setRevertListConfig) {
      this.props.setRevertListConfig(!this.props.revertListConfig);
    }
    this.handleFilterMenuClose();
  };

  getPaginationSectionProperties = (): any => {
    resetListViewPagination(this.props.history);
    let listViewPagination: any = sessionStorage.getItem('listViewPagination') != null ? sessionStorage.getItem('listViewPagination') : JSON.stringify({ page: 0, rowsPerPage: 10 });
    let paginationSection = JSON.parse(listViewPagination);
    return paginationSection;
  }

  filterByEntityUtility = () => {
    return (
      <Grid item container xs={11} direction="column">
        <ToggleButtonGroup size="small" value={this.state.filterByEntity} exclusive orientation="vertical" onChange={this.handleFilterByEntity}>
          <ToggleButton value="client" style={{ border: "1px solid #DDF1FF" }}>
            <Grid item container alignItems="center">
              <Grid item container xs={4} justify="center">
                <Group className="groupIcon" />
              </Grid>
              <Grid item xs={7}>
                <Typography variant="body2" style={{ textTransform: "capitalize", color: "black" }}>
                  <Box fontWeight={700}>Client</Box>
                </Typography>
              </Grid>
            </Grid>
          </ToggleButton>
          <ToggleButton value="user" style={{ border: "1px solid #DDF1FF" }}>
            <Grid item container alignItems="center">
              <Grid item container xs={4} justify="center">
                <Person className="personIcon" />
              </Grid>
              <Grid item xs={7}>
                <Typography variant="body2" style={{ textTransform: "capitalize", color: "black" }}>
                  <Box fontWeight={700}>User</Box>
                </Typography>
              </Grid>
            </Grid>
          </ToggleButton>
        </ToggleButtonGroup>
      </Grid>
    );
  };

  handleSort = (index: number) => {
    if (this.state.sortOptions) {
      let tempSortOptions = this.state.sortOptions;
      if (this.state.selectedSortOption !== null && this.state.selectedSortOption !== index) {
        tempSortOptions[this.state.selectedSortOption].sortOrder = 0;
      }
      if (tempSortOptions[index].sortOrder === 0) {
        tempSortOptions[index].sortOrder = -1;
      } else if (tempSortOptions[index].sortOrder === -1) {
        tempSortOptions[index].sortOrder = 1;
      } else if (tempSortOptions[index].sortOrder === 1) {
        tempSortOptions[index].sortOrder = 0;
      }
      if (this.props.handleSort) {
         this.props.handleSort(tempSortOptions[index]);
      }
      this.setState({ page: 0, rowsPerPage: 10, sortOptions: tempSortOptions, selectedSortOption: tempSortOptions[index].sortOrder === 0 ? null : index });
      if(tempSortOptions[index].sortOrder == 0){
        sessionStorage.removeItem('sortOptionDetails');
      }else{
        sessionStorage.setItem('sortOptionDetails',JSON.stringify(tempSortOptions));
        sessionStorage.setItem('lastAction', ACTION_TYPE.SORT);
      }
    }
  };

  handleListItemClick = (listObject: any) => {
    if (this.props.handleListItemClick) {
      this.props.handleListItemClick(listObject);
    }
  };

  handleSearchOnEnter = (event: React.KeyboardEvent<HTMLElement>) => {
    if (this.props.searchCriteria.length > 0 && event.key === "Enter") {
      if (this.props.data && this.props.data.length > 0) {
        if (this.props.handleListItemClick) {
          this.props.handleListItemClick(this.props.data[0]);
        }
      } else {
        if (this.props.handleListItemClick) {
          this.props.handleListItemClick({});
        }
      }
    }
  };
  handleTextFieldChange = (event: any) => {
    if (event != null) {
      this.setState({ tempSelectedUser: event.target.textContent });
    }
  };

  handleInputChange = (event: any, data: any) => {
    if (event != null) {
      let tempUserOptions: any = [];
      tempUserOptions = this.props.definedFiltersUsers.filter((user: string) => {
        return user.toLowerCase().includes(data.trim().toLowerCase());
      });
      data = data.trim();
      this.setState({ tempSelectedUser: data.trim(), userOptions: tempUserOptions });
    }
  };

  enablePrevPagination = () => {
     if(this.state.page == 0 ){
      return {disabled:true}
     }else{
       return {disabled:false}
     }
  }

  enableNextPagination = () =>{
    let lastPage = Math.floor((this.props.data.length-1)/this.state.rowsPerPage);
    if(this.state.page >= lastPage ){
      return {disabled:true}
     }else{
      return {disabled:false}
    }
  }
  

  render() {
    return (
      <Grid container>
        <PageWrapper>
          {this.props.listLoader || this.props.showLoader ? (
            <Grid container direction="column" justify={"center"} alignContent="center" xs={12} style={{ minHeight: 300 }}>
              <CircularProgress />
            </Grid>
          ) : (
            <React.Fragment>
              <Grid container xs={12}>
                <Grid item container direction="row" style={{ padding: theme.spacing(2, 1, 1, 2) }}>
                  <Grid item xs={this.props.showFilter ? 9 : 10}>
                    <TextField
                      data-testid="search-field"
                      variant="outlined"
                      size="small"
                      fullWidth
                      placeholder={this.props.searchLabel}
                      value={this.props.searchCriteria}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Search style={{ height: 16, width: 16 }} />
                          </InputAdornment>
                        ),
                        classes: {
                          input: this.props.classes.input,
                          notchedOutline: this.props.classes.notchedOutline,
                        },
                      }}
                      style={{ background: "#C8DAE2", borderRadius: 2 }}
                      onChange={(event) => this.handleSearch(event)}
                      onKeyPress={(event) => this.handleSearchOnEnter(event)}
                    />
                  </Grid>
                  <Grid item container xs={this.props.showFilter ? 3 : 2} direction="row" justify={this.props.showFilter ? "flex-end" : "center"}>
                    {this.props.data && this.props.data.length > 1 ? (
                      <Grid xs={5} item style={{ alignSelf: "center" }}>
                        <IconButton data-testid="sort-button" size="small" color="secondary" onClick={this.handleSortMenuOpen}>
                          <Sort />
                        </IconButton>
                        <Popper anchorEl={this.state.anchorEl} open={Boolean(this.state.anchorEl)}>
                          <ClickAwayListener onClickAway={this.handleSortMenuClose}>
                            <Paper>
                              {this.state.sortOptions &&
                                this.state.sortOptions.length > 0 &&
                                this.props.sortOptions &&
                                this.props.sortOptions.map((sortObject, index) => (
                                  <MenuItem data-testid={`sortMenuItem${index}`} onClick={(event) => this.handleSort(index)}>
                                    <ListItemIcon>{this.getSortIcon(index)}</ListItemIcon>
                                    <ListItemText style={{ color: this.state.selectedSortOption === index ? colorPalette.secondary : "" }} primary={sortObject.sortLabel} />
                                  </MenuItem>
                                ))}
                            </Paper>
                          </ClickAwayListener>
                        </Popper>
                      </Grid>
                    ) : null}
                    {this.props.showFilter && (
                      <Grid xs={5} item style={{ alignSelf: "center", position: "relative" }}>
                        <IconButton data-testid="filter-button" size="small" color="secondary" onClick={this.handleFilterMenuOpen}>
                          <FilterSvg />
                        </IconButton>
                        <Modal disableBackdropClick open={Boolean(this.state.filterPopper)} onClose={this.handleFilterMenuClose} aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description" style={{ width: "22%", height: "fit-content", position: "absolute", top: 250, left: 30 }}>
                          <Paper>
                            <Grid container direction="column">
                              <Grid item container justify="flex-end" alignItems="center">
                                <Grid item xs={9}>
                                  <Typography variant="body1">
                                    <Box fontWeight={600}>Filter</Box>
                                  </Typography>
                                </Grid>
                                <Grid item xs={2}>
                                  <IconButton onClick={this.handleFilterMenuClose}>
                                    <Close />
                                  </IconButton>
                                </Grid>
                              </Grid>
                              <Divider />
                              <Grid item container>
                                <Grid item container xs={4} style={{ borderRight: "1px solid #DDF1FF" }} justify="center">
                                  {this.filterByEntityUtility()}
                                </Grid>
                                <Grid item xs={8} container style={{ padding: "10px", minHeight: "300px", maxHeight: 300 }}>
                                  <Grid item container alignContent="flex-start">
                                    <Grid item container>
                                      {this.state.filterByEntity === "client" && <FilterCheckBox definedFilterClients={this.props.definedFilterClients} setSelectedFilters={this.setSelectedFilters} checked={this.state.selectedClients} />}
                                      {this.state.filterByEntity === "user" && <Autocomplete id="free-solo-demo" freeSolo fullWidth size="small" className="auto-complete" clearOnBlur={true} value={this.state.tempSelectedUser} onChange={(event) => this.handleTextFieldChange(event)} onInputChange={(e, data) => this.handleInputChange(e, data)} options={this.state.userOptions ? this.state.userOptions : []} renderInput={(params) => <TextField {...params} margin="normal" fullWidth variant="outlined" />} />}
                                    </Grid>
                                  </Grid>
                                  <Grid item container justify="flex-end">
                                    <Grid item container xs={11} justify="space-between" alignItems="flex-end">
                                      <Grid item xs={5}>
                                        <Button variant="contained" size="small" disableElevation onClick={this.handleFilterMenuClose}>
                                          <Typography variant="body2" style={{ color: "#707070" }}>
                                            <Box fontWeight={600}>{messages.cancel}</Box>
                                          </Typography>
                                        </Button>
                                      </Grid>
                                      <Grid item xs={5}>
                                        <Button data-testid="save-button" variant="contained" size="small" color="secondary" disableElevation onClick={this.handleApplyFilters}>
                                          <Typography variant="body2" style={{ color: "#ffffff" }}>
                                            <Box fontWeight={600}>{messages.apply}</Box>
                                          </Typography>
                                        </Button>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Paper>
                        </Modal>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
                {!this.props.data || this.props.data.length < 1 ? (
                  <Grid container direction="column" justify={"center"} alignContent="center" xs={12} style={{ minHeight: 300 }}>
                    <Typography variant="body2" style={{ opacity: 0.6 }}>
                      {"NO DATA FOUND"}
                    </Typography>
                  </Grid>
                ) : (
                  <React.Fragment>
                    <List style={{ width: "100%" }}>
                      {this.props.data.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage).map((row, index) => {
                        return (
                          <React.Fragment>
                            <ListItem data-testid={`listItem${index}`} style={{ cursor: "pointer", background: eval(`row.${this.props.selectedItemFieldName}`) === this.props.selectedItemFieldValue ? colorPalette.listItemSelectedShade : "" }} onClick={(event) => this.handleListItemClick(row)}>
                              {this.props.rowsRenderer(row)}
                            </ListItem>
                            <Divider style={{ margin: theme.spacing(1) }} />
                          </React.Fragment>
                        );
                      })}
                    </List>
                    {this.props.pagination ? (
                      <Grid container justify="flex-end">
                        <TablePagination component="div" data-testid="pagination" rowsPerPageOptions={[10, 15, 20]} count={this.props.data.length} page={this.state.page} onChangePage={this.handleChangePage} rowsPerPage={this.state.rowsPerPage} onChangeRowsPerPage={this.handleChangeRowsPerPage} nextIconButtonProps= {this.props.detailsLoader?{disabled:true}:this.enableNextPagination()} backIconButtonProps = {this.props.detailsLoader?{disabled:true}:this.enablePrevPagination()}/>
                      </Grid>
                    ) : null}
                  </React.Fragment>
                )}
              </Grid>
            </React.Fragment>
          )}
        </PageWrapper>
      </Grid>
    );
  }
}

const styles = (theme: any) =>
  createStyles({
    input: {
      "&::placeholder": {
        color: "#000000",
        opacity: 1,
      },
    },
    notchedOutline: {
      borderWidth: "0px",
      borderColor: "#ffffff",
    },
  });

export default withStyles(styles)(ListComponent);
