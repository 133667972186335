import messageBundle from '../../src/assets/resource_bundle/messages.json'
import { datePickerConstants } from './dfbConstants'
/**
 * This needs to be deleted
 * @deprecated
 * @param data 
 * @returns 
 */

export const fileCreationDateValidation = (data: any) => {
    data.v = data.v.trim();
    if (data.v.length === 10) {
        const month = Number(data.v.substring(0, 2)) - 1;
        const day = Number(data.v.substring(3, 5));
        const year = Number(data.v.substring(6, 10));
        const date = new Date(year, month, day);
        if (date.getFullYear() === year && date.getMonth() === month && date.getDate() === day) {
            return { "isValid": true };
        }
        return { "isValid": false, "message": data.error }
    } else {
        return { "isValid": false, "message": data.error }
    }
}