import { CircularProgress, Collapse, createStyles, Divider, Grid, IconButton, makeStyles, TableCell, Theme, Tooltip, Typography } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { bindActionCreators, Dispatch } from 'redux';
import { IMMUTABLE_RUN_LOG_HEADERS, runLogHeaders, targetListsDisplayHeaders } from '../../../actions/dfbBusinessObjectActions';
import theme, { colorPalette, getDFBBusinessObjectStatusColorPalette } from '../../../assets/theme';
import { RootState } from '../../../reducers/rootReducer';
import DialogContainer from '../../ui/DialogContainer';
import PageWrapper from '../../ui/PageWrapper';
import StatusDisplay from '../../ui/StatusDisplay';
import EnhancedTable from '../../ui/TableComponent';
import DetailsMenuComponent from './DetailsMenuComponent';
import DFBBusinessObjectRunLogDisplayComponent from './DFBBusinessObjectRunLogDisplayComponent';

import DeleteIcon from '@material-ui/icons/Delete';
import Papa from 'papaparse';
import { saveDFBBusinessObjectDataRequests, setFileProcessedPercentage, setSaveDFBBusinessObjectInitialState } from '../../../actions/dfbBusinessObjectActions';
import { setFormatMetaData } from '../../../actions/formatAction';
import { fetchLicenseReports, LicenseFetchReportParams } from '../../../actions/licenseAuditReportActions';
import {fetchLicensedHcpDetails, resetLicensedHcpState} from '../../../actions/licensedHcpsActions'
import messageBundle from '../../../assets/resource_bundle/messages.json';
import { customValidatorForObjectTypeReturnValue } from '../../../exports/interfaces/customValidatorForObjectTypeReturnValue';
import { reportsAdapter } from '../../../utils/chartUtils/reportsAdapter';
import { customValidatorForObjectType } from '../../../utils/customValidators/license/common/customValidatorForObjectType';
import {dfbEncoding, dfbLineEndingConfig, DISPLAY_DELIMITER_FOR_MSL, formatTypes, formatTypesFullform, MSL, userGroups } from '../../../utils/dfbConstants';
import { downloadLicensedHcp, downloadReport } from '../../../utils/downloadReport';
import { extractFieldInformation } from '../../../utils/extractFieldInformationFromList';
import { authorities, isAuthorityAvailable } from '../../../utils/permissions';
import { getOutPutFilesToBeDeleted, getRunLogIds, groupOutPutFiles } from '../../../utils/runLogIdUtil';
import { getStatusIcon } from '../../../utils/statusIconUtil';
import * as validatorUtils from '../../../utils/validatorUtils';
import { ChartReportDialogContainer } from '../../ui/chartReport/chartReportContainerDialog';
import { LineChartReportComponent } from '../../ui/chartReport/lineChartComponent';
import { LicenseLineChartHeader } from '../license/licenseReports/licenseLineChartHeader';
import { legends } from '../license/licenseReports/licenseReportLegendsJson';
import DeleteOutputFileAlertDialog from './DeleteOutputFileAlertDialog';
import TargetListUploadComponent from './TargetListUploadComponent';
import ValidateAndSaveActionButtonsComponent from './ValidateAndSaveActionButtonsComponent';
import { LicensedHcpsFetchParams } from '../../../actions/licensedHcpsActions';
import ValidationProgressDialogComponent from './ValidationProgressDialogComponent';
import { clearPaginationSessions } from '../../../utils/paginationUtils';

interface ListDetailsProps {
    dfbBusinessObject: any;
    metaData: Array<any>;
    pageLoader: boolean;
    dfbBusinessObjectDelete: (queryString: string, dfbBusinessObject: any) => Promise<boolean>;
    formatTypeName: string;
    outputFileDelete: (queryString: string) => Promise<boolean>;
    formatType: string;
    showLoader: boolean;
    isArchiveSelected: boolean
}

const UnconnectedListDetailsComponent: React.FC<Props & ListDetailsProps> = (props) => {
    const [expanded, setExpanded] = React.useState(false);
    const [fileSummaryExpanded, setFileSummaryExpanded] = React.useState(true);
    const [deleteConfirmationDialogOpen, setDeleteConfirmationDialogOpen] = React.useState(false);
    const [runLogDialogOpen, setRunDialogOpen] = React.useState(false);
    const [fileFieldValidationErrors, setFileFieldValidationErrors] = useState<Array<any>>([]);
    const [isFileValidated, setIsFileValidated] = useState(false);
    const [fileValidationErrors, setFileValidationErrors] = useState<Array<any>>([]);
    const [dfbBusinessObjectsData, setDFBBusinessObjectsData] = useState<string>('');
    const [fileSelected, setFileSelected] = useState(false);
    const [file, setFile] = useState<File | null>(null);
    const [totalRecordCount, setTotalRecordCount] = useState<number | null>(null);
    const [validationInitiated, setValidationInitiated] = useState(false);
    const [fileValidationInProgress, setFileValidationInProgress] = useState(false);
    const [validationProgressDialogOpen, setValidationProgressDialogOpen] = React.useState(false);
    const [hideFileUpload, setHideFileUpload] = React.useState(false);
    const [outputFileDeleteConfirmationDialogOpen, setOutputFileDeleteConfirmationDialogOpen] = React.useState(false);
    const [outputFileDeleteInProgress, setOutputFileDeleteInProgress] = React.useState(false);
    const [selectedOutputFile, setSelectedOutputFile] = React.useState<any>({});
    const [sortOrder, setSortOrder] = React.useState("desc");
    const [selectedIndex, setSelectedIndex] = React.useState(-1);
    const [outPutFilesToBeDeleted, setOutPutFilesToBeDeleted] = React.useState({});
    const [customValidationErrors, setCustomValidationErrors] = useState<Array<string>>([]);
    const [customValidation, setCustomValidation] = React.useState(false)
    const [openViewReportsDialog, setOpenViewReportDialog] = React.useState(false);
    const [numberOfFileterdLicense, setNumberOfFilteredLicense] = React.useState(0);
    const classes = useStyles();
    const handleExpandClick = () => {
        setFileSummaryExpanded(true);
        setExpanded(!expanded);
    };
    const handleFileSummaryExpandClick = () => {
        setExpanded(true);
        setFileSummaryExpanded(!fileSummaryExpanded);
    };
    const handleDeleteDialogOpen = () => {
        setDeleteConfirmationDialogOpen(true);
    };
    const handleDeleteDialogClose = () => {
        setDeleteConfirmationDialogOpen(false);
    };
    const handleViewReportDialogOpen = () => {
        setOpenViewReportDialog(true);
    }
    const handleViewReportDialogClose = () => {
        setNumberOfFilteredLicense(0)
        setOpenViewReportDialog(false);
    }
    const handleOutputFileDialogOpen = (outputFile: any, index: number) => {
        const dataArray = props.dfbBusinessObject === undefined || props.dfbBusinessObject.dfbBusinessObjectRunLog === undefined || props.dfbBusinessObject.dfbBusinessObjectRunLog.length < 1 ? [] : props.dfbBusinessObject.dfbBusinessObjectRunLog;
        let tempOutPutFilesToBeDeleted = getOutPutFilesToBeDeleted(dataArray, outputFile);
        setOutPutFilesToBeDeleted(tempOutPutFilesToBeDeleted);
        setSelectedIndex(index);
        setSelectedOutputFile(outputFile);
        setOutputFileDeleteConfirmationDialogOpen(true);
    };
    const handleOutputFileDialogClose = () => {
        setSelectedIndex(-1);
        setOutputFileDeleteConfirmationDialogOpen(false);
    };

    const setReportsFilterCount = (count: number) => {
        setNumberOfFilteredLicense(count);
    }

    const getLicenseReports = async () => {
        const licenseReportsFetchParams: LicenseFetchReportParams = {
            clientId: props.dfbBusinessObject.campaignFormat.client.clientId,
            licenseId: props.dfbBusinessObject.objectId
        }
        await props.fetchLicenseReports(licenseReportsFetchParams);

    }
    const downloadReportsAsExcel = () => {
        const chartData = reportsAdapter(props.licenseReports.licenseAuditReport, legends, 1);
        downloadReport(chartData, 'License Report', 'Reports','xlsx')
    }

    const handleOnClickLicensedHCPs = async () => {
        const licensedHcpsFetchParams: LicensedHcpsFetchParams = {
            clientId: props.dfbBusinessObject.clientId,
            clientName: props.dfbBusinessObject?.client?.clientName,
            dfbBusinessObjectId: props.dfbBusinessObject.objectId,
            formatId: props.dfbBusinessObject.format_id,
            objectType: props.dfbBusinessObject.campaignFormat.formatType.formatTypeAbbreviation
        }
        await props.fetchLicensedHcpDetails(licensedHcpsFetchParams)
    }

    const handleDFBBusinessObjectDelete = async () => {
        let deleteURL = `id=${props.dfbBusinessObject.objectId}&billCode=${props.dfbBusinessObject.billCode}&email=${props.user ? props.user.email : ''}&objectType=${props.dfbBusinessObject.campaignFormat.formatType.formatTypeAbbreviation}&createdBy=${props.user ? props.user.username : ""}`;

        if (props.dfbBusinessObject.campaignFormat.isGeneric && props.dfbBusinessObject?.client?.clientName) {
            let clientName = encodeURIComponent(props.dfbBusinessObject.client.clientName);
            deleteURL = `${deleteURL}&isGeneric=${props.dfbBusinessObject.campaignFormat.isGeneric}&clientName=${clientName}`;

        }
        let result = await props.dfbBusinessObjectDelete(deleteURL, props.dfbBusinessObject);

        if (result) {
            setDeleteConfirmationDialogOpen(false);
            toast(<Typography style={{ color: 'black', opacity: 0.7 }}>{props.messages.deleteInitiated}</Typography>);
        } else {
            toast.error(<Typography style={{ color: 'white' }}>{props.messages.deleteDFBBusinessObjectFailureMessage}</Typography>);
        }
    }
    const handleOutputFileDelete = async (changeComments: string) => {
        setOutputFileDeleteInProgress(true);    //set loader to true when api is called
        let runLogIds = getRunLogIds(outPutFilesToBeDeleted);
        let queryString = `id=${props.dfbBusinessObject.objectId}&objectType=${props.dfbBusinessObject.campaignFormat.formatType.formatTypeAbbreviation}&action=deleteRunLog&runLogIds=${runLogIds}&createdBy=${props.user ? props.user.username : ""}&runDate=${selectedOutputFile.logDate}&dataFeedConfigId=${selectedOutputFile.dataFeedConfigId}&changeComments=${changeComments}`;
        let result = await props.outputFileDelete(queryString);
        if (result) {
            toast(<Typography style={{ color: 'black', opacity: 0.7 }}>{props.messages.outputFileDeletedSuccessfully}</Typography>);
        } else {
            toast.error(<Typography style={{ color: 'white' }}>{props.messages.failedToDeleteOutputFile}</Typography>);
        }
        setDeleteConfirmationDialogOpen(false);//Close the confirmation dialog
        setOutputFileDeleteConfirmationDialogOpen(false); //Close the output file dialog after deletion os done  
        setOutputFileDeleteInProgress(false);    //set loader to false when api call is complete
        setSelectedIndex(-1); //Remove the highlighted row colour
    }
    // File upload code starts
    useEffect(() => {
        if (openViewReportsDialog) {
            getLicenseReports();
        }

    }, [openViewReportsDialog])

    useEffect(()=>{
        if(props.licensedHcpDetailsFetchingSuccess && props.licensedHcpDetails.length > 0){
            downloadLicensedHcp(props.licensedHcpDetails[0]);
            props.resetLicensedHcpState();
        }
        if(props.licensedHcpDetailsFetchingFailure){
            toast.error(<Typography style={{ color: 'black', opacity: 0.7 }}>{props.messages.genericFailureMessage}</Typography>);
            props.resetLicensedHcpState();
        }
    },[props.licensedHcpDetails,props.licensedHcpDetailsFetchingInProgress,props.licensedHcpDetailsFetchingSuccess,props.licensedHcpDetailsFetchingFailure]);


    useEffect(() => {
        if (fileValidationInProgress === true) {
            handleFormSubmission();
        }
    }, [fileValidationInProgress])
    useEffect(() => {
        setHideFileUpload(false);
        fileUploadInitialState()
    }, [props.dfbBusinessObject])
    const fileUploadInitialState = () => {
        setIsFileValidated(false);
        setFileValidationErrors([]);
        setDFBBusinessObjectsData('')
        setFileSelected(false)
        setFile(null);
        setTotalRecordCount(null);
        setValidationInitiated(false);
        setFileValidationInProgress(false);
        setValidationProgressDialogOpen(false);
        setExpanded(false);
        setFileSummaryExpanded(true);
        setFileFieldValidationErrors([]);
    }
    const handleClickOpen = () => {
        setValidationProgressDialogOpen(true);
    };

    const handleClose = () => {
        setValidationProgressDialogOpen(false);
    };

    const fileValidation = async (parseResult: any[]) => {
        let result: any;
        if (props.dfbBusinessObject.campaignFormat.campaignFormatsFields !== null && parseResult.length > 0) {
            if (file && file.name.length > 200) {
                setFileValidationErrors([props.messages.FileNameLengthError])
                setIsFileValidated(false)
            }
            else {
                result = await validatorUtils.fieldValidator(props.dfbBusinessObject.campaignFormat.campaignFormatsFields,
                    parseResult, props.setFileProcessedPercentage, props.messages)
                setFileValidationErrors(result.errorObj.errors)
                setFileFieldValidationErrors(result.errorObj.fieldErrors)
                setIsFileValidated(result.errorObj.validated)
                setTotalRecordCount(result.recordCount);
                setCustomValidation(!customValidation);
            }
        }

        handleClose()
        setFileValidationInProgress(false)
    }
    const getDelimiter = (delimiterKey: string) => {
        if (delimiterKey.trim() === `\\t`) {
            return ['\t'];
        }
        else {
            return [delimiterKey];
        }
    }
    const generateParsingConfig = () => {
        const selectedDFBBusinessObject = props.dfbBusinessObject.campaignFormat;
        const config = {
            delimitersToGuess: getDelimiter(selectedDFBBusinessObject.targetListDelim),
            encoding: dfbEncoding[selectedDFBBusinessObject.targetListEncoding - 1].toLowerCase(),
            newline: dfbLineEndingConfig[selectedDFBBusinessObject.targetListLineEndings - 1],
            quoteChar: selectedDFBBusinessObject.targetListTextQualifier ? selectedDFBBusinessObject.targetListTextQualifier : ''
        }
        return config;
    };

    const handleParseAndValidation = async () => {
        let parseResult = [];
        const selectedDFBBusinessObject = props.dfbBusinessObject.campaignFormat;
        const config: any = generateParsingConfig();
        if (validatorUtils.validateLineEnding(dfbBusinessObjectsData, selectedDFBBusinessObject.targetListLineEndings)) {
            await Papa.parse(file ? file : '', {
                ...config,
                complete: function (results) {
                    parseResult = results.data;
                    if (results.errors.length > 0) {
                        if (results.errors[0].code === 'UndetectableDelimiter') {
                            setFileValidationErrors([props.messages.DelimiterMismatchError]);
                        }
                        else {
                            setFileValidationErrors([props.messages.InvalidFileFormat]);
                        }
                        setIsFileValidated(false);
                        handleClose();
                        setFileValidationInProgress(false);
                    }
                    else {
                        fileValidation(parseResult);
                    }
                }
            });
        } else {
            setFileValidationErrors([props.messages.InvalidFileFormat]);
            setIsFileValidated(false);
            handleClose();
            setFileValidationInProgress(false);
        }
    };
    useEffect(() => {
        if (totalRecordCount) {
            let validationResult: customValidatorForObjectTypeReturnValue
                = customValidatorForObjectType({
                    objectType: props.dfbBusinessObject.campaignFormat.formatType.formatTypeAbbreviation,
                    recordCap: extractFieldInformation(props.metaData, 'f_id', 'Record Cap').v,
                    fileRecordCount: totalRecordCount + props.dfbBusinessObject.recordCount
                });
            if (validationResult && !validationResult.isValidated && validationResult.errorMessage != null) {
                setCustomValidationErrors([validationResult.errorMessage]);
                setValidationInitiated(false)
                setIsFileValidated(false);
            } else {
                setCustomValidationErrors([]);
            }

        }
    }, [customValidation]);


    const handleFormSubmission = async () => {
        try {
            handleClickOpen()
            setValidationInitiated(true);
            handleParseAndValidation()
        } catch (error) {
            handleClose()
            setFileValidationInProgress(false)
        }
    }
    const handleFileSelected = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (props.saveDFBBusinessObjectDataFailure) {
            props.setSaveDFBBusinessObjectInitialState()
        }
        if (event.target.files !== null && event.target.files[0] !== undefined && event.target.files[0].size > 0) {
            if (isFileValidated) { setIsFileValidated(false) }
            if (fileValidationErrors.length > 0) { setFileValidationErrors([]) }
            if (fileFieldValidationErrors.length > 0) { setFileFieldValidationErrors([]) }
            if (dfbBusinessObjectsData.length > 0) { setDFBBusinessObjectsData('') }
            if (customValidationErrors.length > 0) { setCustomValidationErrors([]) }
            setFileSelected(true)
            setFile(event.target.files[0])
            const reader = new FileReader()
            reader.readAsText(event.target.files[0])
            reader.onloadend = function () {
                if (reader.result !== null) {
                    let fileArrayBuffer = reader.result.toString()
                    setDFBBusinessObjectsData(fileArrayBuffer);
                    setTotalRecordCount(null);
                }
            }
        }
        event.currentTarget.value='';
    }
    const cancel = () => {
        fileUploadInitialState();
    }
    const validate = () => {
        setFileValidationInProgress(true);
    }
    const save = async () => {
        handleClickOpen()
        let userEmail = props.user ? props.user.email : '';
        let dfbBusinessObject = {
            billCode: props.dfbBusinessObject.billCode,
            objectId: props.dfbBusinessObject.objectId,
            recordCount: totalRecordCount,
            fileName: file ? file.name : undefined,
            createdBy: props.user ? props.user.username : '',
            campaignFormatId: props.dfbBusinessObject.campaignFormat.campaignFormatId,
            campaignFormatMajorVersion: props.dfbBusinessObject.campaignFormat.campaignFormatMajorVersion,
            clientName: props.dfbBusinessObject.client ? props.dfbBusinessObject.client.clientName : '',
            isGeneric: props.dfbBusinessObject.campaignFormat?.isGeneric
        }
        let dataNeedsToBeSend = { email: userEmail, data: dfbBusinessObjectsData, dfbBusinessObject: dfbBusinessObject, objectType: props.dfbBusinessObject.campaignFormat.formatType.formatTypeAbbreviation }
        let response: any = await props.saveDFBBusinessObjectDataRequests(dataNeedsToBeSend)
        if (response.status) {
            setHideFileUpload(true);
            handleClose();
            fileUploadInitialState()
            toast(<Typography style={{ color: 'black', opacity: 0.7 }}>{props.messages.additionalFileUploadInitiated}</Typography>);
        }
        else {
            handleClose();
            toast.error(<Typography style={{ color: 'white' }}>{response.message}</Typography>);
        }
    }
    // File upload code ends
    const rowRender = (runLogs: any, index: number) => {
        let rowCells: any = [];
        let headers = runLogHeaders
        for (var i = 0; i < headers.length; i++) {
            let rowCell = null;
            if (i === 2) {
                rowCell = (
                    <TableCell align='center'>
                        <Grid container direction='column'>
                            {runLogs.length > 1 ? runLogs.map((runLog: any) =>
                                <Grid item container style={{ height: '40px' }} alignItems='center'>
                                    <Typography variant='subtitle2'>
                                        {new Date(eval(`runLog.${headers[i].id}`)).toDateString()}
                                    </Typography>
                                </Grid>
                            ) :
                                <Grid item container>
                                    <Typography variant='subtitle2'>
                                        {new Date(eval(`runLogs[0].${headers[i].id}`)).toDateString()}
                                    </Typography>
                                </Grid>
                            }
                        </Grid>
                    </TableCell>
                );
            } else if (i === 3 && props.formatType === 'campaign') {
                rowCell = (
                    isAuthorityAvailable(props.user ? props.user.auths : [], authorities.DELETE_OUTPUT_FILE, userGroups.DFB_ADMINISTRATOR, props.user ? props.user.groups : []) ?
                        < TableCell align='center' style={{ padding: theme.spacing(0) }
                        }>
                            <Tooltip title="Delete">
                                <IconButton color='secondary' onClick={() => handleOutputFileDialogOpen(runLogs[0], index)}>
                                    <DeleteIcon />
                                </IconButton>
                            </Tooltip>
                        </TableCell > : null
                );
            }
            else {
                rowCell = (
                    <TableCell style={{
                        whiteSpace: 'normal',
                        wordBreak: 'break-word'
                    }}>
                        {runLogs.length > 1 ? runLogs.map((runLog: any) =>
                            <Grid item container style={{ height: '40px' }} alignItems='center'>
                                <Typography variant={'subtitle2'}>
                                    {eval(`runLog.${headers[i].id}`)}
                                </Typography>
                            </Grid>
                        ) :
                            <Grid item container >
                                <Typography variant={'subtitle2'} >
                                    {eval(`runLogs[0].${headers[i].id}`)}
                                </Typography>
                            </Grid>
                        }
                    </TableCell>
                );
            }
            rowCells.push(rowCell);
        }
        return rowCells;
    };
    const rowRenderForTargetLists = (targetListFile: any) => {
        let rowCells = [];
        let headers = targetListsDisplayHeaders;
        for (var i = 0; i < headers.length; i++) {
            let rowCell = null;
            if (headers[i].id.toLowerCase() === 'createddate') {
                rowCell = (
                    <TableCell style={{ background: '#ffffff' }}>
                        <Typography variant='subtitle2'>
                            {new Date(eval(`targetListFile.${headers[i].id}`)).toDateString()}
                        </Typography>
                    </TableCell>
                );
            }
            else {
                rowCell = (
                    <TableCell style={{
                        background: '#ffffff',
                        whiteSpace: 'normal',
                        wordBreak: 'break-word'
                    }}>
                        <Typography variant={'subtitle2'}>
                            {eval(`targetListFile.${headers[i].id}`)}
                        </Typography>
                    </TableCell>
                );
            }
            rowCells.push(rowCell);
        }
        return rowCells;
    };
    const handleOutputLogDialogOpen = () => {
        if (props.formatType === 'campaign') {
            if (!isAuthorityAvailable(props.user ? props.user.auths : [], authorities.DELETE_OUTPUT_FILE, userGroups.DFB_ADMINISTRATOR, props.user ? props.user.groups : []) && props.formatType !== formatTypes.CAMPAIGN_TYPE) {
                for (let i = 0; i < runLogHeaders.length; i++) {
                    if (runLogHeaders[i].id == "action") {
                        runLogHeaders.splice(i, 1);
                    }
                }
            }
            else {
                if (runLogHeaders.length === 3) {
                    runLogHeaders[3] = IMMUTABLE_RUN_LOG_HEADERS[3];
                }
            }
        } else if (props.formatType === 'license') {
            for (let i = 0; i < runLogHeaders.length; i++) {
                if (runLogHeaders[i].id == "action") {
                    runLogHeaders.splice(i, 1);
                }
            }
        }
        setRunDialogOpen(true);
    }
    const handleOutputLogDialogClose = () => {
        setRunDialogOpen(false);
    }
    const DeleteDialogBody = (props: any) => {
        return (
            <div style={{ padding: theme.spacing(0, 0, 3, 0) }}>
                <Typography variant={'body1'}>
                    {props.message}
                </Typography>
            </div>
        )
    };
    const getMetaData = () => {
        props.setFormatMetaData(props.dfbBusinessObject.campaignFormat.campaignFormatsMetadataFields);
    }
    const getValuesForMultiSelect = (fieldValue: string) => {

        let mslTypes = fieldValue.split(/\|_\|/gm);

        let multiLineDisplayComponent = mslTypes.map((mslType: string, index: number) => (
            <Typography
                variant='body2'
                align='left'
                key={index}
                style={{ opacity: 0.87, display: 'inline-block', paddingRight: '5px' }}>
                {mslType}{mslTypes.length - 1 > index && DISPLAY_DELIMITER_FOR_MSL}
            </Typography>
        ));
        return multiLineDisplayComponent;
    }
    return (
        <PageWrapper pageTitle={props.pageLoader || props.showLoader || (!props.dfbBusinessObject && !props.metaData) ? "" : props.dfbBusinessObject.billCode} badgeTitle={`${("" + props.formatTypeName).toUpperCase()}`} additionalTitleInfo={<StatusDisplay statusColor={getDFBBusinessObjectStatusColorPalette(props.dfbBusinessObject ? props.dfbBusinessObject.dfbBusinessObjectStatus.statusCode : "")} statusText={props.pageLoader || props.showLoader || !props.dfbBusinessObject ? "" : props.dfbBusinessObject.dfbBusinessObjectStatus.statusDescription} icon={getStatusIcon(props.dfbBusinessObject ? props.dfbBusinessObject.dfbBusinessObjectStatus.statusCode : "", "small")} />}>
            {props.pageLoader || props.showLoader ? (
                <Grid container direction="column" justify="center" alignContent="center" xs={12} style={{ height: "100%" }}>
                    <CircularProgress size={50} />
                </Grid>
            ) : (
                <React.Fragment>
                    <DFBBusinessObjectRunLogDisplayComponent heading={props.messages.SelectFormat} open={runLogDialogOpen} body={<EnhancedTable dataArray={props.dfbBusinessObject === undefined || props.dfbBusinessObject === null || props.dfbBusinessObject.dfbBusinessObjectRunLog === undefined || props.dfbBusinessObject.dfbBusinessObjectRunLog.length < 1 ? [] : groupOutPutFiles(props.dfbBusinessObject.dfbBusinessObjectRunLog, sortOrder)} headers={runLogHeaders} rowRenderer={rowRender} initialSortKey={"logDate"} setSortOrder={setSortOrder} sortOrder={sortOrder} selectedIndex={selectedIndex} />} handleClose={handleOutputLogDialogClose} />
                    <DeleteOutputFileAlertDialog open={outputFileDeleteConfirmationDialogOpen} onPrimaryButtonClick={handleOutputFileDelete} handleClose={handleOutputFileDialogClose} primaryButtonLoader={outputFileDeleteInProgress} outPutFilesToBeDeleted={outPutFilesToBeDeleted} isEditConfirmDialogue={false} />
                    {!props.pageLoader && !props.dfbBusinessObject && !props.metaData ? (
                        <Grid container direction="column" justify="center" alignContent="center" xs={12} style={{ height: "100%" }}>
                            <Typography variant="body2" style={{ opacity: 0.6 }}>
                                {"NO DATA FOUND"}
                            </Typography>
                        </Grid>
                    ) : (
                        <Grid container xs={12}>
                            <Grid item container direction="row" justify="flex-start">
                                <Grid item container xs={10} direction="row" style={{ padding: theme.spacing(0, 3, 0) }}>
                                    <Grid item container direction="column" xs={12} alignItems="flex-start">
                                        <Typography data-testid="format-name" variant="body2" align="left" style={{ opacity: 0.87 }}>
                                            {`${props.dfbBusinessObject.campaignFormat.formatName}- V ${props.dfbBusinessObject.campaignFormat.campaignFormatMajorVersion} `}
                                        </Typography>
                                        <Typography data-testid="formatDescription" variant="caption" align="left" style={{ opacity: 0.6, padding: theme.spacing(0, 0, 1) }}>
                                            {props.dfbBusinessObject.campaignFormat.formatDescription && props.dfbBusinessObject.campaignFormat.formatDescription.length > 0 ? props.dfbBusinessObject.campaignFormat.formatDescription : "No description"}
                                        </Typography>
                                        <Typography data-testid="created-by-label" variant="caption" align="left" style={{ opacity: 1, color: colorPalette.primaryShade }}>
                                            {"Log date"}
                                        </Typography>
                                    </Grid>
                                    <Grid item container xs={12} justify="space-between">
                                        <Grid item xs={4}>
                                            <Typography data-testid="created-by-value" variant="body1" align="left" style={{ opacity: 0.87 }}>
                                                {props.dfbBusinessObject.createdBy ? props.dfbBusinessObject.createdBy : "-"}
                                            </Typography>
                                            <Typography data-testid="created-date" variant="body2" align="left" style={{ opacity: 0.6, padding: theme.spacing(0, 0, 3) }}>
                                                {props.dfbBusinessObject.createdDate ? new Date(props.dfbBusinessObject.createdDate).toDateString() : "-"}
                                            </Typography>
                                        </Grid>
                                        {props.formatType === formatTypesFullform.CAMPAIGN_TYPE && props.isArchiveSelected === true && (
                                            <Grid item container direction="column" xs={4} alignItems="flex-start" >
                                                <Typography variant="caption" style={{ opacity: 1, color: colorPalette.primaryShade }} data-testid="archivedDate-label" align="left">
                                                    {props.messages.acrhivedOn}
                                                </Typography>
                                                <Typography variant="body2" align="left" style={{ opacity: 0.6 }} data-testid="updatedDate">
                                                    {props.dfbBusinessObject.updatedDate ? new Date(props.dfbBusinessObject.updatedDate).toDateString() : "-"}
                                                </Typography>
                                            </Grid>
                                        )}
                                        {props.dfbBusinessObject.vendor ? (
                                            <Grid item container direction="column" xs={4} alignItems="flex-start" >
                                                <Typography variant="caption" style={{ opacity: 1, color: colorPalette.primaryShade }} data-testid="vendor-label">
                                                    {messageBundle.vendor}
                                                </Typography>
                                                <Typography variant="body1" align="left" style={{ opacity: 0.87 }} data-testid="vendor">
                                                    {props.dfbBusinessObject.vendor ? props.dfbBusinessObject.vendor.vendorName : "-"}
                                                </Typography>
                                            </Grid>
                                        ) : props.dfbBusinessObject.campaignFormat.formatType.formatTypeAbbreviation === formatTypes.LICENSE_TYPE ? (
                                            <Grid item container direction="column" xs={5} alignItems="flex-start">
                                                <Typography variant="caption" style={{ opacity: 1, color: colorPalette.primaryShade }} data-testid="client-label">
                                                    {props.messages.client}
                                                </Typography>
                                                <Typography variant="body1" align="left" style={{ opacity: 0.87 }} data-testid="client">
                                                    {   props.dfbBusinessObject.client ? props.dfbBusinessObject.client.clientName : "-"}
                                                </Typography>
                                            </Grid>
                                        ) : null}
                                    </Grid>
                                </Grid>
                                <Grid item container xs={2} justify="center" direction="row" style={{ padding: theme.spacing(0, 1, 0) }}>
                                    <Grid item justify="center">
                                        <DetailsMenuComponent user={props.user} dfbBusinessObject={props.dfbBusinessObject} handleDeleteDialogOpen={handleDeleteDialogOpen} handleOutputLogDialogOpen={handleOutputLogDialogOpen} handleViewReportsDialogOpen={handleViewReportDialogOpen} 
                                        handleDownlaodHCPs={handleOnClickLicensedHCPs}
                                        hideDelete={hideFileUpload} getMetaData={getMetaData} />
                                    </Grid>
                                    <DialogContainer heading={`${messageBundle.delete + " " + props.formatTypeName}`} open={deleteConfirmationDialogOpen} body={<DeleteDialogBody message={messageBundle.deleteWarningMessage + " " + props.formatTypeName.toLocaleLowerCase() + "?"} />} primaryButtonHeading={"Delete"} primaryButtonLoader={props.dfbBusinessObjectDeleteInProgress} onPrimaryButtonClick={handleDFBBusinessObjectDelete} handleClose={handleDeleteDialogClose} secondaryButtonHeading="Cancel" />
                                    <ChartReportDialogContainer openDialog={openViewReportsDialog} handleCloseDialog={handleViewReportDialogClose} chartReportHeader={<LicenseLineChartHeader handleReportsDownload={downloadReportsAsExcel} isReportDialogOpen={openViewReportsDialog} reportFilterCount={setReportsFilterCount} />} chartReportBody={<LineChartReportComponent noDataFoundText="NO REPORT FOUND" yAxisText="Number of HCP's" xAxisText="Email Refresh" numberOfFilteredLicense={numberOfFileterdLicense} />} reportFetchingInProgress={props.licenseReportFetchingInProgress} />
                                    <ValidationProgressDialogComponent open={props.licensedHcpDetailsFetchingInProgress} fileProcessedPercentage={null} title={props.messages.downloadHcpTitle} description={props.messages.downloadHcpDescription} />
                                </Grid>
                            </Grid>
                            <Grid item container direction="column" xs={11} style={{ padding: theme.spacing(0, 0, 0, 3) }}>
                                <Grid item container justify="space-between" direction="row" style={{ cursor: "pointer" }} onClick={() => handleExpandClick()}>
                                    <Grid item>
                                        <Typography data-testid="metadata-label" variant="body1" style={{ color: colorPalette.primaryShade, fontWeight: 600 }}>
                                            {("" + props.messages.Metadata).toUpperCase()}
                                        </Typography>
                                    </Grid>
                                   
                                        <Grid item>
                                            <ExpandMore
                                                className={clsx(classes.expand, {
                                                    [classes.expandOpen]: expanded,
                                                })}
                                                aria-expanded={expanded}
                                                aria-label="show more"
                                            />
                                        </Grid>
                            
                                </Grid>
                                <Divider />
                            </Grid>
                            <Collapse in={!expanded} style={{ width: "100%" }}>
                                <Grid item container xs={12} direction="row" style={{ padding: theme.spacing(2, 4, 0), width: "100%" }}>
                                    {props.metaData.map((metaDataField, index) => {
                                        return (
                                            <Grid item xs={4} direction="column" style={{ padding: theme.spacing(2, 4, 0) }}>
                                                <Grid item container>
                                                    <Typography data-testid={metaDataField.formatField.fieldName} variant="caption" style={{ opacity: 0.6 }}>
                                                        {metaDataField.formatField.fieldName}
                                                    </Typography>
                                                </Grid>
                                                <Typography data-testid={metaDataField.fieldValue} variant="body2" align="left" style={{ opacity: 0.87, wordWrap: "break-word" }}>
                                                    {metaDataField.v ? (metaDataField.formatField.fieldDataType.dataTypeAbbreviation === MSL ? getValuesForMultiSelect(metaDataField.v) : metaDataField.v) : "-"}
                                                </Typography>
                                            </Grid>
                                        );
                                    })}
                                </Grid>
                            </Collapse>
                                <React.Fragment>
                                    <Grid item container direction="column" xs={11} style={{ padding: theme.spacing(3, 0, 0, 3) }}>
                                        <Grid item container justify="space-between" direction="row" style={{ cursor: "pointer" }} onClick={handleFileSummaryExpandClick}>
                                            <Grid item>
                                                <Typography data-testid="file-summary" variant="body1" style={{ color: colorPalette.primaryShade, fontWeight: 600 }}>
                                                    FILE SUMMARY{/* {('' + props.messages.Metadata).toUpperCase()} */}
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <ExpandMore
                                                    className={clsx(classes.expand, {
                                                        [classes.expandOpen]: fileSummaryExpanded,
                                                    })}
                                                    aria-expanded={fileSummaryExpanded}
                                                    aria-label="show more"
                                                />
                                            </Grid>
                                        </Grid>
                                        <Divider />
                                    </Grid>
                                    <Collapse in={!fileSummaryExpanded} style={{ width: "100%" }}>
                                        <Grid item container xs={11} direction="row" style={{ padding: theme.spacing(3, 0, 0, 3), width: "100%" }}>
                                            <EnhancedTable dataArray={props.dfbBusinessObject === undefined || props.dfbBusinessObject.dfbBusinessObjectFile === undefined || props.dfbBusinessObject.dfbBusinessObjectFile.length < 1 ? [] : props.dfbBusinessObject.dfbBusinessObjectFile} headers={targetListsDisplayHeaders} rowRenderer={rowRenderForTargetLists} initialSortKey={"createdDate"} />
                                        </Grid>
                                        {isAuthorityAvailable(props.user ? props.user.auths : [], authorities.CAMPAIGN_CREATE, userGroups.DFB_ACCOUNT_MANAGER, props.user ? props.user.groups : []) && !hideFileUpload && props.dfbBusinessObject.campaignFormat.hasInputFile && props.dfbBusinessObject.dfbBusinessObjectStatus.statusCode === "C" ? (
                                            <Grid item container xs={11} direction="row" style={{ padding: theme.spacing(1, 0, 0, 1), width: "100%" }}>
                                                <TargetListUploadComponent handleFileSelected={handleFileSelected} cancel={cancel} validate={validate} save={save} closeAllCalenders={() => { }} isFileValidated={isFileValidated} messages={props.messages} fileSelected={fileSelected} validationInitiated={validationInitiated} metaDataErrorPresent={false} fileValidationInProgress={fileValidationInProgress} saveDFBBusinessObjectDataInProgress={props.saveDFBBusinessObjectDataInProgress} file={file} fileValidationErrors={fileValidationErrors} totalRecordCount={totalRecordCount} displayTitle={false} message={props.messages.UploadFileCampaignDetails} fieldValidationErrors={fileFieldValidationErrors} recordCount={totalRecordCount} customValidationErrors={customValidationErrors} />

                                                <ValidateAndSaveActionButtonsComponent isFileValidated={isFileValidated} messages={props.messages} validationInitiated={validationInitiated} metaDataErrorPresent={false} fileValidationInProgress={fileValidationInProgress} saveDFBBusinessObjectDataInProgress={props.saveDFBBusinessObjectDataInProgress} file={file} fileValidationErrors={fileValidationErrors} validationProgressDialogOpen={validationProgressDialogOpen} fileProcessedPercentage={props.fileProcessedPercentage} cancel={cancel} validate={validate} save={save} fieldValidationErrors={fileFieldValidationErrors} recordCount={totalRecordCount} hasInputFile={props.dfbBusinessObject.campaignFormat.hasInputFile} dfbBusinessObjectsData ={dfbBusinessObjectsData} />
                                            </Grid>
                                        ) : null}
                                    </Collapse>
                                </React.Fragment>
                        </Grid>
                    )}
                </React.Fragment>
            )}
        </PageWrapper>
    );
}

const mapStateToProps = (state: RootState) => ({
    messages: state.messageBundle.messages,
    user: state.user.user,
    dfbBusinessObjectDeleteInProgress: state.dfbBusinessObject.dfbBusinessObjectDeleteInProgress,
    saveDFBBusinessObjectDataInProgress: state.dfbBusinessObject.saveDFBBusinessObjectDataInProgress,
    saveDFBBusinessObjectDataFailure: state.dfbBusinessObject.saveDFBBusinessObjectDataFailure,
    saveDFBBusinessObjectDataFailureMessage: state.dfbBusinessObject.saveDFBBusinessObjectDataFailureMessage,
    fileProcessedPercentage: state.dfbBusinessObject.fileProcessedPercentage,
    fileFormatFields: state.format.fileFormatFields,
    licenseReportFetchingInProgress: state.licenseReport.licenseReportsFetchingInProgress,
    licenseReportsFetchingSuccess: state.licenseReport.licenseReportsFetchingSuccess,
    licenseReports: state.licenseReport.licenseReports,
    licensedHcpDetails: state.licensedHcps.licensedHcpDetails,
    licensedHcpDetailsFetchingInProgress: state.licensedHcps.licensedHcpDetailsFetchingInProgress,
    licensedHcpDetailsFetchingSuccess: state.licensedHcps.licensedHcpDetailsFetchingSuccess,
    licensedHcpDetailsFetchingFailure: state.licensedHcps.licensedHcpDetailsFetchingFailure
})
const mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators({
        setSaveDFBBusinessObjectInitialState,
        setFileProcessedPercentage,
        saveDFBBusinessObjectDataRequests,
        setFormatMetaData,
        fetchLicenseReports,
        fetchLicensedHcpDetails,
        resetLicensedHcpState
    }, dispatch)
};
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            maxWidth: 345,
        },
        expand: {
            transform: 'rotate(0deg)',
            marginLeft: 'auto',
            cursor: 'pointer',
            transition: theme.transitions.create('transform', {
                duration: theme.transitions.duration.shortest,
            }),
        },
        expandOpen: {
            transform: 'rotate(180deg)',
        },
    }),
);
type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;
export const ListDetailsComponent = connect(mapStateToProps, mapDispatchToProps)(UnconnectedListDetailsComponent);