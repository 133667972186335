import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Grid, CircularProgress } from '@material-ui/core';
import CircularProgressWithLabel from '../../ui/CircularProgressWithLabel';
import theme from '../../../assets/theme';
interface DialogProps {
    open: boolean;
    fileProcessedPercentage: number | null;
    title: string;
    description: string;
}
const ValidationProgressDialogComponent: React.FC<DialogProps> = ({ open, fileProcessedPercentage, title, description }) => {
    return (
        <div>
            <Dialog
                open={open}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                disableBackdropClick={true}
                disableEscapeKeyDown={true}
            >
                <DialogTitle id="alert-dialog-title" data-testid='title'>{title}</DialogTitle>
                <DialogContent>
                    <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                    >
                        <Grid item data-testid='process-percentage' style={{ padding: theme.spacing(0, 4, 0, 0) }}>{fileProcessedPercentage !== null ? <CircularProgressWithLabel value={fileProcessedPercentage} /> : null} <CircularProgress /></Grid>

                    </Grid>
                    <DialogContentText id="alert-dialog-description" data-testid='description'>
                        {description}
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        </div >
    );
}

export default ValidationProgressDialogComponent;