import React from 'react'; 
import { CampaignFormatComponent } from './campaignFormatComponent';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { RootState } from '../../../reducers/rootReducer';

const CampaignFormatListContainerComp: React.FC<Props> = (props) => {
    sessionStorage.setItem('navIndex','1');
    return (
        <CampaignFormatComponent />
    )
}

const mapStateToProps = (state: RootState) => ({

})
const mapDispatchToProps = (dispatch: Dispatch) => { return bindActionCreators({ }, dispatch) };
type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;
export const CampaignFormatListContainer = connect(mapStateToProps, mapDispatchToProps)(CampaignFormatListContainerComp);