import { frcJsonAttributes, errorObjectAttributes } from '../../components/functional/fileTransferConfiguration/fileRetrievalConfigurationJson';
import { validateHostName } from '../dataConfigValidator'
import { AWS_S3, FTP, SFTP } from '../dfbConstants';

export function hostNameValidation(frcDataNeedsToBeSend: frcJsonAttributes, errorObject: errorObjectAttributes, messages: any) {
    return (): boolean => {
        if ((frcDataNeedsToBeSend.transferType === FTP || frcDataNeedsToBeSend.transferType === SFTP || frcDataNeedsToBeSend.transferType === AWS_S3) && (!frcDataNeedsToBeSend.hostName || frcDataNeedsToBeSend.hostName.trim().length < 1)) {
            errorObject.hostName = messages.FieldRequired;
            return false;
        } else if ((frcDataNeedsToBeSend.transferType === FTP || frcDataNeedsToBeSend.transferType === SFTP) && frcDataNeedsToBeSend.hostName !== null && !validateHostName(frcDataNeedsToBeSend.hostName)) {
            errorObject.hostName = messages.HostNameError;
            return false;
        } else if (frcDataNeedsToBeSend.transferType === AWS_S3) {
            let isValidBucketName: boolean = true;
            const awsBucketName =frcDataNeedsToBeSend.hostName!=null? frcDataNeedsToBeSend.hostName.split("/")[0].trim():"";

            if (awsBucketName.length < 3 || awsBucketName.length > 63 || !(/^[\a-z\d\.\-]*$/.test(awsBucketName))
                || !(/^[\a-z\d]/.test(awsBucketName)) || !(!/\-$/.test(awsBucketName)) || !(!/\.+\./.test(awsBucketName))
                || !(!/\-+\.$/.test(awsBucketName)) || !(!/^(?:(?:^|\.)(?:2(?:5[0-5]|[0-4]\d)|1?\d?\d)){4}$/.test(awsBucketName))
                || awsBucketName.length >= 4 && awsBucketName.substring(0, 4) === 'xn--' || awsBucketName.charAt(awsBucketName.length - 1) === '.'
                || awsBucketName.length >= 8 && awsBucketName.substring(awsBucketName.length - 8, awsBucketName.length) === '-s3alias') {
                isValidBucketName = false;
            } else {
                errorObject.hostName = '';
                isValidBucketName = true;
            }
            if (isValidBucketName) {
                errorObject.hostName = '';
                return true;
            } else {
                errorObject.hostName = messages.invalidS3BucketName;
                return false;
            }
        }
        else {
            errorObject.hostName = '';
            return true;
        }
    }
}