import React from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import messageBundle from "../../../assets/resource_bundle/messages.json";
import { RootState } from "../../../reducers/rootReducer";
import { campaignFilterStatuses, formatTypes, sessionStorageLabels } from "../../../utils/dfbConstants";
import { withAppShell } from "../../hoc/app_shell/withAppShell";
import { DFBBusinessObjectContainer } from "../dfbBusinessObject/DFBBusinessObjectContainer";

const CampaignContainerComponent: React.FC<Props> = (props) => {
  sessionStorage.setItem("navIndex", "0");
  sessionStorage.setItem("activeFormatType", formatTypes.CAMPAIGN_TYPE);
  if (sessionStorage.getItem(sessionStorageLabels.FILTERED_CAMPAIGN_STATUS) == null || sessionStorage.getItem(sessionStorageLabels.FILTERED_CAMPAIGN_STATUS) == campaignFilterStatuses.ACTIVE_STATUS) {
    sessionStorage.setItem(sessionStorageLabels.FILTERED_CAMPAIGN_STATUS, campaignFilterStatuses.ACTIVE_STATUS);
  } else {
    sessionStorage.setItem(sessionStorageLabels.FILTERED_CAMPAIGN_STATUS, campaignFilterStatuses.ARCHIVED_STATUS);
  }
  
  const getSortOptionsBasedOnFilter = (isArchiveFilterSelected: boolean) => {
    if (isArchiveFilterSelected) {
      return displayPropertiesForArchived;
    } else {
      return displayPropertiesForActive;
    }
  };

  const displayProperties = [
    { sortLabel: messageBundle.CreatedDate, sortField: "createdDate" },
    { sortLabel: messageBundle.BillCode, sortField: "billCode" },
    { sortLabel: messageBundle.CampaignFormat, sortField: "campaignFormat.formatName" },
    { sortLabel: messageBundle.Records, sortField: "recordCount" },
  ];
  const displayPropertiesForActive = [...displayProperties, { sortLabel: messageBundle.Status, sortField: "dfbBusinessObjectStatus.statusDescription" }];

  const displayPropertiesForArchived = [...displayProperties, { sortLabel: messageBundle.archivedDate, sortField: "updatedDate" }];

  return <DFBBusinessObjectContainer formatType={formatTypes.CAMPAIGN_TYPE} formatTypeName={messageBundle.Campaign} searchPlaceHolder={messageBundle.SearchCampaign} getSortOptionsBasedOnFilter={getSortOptionsBasedOnFilter} />;
};

const mapStateToProps = (state: RootState) => ({});
const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators({}, dispatch);
};
type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;
export const CampaignContainer = withAppShell(connect(mapStateToProps, mapDispatchToProps)(CampaignContainerComponent), { enableNav: true });
