export const fieldParser = (inputValue: any, outputType: string) => {
    try {
        if (outputType.toLowerCase() === dfbDataTypes.string || outputType.toLowerCase() === dfbDataTypes.list || outputType.toLowerCase() === dfbDataTypes.date) {
            return String(inputValue);
        }
        if (outputType.toLowerCase() === dfbDataTypes.integer || outputType.toLowerCase() === dfbDataTypes.float) {
            if (inputValue.toString().length > 0) {
                return Number(inputValue);
            } else {
                return "";
            }
        }
    } catch (error) {
        return inputValue;
    }
}

export const dfbDataTypes = {
    string: 'string',
    integer: 'integer',
    float: 'float',
    boolean: 'boolean',
    date: 'date',
    list: 'list',
    multiselectlist: 'multiselectlist'
}