import { AnyAction } from "redux"
import { actionType } from "../actions/actionTypes";

type apiVersionReducerState = {
    apiVersionFetchingInProgress: boolean,
    apiVersionFetchingSuccess: boolean,
    apiVersionFetchingFailure: boolean,
    apiVersion: any,
    
}

const initialState: apiVersionReducerState = {
    apiVersionFetchingInProgress: false,
    apiVersionFetchingSuccess: false,
    apiVersionFetchingFailure: false,
    apiVersion: null,
    
}

export function apiVersionReducer(state = initialState, action: AnyAction): apiVersionReducerState {
    switch (action.type) {
        case actionType.FETCHING_API_VERSION_IN_PROGRESS:
            return { ...state, 
                apiVersionFetchingInProgress: true, 
                apiVersionFetchingSuccess: false, 
                apiVersionFetchingFailure: false 
            };

        case actionType.FETCHING_API_VERSION_SUCCESS:
            return { ...state, 
                apiVersionFetchingInProgress: false, 
                apiVersionFetchingSuccess: true, 
                apiVersionFetchingFailure: false, 
                apiVersion: action.payload 
            };

        case actionType.FETCHING_API_VERSION_FAILURE:
            return { ...state, 
                apiVersionFetchingInProgress: true, 
                apiVersionFetchingSuccess: false, 
                apiVersionFetchingFailure: false 
            };

        default:
            return state;
    }
}