
export const filterDFBBusinessObjectsByValue = (array: Array<any>, seachValue: string, allowedFields: Array<any>) => {
    return array.filter(o =>
        Object.keys(o).some(k => {
            let found = false
            if (k === 'campaignFormat' || k === 'campaignStatus' || k === 'vendor') {
                if(o[k] != null){
                    found = Object.keys(o[k]).some(key => {
                        return allowedFields.indexOf(key) > -1 && String(o[k][key]).toLowerCase().includes(seachValue.toLowerCase())
                    })
                }
            }
            else {
                return allowedFields.indexOf(k) > -1 && String(o[k]).toLowerCase().includes(seachValue.toLowerCase())
            }
            return found
        }));
}

export const handleNumericSort = (dataArray: Array<any>, fieldName: string, sortOrder: number) => {
    let tempArray= new Array(...dataArray);
    return tempArray.sort((a, b) => {
        if (sortOrder === 1) {
            return a[fieldName] - b[fieldName];
        }
        else if (sortOrder === -1) {
            return b[fieldName] - a[fieldName];
        }
        return 0;
    })
}

export const handleStringSort = (dataArray: Array<any>, fieldName: string, sortOrder: number) => {
    let tempArray= new Array(...dataArray);
    
    return tempArray.sort((a, b) => {
        let fa = eval(`a.${fieldName}`).toLowerCase();
        let fb = eval(`b.${fieldName}`).toLowerCase();

        if (fa < fb) {
            return -sortOrder;
        }
        if (fa > fb) {
            return sortOrder;
        }
        return 0;
    })
}

export const handleDateSort = (dataArray: Array<any>, fieldName: string, sortOrder: number) => {
    let tempArray= new Array(...dataArray);
    return tempArray.sort((a, b) => {
        let dateA= new Date(a[fieldName]).getTime();
        let dateB= new Date(b[fieldName]).getTime();
        if (sortOrder === 1) {
            return dateA-dateB;
        }
        else if (sortOrder === -1) {
            return dateB - dateA;
        }
        return 0;
    })
}