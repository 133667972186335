import { AnyAction } from "redux"
import { actionType } from "../actions/actionTypes";

type licensedHcpsReducerState = {
    licensedHcpDetailsFetchingInProgress: boolean,
    licensedHcpDetailsFetchingSuccess: boolean,
    licensedHcpDetailsFetchingFailure: boolean,
    licensedHcpDetails:any,
}

const initialState: licensedHcpsReducerState = {
    licensedHcpDetailsFetchingInProgress: false,
    licensedHcpDetailsFetchingSuccess: false,
    licensedHcpDetailsFetchingFailure: false,
    licensedHcpDetails: [],
}

export function licensedHcpsReducer(state = initialState, action: AnyAction): licensedHcpsReducerState {
    switch (action.type) {
        case actionType.FETCHING_LICENSED_HCP_DETAILS_IN_PROGRESS:
            return { ...state, licensedHcpDetailsFetchingInProgress: true, licensedHcpDetailsFetchingSuccess: false, licensedHcpDetailsFetchingFailure: false };

        case actionType.FETCHING_LICENSED_HCP_DETAILS_SUCCESS:
            return { ...state, licensedHcpDetailsFetchingInProgress: false, licensedHcpDetailsFetchingSuccess: true, licensedHcpDetailsFetchingFailure: false, licensedHcpDetails: action.payload };

        case actionType.FETCHING_LICENSED_HCP_DETAILS_FAILURE:
            return { ...state, licensedHcpDetailsFetchingInProgress: false, licensedHcpDetailsFetchingSuccess: false, licensedHcpDetailsFetchingFailure: true };

        case actionType.RESET_LICENSED_HCP_STATE:
            return {...state, licensedHcpDetailsFetchingInProgress: false,
                licensedHcpDetailsFetchingSuccess: false,
                licensedHcpDetailsFetchingFailure: false,licensedHcpDetails: []};

        default:
            return state;
    }
}