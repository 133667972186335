import { AnyAction } from "redux";

type TestState = {
    name: string
};

const initialState: TestState = {name: 'DFB'};

export function testReducer(state= initialState, action: AnyAction): TestState{
    return state;
}