import { makeStyles, Radio, RadioProps, TextField, withStyles } from '@material-ui/core';
import React, { useEffect } from 'react';


interface RadioButtonComponentProps {
  isChecked: boolean,
  onChange(value: string): void,
  value: string,
  testId: string,
  size: any,
  uncheckedColor: any,
  checkedColor: any,
  isDisabled: boolean
}


const RadioButtonComponent: React.FC<RadioButtonComponentProps> = ({ isChecked, onChange, value, testId, size, uncheckedColor, checkedColor ,isDisabled }) => {

  const CustomRadioButton = withStyles({
    root: {
      color: `${uncheckedColor}`,
      '&$checked': {
        color: `${checkedColor}`,
      },
    },
    checked: {},
  })((props: RadioProps) => <Radio color="default" {...props} />);

  const onChangeStatus = (event: React.ChangeEvent<HTMLInputElement>): void => {
    onChange(event.target.value);
  }


  return (
    <CustomRadioButton
      checked={isChecked}
      onChange={onChangeStatus}
      value={value}
      disabled={isDisabled}
      data-testId={testId}
      size={size}
      inputProps={{ 'aria-label': value }}
    />

  )
}

export default RadioButtonComponent;