export const dfbConstants = {
    CREATION_IN_PROGRESS: 'CREATION_IN_PROGRESS',
    CREATED: 'CREATED',
    DELETION_IN_PROGRESS: 'DELETION_IN_PROGRESS'
};

export const dfbLineEndings = ['CRLF', 'LF'];

export const dfbEncoding = ['Ascii', 'UTF-8'];

export const dfbLineEndingConfig = ['\r\n', '\n'];

const APP_ENV = process.env.REACT_APP_ENV || 'local';

export const AZURE_BLOB = 'Azure Blob';

export const AWS_S3 = 'AWS S3';

export const FTP = 'FTP';

export const SFTP = 'SFTP';

export const ROLL_BASED = 'R';

export const transferTypeKey = 'transferType';

export const authenticationTypeKey = 'authenticationType';

export const CUSTOM_DELIMETER_FOR_MSL = '|_|';

export const DISPLAY_DELIMITER_FOR_MSL = ',';

export const MSL = 'msl';

export const MULTI_SELECT_LIST = 'MultiSelectList';

export const transferTypeValues = ['', 'AWS S3', 'Azure Blob', 'FTP', 'SFTP'];

export const passwordTypeKeys = ['Password', 'Secret Key', 'File Password', 'SSH Key File'];

export const scheduleTypes = [{ schedule: 'Once', scheduleAbbr: 'O' }, { schedule: 'Daily', scheduleAbbr: 'D' },
{ schedule: 'Weekly', scheduleAbbr: 'W' }, { schedule: 'Monthly', scheduleAbbr: 'M' }, { schedule: 'Quarterly', scheduleAbbr: 'Q' }, { schedule: 'Yearly', scheduleAbbr: 'Y' }]

export const statuses = [{ status: 'Active', statusCode: 'Y' }, { status: 'Inactive', statusCode: 'N' }];

export const lastRetrievalDate = '1990-01-01'

export const LICENSE_DEFAULT_FILTER_OPTION = { label: 'All', offset: 0 };

export const REFRESH = 'refresh';

export const REFRESH_DATE = 'Refresh Date';

export const MATCH_FROM_START = 'start';

export const ARCHIVED_STATUS_CODE = 'ARC';

export const CLIENT_NAME = 'clientName';
export const CLIENT_CODE = 'clientCode';
export const SALESFORCE_ID = 'salesforceId';
export const SALESFORCE_NAME = 'salesforceName';
export const DUPLICATE_CLIENT_NAME = 'duplicateClientName';
export const DUPLICATE_CLIENT_CODE = 'duplicateClientCode';
export const DUPLICATE_CLIENT_NAME_AND_DUPLICATE_CLIENT_CODE = 'duplicateClientNameAndDuplicateClientCode';
export const CLIENT_CODE_GENERATION_FAILED = 'ClientCodeGenerationFailed';

export const PATH_TYPE_ABSOLUTE = 'Absolute';
export const LICENSED_HCPS = 'LicensedHCPs';
export const CSV = 'csv';

export const LICENSE_REFRESH_FILTER_OPTIONS = [
    { label: 'Last two refresh', offset: 2 },
    { label: 'Last four refresh', offset: 4 },
    { label: 'Last eight refresh', offset: 8 }
]


export const userGroups = {
    DFB_ADMINISTRATOR: `DFB_ADMINISTRATOR_${APP_ENV}`.toLocaleUpperCase(),
    DFB_ACCOUNT_MANAGER: `DFB_ACCOUNT_MANAGER_${APP_ENV}`.toLocaleUpperCase()
}
export const datePickerConstants = {
    UI_DATE_FORMAT: 'MM/dd/yyyy',
    INVALID_DATE: 'INVALID_DATE',
    NOT_A_NUMBER: 'NaN-NaN-NaN'
}
export const dfbFieldLengthConstraints = {
    BILLCODE_MAX_LENGTH: '100',
    BILLCODE_FIELD_NAME: 'Bill Code'
}

export const formatTypes = {
    CAMPAIGN_TYPE: 'C',
    LICENSE_TYPE: 'L'
}

export const statusCode = {
    UPDATE_IN_PROGRESS: 'UIP',
    CREATED: 'C',
    ARCHIVED: 'A',
}

export const authTypes = {
    AUTH_PASSWORD: 'P',
    AUTH_KEY: 'K',
    AUTH_SAS: 'SAS'
}

export const campaignFilterStatuses = {
    ACTIVE_STATUS: 'Active',
    ARCHIVED_STATUS: 'Archived'
}

export const sessionStorageLabels = {
    FILTERED_CAMPAIGN_STATUS: "filteredCampaignStatus",
    ARCHIVE_MESSAGE_DIALOG: "archiveMessageDialog",
};

export const formatTypesFullform = {
    CAMPAIGN_TYPE: "campaign",
    LICENSE_TYPE: "license",
};

export const configurationCombination = {
    AWS_S3_K: "AWS_S3_K",
    AZURE_BLOB_SAS: "Azure_Blob_SAS"
}

export const skipValidationsLabel = {
    AWS_S3_K: {
        bucketExistsValidationName: 'Bucket exists',
        bucketExistsValidationCode: 's3_bkt_exst',
        bucketExistsDetail: 'Bucket Existence'
    },
    AZURE_BLOB_SAS: {
        containerExistsValidationName: 'Container exists',
        containerExistValidationCode: 'cntr_exst',
        containerExistDetail: 'Container Existence',
        listContainerValidationName: 'List the files inside the container',
        listContainerValidationCode: 'cntr_lst',
        listContainerDetail: 'File List'
    }
}

export const HTTP_RESPONSE_STATUS_CODE = {
    OK: 200
}

export const TOAST_TYPE = {
    SUCCESS: 'success',
    ERROR: 'error'
}

export const ACTION_TYPE = {
    SORT: 'sort',
    SEARCH: 'search',
}