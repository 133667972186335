import { frcJsonAttributes, errorObjectAttributes } from '../../components/functional/fileTransferConfiguration/fileRetrievalConfigurationJson';
import { validatePort } from '../dataConfigValidator';
import { FTP, SFTP } from '../dfbConstants';

export function portValidation(frcDataNeedsToBeSend: frcJsonAttributes, errorObject: errorObjectAttributes, messages: any) {
    return (): boolean => {
        if ((frcDataNeedsToBeSend.transferType === FTP || frcDataNeedsToBeSend.transferType === SFTP) && (!frcDataNeedsToBeSend.port || String(frcDataNeedsToBeSend.port).trim().length < 1)){
            errorObject.port = messages.FieldRequired;
            return false;
        } else if ((frcDataNeedsToBeSend.transferType === FTP || frcDataNeedsToBeSend.transferType === SFTP) && (!validatePort(String(frcDataNeedsToBeSend.port).trim()))) {
            errorObject.port = messages.PortError;
            return false;
        } else {
            errorObject.port = '';
            return true;
        }
    }
}