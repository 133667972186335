import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { RootState } from '../../../reducers/rootReducer';
import { DFBBusinessObjectContainer } from '../dfbBusinessObject/DFBBusinessObjectContainer';
import { withAppShell } from '../../hoc/app_shell/withAppShell';
import messageBundle from '../../../assets/resource_bundle/messages.json';
import { formatTypes } from '../../../utils/dfbConstants';

const LicenseContainerComponent: React.FC<Props> = (props) => {
    sessionStorage.setItem('navIndex', '2');
    sessionStorage.setItem('activeFormatType', formatTypes.LICENSE_TYPE);
    const displayProperties = [
        { sortLabel: messageBundle.CreatedDate, sortField: 'createdDate' },
        { sortLabel: messageBundle.BillCode, sortField: 'billCode' },
        { sortLabel: messageBundle.LicenseFormat, sortField: 'campaignFormat.formatName' },
        { sortLabel: messageBundle.Records, sortField: 'recordCount' },
        { sortLabel: messageBundle.Status, sortField: 'dfbBusinessObjectStatus.statusDescription' }
    ];
    return (
        <DFBBusinessObjectContainer formatType={formatTypes.LICENSE_TYPE} formatTypeName={messageBundle.License} searchPlaceHolder={messageBundle.SearchLicense} displayProperties={displayProperties} />
    )
}

const mapStateToProps = (state: RootState) => ({

})
const mapDispatchToProps = (dispatch: Dispatch) => { return bindActionCreators({}, dispatch) };
type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;
export const LicenseContainer = withAppShell(connect(mapStateToProps, mapDispatchToProps)(LicenseContainerComponent), { enableNav: true });