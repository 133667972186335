import {Grid} from '@material-ui/core';
import React from 'react';
import theme from '../../../assets/theme';
import { NavBar } from '../../functional/navigation/NavBar';
import CustomAppBar from '../../ui/CustomAppBar';

export const withAppShell = <P extends object> (WrappedComponent: React.ComponentType<P>, {enableNav= false}): React.FC<P>=>
    function withAS({...props}:any){
        return(
            <div>
                <CustomAppBar/>
                {enableNav?
                <NavBar/>:null}
                <Grid item container style={componentStyle.wrapperContainer}>
                <WrappedComponent {...props}/>
                </Grid>
            </div>
        )

    }

const componentStyle={
    wrapperContainer:{
        padding: theme.spacing(1,5,5,5), 
        flexGrow:1,
        background:"#FAFDFF"
    },
    titleContainer:{
        padding: theme.spacing(2,0,2)
    }
};