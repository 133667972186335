import { AnyAction } from "redux";
import { routes } from "../navigation/Routes";

type navigationState = {
    navigationRoutes: Array<any>,
};


const navRoutes =[
    {
        route: routes.campaigns,
        label: 'Campaigns'
    },
    {
        route: routes.formats,
        label: 'Formats'
    },
    {
        route: routes.licenses,
        label: 'Licenses'
    },
    {
        route: routes.reports,
        label: 'Reports'
    }
];

const initialState: navigationState = {navigationRoutes: navRoutes};

export function navigationReducer(state= initialState, action: AnyAction): navigationState{
    switch (action.type) {
        default:
            return state;
    }
};
