import { frcJsonAttributes } from './fileRetrievalConfigurationJson';

export const getLabelProperties = (transferType: string): any => {
    switch (transferType) {
        case 'AWS S3':
            return { "userNameLabel": "Access Key", "passwordLabel": "Secret Key", "hostLabel": "Bucket name", "sourceFolderLabel": "Subfolder", };
        case 'Azure Blob':
            return { "userNameLabel": "Azure storage account name", "sourceFolderLabel": "Azure container name", "hostLabel": "SAS URL", "subfolderLabel": "Sub folder" };
        default:
            return { "userNameLabel": "User Name", "passwordLabel": "Password", "sourceFolderLabel": "Source folder", "hostLabel": "Host name" };
    }
}

export const getAuthTypeValues = (transferType: string) => {
    const transferTypeValue = transferType != null ? transferType.toLowerCase() : '';
    switch (transferTypeValue) {
        case 'sftp': return ['', 'P', 'K'];
        case 'ftp': return ['', 'P'];
        case 'aws s3': return ['P'];
        case 'azure blob': return ['SAS'];
        default: return [''];
    }
}

const parseDate = (givenDate: Date | null) => {
    if (givenDate != null && typeof givenDate === 'string') {
        givenDate = new Date(givenDate)
    }
    let givenDateYear = givenDate != null ? givenDate.getFullYear() : "";
    let givenDateMonth = givenDate != null ? givenDate.getMonth() + 1 : "";
    let givenDateDay = givenDate != null ? givenDate.getDate() : "";

    let paresedDate = `${givenDateYear}-${givenDateMonth}-${givenDateDay}`;
    return paresedDate;
}

export function frcJsonTrim(dataNeedsToBeSend: frcJsonAttributes): void {
    dataNeedsToBeSend.groupName = dataNeedsToBeSend.groupName.trim();
    dataNeedsToBeSend.authUserName = dataNeedsToBeSend.authUserName.trim();
    dataNeedsToBeSend.authPassword = dataNeedsToBeSend.authPassword != null && dataNeedsToBeSend.authPassword.length > 1 ? dataNeedsToBeSend.authPassword.trim() : null;
    dataNeedsToBeSend.authKeyFileName = dataNeedsToBeSend.authKeyFileName != null ? dataNeedsToBeSend.authKeyFileName.trim() : null;
    dataNeedsToBeSend.port = Number(String(dataNeedsToBeSend.port).trim());
    dataNeedsToBeSend.sourceFolder = dataNeedsToBeSend.sourceFolder.trim();
    dataNeedsToBeSend.sourceFilePassword = dataNeedsToBeSend.sourceFilePassword != null ? dataNeedsToBeSend.sourceFilePassword.trim() : null;
    dataNeedsToBeSend.sourceFileNamePrefix = dataNeedsToBeSend.sourceFileNamePrefix.trim();
    dataNeedsToBeSend.sourceFileNameDatamask = dataNeedsToBeSend.sourceFileNameDatamask != null ? dataNeedsToBeSend.sourceFileNameDatamask.trim() : null;
    dataNeedsToBeSend.sourceFileNameSuffix = dataNeedsToBeSend.sourceFileNameSuffix != null ? dataNeedsToBeSend.sourceFileNameSuffix.trim() : null;
    dataNeedsToBeSend.sourceFileNameExtension = dataNeedsToBeSend.sourceFileNameExtension.trim();
    dataNeedsToBeSend.retryLimit = Number(String(dataNeedsToBeSend.retryLimit).trim());
    dataNeedsToBeSend.retryInterval = Number(String(dataNeedsToBeSend.retryInterval).trim());
}

export function getRequestBody(dataNeedsToBeSend: frcJsonAttributes): any {
    let requestBody = {
        clientName: dataNeedsToBeSend.clientName,
        groupName: dataNeedsToBeSend.groupName,
        transferType: dataNeedsToBeSend.transferType,
        schedule: dataNeedsToBeSend.schedule,
        authenticationType: dataNeedsToBeSend.authenticationType,
        authKeyFileName: dataNeedsToBeSend.authKeyFileName,
        authKeyPassPhrase: dataNeedsToBeSend.authKeyPassPhrase,
        authUserName: dataNeedsToBeSend.authUserName,
        authPassword: dataNeedsToBeSend.authPassword,
        hostName: dataNeedsToBeSend.hostName,
        port: dataNeedsToBeSend.port,
        sourceFolder: dataNeedsToBeSend.sourceFolder,
        sourceFileNamePrefix: dataNeedsToBeSend.sourceFileNamePrefix,
        sourceFileNameDatamask: dataNeedsToBeSend.sourceFileNameDatamask != null && dataNeedsToBeSend.sourceFileNameDatamask.trim() !== "" ? dataNeedsToBeSend.sourceFileNameDatamask : null,
        sourceFileNameSuffix: dataNeedsToBeSend.sourceFileNameSuffix != null && dataNeedsToBeSend.sourceFileNameSuffix.trim() !== "" ? dataNeedsToBeSend.sourceFileNameSuffix : null,
        sourceFileNameExtension: dataNeedsToBeSend.sourceFileNameExtension,
        sourceFilePassword: dataNeedsToBeSend.sourceFilePassword != null && dataNeedsToBeSend.sourceFilePassword.trim() !== "" ? dataNeedsToBeSend.sourceFilePassword : null,
        archiveStaticSuffix: dataNeedsToBeSend.archiveStaticSuffix,
        active: dataNeedsToBeSend.active,
        nextRetrievalDate: parseDate(dataNeedsToBeSend.nextRetrievalDate),
        lastRetrievalDate: parseDate(dataNeedsToBeSend.lastRetrievalDate),
        retryLimit: dataNeedsToBeSend.retryLimit,
        retryInterval: dataNeedsToBeSend.retryInterval,
        updateDate: new Date(),
        updateBy: dataNeedsToBeSend.updateBy,
    }
    return requestBody;
}

export const getFrcConfigurationsGroupByClientName = (configs: any) => {
    let configurations = configs.reduce(function (prevConfig: any, currentConfig: any) {
        prevConfig[currentConfig.clientName] = prevConfig[currentConfig.clientName] || [];
        prevConfig[currentConfig.clientName].push(currentConfig);
        return prevConfig;
    }, Object.create(null));
    return configurations;

}