export const routes = {
    unProtectedMain:'/',
    main: '/auth/:jwttoken',
    metadata:'/metadata',
    campaigns: '/campaigns',
    licenses: '/licenses',
    createLicenses: '/createLicenses',
    formats:'/formats',
    reports:'/reports',
    editLicenses: '/editLicenses',
    editCampaigns: '/editCampaigns'
}