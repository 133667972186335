import React from 'react';
import './App.css';
import AppRouter from './navigation/AppRouter';
import {Provider} from 'react-redux';
import { store } from './reducers';
import { ThemeProvider } from '@material-ui/core';
import theme from './assets/theme';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <div className="App">
      <Provider store={store}>
        <ToastContainer hideProgressBar pauseOnHover={false} closeButton={false}/>
        <ThemeProvider theme={theme}>
          <AppRouter/>
        </ThemeProvider>
      </Provider>

    </div>
  );
}

export default App;
