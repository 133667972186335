import { frcJsonAttributes, errorObjectAttributes } from '../../components/functional/fileTransferConfiguration/fileRetrievalConfigurationJson';

export function sourceFileDataMaskValidation(frcDataNeedsToBeSend: frcJsonAttributes,
    errorObject: errorObjectAttributes, messages: any) {
    return (): boolean => {
        if (frcDataNeedsToBeSend.sourceFileNameDatamask != null && frcDataNeedsToBeSend.sourceFileNameDatamask.trim() !== '' && frcDataNeedsToBeSend.sourceFileNameDatamask.trim().length > 50) {
            errorObject.sourceFileNameDatamask = messages.exceededFieldLength.concat('50');
            return false;
        } else {
            errorObject.sourceFileNameDatamask = '';
            return true;
        }
    }
}