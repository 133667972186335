import { HTTP } from "../rest";
import { actionType } from "./actionTypes"
import { apiRoutes } from "../rest/APIRoutes"

export const fetchClients = (queryString: string) => {
    return async (dispatch: (action: any) => void) => {
        try {
            dispatch(setClientsToState({ type: actionType.FETCHING_CLIENTS_IN_PROGRESS, payload: null }));
            let url = `${apiRoutes.vendorsURL}?${queryString}`;
            console.log('fetchClients',url);
            let result = await HTTP.get(url);
            dispatch(setClientsToState({ type: actionType.FETCHING_CLIENTS_SUCCESS, payload: result.data }));
            return result.data;
        } catch (error) {
            dispatch(setClientsToState({ type: actionType.FETCHING_CLIENTS_FAILURE, payload: null }));
            throw error;
        }
    }
}

const setClientsToState = ({ type, payload }: { type: string, payload: Array<any> | null }) => {
    return { type: type, payload: payload };
}

export const saveClient = (dataNeedsToBeSend: any) => {
    return async (dispatch: (action: any) => void) => {
        try {
           let url = `${apiRoutes.vendorsURL}?category=client`;
            dispatch(setClientDataToState({ type: actionType.SAVE_CLIENT_OBJECT_DATA_IN_PROGRESS, payload: null }));
            let response = await HTTP.post(url, dataNeedsToBeSend, {});
            if (response.status === 200) {
                if(response.data.errorCode){
                    dispatch(setClientDataToState({ type: actionType.SAVE_CLIENT_OBJECT_DATA_ERROR, payload: response.data }));
                    return { status: false, errorCode: response.data.errorCode };
                }
                dispatch(setClientDataToState({ type: actionType.SAVE_CLIENT_OBJECT_DATA_SUCCESS, payload: response.data }));
                return { status: true , newClientDetails: response.data};
            } else {
                dispatch(setClientDataToState({ type: actionType.SAVE_CLIENT_OBJECT_DATA_FAILURE, payload: null }));
                return { status: false };
            }
        } catch (error) {
            console.log('Error in save client',error);
            dispatch(setClientDataToState({ type: actionType.SAVE_CLIENT_OBJECT_DATA_FAILURE, payload: null }));
            return { status: false };
        }
    }
}

export const resetClientErrorData = () => {
    return async (dispatch: (action: any) => void) => {
        try {
            dispatch(setClientDataToState({ type: actionType.RESET_CLIENT_ERROR_OBJECT,payload:null }));
        }catch(error){
            throw error;
        }
    }
}

const setClientDataToState = ({ type, payload }: { type: string, payload: Array<any> | null }) => {
    return { type: type, payload: payload };
}

export const clearNewClientDataFromState = () => {
    return async (dispatch: (action: any) => void)=>{
        try{
            dispatch(setClientDataToState({ type: actionType.CLEAR_NEW_CLIENT, payload: [] }));
        }
        catch (error) {
            throw error;
        }
    }
}

export const setClientNameChanged = (flag: boolean) => {
    return async (dispatch: (action: any) => void)=>{
        try{
            dispatch(setClientDataToState({ type: actionType.CLIENT_NAME_CHANGED, payload: [flag] }));
        }
        catch (error) {
            throw error;
        }
    }
}

