import { frcJsonAttributes, errorObjectAttributes } from '../../components/functional/fileTransferConfiguration/fileRetrievalConfigurationJson';

export function sourceFilePrefixValidation(frcDataNeedsToBeSend: frcJsonAttributes,
    errorObject: errorObjectAttributes, messages: any) {
    return (): boolean => {
        if (!frcDataNeedsToBeSend.sourceFileNamePrefix || frcDataNeedsToBeSend.sourceFileNamePrefix.trim().length < 1) {
            errorObject.sourceFileNamePrefix = messages.FieldRequired;
            return false;
        } else if (frcDataNeedsToBeSend.sourceFileNamePrefix.trim().length > 50) {
            errorObject.sourceFileNamePrefix = messages.exceededFieldLength.concat('50');
            return false;
        } else {
            errorObject.sourceFileNamePrefix = '';
            return true;
        }
    }
}