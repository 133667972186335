import { frcJsonAttributes, errorObjectAttributes } from '../../components/functional/fileTransferConfiguration/fileRetrievalConfigurationJson';

export function sourceFileExtensionValidation(frcDataNeedsToBeSend: frcJsonAttributes,
    errorObject: errorObjectAttributes, messages: any) {
        return (): boolean => {
            if (!frcDataNeedsToBeSend.sourceFileNameExtension || frcDataNeedsToBeSend.sourceFileNameExtension.trim().length < 1) {
                errorObject.sourceFileNameExtension = messages.FieldRequired;
                return false;
            } else if (frcDataNeedsToBeSend.sourceFileNameExtension.trim() !== '.txt' && frcDataNeedsToBeSend.sourceFileNameExtension.trim() !== '.csv') {
                errorObject.sourceFileNameExtension = messages.invalidFileExtension;
                return false;
            } else {
                errorObject.sourceFileNameExtension = '';
                return true
            }
        }
}