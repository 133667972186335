import { LICENSE_DEFAULT_FILTER_OPTION, LICENSE_REFRESH_FILTER_OPTIONS } from "../../../../utils/dfbConstants";
import { ReportsOptionType } from "./licenseLineChartHeader";

export const legends: any = {
    totalLicenseCap: {
        showLegend: true,
        legendName: 'Record Cap',
        legendColor: '#023E8A',
    },
    countOfHcpsInMaster: {
        showLegend: true,
        legendName: 'Total Number Of Audience',
        legendColor: '#40798C',
    },
    countOfNewlyMatchedFromNormalRefresh: {
        showLegend: false,
        legendName: 'Newly Matched From Normal Refresh',
        legendColor: '#00497B',
    },
    countOfNewlyMatchedFromToBeRentedList: {
        showLegend: false,
        legendName: 'Newly Matched From Rented List',
        legendColor: '#4C7C9B',
    },
    countOfHcpSuppressedViaHcpSuppression: {
        showLegend: false,
        legendName: 'Suppressed Via Hcp Suppression',
        legendColor: '#EB6424',
    },
    countOfHcpSuppressedViaEmailSuppression: {
        showLegend: false,
        legendName: 'Suppressed Via Email Suppression',
        legendColor: '#86B0CC',
    },
    countOfHcpSuppressedViaDomainSuppression: {
        showLegend: false,
        legendName: 'Suppressed Via Domain Suppression',
        legendColor: '#A3CBE5',
    },
    countOfHcpSuppressedViaLicenseCap: {
        showLegend: true,
        legendName: 'Suppressed Via Record Cap',
        legendColor: '#EB6424',
    },
    countOfEmailsProvidedForCurrentRun: {
        showLegend: true,
        legendName: 'Emails Provided In Current Refresh',
        legendColor: '#09BC8A',
    },
    countOfHcpsLicensedDuringContractTerm: {
        showLegend: true,
        legendName: 'HCPs Licensed During Contract Term',
        legendColor: '#2B9348',
    },
    countOfRecordsRemainingInLicenseVsAudienceCap: {
        showLegend: false,
        legendName: 'Remaining In License Vs Record Cap',
        legendColor: '#FFB700',
    },
    countOfHcpWithNoEmail: {
        showLegend: false,
        legendName: 'Hcp With No Email',
        legendColor: '#FFB700',
    },
    countOfHcpSuppressedViaOptout: {
        showLegend: false,
        legendName: 'Hcp Suppressed Via Opt Out',
        legendColor: '#FFB700',
    }

};

export const getChartFilterOptions = (reports: any): Array<ReportsOptionType> => {
    const filterOptions: Array<ReportsOptionType> = [LICENSE_DEFAULT_FILTER_OPTION];
    if (reports != null) {
        if (reports.length >= 8) {
            filterOptions.push(LICENSE_REFRESH_FILTER_OPTIONS[0],LICENSE_REFRESH_FILTER_OPTIONS[1],LICENSE_REFRESH_FILTER_OPTIONS[2]);
            return filterOptions;
        } else if (reports.length >= 4) {
            filterOptions.push(LICENSE_REFRESH_FILTER_OPTIONS[0],LICENSE_REFRESH_FILTER_OPTIONS[1]);
            return filterOptions;
        } else if (reports.length >= 2) {
            filterOptions.push(LICENSE_REFRESH_FILTER_OPTIONS[0]);
            return filterOptions;
        }
        else {
            return filterOptions;
        }
    }
    else{
        return filterOptions;
    }
}