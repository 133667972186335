import React from 'react'
import { RootState } from '../../../reducers/rootReducer';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Grid, Button, Dialog, DialogActions, DialogContent, DialogTitle, makeStyles, Typography, TableCell } from '@material-ui/core';
import theme, { colorPalette } from '../../../assets/theme';
import EnhancedTable from '../../ui/TableComponent';

interface ValidationErrorDialogContainerProps {
    open: boolean,
    handleClose: () => any,
    errors: Array<any>
}

const UnconnectedValidationErrorDialogContainer: React.FC<Props & ValidationErrorDialogContainerProps> = (props) => {
    const classes = useStyles();

    const errorTableHeaders = [{
        id: 'rowNumber',
        label: 'Row Number',
    },
    {
        id: 'fieldName',
        label: 'Field Name',
    },
    {
        id: 'errorDescription',
        label: 'Error Description',
    },]

    const rowRenderForErrors = (errorObject: any) => {
        let rowCells = [];
        let headers = errorTableHeaders;
        for (var i = 0; i < headers.length; i++) {
            let rowCell = null;
                rowCell = (
                    <TableCell style={{
                        background: '#ffffff',
                        whiteSpace: 'normal',
                        wordBreak: 'break-word'
                    }}>
                        <Typography variant={'subtitle2'}>
                            {eval(`errorObject.${headers[i].id}`)}
                        </Typography>
                    </TableCell>
                );
            rowCells.push(rowCell);
        }
        return rowCells;
    };

    return (
        <div>
            <Dialog
                open={props.open}
                onClose={props.handleClose}
                classes={{ paper: classes.dialogContainer }}
                disableBackdropClick
            >
                <div className={classes.topStripBlue} />
                <DialogTitle>
                    <Typography id="dialog-title" variant='h6' color='error' style={{ fontWeight: 'bold' }} data-testid='errors'>
                        {props.messages.Errors}
                    </Typography>
                </DialogTitle>
                <DialogContent>
                <Grid item container data-testid='field-error-table' xs={12} style={{ padding: theme.spacing(0) }}>
                                        <EnhancedTable
                                            dataArray={props.errors}
                                            rowRenderer={rowRenderForErrors}
                                            headers={errorTableHeaders}
                                            initialSortKey={''}
                                        />
                                    </Grid>
                </DialogContent>
                <DialogActions style={{ padding: theme.spacing(0, 2, 2, 2) }}>
                    <Button data-testid='edit-close-button' variant='contained' color='secondary' onClick={props.handleClose}>
                        {'Close'}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    dialogContainer: {
        minWidth: '900px'
    },
    topStripBlue: {
        background: colorPalette.primaryShade,
        height: '10px'
    }
}));

const mapStateToProps = (state: RootState) => ({
    messages: state.messageBundle.messages,
})
const mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators({

    }, dispatch)
};
type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export const ValidationErrorDialogContainer = connect(mapStateToProps, mapDispatchToProps)(UnconnectedValidationErrorDialogContainer);