import { Box, Button, Chip, CircularProgress, Grid, TextField, Typography } from "@material-ui/core";
import { orange } from "@material-ui/core/colors";
import { Close } from "@material-ui/icons";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, { useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import { bindActionCreators, Dispatch } from "redux";
import { HTTP } from "../../../rest";
import { fetchClients } from "../../../actions/clientActions";
import { deleteDFBBusinessObject, deleteOutputFile, fetchDFBBusinessObjectList, fetchDFBBusinessObjectWithData, setDefaultDFBBusinessObject } from "../../../actions/dfbBusinessObjectActions";
import { fetchFormatByType, fetchFormatFieldsByFormat, fetchFormatList } from "../../../actions/formatAction";
import messageBundle from "../../../assets/resource_bundle/messages.json";
import theme, { colorPalette } from "../../../assets/theme";
import { routes } from "../../../navigation/Routes";
import { RootState } from "../../../reducers/rootReducer";
import { TOAST_TYPE, campaignFilterStatuses, formatTypes, formatTypesFullform, sessionStorageLabels, userGroups, ACTION_TYPE } from "../../../utils/dfbConstants";
import { dfbBusinessObjectListType } from "../../../utils/dmdTypes";
import { generateFilterObject } from "../../../utils/filterUtils";
import { filterDFBBusinessObjectsByValue, handleDateSort, handleNumericSort, handleStringSort } from "../../../utils/listUtils";
import { authorities, isAuthorityAvailable } from "../../../utils/permissions";
import { getStatusIcon } from "../../../utils/statusIconUtil";
import DialogContainer from "../../ui/DialogContainer";
import ListComponent from "../../ui/ListComponent";
import MasterSlaveContainer from "../../ui/MasterSlaveContainer";
import RadioButtonComponent from "../../ui/RadioButtonComponent";
import TabTitleComponent from "../../ui/TabTitleComponent";
import { ListDetailsComponent } from "./ListDetailsComponent";
import { clearPaginationSessions, clearSortAndSearchSessions, getLastAction, setSearchDataFromSessionStorage, setSortDataFromSessionStorage } from "../../../utils/paginationUtils";
import toastMessage from "../../ui/ToastMessage";
import { async } from "rxjs";
interface formatTypeData {
  formatType: string;
  formatTypeName: string;
  searchPlaceHolder: string;
  displayProperties?: any;
  getSortOptionsBasedOnFilter?: Function;
}

const UnconnectedDFBBusinessObjectContainer: React.FC<Props & formatTypeData> = (props) => {
  const [formatSelectDialogOpen, setFormatSelectDialogOpen] = React.useState(false);
  const [redirectToMetadata, setRedirectToMetadata] = React.useState(false);
  const location = useLocation();
  const [selectedDFBBusinessObject, setSelectedDFBBusinessObject] = React.useState({ campaignFormatId: 0, campaignFormatMajorVersion: 0, hasInputFile: true, isGeneric: false });
  const [error, setError] = React.useState("");
  const [dfbBusinessObjectList, setdfbBusinessObjectList] = React.useState<Array<dfbBusinessObjectListType>>([]);
  const [detailsLoader, setDetailsLoader] = React.useState(true);
  const [selectedObjectIdFromList, setSelectedObjectIdFromList] = React.useState(0);
  const [refreshList, setRefreshList] = React.useState(false);
  const [revertListConfig, setRevertListConfig] = React.useState(false);
  const [chips, setChips] = React.useState([]);
  const [selectedUser, setSelectedUser] = React.useState();
  const [selectedClients, setSelectedClients] = React.useState([]);
  const [definedFilterClients, setDefinedFilterClients] = React.useState([]);
  const [definedFiltersUsers, setDefinedFiltersUsers] = React.useState([]);
  const [filterFormats, setFilterFormats] = React.useState<Array<string>>([]);
  const [filteredDFBBusinessObjects, setFilteredDFBBusinessObjects] = React.useState<Array<string>>([]);
  const [formatType, setFormatType] = React.useState(props.formatType === formatTypes.CAMPAIGN_TYPE ? formatTypesFullform.CAMPAIGN_TYPE : formatTypesFullform.LICENSE_TYPE);
  const [listPageLoader, setListPageLoader] = React.useState(true);
  const [searchCriteria, setSearchCriteria] = React.useState("");
  const [vendorDetails, setVendorDetails] = React.useState<string[]>([]);
  const [clientDetails, setClientDetails] = React.useState<string[]>([]);
  const [clientsObj, setClientsObj] = React.useState([] as any[]);
  const [vendorDropDownValue, setVendorDropDownValue] = React.useState("");
  const [clientDropDownValue, setClientDropDownValue] = React.useState("");
  const [showClientDropdown, setShowClientDropdown] = React.useState(false);
  const [vendorId, setVendorId] = React.useState(0);
  const [disableDropDown, setDisableDropDown] = React.useState(true);
  const [openConfirmDialog, setOpenConfirmDialog] = React.useState(false);
  const [defaultVendorValue, setDefaultVendorValue] = React.useState("");
  const [defaultVendorId, setDefaultVendorId] = React.useState(0);
  const [openArchiveMessageDialog, setOpenArchiveMessageDialog] = React.useState(true);
  const [isActiveFilterSelected, setIsActiveFilterSelected] = React.useState(true);
  const [isArchiveFilterSelected, setIsArchiveFilterSelected] = React.useState(false);
  const [sortOptions, setSortOptions] = React.useState([]);
  const formats = useSelector((state: RootState) => state.format.activeCampaignFormatList);

  const dispatch = useDispatch();
  var vendorObject: Array<any> = [];
  const history = useHistory();

  useEffect(() => {
    if (sessionStorage.getItem(sessionStorageLabels.FILTERED_CAMPAIGN_STATUS) == campaignFilterStatuses.ACTIVE_STATUS) {
      setIsActiveFilterSelected(true);
      setIsArchiveFilterSelected(false);
    } else {
      setIsActiveFilterSelected(false);
      setIsArchiveFilterSelected(true);
    }

    async function fetchDFBBusinessObjectListFromAPI() {
      try {
        const formatListPromise = props.fetchFormatByType(props.formatType, "ACT");
        const dfbBusinessObjectListPromise = props.fetchDFBBusinessObjectList(`getBy=formatType&objectType=${props.formatType}&createdBy=${props.user ? props.user.username : ""}`);
        const [formatsList, dfbBusinessObjectList] = await HTTP.getAll([formatListPromise, dfbBusinessObjectListPromise]);
        // Handle the results

        let tempChips = setChipDataFromSessionStorage();
        let tempSortData = setSortDataFromSessionStorage(setSortOptions);
        let tempSearchField = setSearchDataFromSessionStorage(setSearchCriteria);
        let lastAction = getLastAction();
        setDetailsLoader(true);

        let formats: any = formatsList;
        let dfbBusinessObjects: any = dfbBusinessObjectList;

        let tempDefinedFilterObjects: any = generateFilterObject(formats, dfbBusinessObjects);
        let tempDefinedFilterClients: any = tempDefinedFilterObjects.clients;
        let handleApplyFiltersUser: any = tempDefinedFilterObjects.users;
        setOpenArchiveMessageDialog(true);
        setDefinedFiltersUsers(handleApplyFiltersUser);
        sessionStorage.setItem(props.formatType === formatTypes.CAMPAIGN_TYPE ? "campaignFilterObject" : "licenseFilterObject", JSON.stringify(tempDefinedFilterClients));
        setDefinedFilterClients(tempDefinedFilterClients);
        let clientsArray: Array<string> = [];
        for (let index: number = 0; index < tempDefinedFilterClients.length; index++) {
          clientsArray.push(...tempDefinedFilterClients[index]["formats"]);
        }

        setFilterFormats(clientsArray);
        if (tempChips.length > 0) {
          dfbBusinessObjects = await getFilteredData(dfbBusinessObjects);
        }
        if (lastAction != null && lastAction === ACTION_TYPE.SORT) {
          if (tempSearchField.length > 0) {
            dfbBusinessObjects = filterDFBBusinessObjectsByValue(dfbBusinessObjects, tempSearchField, searchableFields);
          }
          if(tempSortData.length > 0){
          dfbBusinessObjects = await getUpdatedSortOptions(tempSortData, dfbBusinessObjects,ACTION_TYPE.SORT);
          setRevertListConfig(!revertListConfig);
          }
          setdfbBusinessObjectList(dfbBusinessObjects);
          setListPageLoader(false);
        } else if (lastAction != null && lastAction === ACTION_TYPE.SEARCH) {
          if (tempSortData.length > 0) {
            getUpdatedSortOptions(tempSortData, dfbBusinessObjects, ACTION_TYPE.SEARCH);
          }
          if(tempSearchField.length > 0){
          dfbBusinessObjects = filterDFBBusinessObjectsByValue(dfbBusinessObjects, tempSearchField, searchableFields);
          setdfbBusinessObjectList(dfbBusinessObjects);
          }
          setListPageLoader(false);
        }
        else {
          setListPageLoader(false);
          compareObjectsLists(dfbBusinessObjects);
          setdfbBusinessObjectList(dfbBusinessObjects);
        }
        if(sessionStorage.getItem('selectedBusinessObjectFromList') != null) {
          let selectedBusinessObject: any = sessionStorage.getItem('selectedBusinessObjectFromList');
          handleDFBBusinessObjectClick(JSON.parse(selectedBusinessObject));
          getCurrentPage(JSON.parse(selectedBusinessObject).objectId, dfbBusinessObjects);
        } else {
          setListPageLoader(false);
          await fetchTheFirstRecord(dfbBusinessObjects);
        }
        setDetailsLoader(false);
      } catch (error) {
        setListPageLoader(false);
        setDetailsLoader(false);
      }
    }
    fetchDFBBusinessObjectListFromAPI();
  }, []);

  useEffect(() => {
    getSortOptions();
  }, [isActiveFilterSelected, isArchiveFilterSelected]);

  const compareObjectsLists = (dfbBusinessObjects: any) => {
    let oldObjectLength: any = parseInt(sessionStorage.getItem('dfbObjectsArrayLength') ? sessionStorage.getItem('dfbObjectsArrayLength') : dfbBusinessObjects.length);
    if (dfbBusinessObjects.length != oldObjectLength) {
      clearPaginationSessions();
    }
    sessionStorage.setItem('dfbObjectsArrayLength', JSON.stringify(dfbBusinessObjects.length));
  }

  const fetchClientsForGenericInputFormat = async (dfbBusinessObject: any) => {
    setClientDropDownValue("");
    sessionStorage.removeItem("selectedClientName");
    sessionStorage.removeItem("selectedClientId");
    let clientsList = await props.fetchClients(`category=client&getBy=campaignFormatId&campaignFormatId=${dfbBusinessObject.campaignFormatId}&campaignFormatMajorVersion=${dfbBusinessObject.campaignFormatMajorVersion}`);
    let clients: any = clientsList;
    let clientsName: Array<string> = [];
    if (clients != null) {
      clients.forEach((client: { client: { clientName: any } }, index: any) => {
        clientsName.push(client.client.clientName);
      });
    }
    setDisableDropDown(props.clientListFetchInProgress);
    setClientsObj(clients);
    setClientDetails(clientsName);
  };

  const handleCampaignsRadioFilter = async (status: string) => {
    clearSortAndSearchSessions();
    setListPageLoader(true);
    setDetailsLoader(true);
    sessionStorage.setItem(sessionStorageLabels.FILTERED_CAMPAIGN_STATUS, status);
    if (sessionStorage.getItem(sessionStorageLabels.FILTERED_CAMPAIGN_STATUS) == campaignFilterStatuses.ACTIVE_STATUS) {
      setIsActiveFilterSelected(true);
      setIsArchiveFilterSelected(false);
    } else {
      setIsActiveFilterSelected(false);
      setIsArchiveFilterSelected(true);
    }
    clearFilters();
    clearPaginationSessions();
    props.setDefaultDFBBusinessObject();
    setdfbBusinessObjectList([]);
    let dfbBusinessObjectList = await props.fetchDFBBusinessObjectList(`getBy=formatType&objectType=${props.formatType}&createdBy=${props.user ? props.user.username : ""}`);
    let dfbBusinessObjects: any = dfbBusinessObjectList;
    if (dfbBusinessObjects.length > 0) {
      setdfbBusinessObjectList(dfbBusinessObjects);
      setSelectedObjectIdFromList(dfbBusinessObjects[0].objectId);
      await fetchTheFirstRecord(dfbBusinessObjects);
    }
    setListPageLoader(false);
    setDetailsLoader(false);
  };
  const getFilteredData = async (dfbBusinessObjects: any) => {
    setListPageLoader(true);
    let selectedUser: string = sessionStorage.getItem(`selected${formatType}User`) || "";
    if (selectedUser.length > 0) {
      dfbBusinessObjects = dfbBusinessObjects.filter((item: any) => {
        return item.createdBy.toLowerCase() === selectedUser.toLowerCase();
      });
    }
    let selectedClients: Array<string> = JSON.parse(sessionStorage.getItem(`selected${formatType}Clients`) || "[]");
    if (selectedClients.length > 0) {
      selectedClients.map(() => {
        dfbBusinessObjects = dfbBusinessObjects.filter((item: any) => {
          return selectedClients.includes(item.campaignFormat.formatName);
        });
      });
    }
    if (selectedClients.length > 0) {
      compareObjectsLists(dfbBusinessObjects);
    }
    setFilteredDFBBusinessObjects(dfbBusinessObjects);
    setdfbBusinessObjectList(dfbBusinessObjects);
    if (sessionStorage.getItem('selectedBusinessObjectFromList') == null && sessionStorage.getItem('searchText') == null && sessionStorage.getItem('sortOptionDetails') == null) {
      await fetchTheFirstRecord(dfbBusinessObjects);
    }
    setListPageLoader(false);
    return dfbBusinessObjects;
  };

  const handleFormatSelectDialogOpenClick = () => {
    setFormatSelectDialogOpen(true);
  };

  const handleFormatSelectDialogClose = () => {
    setFormatSelectDialogOpen(false);
    setRedirectToMetadata(false);
    setOpenConfirmDialog(false);
    setDisableDropDown(true);
    setVendorDropDownValue("");
    setClientDropDownValue("");
    sessionStorage.removeItem("selectedClientName");
    sessionStorage.removeItem("selectedClientId");
    sessionStorage.removeItem("selectedDFBBusinessObject");
    setShowClientDropdown(false);
    setSelectedDFBBusinessObject({ campaignFormatId: 0, campaignFormatMajorVersion: 0, hasInputFile: true, isGeneric: false });
    if (error.length > 1) {
      setError("");
    }
  };

  const onDFBBusinessObjectCreateClick = async () => {
    setDisableDropDown(true);
    if (selectedDFBBusinessObject != null && selectedDFBBusinessObject.campaignFormatId > 0 && selectedDFBBusinessObject.campaignFormatMajorVersion > 0) {
      if (selectedDFBBusinessObject.isGeneric && clientDropDownValue.trim().length == 0) {
        setError("Please select a client");
        setDisableDropDown(false);
      } else {
        await props.fetchFormatFieldsByFormat(selectedDFBBusinessObject.campaignFormatId, selectedDFBBusinessObject.campaignFormatMajorVersion, "metadata");
        if (selectedDFBBusinessObject && selectedDFBBusinessObject.hasInputFile) {
          await props.fetchFormatFieldsByFormat(selectedDFBBusinessObject.campaignFormatId, selectedDFBBusinessObject.campaignFormatMajorVersion, "file");
        }

        if (formatSelectDialogOpen) {
          setRedirectToMetadata(true);
          sessionStorage.removeItem("editMode");
          history.push({
            pathname: props.formatType == formatTypes.CAMPAIGN_TYPE ? routes.metadata : routes.createLicenses,
            state: {
              selectedDFBBusinessObject: selectedDFBBusinessObject,
              route: location.pathname,
              formatTypeName: props.formatTypeName,
              isNewDFBBusinessObject: true,
              editMode: false
            }
          })

        }
      }
    } else {
      setError("Please select a format");
    }
  };

  const onDFBBusinessObjectSelect = (dfbBusinessObject: any) => {
    let vendorsName: Array<string> = [];
    vendorObject = [];
    sessionStorage.setItem("selectedDFBBusinessObject", JSON.stringify(dfbBusinessObject));
    sessionStorage.setItem("formatTypeName", props.formatTypeName);
    sessionStorage.setItem("route", location.pathname);
    if (dfbBusinessObject != null && dfbBusinessObject.isGeneric) {
      setShowClientDropdown(true);
    } else {
      setShowClientDropdown(false);
    }
    setSelectedDFBBusinessObject(dfbBusinessObject);
    var clientVendors: any = dfbBusinessObject !== null && dfbBusinessObject.client !== null && dfbBusinessObject.client.vendorToClientMappings.length > 0 ? dfbBusinessObject.client.vendorToClientMappings : [];
    for (let index = 0; index < clientVendors.length; index++) {
      vendorsName.push(clientVendors[index].vendor.vendorName);
      vendorObject.push({ vendorId: clientVendors[index].vendor.vendorId, vendorName: clientVendors[index].vendor.vendorName });
    }
    setVendorDetails(vendorsName);
    if (dfbBusinessObject !== null) {
      if (!dfbBusinessObject.isGeneric) {
        setVendorDropDownValue(vendorObject[0].vendorName);
        setVendorId(vendorObject[0].vendorId);
        sessionStorage.setItem("selectedVendorName", vendorObject[0].vendorName);
        sessionStorage.setItem("selectedVendorId", vendorObject[0].vendorId);
        setDefaultVendorValue(vendorObject[0].vendorName);
        setDefaultVendorId(vendorObject[0].vendorId);
        setDisableDropDown(false);
      } else {
        sessionStorage.removeItem("selectedVendorName");
        sessionStorage.removeItem("selectedVendorId");
        setDisableDropDown(true);
        fetchClientsForGenericInputFormat(dfbBusinessObject);
      }
    } else {
      setDisableDropDown(true);
      setClientDropDownValue("");
      setVendorDropDownValue("");
      sessionStorage.removeItem("selectedClientName");
      sessionStorage.removeItem("selectedClientId");
    }
    setError("");
  };
  const onVendorOrClientSelect = (value: string | null) => {
    if (value != null) {
      if (!selectedDFBBusinessObject.isGeneric) {
        let vendorIdByVendorName: number = getVendorIdByVendorName(selectedDFBBusinessObject, value);
        setVendorDropDownValue(value);
        setVendorId(vendorIdByVendorName);
        sessionStorage.setItem("selectedVendorName", value);
        sessionStorage.setItem("selectedVendorId", vendorIdByVendorName.toString());
        setOpenConfirmDialog(true);
      } else {
        setClientDropDownValue(value);
        sessionStorage.setItem("selectedClientName", value);
        let clientId = getClientIdByClientName(clientsObj, value);
        sessionStorage.setItem("selectedClientId", clientId.toString());
        setError("");
      }
    } else if (selectedDFBBusinessObject.isGeneric && value == null) {
      setClientDropDownValue("");
      sessionStorage.removeItem("selectedClientName");
      sessionStorage.removeItem("selectedClientId");
      setError("Please select a client");
    }
  };

  const getClientIdByClientName = (clientsList: any[], clientName: string): number => {
    let clientId: number = 0;
    if (clientsList.length > 0) {
      clientsList.forEach((client) => {
        if (client.client.clientName === clientName) {
          clientId = client.client.clientId;
        }
      });
    }
    return clientId;
  };

  const getVendorIdByVendorName = (dfbBusinessObject: any, vendorName: string): number => {
    let tempDFBBusinessObject: any = dfbBusinessObject;
    let clientVendorId: number = vendorId;
    for (let index = 0; index < tempDFBBusinessObject.client.vendorToClientMappings.length; index++) {
      if (tempDFBBusinessObject.client.vendorToClientMappings[index].vendor.vendorName === vendorName) {
        clientVendorId = tempDFBBusinessObject.client.vendorToClientMappings[index].vendor.vendorId;
      }
    }
    return clientVendorId;
  };
  const handleConfirmationDialogClose = () => {
    sessionStorage.setItem("selectedVendorName", defaultVendorValue);
    sessionStorage.setItem("selectedVendorId", defaultVendorId.toString());
    setVendorDropDownValue(defaultVendorValue);
    setOpenConfirmDialog(false);
  };
  function getSearchPlaceHolder() {
    return props.searchPlaceHolder;
  }

  const handleChipDelete = (chipToDelete: any) => () => {
    clearSortAndSearchSessions();
    setSearchCriteria("");
    getSortOptions();
    if (!filterFormats.includes(chipToDelete)) {
      sessionStorage.setItem(`selected${formatType}User`, "");
    } else {
      let newSelectedClients: any = [];
      let selectedClients: any = sessionStorage.getItem(`selected${formatType}Clients`);
      newSelectedClients = JSON.parse(selectedClients);
      newSelectedClients = newSelectedClients.filter((item: string) => item !== chipToDelete);
      setSelectedClients(newSelectedClients);
      sessionStorage.setItem(`selected${formatType}Clients`, JSON.stringify(newSelectedClients));
    }
    setRevertListConfig(!revertListConfig);
    setSearchCriteria("");
    setChips((chips) => chips.filter((chip) => chip !== chipToDelete));
    clearPaginationSessions();
    getFilteredData(props.dfbBusinessObjects ? props.dfbBusinessObjects : []);
  };

  const getUpdatedSortOptions = async (tempSortOptions: any, dfbList: any, lastAction:any) => {
    let tempSortArray: any = tempSortOptions;
    let sortObject: any;
    for (let i = 0; i < tempSortArray.length; i++) {
      if (tempSortArray[i].sortOrder !== 0) {
        sortObject = tempSortArray[i];
      }
    }
    if (sortObject != null) {
      dfbList = await handleSort(sortObject, dfbList, lastAction);
    } else {
      getSortOptions();
    }
    return dfbList;
  }

  const getSortOptions = () => {
    let tempSortOptions = [];
    if (sessionStorage.getItem('sortOptionDetails') != null) {
      setSortDataFromSessionStorage(setSortOptions);
    }
    else {
      if (formatType === formatTypesFullform.CAMPAIGN_TYPE) {
        tempSortOptions = props.getSortOptionsBasedOnFilter?.(isArchiveFilterSelected);
      } else {
        tempSortOptions = props.displayProperties;
      }
      setSortOptions(tempSortOptions);
    }
    setRevertListConfig(!revertListConfig);
  };

  const clearFilters = () => {
    clearSortAndSearchSessions();
    getSortOptions();
    setChips([]);
    setSelectedClients([]);
    setSearchCriteria("");
    setRevertListConfig(!revertListConfig);
    sessionStorage.removeItem(`selected${formatType}User`);
    sessionStorage.removeItem(`selected${formatType}Clients`);
    clearPaginationSessions();
    getFilteredData(props.dfbBusinessObjects ? props.dfbBusinessObjects : []);
  };

  const setChipDataFromSessionStorage = () => {
    let selectedClients: any = sessionStorage.getItem(`selected${formatType}Clients`);
    let selectedUser: any = sessionStorage.getItem(`selected${formatType}User`);
    let tempChips: any = [];
    if (selectedClients != null) {
      setSelectedClients(JSON.parse(selectedClients));
      tempChips = JSON.parse(selectedClients);
    }
    if (selectedUser != null && selectedUser.length > 0) {
      setSelectedUser(selectedUser);
      tempChips.push(selectedUser);
    }
    setChips(tempChips);
    return tempChips;
  };

  const setChipsData = () => {
    setChipDataFromSessionStorage();
  };
  const warningDialog = (
    <div style={{ padding: theme.spacing(0, 0, 2, 0), width: "270px", height: "35px" }}>
      <Typography variant={"body1"}>{messageBundle.ChangeVendorName}</Typography>
    </div>
  );
  const body = (
    <div>
      {props.formatListFetchingInProgress ? (
        <Grid container justify="center" style={componentStyle.loaderContainer}>
          <CircularProgress />
        </Grid>
      ) : (
        <div>
          <Autocomplete disabled={props.formatFieldsFetchInProgress} options={formats === null || formats === undefined ? [] : formats} getOptionLabel={(option) => option.formatName + ` v-${option.campaignFormatMajorVersion}`} style={{ minWidth: 340 }} onChange={(event, value) => onDFBBusinessObjectSelect(value)} renderInput={(params) => <TextField {...params} label={props.messages.Format} required={true} variant="outlined" />} />
          {props.formatType === formatTypes.CAMPAIGN_TYPE ? <Autocomplete disableClearable disabled={props.formatFieldsFetchInProgress} options={vendorDetails.length > 0 ? vendorDetails : []} getOptionLabel={(option) => option} style={{ minWidth: 340, paddingTop: "20px", paddingBottom: "5px" }} value={vendorDropDownValue} autoSelect={true} onChange={(event, value) => onVendorOrClientSelect(value)} renderInput={(params) => <TextField {...params} label={props.messages.vendor} required={true} variant="outlined" />} /> : props.formatType === formatTypes.LICENSE_TYPE && showClientDropdown ? <Autocomplete disabled={disableDropDown} options={clientDetails.length > 0 ? clientDetails : []} getOptionLabel={(option) => option} style={{ minWidth: 340, paddingTop: "20px", paddingBottom: "5px" }} value={clientDropDownValue} onChange={(event, value) => onVendorOrClientSelect(value)} renderInput={(params) => <TextField {...params} label={`Client`} required={true} variant="outlined" />} /> : null}
        </div>
      )}
      {error.length > 1 ? <Typography color="error">{error}</Typography> : null}
    </div>
  );

  let listRowRenderer = (dfbBusinessObject: any) => {
    let row = (
      <Grid container direction="column">
        <Grid item container direction="row" style={{ padding: theme.spacing(0, 0, 1.5, 0) }}>
          <Grid item xs={6} style={{ display: "flex" }}>
            {dfbBusinessObject.dfbBusinessObjectStatus && getStatusIcon(dfbBusinessObject.dfbBusinessObjectStatus.statusCode)}
            <Typography variant="body2" style={{ fontWeight: 600, padding: theme.spacing(0, 0, 0, 0.5) }}>
              {dfbBusinessObject.billCode}
            </Typography>
          </Grid>
          <Grid item container xs={6} justify="flex-end">
            {dfbBusinessObject.vendor && <Typography variant="body2">{dfbBusinessObject.vendor ? dfbBusinessObject.vendor.vendorName : ""}</Typography>}
          </Grid>
        </Grid>
        <Grid item container direction="row">
          <Grid item xs={8}>
            <Typography variant="body2" style={{ opacity: 0.87 }}>
              {dfbBusinessObject.campaignFormat ? dfbBusinessObject.campaignFormat.formatName : ""}
            </Typography>
          </Grid>
          <Grid item container xs={4} justify="flex-end">
            <Typography variant="body2" style={{ opacity: 0.87 }}>
              {(dfbBusinessObject.recordCount && dfbBusinessObject.recordCount > 0) ? `${dfbBusinessObject.recordCount} records ` : '0 records'}
            </Typography>
          </Grid>
        </Grid>
        {((formatType === formatTypesFullform.CAMPAIGN_TYPE && isActiveFilterSelected) || formatType === formatTypesFullform.LICENSE_TYPE) && (
          <Grid item container direction="row">
            <Grid item xs={8}>
              <Typography variant="body2" style={{ opacity: 0.6 }}>
                {dfbBusinessObject.createdBy ? dfbBusinessObject.createdBy : ""}
              </Typography>
            </Grid>
            <Grid item container xs={4} justify="flex-end">
              <Typography variant="caption" align="right" style={{ opacity: 0.6 }}>
                {dfbBusinessObject.createdDate ? new Date(dfbBusinessObject.createdDate).toLocaleDateString() : ""}
              </Typography>
            </Grid>
          </Grid>
        )}
        {isArchiveFilterSelected && formatType === formatTypesFullform.CAMPAIGN_TYPE && (
          <Grid item container direction="row" justify="space-between" spacing={0}>
            <Grid item xs={6} direction="column">
              <Box mb={-0.5} mt={1}>
                <Typography variant="caption" style={{ opacity: 0.6 }}>
                  {props.messages.createdBy}
                </Typography>
              </Box>
              <Box>
                <Typography variant="caption" style={{ opacity: 0.6 }}>
                  {dfbBusinessObject.createdBy ? dfbBusinessObject.createdBy : ""}
                  &nbsp;&nbsp;
                  {dfbBusinessObject.createdDate ? new Date(dfbBusinessObject.createdDate).toLocaleDateString() : ""}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6} container direction="column" alignItems="flex-end">
              <Box mb={-0.5} mt={1}>
                <Typography variant="caption" style={{ opacity: 0.6 }}>
                  {props.messages.acrhivedOn}
                </Typography>
              </Box>
              <Box>
                <Typography variant="caption" style={{ opacity: 0.6 }}>
                  {dfbBusinessObject.updatedDate ? new Date(dfbBusinessObject.updatedDate).toLocaleDateString() : ""}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        )}
      </Grid>
    );
    return row;
  };

  const searchableFields = ["billCode", "formatName", "recordCount", "createdBy", "statusDescription", "vendorName"];

  const handleSearch = async (searchText: string) => {
    let dfbBusinessObjects: any = [];
    dfbBusinessObjects = props.dfbBusinessObjects;
    let tempRows: any = filterDFBBusinessObjectsByValue(chips.length > 0 ? filteredDFBBusinessObjects : dfbBusinessObjects, searchText, searchableFields);
    setdfbBusinessObjectList(tempRows ? tempRows : []);
    sessionStorage.removeItem('selectedBusinessObjectFromList');
  };

  const handleSort = async (sortOptions: any, dfbBusinessObjectLists?: any, lastAction?:any) => {
    let array: any = [];
    if (sortOptions.sortOrder === 0) {
      if (dfbBusinessObjectLists != null && dfbBusinessObjectLists.length > 0) {
        setdfbBusinessObjectList(dfbBusinessObjectLists);
      }
      else {
        setdfbBusinessObjectList(props.dfbBusinessObjects ? props.dfbBusinessObjects : []);
      }
    }
    if (sortOptions.sortField === "recordCount") {
      if (dfbBusinessObjectLists != null && dfbBusinessObjectLists.length > 0) {
        array = handleNumericSort(dfbBusinessObjectLists, sortOptions.sortField, sortOptions.sortOrder);
      } else {
        array = handleNumericSort(dfbBusinessObjectList, sortOptions.sortField, sortOptions.sortOrder);
      }
      setdfbBusinessObjectList(array);
    } else if (sortOptions.sortField === "createdDate") {
      if (dfbBusinessObjectLists != null && dfbBusinessObjectLists.length > 0) {
        array = handleDateSort(dfbBusinessObjectLists, sortOptions.sortField, sortOptions.sortOrder);
      } else {
        array = handleDateSort(dfbBusinessObjectList, sortOptions.sortField, sortOptions.sortOrder);
      }
      setdfbBusinessObjectList(array);
    } else if (sortOptions.sortField === "updatedDate") {
      if (dfbBusinessObjectLists != null && dfbBusinessObjectLists.length > 0) {
        array = handleDateSort(dfbBusinessObjectLists, sortOptions.sortField, sortOptions.sortOrder);
      } else {
        array = handleDateSort(dfbBusinessObjectList, sortOptions.sortField, sortOptions.sortOrder);
      }
      setdfbBusinessObjectList(array);
    } else {
      if (dfbBusinessObjectLists != null && dfbBusinessObjectLists.length > 0) {
        array = handleStringSort(dfbBusinessObjectLists, sortOptions.sortField, sortOptions.sortOrder);
      } else {
        array = handleStringSort(dfbBusinessObjectList, sortOptions.sortField, sortOptions.sortOrder);
      }
      setdfbBusinessObjectList(array);
    }
    if (array.length > 0) {
      if(lastAction!=null && (dfbBusinessObjectLists != null || dfbBusinessObjectLists.length > 0)){
        return array;
      }else{
      setSelectedObjectIdFromList(array[0].objectId);
      if(dfbBusinessObjectLists == null || dfbBusinessObjectLists.length <0){
        clearPaginationSessions();
      }
      await props.fetchDFBBusinessObjectWithData(`id=${array[0].objectId}&objectType=${props.formatType}`, array[0].objectId);
      }
    }
    
  };
  const handleDFBBusinessObjectDelete = async (queryString: string, dfbBusinessObject: any) => {
    try {
      setSearchCriteria("");
      clearPaginationSessions();
      clearSortAndSearchSessions();
      let result = await props.deleteDFBBusinessObject(queryString, dfbBusinessObject);
      if (result) {
        setDetailsLoader(true);
        setListPageLoader(true);
        let dfbBusinessObjectList = await props.fetchDFBBusinessObjectList(`getBy=formatType&objectType=${props.formatType}&createdBy=${props.user ? props.user.username : ""}`);
        let dfbBusinessObjects: any = dfbBusinessObjectList;
        if (chips.length > 0) {
          dfbBusinessObjects = await getFilteredData(dfbBusinessObjects);
        } else {
          compareObjectsLists(dfbBusinessObjects);
          setListPageLoader(false);
          setdfbBusinessObjectList(dfbBusinessObjects);
          await fetchTheFirstRecord(dfbBusinessObjects);
        }
        setDetailsLoader(false);
        setRevertListConfig(!revertListConfig);
        return true;
      }
      return false;
    } catch (error) {
      return false;
    }
  };

  const handleOutputFileDelete = async (queryString: string) => {
    try {
      let result = await props.deleteOutputFile(queryString);
      if (result) {
        return true;
      }
      return false;
    } catch (error) {
      return false;
    }
  };
  const getCurrentPage = (objectId: any, dfbObjects: Array<dfbBusinessObjectListType>) => {
    let pageDetails: any = sessionStorage.getItem('listViewPagination') != null ? sessionStorage.getItem('listViewPagination') : JSON.stringify({ page: 0, rowsPerPage: 10 });
    let { page, rowsPerPage } = JSON.parse(pageDetails);
    let pageIndex = dfbObjects.findIndex((obj) => obj.objectId === objectId);
    let pageNumber = Math.floor(pageIndex / rowsPerPage);
    if (pageNumber < 0) {
      pageNumber = 0;
    }
    sessionStorage.setItem('listViewPagination', JSON.stringify({ page: pageNumber, rowsPerPage: rowsPerPage }))
    return pageNumber;
  }
  const handleDFBBusinessObjectClick = async (dfbBusinessObject: any) => {
    sessionStorage.setItem('selectedBusinessObjectFromList', JSON.stringify(dfbBusinessObject));
    if (!props.dfbBusinessObjectFetchingInProgress) {
      setSelectedObjectIdFromList(dfbBusinessObject.objectId);
      if (dfbBusinessObject.objectId != null) {
        await props.fetchDFBBusinessObjectWithData(`id=${dfbBusinessObject.objectId}&objectType=${props.formatType}`, dfbBusinessObject.objectId);
      }
    }
  };
  useEffect(() => {
    if (props.dfbBusinessObject !== undefined) {
      if (props.dfbBusinessObject.campaignFormat.formatType.formatTypeName !== formatType.toUpperCase()) {
        props.setDefaultDFBBusinessObject();
      }
      const dfbBusinessObjectIndex = dfbBusinessObjectList.findIndex((dfbBusinessObjectObj) => {
        if (props.dfbBusinessObject != undefined) {
          return dfbBusinessObjectObj.objectId === props.dfbBusinessObject.objectId;
        }
      });
      if (dfbBusinessObjectIndex !== -1) {
        dfbBusinessObjectList[dfbBusinessObjectIndex] = props.dfbBusinessObject;
        setdfbBusinessObjectList(dfbBusinessObjectList);
        setRefreshList(!refreshList);
      } else if (dfbBusinessObjectList.length > 0 && dfbBusinessObjectIndex === -1) {
        let activeFormatType = props.formatType === formatTypes.CAMPAIGN_TYPE ? formatTypesFullform.CAMPAIGN_TYPE : formatTypesFullform.LICENSE_TYPE;
        let toastNotificationText = `${props.messages.dfbBusinessObjectNotFound.replace("{{formatTypeName}}", `${activeFormatType}`)}`;
        toastMessage(TOAST_TYPE.SUCCESS, toastNotificationText);
        clearPaginationSessions();
        fetchTheFirstRecord(dfbBusinessObjectList);
      }
    }
  }, [props.dfbBusinessObject]);

  const fetchTheFirstRecord = async (dfbBusinessObjects: any) => {
    if (dfbBusinessObjects.length > 0) {
      sessionStorage.setItem('selectedBusinessObjectFromList', JSON.stringify(dfbBusinessObjects[0]));
      setSelectedObjectIdFromList(dfbBusinessObjects[0].objectId);
      await props.fetchDFBBusinessObjectWithData(`id=${dfbBusinessObjects[0].objectId}&objectType=${props.formatType}`, dfbBusinessObjects[0].objectId);
    } else {
      await props.setDefaultDFBBusinessObject();
    }
  };

  const closeArchiveMessageDialog = () => {
    sessionStorage.setItem(sessionStorageLabels.ARCHIVE_MESSAGE_DIALOG, "Disable");
    setOpenArchiveMessageDialog(false);
  };
  const getDFBBusinessObjectAndMetaData = () => {
    if (dfbBusinessObjectList.length > 0) {
      return { businessObject: props.dfbBusinessObject, metaData: props.dfbBusinessObjectMetadata };
    } else {
      return { businessObject: null, metaData: null };
    }
  };

  const getTabTitle = (): string => {
    if (props.formatType === formatTypes.CAMPAIGN_TYPE) {
      return props.messages.Campaigns;
    } else {
      return props.messages.Licenses;
    }
  };
  const informationMessageDialog = (
    <div style={{ padding: theme.spacing(0, 0, 3, 0), width: "500px", height: "50px" }}>
      <Typography variant={"body1"}>{props.messages.archivedDialogMessage}</Typography>
    </div>
  );

  /**
   * Currently not showing the button for
   * 1. if the user has not access
   * 2. if archived campaigns are chosen chose
   *
   * @returns
   */
  const shouldShowActionButton = () => {
    if (!isAuthorityAvailable(props.user ? props.user.auths : [], authorities.CAMPAIGN_CREATE, userGroups.DFB_ACCOUNT_MANAGER, props.user ? props.user.groups : []) || (props.formatType === formatTypes.CAMPAIGN_TYPE && isArchiveFilterSelected === true)) {
      return false;
    } else {
      return true;
    }
  };

  const defaultSelector = () => {
    let pageDetails: any = sessionStorage.getItem('listViewPagination') != null ? sessionStorage.getItem('listViewPagination') : JSON.stringify({ page: 0, rowsPerPage: 10 });
    let { page, rowsPerPage } = JSON.parse(pageDetails);
    let arrayData: Array<any> = dfbBusinessObjectList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    handleDFBBusinessObjectClick(arrayData[0]);
  }
  return (
    <div style={{ height: "100%", width: "100%" }}>
      <Grid item container>
        <Grid item container md={12} direction="row" justify="space-between">
          <Grid item container md={6}>
            <TabTitleComponent tabTitle={getTabTitle()}></TabTitleComponent>
          </Grid>
          <Grid item container md={6} justify="flex-end" style={{ padding: theme.spacing(1, 0, 0, 0) }}>
            {shouldShowActionButton() ? (
              <Button variant="contained" color="secondary" onClick={handleFormatSelectDialogOpenClick} data-testid="new-dfb-business-object-button">
                <Typography variant="button" data-testid="new-campaign">
                  {props.formatType === formatTypes.CAMPAIGN_TYPE ? props.messages.NewCampaign : props.messages.NewLicense}
                </Typography>
              </Button>
            ) : null}
          </Grid>
        </Grid>
        <div data-testid="format-selection-dropdown">
          <DialogContainer heading={props.formatType === formatTypes.CAMPAIGN_TYPE ? props.messages.SelectFormatAndVendor : showClientDropdown ? props.messages.SelectFormatAndClient : props.messages.SelectFormat} open={formatSelectDialogOpen} body={body} primaryButtonHeading={"Create"} onPrimaryButtonClick={onDFBBusinessObjectCreateClick} handleClose={handleFormatSelectDialogClose} primaryButtonStyle={componentStyle.primaryButton} primaryButtonLoader={props.formatFieldsFetchInProgress} secondaryButtonHeading={"Cancel"} />
        </div>
        <div data-testid="confirmation-dialog">
          <DialogContainer
            heading={props.messages.vendorNameConfirmationDialog}
            open={openConfirmDialog}
            body={warningDialog}
            primaryButtonHeading={"Yes"}
            onPrimaryButtonClick={() => {
              setOpenConfirmDialog(false);
            }}
            handleClose={handleConfirmationDialogClose}
            primaryButtonStyle={componentStyle.primaryButton}
            primaryButtonLoader={false}
            secondaryButtonHeading="Cancel"
          />
        </div>
        <div>
          <DialogContainer heading={"Information"} open={sessionStorage.getItem(sessionStorageLabels.ARCHIVE_MESSAGE_DIALOG) == "Enable" && openArchiveMessageDialog} body={informationMessageDialog} primaryButtonHeading={"Ok"} primaryButtonLoader={false} onPrimaryButtonClick={closeArchiveMessageDialog} />
        </div>
      </Grid>
      {sessionStorage.getItem("navIndex") == "0" && sessionStorage.getItem("activeFormatType") == formatTypes.CAMPAIGN_TYPE ? (
        <Grid container direction="row" spacing={1} md={6} justify="flex-start" style={{ padding: theme.spacing(1, 0, 0, 0) }}>
          <Grid item container md={4} direction="row" justify="flex-start" style={{ padding: theme.spacing(1, 0, 0, 0) }}>
            <Grid item>
              <RadioButtonComponent isChecked={isActiveFilterSelected} onChange={handleCampaignsRadioFilter} value="Active" testId="radio-button-active" size="small" uncheckedColor={isActiveFilterSelected ? orange[400] : colorPalette.dfbBlack} checkedColor={orange[600]} isDisabled={listPageLoader || detailsLoader || props.dfbBusinessObjectListFetchingInProgress || props.dfbBusinessObjectFetchingInProgress}></RadioButtonComponent>
            </Grid>
            <Grid item style={{ padding: theme.spacing(1, 0, 0, 0) }}>
              <span style={isActiveFilterSelected ? componentStyle.checkedRadioButton : componentStyle.unCheckedRadioButton}>{props.messages.activeStatus}</span>
            </Grid>
          </Grid>
          <Grid item container md={3} direction="row" justify="flex-start" style={{ padding: theme.spacing(1, 0, 0, 0) }}>
            <Grid item>
              <RadioButtonComponent isChecked={isArchiveFilterSelected} onChange={handleCampaignsRadioFilter} value="Archived" testId="radio-button-archived" size="small" uncheckedColor={isArchiveFilterSelected ? orange[400] : colorPalette.dfbBlack} checkedColor={orange[600]} isDisabled={listPageLoader || detailsLoader || props.dfbBusinessObjectListFetchingInProgress || props.dfbBusinessObjectFetchingInProgress}></RadioButtonComponent>
            </Grid>
            <Grid item style={{ padding: theme.spacing(1, 0, 0, 0) }}>
              <span style={isArchiveFilterSelected ? componentStyle.checkedRadioButton : componentStyle.unCheckedRadioButton}>{props.messages.archivedStatus}</span>
            </Grid>
          </Grid>
        </Grid>
      ) : null}
      {!listPageLoader && chips.length > 0 && (
        <Grid container alignItems="center" style={{ marginTop: "1%" }}>
          <Grid item container xs={1}>
            <Typography variant="body2">
              <Box fontWeight={700}>Active Filters</Box>
            </Typography>
          </Grid>
          <Grid item container xs={10} style={componentStyle.list}>
            {chips.map((data) => {
              return (
                <li>
                  <Chip size="small" deleteIcon={<Close />} label={data} onDelete={handleChipDelete(data)} style={componentStyle.chip} />
                </li>
              );
            })}
          </Grid>
          <Grid item container xs={1}>
            <Typography variant="body2" color="secondary" style={{ cursor: "pointer" }} onClick={clearFilters}>
              <Box fontWeight={700}>CLEAR FILTER</Box>
            </Typography>
          </Grid>
        </Grid>
      )}

      <MasterSlaveContainer
        listComponent={
          <ListComponent
            data={dfbBusinessObjectList}
            rowsRenderer={listRowRenderer}
            searchLabel={getSearchPlaceHolder()}
            handleSearch={handleSearch}
            sortOptions={sortOptions}
            handleSort={handleSort}
            handleListItemClick={handleDFBBusinessObjectClick}
            pagination={true}
            listLoader={listPageLoader}
            selectedItemFieldName={"objectId"}
            selectedItemFieldValue={selectedObjectIdFromList}
            refreshList={refreshList}
            revertListConfig={revertListConfig}
            selectedUser={selectedUser}
            selectedClients={selectedClients}
            setChipsData={setChipsData}
            definedFilterClients={definedFilterClients}
            getFilteredData={getFilteredData}
            dfbBusinessObjects={props.dfbBusinessObjects}
            definedFiltersUsers={definedFiltersUsers}
            showFilter={true}
            formatType={formatType}
            searchCriteria={searchCriteria}
            setSearchCriteria={setSearchCriteria}
            setRevertListConfig={setRevertListConfig}
            handleCampaignsRadioFilter={handleCampaignsRadioFilter}
            detailsLoader={detailsLoader || props.dfbBusinessObjectFetchingInProgress}
            showLoader={props.dfbBusinessObjectListFetchingInProgress}
            isArchiveFilterSelected={isArchiveFilterSelected}
            isActiveFilterSelected={isActiveFilterSelected}
            history={history}
            defaultSelect={defaultSelector}
            getSortOption={getSortOptions}
          />
        }
        detailsComponent={<ListDetailsComponent metaData={getDFBBusinessObjectAndMetaData().metaData} dfbBusinessObject={getDFBBusinessObjectAndMetaData().businessObject} pageLoader={detailsLoader || props.dfbBusinessObjectListFetchingInProgress} dfbBusinessObjectDelete={handleDFBBusinessObjectDelete} formatTypeName={props.formatTypeName} outputFileDelete={handleOutputFileDelete} formatType={formatType} showLoader={props.dfbBusinessObjectFetchingInProgress} isArchiveSelected={isArchiveFilterSelected} />}
      />
    </div>
  );
};

const componentStyle = {
  primaryButton: {},
  loaderContainer: {
    minHeight: 50,
  },
  chip: {
    borderRadius: "4px",
    border: "1px solid #F85E00",
    background: "white",
    margin: theme.spacing(0.5),
  },
  list: {
    listStyle: "none",
    display: "flex",
  },
  wrapperContainer: {
    padding: theme.spacing(1, 0, 0, 0),
    flexGrow: 1,
    background: "#FAFDFF",
  },
  checkedRadioButton: {
    color: colorPalette.secondary,
    fontWeight: "bold",
    opacity: 1,
  },
  unCheckedRadioButton: {
    color: colorPalette.dfbBlack,
    fontWeight: "bold",
    opacity: 0.8,
  },
};

const mapStateToProps = (state: RootState) => ({
  messages: state.messageBundle.messages,
  formatListFetchingInProgress: state.format.formatListFetchingInProgress,
  user: state.user.user,
  formatFieldsFetchInProgress: state.format.formatFieldsFetchingInProgress,
  dfbBusinessObjects: state.dfbBusinessObject.dfbBusinessObjects,
  dfbBusinessObjectListFetchingInProgress: state.dfbBusinessObject.dfbBusinessObjectListFetchingInProgress, // not using anywhere remove
  dfbBusinessObject: state.dfbBusinessObject.dfbBusinessObject,
  dfbBusinessObjectMetadata: state.dfbBusinessObject.dfbBusinessObjectMetadata,
  dfbBusinessObjectFetchingInProgress: state.dfbBusinessObject.dfbBusinessObjectFetchingInProgress,
  client: state.client.clients,
  clientListFetchInProgress: state.client.clientsFetchingInProgress,
});
const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      fetchFormatList,
      fetchFormatFieldsByFormat,
      fetchDFBBusinessObjectList,
      fetchDFBBusinessObjectWithData,
      deleteDFBBusinessObject,
      fetchFormatByType,
      setDefaultDFBBusinessObject,
      deleteOutputFile,
      fetchClients,
    },
    dispatch
  );
};
type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;
export const DFBBusinessObjectContainer = connect(mapStateToProps, mapDispatchToProps)(UnconnectedDFBBusinessObjectContainer);
