export const generateFilterObject = (formats: Array<any>, campaigns: Array<any>) => {
    try {
        let clients = [];
        let users = new Set();
        if (formats != undefined && campaigns != undefined) {
            for (let i = 0; i < formats.length; i++) {
                if (formats[i].client != null) {
                    let client: any = clients.find(function (element: any) {
                        return element.clientCode === formats[i].client.clientName;
                    });
                    if (client == undefined) {
                        client = {};
                        client.clientCode = formats[i].client.clientName;
                        client.formats = [];
                        client.formats.push(formats[i].formatName)
                        clients.push(client);
                    } else {
                        let formatName = client.formats.find(function (element: any) {
                            return element === formats[i].formatName;
                        });
                        if (formatName === undefined) {
                            client.formats.push(formats[i].formatName)
                        }
                    }
                }
            }
            for (let j = 0; j < campaigns.length; j++) {
                users.add(campaigns[j].createdBy)
            }
        }
        return { clients, users: Array.from(users.values()) };
    } catch (error) {
        console.log(error);
    }
}