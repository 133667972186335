import { actionType } from "./actionTypes";

export const setUser = (user: any) => {
    return (dispatch: (action:any)=>void)=>{
        dispatch(setUserToState({type: actionType.SETTING_USER, payload: user}));
    };
}

export const setUserToState = ({type, payload}:{type:string, payload:any})=>{
    console.log("setUser ")
    return {type: type, payload: payload};
}

export const userStub = {
    "firstname": "Girish",
    "lastname": "Prasad",
    "email": "gprasad@dmdconnects.com",
    "username": "gprasad",
    "groups": [
      "DFB_ADMINISTRATOR",
      "DFB_ACCOUNT_MANAGER"
    ],
    "auths": [
      "CAMPAIGN_CREATE",
      "CAMPAIGN_DELETE",
      "CAMPAIGN_VIEW"
    ],
    "iat": 1603367112,
    "exp": 1603403112
  }; 
