import React from 'react';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { Grid, IconButton, ListItemIcon, ListItemText } from '@material-ui/core';
import { Delete, MoreVert, FileCopy, Edit } from '@material-ui/icons';
import AssessmentOutlinedIcon from '@material-ui/icons/AssessmentOutlined';
import GetAppIcon from '@material-ui/icons/GetApp';
import { icons } from '../../../assets/theme';
import { authorities, isAuthorityAvailable } from '../../../utils/permissions';
import { formatTypes, userGroups, statusCode } from '../../../utils/dfbConstants';
import { useLocation, useHistory } from 'react-router-dom';
import { routes } from '../../../navigation/Routes';
import { useSelector, useDispatch } from "react-redux";
import { setFormatFieldsFromStorageToState } from '../../../actions/formatAction';
import { RootState } from '../../../reducers/rootReducer';

interface MenuListCompositionProps {
    handleDeleteDialogOpen(): void;
    handleOutputLogDialogOpen(): void;
    handleViewReportsDialogOpen(): void;
    handleDownlaodHCPs(): void;
    user: any;
    dfbBusinessObject: any;
    hideDelete: boolean;
    getMetaData(): any
}
const DetailsMenuComponent: React.FC<MenuListCompositionProps> = ({ user, dfbBusinessObject: dfbBusinessObject, handleDeleteDialogOpen, handleOutputLogDialogOpen, handleViewReportsDialogOpen, hideDelete, getMetaData, handleDownlaodHCPs }) => {

    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef<HTMLButtonElement>(null);
    const [redirectToMetadata, setRedirectToMetadata] = React.useState(false);
    const [editMode, setEditMode] = React.useState(false);
    const [copyMode, setCopyMode] = React.useState(false);
    const location = useLocation();
    const dispatch = useDispatch();
    const history = useHistory();
    const dfbBusinessObjectMetadata = useSelector((state: RootState) => state.dfbBusinessObject.dfbBusinessObjectMetadata);
    const handleClose = (event: React.MouseEvent<EventTarget>) => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return;
        }
        setOpen(false);
    };
    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };
    // return focus to the button when we transitioned from !open -> open
    const prevOpen = React.useRef(open);
    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current!.focus();
        }
        prevOpen.current = open;
    }, [open]);

    const handleDeleteDialog = (event: React.MouseEvent<EventTarget>) => {
        handleClose(event)
        handleDeleteDialogOpen()
    }

    const handleViewReportDialog = (event: React.MouseEvent<EventTarget>) => {
        setOpen(false);
        sessionStorage.setItem('selectedDFBBusinessObject', JSON.stringify(dfbBusinessObject));
        handleViewReportsDialogOpen();
    }

    const handleOnClickDownlaodLicensedHCPs = async () => {
        setOpen(false);
        handleDownlaodHCPs()
    }

    const handleEditDialog = async () => {
        sessionStorage.setItem('selectedClientName', dfbBusinessObject.campaignFormat.formatType.formatTypeAbbreviation == formatTypes.LICENSE_TYPE ? dfbBusinessObject?.client?.clientName : dfbBusinessObject.campaignFormat.client.clientName);
        sessionStorage.setItem('formatTypeName', dfbBusinessObject.campaignFormat.formatType.formatTypeName);
        sessionStorage.setItem('selectedDFBBusinessObject', JSON.stringify(dfbBusinessObject.campaignFormat));
        sessionStorage.setItem('editMode', JSON.stringify(true));
        sessionStorage.setItem('route', location.pathname);
        sessionStorage.setItem('dfbBusinessObject', JSON.stringify(dfbBusinessObject))
        sessionStorage.setItem('dfbBusinessObjectRunLog',JSON.stringify(dfbBusinessObject.dfbBusinessObjectRunLog))
        if(dfbBusinessObject?.campaignFormat?.formatType?.formatTypeAbbreviation == formatTypes.CAMPAIGN_TYPE){
            sessionStorage.setItem('selectedVendorName',dfbBusinessObject.vendor.vendorName);
        }
        setEditMode(true);
        sessionStorage.removeItem("copyMode");
        setRedirectToMetadata(true);
        getMetaData();
        history.push({
            pathname: dfbBusinessObject.campaignFormat.formatType.formatTypeAbbreviation == formatTypes.LICENSE_TYPE ? routes.editLicenses : routes.editCampaigns,
            state: {
                selectedDFBBusinessObject: dfbBusinessObject.campaignFormat,
                route: location.pathname,
                formatTypeName: dfbBusinessObject.campaignFormat.formatType.formatTypeName,
                isNewDFBBusinessObject: false,
                copiedBillcode: dfbBusinessObject.billCode,
                editMode: editMode
            }
        })
    }

    const handleOutputLogDialog = (event: React.MouseEvent<EventTarget>) => {
        handleClose(event)
        handleOutputLogDialogOpen()
    }
    const handleCopyDFBBusinessObject = async () => {
        sessionStorage.setItem('selectedDFBBusinessObject', JSON.stringify(dfbBusinessObject.campaignFormat));
        sessionStorage.setItem('formatTypeName', dfbBusinessObject.campaignFormat.formatType.formatTypeName);
        sessionStorage.setItem('route', location.pathname);
        sessionStorage.setItem('selectedVendorName', dfbBusinessObject.vendor.vendorName);
        sessionStorage.setItem('selectedVendorId', dfbBusinessObject.vendor.vendorId);
        sessionStorage.setItem('copyMode', JSON.stringify(true));;
        let metadataFormatsFields: any[] = [];
        if (dfbBusinessObjectMetadata) {
            for (let i = 0; i < dfbBusinessObjectMetadata.length; i++) {
                metadataFormatsFields.push(dfbBusinessObjectMetadata[i].formatField)
            }
        }
        sessionStorage.setItem('formatFields', JSON.stringify(metadataFormatsFields));
        sessionStorage.setItem('fileFormatFields', JSON.stringify(dfbBusinessObject.campaignFormat.campaignFormatsFields));
        sessionStorage.setItem('dfbBusinessObjectMetadata', JSON.stringify(dfbBusinessObjectMetadata));
        sessionStorage.removeItem("editMode");
        await dispatch(setFormatFieldsFromStorageToState());
        setRedirectToMetadata(true);
        history.push({
            pathname: dfbBusinessObject.campaignFormat.formatType.formatTypeAbbreviation == formatTypes.CAMPAIGN_TYPE ? routes.metadata : routes.createLicenses,
            state: {
                selectedDFBBusinessObject: dfbBusinessObject.campaignFormat,
                route: location.pathname,
                formatTypeName: dfbBusinessObject.campaignFormat.formatType.formatTypeName,
                isNewDFBBusinessObject: false,
                copiedBillcode: dfbBusinessObject.billCode
            }
        })
    }

    return (
        <Grid>
            <IconButton
                data-testid='more-button'
                color='secondary'
                ref={anchorRef}
                aria-haspopup="true"
                onClick={handleToggle}>
                <MoreVert />
            </IconButton>
            <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition placement='bottom-end'>
                <Paper>
                    <ClickAwayListener onClickAway={handleClose}>
                        <MenuList autoFocusItem={open} id="menu-list-grow">
                            {dfbBusinessObject.campaignFormat.formatType.formatTypeAbbreviation == formatTypes.LICENSE_TYPE && dfbBusinessObject.dfbBusinessObjectRunLog.length > 0 && dfbBusinessObject.dfbBusinessObjectStatus.statusCode == statusCode.CREATED ?
                                <div>
                                    <MenuItem onClick={handleViewReportDialog} data-testid='view-reports'>
                                        <ListItemIcon>
                                            <AssessmentOutlinedIcon />
                                        </ListItemIcon>
                                        <ListItemText primary={'View Reports'} />
                                    </MenuItem>
                                    <MenuItem onClick={handleOnClickDownlaodLicensedHCPs} data-testid='licensed-hcp'>
                                        <ListItemIcon>
                                            <GetAppIcon />
                                        </ListItemIcon>
                                        <ListItemText primary={'Licensed HCPs'} />
                                    </MenuItem>
                                </div>
                                : null
                            }
                            <MenuItem onClick={handleOutputLogDialog} data-testid='view-output-file'>
                                <ListItemIcon>
                                    <img alt='' src={icons.preview} style={{ opacity: 0.6 }} />
                                </ListItemIcon>
                                <ListItemText primary={'View Output Files'} />
                            </MenuItem>
                            {dfbBusinessObject.campaignFormat.formatType.formatTypeAbbreviation == formatTypes.CAMPAIGN_TYPE && isAuthorityAvailable(user ? user.auths : [], authorities.CAMPAIGN_CREATE, userGroups.DFB_ACCOUNT_MANAGER, user ? user.groups : []) ?
                                <MenuItem onClick={handleCopyDFBBusinessObject} data-testid='copy-button'>
                                    <ListItemIcon>
                                        <FileCopy />
                                    </ListItemIcon>
                                    <ListItemText primary={'Copy'} />
                                </MenuItem> : null}
                            {!hideDelete && isAuthorityAvailable(user ? user.auths : [], authorities.CAMPAIGN_DELETE, userGroups.DFB_ACCOUNT_MANAGER, user ? user.groups : [])
                                && (dfbBusinessObject && (dfbBusinessObject.dfbBusinessObjectRunLog === undefined || dfbBusinessObject.dfbBusinessObjectRunLog.length < 1) && dfbBusinessObject.dfbBusinessObjectStatus.statusCode !== 'UIP') ?
                                <MenuItem onClick={handleDeleteDialog} data-testid='delete-button'>
                                    <ListItemIcon>
                                        <Delete />
                                    </ListItemIcon>
                                    <ListItemText primary={'Delete'} />
                                </MenuItem> : null}
                            {dfbBusinessObject.dfbBusinessObjectStatus.statusCode == statusCode.CREATED && isAuthorityAvailable(user ? user.auths : [], authorities.CAMPAIGN_CREATE, userGroups.DFB_ACCOUNT_MANAGER, user ? user.groups : []) ?
                                <MenuItem onClick={handleEditDialog} data-testid='edit-button'>
                                    <ListItemIcon>
                                        <Edit />
                                    </ListItemIcon>
                                    <ListItemText primary={'Edit'} />
                                </MenuItem> : null}
                        </MenuList>
                    </ClickAwayListener>
                </Paper>
            </Popper>
        </Grid>
    );
}

export default DetailsMenuComponent;