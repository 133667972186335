import { Divider, Grid, makeStyles, TextField, Typography } from "@material-ui/core";
import { Info } from "@material-ui/icons";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { Autocomplete } from "@material-ui/lab";
import { DesktopDatePicker, LocalizationProvider } from "@material-ui/pickers";
import DateFnsUtils from "@material-ui/pickers/adapter/date-fns";
import "date-fns";
import Papa from "papaparse";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { bindActionCreators, Dispatch } from "redux";
import { deleteDFBBusinessObject, getDFBBusinessObjectDetailsByBillCode, saveDFBBusinessObject, saveDFBBusinessObjectDataRequests, setDefaultDFBBusinessObject, setFileProcessedPercentage, setSaveDFBBusinessObjectInitialState, updateMetadata } from "../../../actions/dfbBusinessObjectActions";
import messageBundle from "../../../assets/resource_bundle/messages.json";
import theme, { colorPalette } from "../../../assets/theme";
import { customValidatorForObjectTypeReturnValue } from "../../../exports/interfaces/customValidatorForObjectTypeReturnValue";
import { RootState } from "../../../reducers/rootReducer";
import { customValidatorForObjectType } from "../../../utils/customValidators/license/common/customValidatorForObjectType";
import { campaignFilterStatuses, CUSTOM_DELIMETER_FOR_MSL, datePickerConstants, dfbEncoding, dfbLineEndingConfig, formatTypes, MULTI_SELECT_LIST, sessionStorageLabels, TOAST_TYPE } from "../../../utils/dfbConstants";
import { extractFieldInformation } from "../../../utils/extractFieldInformationFromList";
import { fieldParser } from "../../../utils/fieldParser";
import { fieldValidator, trimWhiteSpaces } from "../../../utils/fieldValidator";
import { formatMessage } from "../../../utils/messagesUtils";
import * as validatorUtils from "../../../utils/validatorUtils";
import { withAppShell } from "../../hoc/app_shell/withAppShell";
import PageWrapper from "../../ui/PageWrapper";
import TabTitleComponent from "../../ui/TabTitleComponent";
import DeleteOutputFileAlertDialogContainer from "./DeleteOutputFileAlertDialog";
import ErrorsDialogComponent from "./ErrorsDialogComponent";
import TargetListUploadComponent from "./TargetListUploadComponent";
import ValidateAndSaveActionButtonsComponent from "./ValidateAndSaveActionButtonsComponent";
import toastMessage from "../../ui/ToastMessage";
import { clearPaginationSessions, clearSortAndSearchSessions } from "../../../utils/paginationUtils";
const useStyles = makeStyles((theme) => ({
  root: {},
  warningStyles: {
    "& .MuiFormLabel-root.Mui-error": {
      color: "#00497B !important",
    },
    "& .MuiInput-underline.Mui-error:after": {
      borderBottomColor: "black !important",
    },
    "& .MuiFormHelperText-root.Mui-error": {
      color: "orange !important",
    },
  },
}));

interface BusinessObjectMetaData {
  campaignFormatId: number;
  dfbBusinessObjectDataId: number;
  objectId: number;
  f_id: string;
  recordNumber: number;
  v: any;
  editMode: number
}
interface ErrorObj {
  fieldName: any;
  validated: boolean;
  error: string;
}
interface FileErrorObj {
  numberOfRecords: number;
  validated: boolean;
  errors: string[];
}

interface BusinessObject {
  billCode: number;
  campaignFormatId: number;
  campaignFormatMajorVersion: number;
  recordCount: number;
  fileName: string;
  createdBy: string;
}
interface BusinessObjectData {
  v: any;
  f_id: number;
  r: number;
}

type UpdateData = {
  changeBy: string | undefined;
  billCode: any;
  metadata: { [k: string]: any };
  objectVersion?: any;
  updatedMetadataChangeCommentsJSON?: UpdatedMetadataChangeCommentsJSON[];
  numberOfBusinessObjectRunLogs?: number,
};

type UpdatedMetadataChangeCommentsJSON = {
  fieldName: string;
  changeComments: string;
  currentFieldValue: any
}
const tempObj: UpdatedMetadataChangeCommentsJSON = {
  fieldName: "",
  changeComments: "",
  currentFieldValue: undefined
}
const UnconnectedCreateAndEditBusinessObjectComponent: React.FC<Props & RouteComponentProps> = (props) => {
  const [dfbBusinessObjectMetaDataList, setDFBBusinessObjectMetaDataList] = useState<Array<BusinessObjectMetaData>>([]);
  const [billCode, setBillCode] = useState<any>();
  const [refresh, setRefresh] = useState(false);
  const [metaDataErrorPresent, setMetaDataErrorPresent] = useState(false);
  const [validationInitiated, setValidationInitiated] = useState(false);
  const [errorList, setErrorList] = useState<Array<ErrorObj>>([]);
  const [fileSelected, setFileSelected] = useState(false);
  const [file, setFile] = useState<File>();
  const [totalRecordCount, setTotalRecordCount] = useState<number | null>(null);
  const [fileValidationInProgress, setFileValidationInProgress] = useState(false);
  const [errorDialogOpen, setErrorDialogOpen] = useState(false);
  const [fileValidationErrors, setFileValidationErrors] = useState<Array<any>>([]);
  const [dfbBusinessObjectsData, setDFBBusinessObjectsData] = useState<string>("");
  const [isFileValidated, setIsFileValidated] = useState(false);
  const [dfbBusinessObject, setDFBBusinessObject] = useState<any>();
  const [duplicateBillCodeCheck, setDuplicateBillCodeCheck] = useState(false);
  const [fileFieldValidationErrors, setFileFieldValidationErrors] = useState<Array<any>>([]);
  const history = useHistory();
  const [validationProgressDialogOpen, setValidationProgressDialogOpen] = React.useState(false);
  let matadataValidationFailed: boolean = false;
  const [selectedDate, setSelectedDate] = React.useState<Date | null>(null);
  const [openCalendarDialog, setOpenCalendarDialog] = React.useState(Array(props.formatFields?.length).fill(false));
  const [isCopied, setIsCopied] = React.useState(false);
  const [editMode, setEditMode] = React.useState(false);
  const [updateInProgress, setUpdateInProgress] = React.useState(false);
  const [customValidationErrors, setCustomValidationErrors] = useState<Array<string>>([]);
  const [customValidation, setCustomValidation] = React.useState(false);
  const [isShowDialogue, setIsShowDialogue] = React.useState(false);
  const [changedFieldAndCommentsList, setChangedFieldAndCommentsList] = React.useState<Array<UpdatedMetadataChangeCommentsJSON>>([]);
  const [metaFieldDetails, setMetaFieldDetails] = React.useState<UpdatedMetadataChangeCommentsJSON>(tempObj);
  const [dbMetaDataList, setdbMetaDataData] = React.useState<Array<BusinessObjectMetaData>>([])
  const [currentMetaDataFieldIndex, setCurrentMetaDataFieldIndex] = React.useState<number>(0);
  let { state }: any = props.location;

  const handleClickOpen = () => {
    setValidationProgressDialogOpen(true);
  };

  const handleClose = () => {
    setValidationProgressDialogOpen(false);
  };

  const navigateBack = () => {
    history.goBack();
  };
  const removeCopy=() =>{
    if (state && state.isNewDFBBusinessObject) {
      sessionStorage.removeItem('copyMode');
      setRefresh(!refresh);
    }
  }
  useEffect(function () {
    getInitialFormatFields();
    removeCopy();
    if (props.saveDFBBusinessObjectDataFailure) {
      props.setSaveDFBBusinessObjectInitialState();
    }
    if (props.dfbBusinessObjectMetadata) {
      sessionStorage.setItem("dfbBusinessObjectMetadata", JSON.stringify(props.dfbBusinessObjectMetadata));
    }
    let selectedDFBBusinessObject: any = JSON.parse(sessionStorage.getItem("selectedDFBBusinessObject") || "{}");
    let tempEditMode = Boolean(sessionStorage.getItem("editMode"));
    if (state != undefined && selectedDFBBusinessObject.formatType.formatTypeAbbreviation == formatTypes.LICENSE_TYPE && state.editMode) {
      setBillCode(props.dfbBusinessObject.billCode);
      setDFBBusinessObject(props.dfbBusinessObject);
      setEditMode(state.editMode);
      if (props.dfbBusinessObject) {
        sessionStorage.setItem("dfbBusinessObject", props.dfbBusinessObject ? JSON.stringify(props.dfbBusinessObject) : "{}");
      }
    } else if (selectedDFBBusinessObject.formatType != null && tempEditMode) {
      let dfbBusinessObject: any = JSON.parse(sessionStorage.getItem("dfbBusinessObject") || "{}");
      setDFBBusinessObject(dfbBusinessObject);
      setEditMode(tempEditMode);
      setBillCode(dfbBusinessObject.billCode);
    }
  }, []);

  useEffect(() => {
    if (state && state.isNewDFBBusinessObject) {
      if (dfbBusinessObjectMetaDataList.length < 1) {
        for (let i = 0; i < (props.formatFields ? props.formatFields.length : 0); i++) {
          let tempObj: any = {
            v: "",
            f_id: props.formatFields ? props.formatFields[i].fieldName : "",
          };
          let tempErrorObj: any = {
            fieldName: props.formatFields ? props.formatFields[i].fieldName : "",
            validated: true,
            error: "",
          };
          errorList.push(tempErrorObj);
          dfbBusinessObjectMetaDataList.push(tempObj);
        }
      }
    } else if (state && !state.isNewDFBBusinessObject) {
      if (dfbBusinessObjectMetaDataList.length < 1) {
        if (props.dfbBusinessObject
          && props.dfbBusinessObject?.dfbBusinessObjectRunLog
          && props.dfbBusinessObject?.campaignFormat?.campaignFormatsMetadataFields
        ) {
          const { campaignFormatsMetadataFields } = props.dfbBusinessObject.campaignFormat;
          const { dfbBusinessObjectRunLog } = props.dfbBusinessObject;
          const editableFields = campaignFormatsMetadataFields.filter((field: { editMode: number; }) => field.editMode !== 0);
          const editModeTwoFields = campaignFormatsMetadataFields.filter((field: { editMode: number; }) => field.editMode === 2);
          const editModeZeroFields = campaignFormatsMetadataFields.filter((field: { editMode: number; }) => field.editMode === 0);
          const totalEditableFields = editModeZeroFields.length + editModeTwoFields.length;

          const isMetadataEditDisabled =
            editableFields.length === 0 ||
            (dfbBusinessObjectRunLog.length > 0 &&
              (editModeTwoFields.length === campaignFormatsMetadataFields.length ||
                totalEditableFields === campaignFormatsMetadataFields.length));

          if (isMetadataEditDisabled) {
            sessionStorage.setItem('isMetadataEditDisabled', 'true');
          }
        }
        for (let i = 0; i < (props.dfbBusinessObjectMetadata ? props.dfbBusinessObjectMetadata.length : 0); i++) {
          let tempObj: any = {};
          if (Boolean(sessionStorage.getItem("editMode")) || props.dfbBusinessObjectMetadata[i].formatField.isCopiable) {
            tempObj = {
              v: props.dfbBusinessObjectMetadata[i].v ? props.dfbBusinessObjectMetadata[i].v : "",
              f_id: props.dfbBusinessObjectMetadata ? props.dfbBusinessObjectMetadata[i].formatField.fieldName : "",
              editMode: props.dfbBusinessObjectMetadata ? props.dfbBusinessObjectMetadata[i].formatField.editMode : null
            };
          } else {
            tempObj = {
              v: "",
              f_id: props.dfbBusinessObjectMetadata ? props.dfbBusinessObjectMetadata[i].formatField.fieldName : "",
            };
          }
          let tempErrorObj: any = {
            fieldName: props.dfbBusinessObjectMetadata ? props.dfbBusinessObjectMetadata[i].formatField.fieldName : "",
            validated: true,
            error: "",
          };
          errorList.push(tempErrorObj);
          dfbBusinessObjectMetaDataList.push(tempObj);
          let temp = { ...tempObj };
          dbMetaDataList.push(temp);
          setRefresh(!refresh);
        }
      }
    } else {
      if (dfbBusinessObjectMetaDataList.length < 1) {
        for (let i = 0; i < (props.formatFields ? props.formatFields.length : 0); i++) {
          let tempObj: any = {
            v: "",
            f_id: props.formatFields ? props.formatFields[i].fieldName : "",
          };
          if (Boolean(sessionStorage.getItem("editMode")) || Boolean(sessionStorage.getItem("copyMode"))) {
            let dfbBusinessObjectMetadata = JSON.parse(sessionStorage.getItem("dfbBusinessObjectMetadata") || "[]");
            if(Boolean(sessionStorage.getItem("copyMode")) && props.dfbBusinessObjectMetadata[i].formatField.isCopiable ){
            tempObj.v = dfbBusinessObjectMetadata[i].v ? dfbBusinessObjectMetadata[i].v : "";
            tempObj.editMode = dfbBusinessObjectMetadata[i].formatField.editMode;
            }
            else if(Boolean(sessionStorage.getItem("editMode"))){
            tempObj.v = dfbBusinessObjectMetadata[i].v ? dfbBusinessObjectMetadata[i].v : "";
            tempObj.editMode = dfbBusinessObjectMetadata[i].formatField.editMode;}
          }
          let tempErrorObj: any = {
            fieldName: props.formatFields ? props.formatFields[i].fieldName : "",
            validated: true,
            error: "",
          };
          errorList.push(tempErrorObj);
          dfbBusinessObjectMetaDataList.push(tempObj);
          let temp = { ...tempObj };
          dbMetaDataList.push(temp);
          setRefresh(!refresh);
        }
      }
    }
    if (editMode || fileValidationInProgress === true) {
      handleFormSubmission();
    }
  }, [props.formatFields, fileValidationInProgress]);

  const isValidDateFormat = (dateFormatValue: string): boolean => {
    let isValid: boolean = true;
    let formatsArray: Array<string> = dateFormatValue.split("/");
    if (formatsArray[0].length != 2 || formatsArray[1].length != 2 || formatsArray[2].length != 4) {
      isValid = false;
    }
    return isValid;
  };
  const handleCalendarDialog = (index: number, isOpen: boolean) => {
    let tempArray: any = [...openCalendarDialog];
    tempArray[index] = isOpen;
    setOpenCalendarDialog(tempArray);
    if (isOpen === false) {
      document.getElementById(`${index}date-picker`)?.focus();
    }
  };
  const formatDate = (userEnteredDate: string, dateFomat: string): string => {
    var formattedDateValue = new Date(userEnteredDate),
      month = "" + (formattedDateValue.getMonth() + 1),
      day = "" + formattedDateValue.getDate(),
      year = formattedDateValue.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [day, month, year].join(dateFomat.charAt(2));
  };
  const isDateValid = (enteredDateString: string): boolean => {
    if (!/^\d\d\/\d\d\/\d\d\d\d$/.test(enteredDateString)) {
      return false;
    }
    const parts = enteredDateString.split("/").map((enteredDateString) => parseInt(enteredDateString, 10));
    parts[0] -= 1;
    const date = new Date(parts[2], parts[0], parts[1]);
    return date.getMonth() === parts[0] && date.getDate() === parts[1] && date.getFullYear() === parts[2];
  };

  const handleChangeOfInput = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number, fieldName: string, formatFieldId: number, fieldTypeConstraint: string, fieldLengthConstraint: number | null, editMode: number) => {
    setCurrentMetaDataFieldIndex(index);
    dfbBusinessObjectMetaDataList[index].f_id = fieldName;
    dfbBusinessObjectMetaDataList[index].v = fieldParser(event.target.value, fieldTypeConstraint);
    dfbBusinessObjectMetaDataList[index].editMode = editMode;
    errorList[index].error = "";
    errorList[index].validated = true;
    if (fieldName === "Bill Code") {
      setBillCode(trimWhiteSpaces(event.target.value));
    }
    if (validationInitiated) {
      setValidationInitiated(false);
    }
    if (metaDataErrorPresent) {
      setMetaDataErrorPresent(false);
    }
    setRefresh(!refresh);
  };
  const handleDateChange = (date: Date | null, index: number, formatfield: any, enteredDate: string | undefined) => {
    setCurrentMetaDataFieldIndex(index);

    let validatedDate: string;
    const dateFormat = formatfield.additionalPropertyJson && formatfield.additionalPropertyJson.format ? formatfield.additionalPropertyJson.format : datePickerConstants.UI_DATE_FORMAT;
    setIsCopied(true);
    if (enteredDate != undefined && trimWhiteSpaces(enteredDate).length == 10) {
      const formattedDate = formatDate(trimWhiteSpaces(enteredDate), dateFormat);
      let isValidDate: boolean = isDateValid(trimWhiteSpaces(enteredDate));
      let dateParse: Date = new Date(trimWhiteSpaces(enteredDate));
      let isInvalidYear: boolean = trimWhiteSpaces(enteredDate).indexOf("0000", 0) == 6 || trimWhiteSpaces(enteredDate).indexOf("00", 0) == 3 || trimWhiteSpaces(enteredDate).indexOf("00", 0) == 0;
      validatedDate = trimWhiteSpaces(enteredDate).includes(" ") != true && isValidDateFormat(trimWhiteSpaces(enteredDate)) == true && isInvalidYear != true && isValidDate == true ? formattedDate : datePickerConstants.INVALID_DATE;
      setSelectedDate(dateParse);
    } else if (enteredDate != undefined && (trimWhiteSpaces(enteredDate).length < 10 || trimWhiteSpaces(enteredDate).length > 10)) {
      validatedDate = datePickerConstants.INVALID_DATE;
    } else {
      const parsedDate = date != null ? date.toString() : "";
      validatedDate = formatDate(trimWhiteSpaces(parsedDate), dateFormat) != datePickerConstants.NOT_A_NUMBER ? formatDate(trimWhiteSpaces(parsedDate), dateFormat) : "";
      setSelectedDate(date);
    }
    handleCalendarDialog(index, false);
    dfbBusinessObjectMetaDataList[index].f_id = formatfield.fieldName;
    dfbBusinessObjectMetaDataList[index].v = fieldParser(validatedDate, formatfield.fieldDataType.dataTypeName);
    errorList[index].error = "";
    errorList[index].validated = true;
    if (validationInitiated) {
      setValidationInitiated(false);
    }
    if (metaDataErrorPresent) {
      setMetaDataErrorPresent(false);
    }
  };
  const handleChangeOfDropDown = (fieldValue: any, index: number, fieldName: string, formatFieldId: number, fieldTypeConstraint: string, fieldLengthConstraint: number | null) => {
    setCurrentMetaDataFieldIndex(index);

    let value =
      props.formatFields &&
      props.formatFields[index].campaignFormatFieldListValues.find((value: any) => {
        return value.campaignFormatFieldListvalue == dfbBusinessObjectMetaDataList[index].v;
      });
    dfbBusinessObjectMetaDataList[index].f_id = fieldName;
    dfbBusinessObjectMetaDataList[index].v = fieldParser(fieldValue.campaignFormatFieldListvalue, fieldTypeConstraint);
    errorList[index].error = "";
    errorList[index].validated = true;
    if (validationInitiated) {
      setValidationInitiated(false);
    }
    if (metaDataErrorPresent) {
      setMetaDataErrorPresent(false);
    }
    setRefresh(!refresh);
  };
  const handleChangeOfMultiselectDropdown = (value: any, index: number, fieldName: string, formatFieldId: string, fieldTypeConstraint: string, fieldLengthConstraint: number | null) => {
    setCurrentMetaDataFieldIndex(index);
    let data = "";
    if (value.length > 0) {
      for (let i = 0; i < value.length; i++) {
        if (i < value.length - 1) {
          data = data + value[i].campaignFormatFieldListvalue + CUSTOM_DELIMETER_FOR_MSL;
        } else {
          data = data + value[i].campaignFormatFieldListvalue;
        }
      }
      dfbBusinessObjectMetaDataList[index].v = data;
      errorList[index].error = "";
      errorList[index].validated = true;
      if (validationInitiated) {
        setValidationInitiated(false);
      }
      if (metaDataErrorPresent) {
        setMetaDataErrorPresent(false);
      }
    } else {
      dfbBusinessObjectMetaDataList[index].v = "";
      setMetaDataErrorPresent(true);
      handleMetadataValidation(index);
    }
    setRefresh(!refresh);
  };

  const handleMetadataValidation = (fieldIndex: number) => {
    if (typeof dfbBusinessObjectMetaDataList[fieldIndex].v === "string") {
      dfbBusinessObjectMetaDataList[fieldIndex].v = trimWhiteSpaces(dfbBusinessObjectMetaDataList[fieldIndex].v);

    }
    if (editMode) {
      const businessObject: any = sessionStorage.getItem('dfbBusinessObject') !== null ? sessionStorage.getItem('dfbBusinessObject') : null;
      setDFBBusinessObject(JSON.parse(businessObject));
    }
    let tempErrorObj = fieldValidator(dfbBusinessObjectMetaDataList[fieldIndex].f_id, dfbBusinessObjectMetaDataList[fieldIndex].v, fieldIndex, dfbBusinessObjectMetaDataList, editMode, dfbBusinessObject, props.formatFields);
    errorList[fieldIndex].validated = tempErrorObj.validated;
    errorList[fieldIndex].error = tempErrorObj.error;
    if (!tempErrorObj.validated) {
      setMetaDataErrorPresent(true);
      matadataValidationFailed = true;
    }

    //checking if Edit Confirmation dialogue should show or not
    if (editMode && dfbBusinessObject.campaignFormat.formatType.formatTypeAbbreviation === formatTypes.CAMPAIGN_TYPE) {
      if (showEditCampaignConfirmDialogue(fieldIndex, matadataValidationFailed)) {
        setIsShowDialogue(true)
        setMetaFieldDetails({ ...metaFieldDetails, fieldName: dfbBusinessObjectMetaDataList[fieldIndex].f_id })
      }
      else {
        setIsShowDialogue(false)
      }
    }

  };
  const validate = () => {
    closeAllCalenders();
    setFileValidationInProgress(true);
  };

  const closeAllCalenders = () => {
    let tempArray: any = Array(openCalendarDialog.length).fill(false);
    setOpenCalendarDialog(tempArray);
  };

  const metaDataListToJson = (metaDataList: any) => {
    let obj: { [k: string]: any } = {};
    for (let i = 0; i < metaDataList.length; i++) {
      obj[metaDataList[i].f_id] = metaDataList[i].v;
    }
    return obj;
  };

  const save = async () => {
    let response;
    let toastNotificationText;

    handleClickOpen();
    setUpdateInProgress(true);

    let selectedDFBBusinessObject = sessionStorage.getItem("selectedDFBBusinessObject");
    let selectedCampaignFormat = selectedDFBBusinessObject ? JSON.parse(selectedDFBBusinessObject) : "";

    if (selectedCampaignFormat.formatType.formatTypeAbbreviation === formatTypes.CAMPAIGN_TYPE) {
      dfbBusinessObject.vendorId = Number(sessionStorage.getItem("selectedVendorId"));
    }

    if (selectedCampaignFormat.formatType.formatTypeAbbreviation === formatTypes.LICENSE_TYPE && selectedCampaignFormat?.client?.clientId) {
      dfbBusinessObject.clientId = selectedCampaignFormat.client.clientId;
    }

    if (selectedCampaignFormat.formatType.formatTypeAbbreviation === formatTypes.LICENSE_TYPE && selectedCampaignFormat.isGeneric) {
      dfbBusinessObject.clientId = Number(sessionStorage.getItem("selectedClientId"));
      dfbBusinessObject.clientName = sessionStorage.getItem("selectedClientName");
      dfbBusinessObject.isGeneric = selectedCampaignFormat.isGeneric;
    }

    if (editMode) {
      let updateDataNeedsToBeSend: UpdateData = {
        changeBy: props.user?.username,
        billCode: billCode,
        metadata: metaDataListToJson(dfbBusinessObjectMetaDataList),
        numberOfBusinessObjectRunLogs: getNumberOfBusinessObjectRunLogs(),
        objectVersion: dfbBusinessObject.objectVersion
      };

      if (dfbBusinessObject.campaignFormat.formatType.formatTypeAbbreviation === formatTypes.CAMPAIGN_TYPE) {
        updateDataNeedsToBeSend.updatedMetadataChangeCommentsJSON = changedFieldAndCommentsList;
        response = await updateMetadata(`action=updateCampaignMetadata&objectType=${formatTypes.CAMPAIGN_TYPE}&campaignId=${dfbBusinessObject.objectId}`, updateDataNeedsToBeSend);
      } else {
        response = await updateMetadata(`action=updateLicenseMetadata&objectType=${formatTypes.LICENSE_TYPE}&licenseId=${dfbBusinessObject.objectId}`, updateDataNeedsToBeSend);
      }
    } else if (!selectedCampaignFormat.hasInputFile) {
      dfbBusinessObject.billCode = billCode;
      let dataNeedsToBeSend = {
        metaData: metaDataListToJson(dfbBusinessObjectMetaDataList),
        dfbBusinessObject: dfbBusinessObject,
        objectType: selectedCampaignFormat.formatType.formatTypeAbbreviation
      };

      response = await props.saveDFBBusinessObject(dataNeedsToBeSend);
    } else {
      let userEmail = props.user ? props.user.email : "";
      dfbBusinessObject.billCode = billCode;
      let dataNeedsToBeSend = {
        email: userEmail,
        metaData: metaDataListToJson(dfbBusinessObjectMetaDataList),
        data: dfbBusinessObjectsData,
        dfbBusinessObject: dfbBusinessObject,
        objectType: selectedCampaignFormat.formatType.formatTypeAbbreviation
      };

      response = await props.saveDFBBusinessObjectDataRequests(dataNeedsToBeSend);
    }

    if (response) {
      let formatTypeName = state !== undefined ? state.formatTypeName.toLowerCase() : sessionStorage.getItem("formatTypeName");

      handleClose();
      setUpdateInProgress(false);

      if (editMode) {
        if (response.status === false && response.errorCode === "campaignArchivedError") {
          clearPaginationSessions();
          toastNotificationText = `${props.messages.editingArchivedObjectMetadataFailed.replaceAll("{{formatTypeName}}", `${formatTypeName.toLowerCase()}`)}`;
          toastMessage(TOAST_TYPE.SUCCESS, toastNotificationText);
        } else if (changedFieldAndCommentsList.length > 0) {
          toastNotificationText = `${props.messages.editAndDeleteSuccessMessage.replace("{{formatTypeName}}", `${formatTypeName.toLowerCase()}s`)}`
          toastMessage(TOAST_TYPE.SUCCESS, toastNotificationText);
        } else {
          toastNotificationText = `${props.messages.editSuccessMessage.replace("{{formatTypeName}}", `${formatTypeName.toLowerCase()}s`)}`
          toastMessage(TOAST_TYPE.SUCCESS, toastNotificationText);
        }
      } else if (!selectedCampaignFormat.hasInputFile) {
        if (response.status === true) {
          toastNotificationText = `${props.messages.dfbBusinessObjectSaveSuccessMessage.replace("{{formatTypeName}}", `${formatTypeName.toLowerCase()}`)}`
          toastMessage(TOAST_TYPE.SUCCESS, toastNotificationText);

          if (sessionStorage.getItem("activeFormatType") == formatTypes.CAMPAIGN_TYPE && sessionStorage.getItem(sessionStorageLabels.FILTERED_CAMPAIGN_STATUS) == campaignFilterStatuses.ARCHIVED_STATUS) {
            openArchiveMessageDialog();
          }
        } else if (response.status === false && response.errorCode === "duplicateBillcode") {
          toastNotificationText = `${props.messages.duplicateBillCodeError}`
          toastMessage(TOAST_TYPE.SUCCESS, toastNotificationText);
        } else if (response.status === false && response.errorCode === "salesForceError") {
          toastNotificationText = `${props.messages.salesForceErrorMessage}`
          toastMessage(TOAST_TYPE.SUCCESS, toastNotificationText);
        } else if (response.status === false) {
          toastNotificationText = `${props.messages.genericFailureMessage}`
          toastMessage(TOAST_TYPE.ERROR, toastNotificationText);
        }
      } else {
        toastNotificationText = `${props.messages.uploadInitiated.replace("{{formatTypeName}}", `${formatTypeName.toLowerCase()}s`)}`
        toastMessage(TOAST_TYPE.SUCCESS, toastNotificationText);

        if (sessionStorage.getItem("activeFormatType") == formatTypes.CAMPAIGN_TYPE && sessionStorage.getItem(sessionStorageLabels.FILTERED_CAMPAIGN_STATUS) == campaignFilterStatuses.ARCHIVED_STATUS) {
          openArchiveMessageDialog();
        }
      }
      if(!editMode){
        clearPaginationSessions();
      }
      clearSortAndSearchSessions();
      props.setDefaultDFBBusinessObject();
      navigateBack();
    } else {
      setUpdateInProgress(false);
      handleClose();
      toastNotificationText = `${props.messages.genericFailureMessage}`
      toastMessage(TOAST_TYPE.ERROR, toastNotificationText);
    }
  };

  const cancel = () => {
    sessionStorage.removeItem('isMetadataEditDisabled');
    props.setDefaultDFBBusinessObject();
    navigateBack();
  };

  const getDelimiter = (delimiterKey: string) => {
    if (delimiterKey.trim() === `\\t`) {
      return ["\t"];
    } else {
      return [delimiterKey];
    }
  };

  const generateParsingConfig = () => {
    const selectedDFBBusinessObject = getInitialFormatFields();
    const config = {
      delimitersToGuess: getDelimiter(selectedDFBBusinessObject.targetListDelim),
      encoding: dfbEncoding[selectedDFBBusinessObject.targetListEncoding - 1].toLowerCase(),
      newline: dfbLineEndingConfig[selectedDFBBusinessObject.targetListLineEndings - 1],
      quoteChar: selectedDFBBusinessObject.targetListTextQualifier ? selectedDFBBusinessObject.targetListTextQualifier : "",
    };
    return config;
  };

  const handleParseAndValidation = async () => {
    let parseResult = [];
    const selectedDFBBusinessObject = getInitialFormatFields();
    const config: any = generateParsingConfig();
    if (validatorUtils.validateLineEnding(dfbBusinessObjectsData, selectedDFBBusinessObject.targetListLineEndings)) {
      await Papa.parse(file ? file : "", {
        ...config,
        complete: function (results) {
          parseResult = results.data;
          if (results.errors.length > 0) {
            if (results.errors[0].code === "UndetectableDelimiter") {
              setFileValidationErrors([props.messages.DelimiterMismatchError]);
            } else {
              setFileValidationErrors([props.messages.InvalidFileFormat]);
            }
            setIsFileValidated(false);
            handleClose();
            setFileValidationInProgress(false);
          } else {
            fileValidation(parseResult);
          }
        },
      });
    } else {
      setFileValidationErrors([props.messages.InvalidFileFormat]);
      setIsFileValidated(false);
      handleClose();
      setFileValidationInProgress(false);
    }
  };

  const fileValidation = async (parseResult: any[]) => {
    let result: any;
    if (props.fileFormatFields !== null && parseResult.length > 0) {
      if (file && file.name.length > 200) {
        setFileValidationErrors([props.messages.FileNameLengthError]);
        setIsFileValidated(false);
      } else {
        result = await validatorUtils.fieldValidator(props.fileFormatFields, parseResult, props.setFileProcessedPercentage, props.messages);
        setFileValidationErrors(result.errorObj.errors);
        setFileFieldValidationErrors(result.errorObj.fieldErrors);
        setIsFileValidated(result.errorObj.validated);
        setTotalRecordCount(result.recordCount);
        setCustomValidation(!customValidation);
        let dfbBusinessObject = {
          billCode: billCode,
          campaignFormatId: props.fileFormatFields ? props.fileFormatFields[0].campaignFormatId : undefined,
          campaignFormatMajorVersion: props.fileFormatFields ? props.fileFormatFields[0].campaignFormatMajorVersion : undefined,
          recordCount: result.recordCount,
          fileName: file ? file.name : undefined,
          createdBy: props.user ? props.user.username : "",
        };
        setDFBBusinessObject(dfbBusinessObject);
      }
    }
    handleClose();
    setFileValidationInProgress(false);
  };

  const duplicateBillCodeValidation = async () => {
    let existingDFBBusinessObject;
    if (billCode !== undefined) {
      setDuplicateBillCodeCheck(true);
      let selectedDFBBusinessObject = sessionStorage.getItem("selectedDFBBusinessObject");
      let selectedCampaignFormat = selectedDFBBusinessObject ? JSON.parse(selectedDFBBusinessObject) : "";
      existingDFBBusinessObject = await getDFBBusinessObjectDetailsByBillCode(`getBy=billCode&billCode=${billCode}&objectType=${selectedCampaignFormat.formatType.formatTypeAbbreviation}`);
      if (existingDFBBusinessObject !== null) {
        let fieldIndex = errorList.findIndex((o) => o.fieldName.toLowerCase() === "bill code");
        if (fieldIndex !== -1) {
          setMetaDataErrorPresent(true);
          errorList[fieldIndex].validated = false;
          errorList[fieldIndex].error = props.messages.duplicateBillCodeError;
          matadataValidationFailed = true;
        }
      }
      setDuplicateBillCodeCheck(false);
    }
  };

  const handleFormSubmission = async () => {
    try {
      handleClickOpen();
      setValidationInitiated(true);
      for (let i = 0; i < dfbBusinessObjectMetaDataList.length; i++) {
        handleMetadataValidation(i);
      }
      if (editMode) {
        if (dfbBusinessObject.billCode !== billCode) {
          await duplicateBillCodeValidation();
        }
      } else {
        await duplicateBillCodeValidation();
      }
      if (matadataValidationFailed) {
        setValidationInitiated(false);
        handleClose();
        setFileValidationInProgress(false);
        window.scrollTo(0, 0);
      } else if (!isFileValidated) {
        setMetaDataErrorPresent(false);
        if (selectedDFBBusinessObject.hasInputFile && !editMode) {
          handleParseAndValidation();
        } else {
          if (!editMode) {
            let selectedDFBBusinessObject = sessionStorage.getItem("selectedDFBBusinessObject");
            let selectedCampaignFormat = selectedDFBBusinessObject ? JSON.parse(selectedDFBBusinessObject) : {};
            let dfbBusinessObject = {
              billCode: billCode,
              campaignFormatId: selectedCampaignFormat.campaignFormatId,
              campaignFormatMajorVersion: selectedCampaignFormat.campaignFormatMajorVersion,
              createdBy: props.user ? props.user.username : "",
            };
            setDFBBusinessObject(dfbBusinessObject);
          }
          setFileValidationInProgress(false);
          setIsFileValidated(true);
        }
      } else {
        setMetaDataErrorPresent(false);
        setFileValidationInProgress(false);
        setCustomValidation(!customValidation);
        handleClose();
      }
    } catch (error) {
      let fieldIndex = errorList.findIndex((o) => o.fieldName.toLowerCase() === "bill code");
      if (fieldIndex !== -1) {
        setMetaDataErrorPresent(true);
        errorList[fieldIndex].validated = false;
        errorList[fieldIndex].error = props.messages.billCodeValidationFailed;
        matadataValidationFailed = true;
      }
      handleClose();
      setFileValidationInProgress(false);
      if (matadataValidationFailed) {
        window.scrollTo(0, 0);
      }
    }
  };

  useEffect(() => {
    if (!editMode && totalRecordCount) {
      let validationResult: customValidatorForObjectTypeReturnValue = customValidatorForObjectType({
        objectType: selectedDFBBusinessObject.formatType.formatTypeAbbreviation,
        recordCap: extractFieldInformation(dfbBusinessObjectMetaDataList, "f_id", "Record Cap").v,
        fileRecordCount: totalRecordCount,
      });
      if (validationResult && !validationResult.isValidated && validationResult.errorMessage != null) {
        setCustomValidationErrors([validationResult.errorMessage]);
        setMetaDataErrorPresent(true);
        matadataValidationFailed = true;
        setValidationInitiated(false);
        setIsFileValidated(false);
      } else {
        setCustomValidationErrors([]);
      }
    }
  }, [customValidation]);

  const handleFileSelected = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (props.saveDFBBusinessObjectDataFailure) {
      props.setSaveDFBBusinessObjectInitialState();
    }
    if (event.target.files !== null && event.target.files[0] !== undefined && event.target.files[0].size > 0) {
      if (isFileValidated) {
        setIsFileValidated(false);
      }
      if (fileValidationErrors.length > 0) {
        setFileValidationErrors([]);
      }
      if (fileFieldValidationErrors.length > 0) {
        setFileFieldValidationErrors([]);
      }
      if (dfbBusinessObjectsData.length > 0) {
        setDFBBusinessObjectsData("");
      }
      if (customValidationErrors.length > 0) {
        setCustomValidationErrors([]);
      }
      setFileSelected(true);
      setFile(event.target.files[0]);
      const reader = new FileReader();
      reader.readAsText(event.target.files[0]);
      reader.onloadend = function () {
        if (reader.result !== null) {
          let fileArrayBuffer = reader.result.toString();
          setDFBBusinessObjectsData(fileArrayBuffer);
          setTotalRecordCount(null);
        }
      };
    }
  };
  const handleDialogOpen = () => {
    setErrorDialogOpen(true);
  };
  const handleDialogClose = () => {
    setErrorDialogOpen(false);
  };
  const getInitialFormatFields = (): any => {
    const { state }: any = props.location;
    let selectedDFBBusinessObject = sessionStorage.getItem("selectedDFBBusinessObject");
    return state && state.selectedDFBBusinessObject ? state.selectedDFBBusinessObject : selectedDFBBusinessObject ? JSON.parse(selectedDFBBusinessObject) : "";
  };
  const selectedDFBBusinessObject = getInitialFormatFields();
  const classes = useStyles();
  const getDate = (index: number): Date => {
    return new Date(dfbBusinessObjectMetaDataList[index].v.split("-").reverse().join("/"));
  };

  const handleNumberChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number, fieldName: string, formatFieldId: number, fieldTypeConstraint: string, fieldLengthConstraint: number | null) => {
    console.log("Current Value==>", dfbBusinessObjectMetaDataList[index].v);
    setCurrentMetaDataFieldIndex(index);

    dfbBusinessObjectMetaDataList[index].f_id = fieldName;
    let data = fieldParser(event.target.value.trim(), fieldTypeConstraint);
    dfbBusinessObjectMetaDataList[index].v = data;
    errorList[index].error = "";
    errorList[index].validated = true;
    if (validationInitiated) {
      setValidationInitiated(false);
    }
    if (metaDataErrorPresent) {
      setMetaDataErrorPresent(false);
    }
    setRefresh(!refresh);
  };

  const isEditable = (editMode: number) => {
    switch (editMode) {
      case 0:
        return true;
      case 1:
        return false;
      case 2: {
        if (dfbBusinessObject && dfbBusinessObject.dfbBusinessObjectRunLog.length > 0) {
          return true;
        } else {
          return false;
        }
      }
      //for edit mode 3 it is alwasy falls 
      default:
        return false;
    }
  };

  const getNumberOfBusinessObjectRunLogs = (): number => {
    let businessObjectRunLog = sessionStorage.getItem('dfbBusinessObjectRunLog')
    let numberOfBusinessObjectRunLogs = businessObjectRunLog != null ? JSON.parse(businessObjectRunLog) : [];
    return numberOfBusinessObjectRunLogs.length;
  }

  const getDropDownValues = (metaDataList: any, index: any, metaDataListObjects: any): any => {
    //split the values by custom delimiter
    let hcpTypes = metaDataList.v.split(/\|_\|/gm);

    // dropDown values should be in the same format as options to prepopulate the values
    let dropDownValues: { campaignFormatFieldListvalue: string }[] = [];

    hcpTypes.forEach((hcpType: string) => {
      metaDataListObjects.forEach((metaDataObject: { campaignFormatFieldListvalue: string }) => {
        if (hcpType.toLowerCase() === metaDataObject.campaignFormatFieldListvalue.toLowerCase()) {
          dropDownValues.push(metaDataObject);
        }
      });
    });

    return dropDownValues;
  };

  const openArchiveMessageDialog = () => {
    sessionStorage.setItem(sessionStorageLabels.ARCHIVE_MESSAGE_DIALOG, "Enable");
  };

  const getTabHeading = (): string => {
    const sessionformatType = sessionStorage.getItem("formatTypeName")?.toLowerCase();
    if (sessionformatType == String(props.messages.Campaign).toLowerCase() && editMode) {
      return props.messages.editCampaign;
    } else if (sessionformatType == String(props.messages.License).toLowerCase() && editMode) {
      return props.messages.editLicense;
    } else if (sessionformatType == String(props.messages.Campaign).toLowerCase()) {
      return props.messages.Campaign;
    } else if (sessionformatType == String(props.messages.License).toLowerCase()) {
      return props.messages.License;
    } else {
      return "";
    }
  };

  function compareOldAndNewFields(fieldIndex: number) {
    let isExistDataIndex = changedFieldAndCommentsList.findIndex(Obj => Obj.fieldName === dfbBusinessObjectMetaDataList[fieldIndex].f_id);
    if (isExistDataIndex == -1) {
      if (dfbBusinessObjectMetaDataList[fieldIndex].v != dbMetaDataList[fieldIndex].v) {
        return true;
      } else {
        return false;
      }
    }
    else if (dfbBusinessObjectMetaDataList[fieldIndex].v != changedFieldAndCommentsList[isExistDataIndex].currentFieldValue) {
      return true;
    } else {
      return false;
    }
  }

  const handleEditConfirmDialogueClose = () => {
    let value = metaFieldDetails.fieldName;
    let isExistValue = changedFieldAndCommentsList.find(obj => obj.fieldName === value);
    dfbBusinessObjectMetaDataList[currentMetaDataFieldIndex].v = isExistValue != undefined ? isExistValue.currentFieldValue : dbMetaDataList[currentMetaDataFieldIndex].v;
    setIsShowDialogue(false);
  }

  const handleSetUpdatedFieldAndComment = (comment: string) => {
    let temp = metaFieldDetails;
    temp.changeComments = comment.replace(/\n/g, ' ');
    metaFieldDetails.currentFieldValue = dfbBusinessObjectMetaDataList[currentMetaDataFieldIndex].v;
    setMetaFieldDetails(temp);
    let tempList = [...changedFieldAndCommentsList];
    let tempMetaField = tempList.find((metaObject) => metaObject.fieldName == metaFieldDetails.fieldName);
    if (tempMetaField == undefined || tempList.length == 0) {
      tempList.push(metaFieldDetails);
      setChangedFieldAndCommentsList(tempList);
    }
    else {
      let index = tempList.findIndex(obj => obj.fieldName == tempMetaField?.fieldName);
      changedFieldAndCommentsList[index] = metaFieldDetails;
    }
    setIsShowDialogue(false);
  }

  const showEditCampaignConfirmDialogue = (fieldIndex: number, matadataValidationFailed: boolean) => {
    if (matadataValidationFailed == false && dfbBusinessObjectMetaDataList[fieldIndex].editMode == 3 && dfbBusinessObject && dfbBusinessObject.dfbBusinessObjectRunLog.length > 0 && compareOldAndNewFields(fieldIndex)) {
      return true
    }
    else {
      return false
    }
  }
  const getBillCode = ( ) => {
    let obj = sessionStorage.getItem('selectedBusinessObjectFromList')
    return  obj != null ?  JSON.parse(obj).billCode :  '';  
  }
  return (
    <React.Fragment>
      <div style={{ padding: theme.spacing(1, 0, 2, 0) }}>
        <TabTitleComponent tabTitle={getTabHeading()}></TabTitleComponent>
      </div>
      <PageWrapper badgeTitle={`${("" + (state !== undefined ? state.formatTypeName : sessionStorage.getItem("formatTypeName"))).toUpperCase()}`} badgeSubtitle={` ${selectedDFBBusinessObject ? selectedDFBBusinessObject.formatName : ""}`}>
        <div style={{ height: "100%", width: "95%", padding: theme.spacing(0, 0, 0, 5) }}>
          <Grid item container direction="column" style={{ padding: theme.spacing(0, 0, 0, 2) }}>
            {selectedDFBBusinessObject.formatType != null && selectedDFBBusinessObject.formatType.formatTypeAbbreviation === formatTypes.CAMPAIGN_TYPE ? (
              <Grid container spacing={1} style={{ marginTop: "5px" }}>
                <Grid item xs={2}>
                  <Grid item container>
                    <Typography variant="body1" data-testid="vendor-header" style={{ color: colorPalette.primaryShade, fontWeight: 600 }}>
                      {("" + props.messages.vendor).toUpperCase()}
                    </Typography>
                  </Grid>
                  <Grid item container>
                    <Typography variant="body2" data-testid="vendor-name" style={{ fontWeight: 400, paddingBottom: "20px" }}>
                      {sessionStorage.getItem("selectedVendorName")}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item data-testid="copy-campaign-message">
                  {  sessionStorage.getItem("copyMode") ? (
                    <Grid container direction="row" alignItems="center" style={{ border: "0.5px solid #0087E0", borderRadius: "2px", opacity: 1, padding: "5px" }}>
                      <Grid item>
                        <Info style={{ color: "#0087E0", paddingRight: "2px" }}></Info>
                      </Grid>
                      <Grid item>
                        <Typography variant="body1">{formatMessage(messageBundle.copyCampaignAlertMessage, 1, "", getBillCode())}</Typography>
                      </Grid>
                    </Grid>
                  ) : null}
                </Grid>
              </Grid>
            ) : selectedDFBBusinessObject.formatType != null && selectedDFBBusinessObject.formatType.formatTypeAbbreviation === formatTypes.LICENSE_TYPE && selectedDFBBusinessObject.isGeneric ? (
              <Grid container spacing={1} style={{ marginTop: "5px" }}>
                <Grid item xs={2}>
                  <Grid item container>
                    <Typography variant="body1" data-testid="client-header" style={{ color: colorPalette.primaryShade, fontWeight: 600 }}>
                      {("" + props.messages.client).toUpperCase()}
                    </Typography>
                  </Grid>
                  <Grid item container>
                    <Typography variant="body2" data-testid="client-name" style={{ fontWeight: 400, paddingBottom: "20px" }}>
                      {sessionStorage.getItem("selectedClientName")}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            ) : null}
            <Grid item container>
              <Typography variant="body1" data-testid="metadata-header" style={{ color: colorPalette.primaryShade, fontWeight: 600 }}>
                {("" + props.messages.Metadata).toUpperCase()}
              </Typography>
              {validationInitiated && !duplicateBillCodeCheck && !metaDataErrorPresent ? <CheckCircleIcon style={{ color: colorPalette.success, width: 20, height: 20, padding: "2px 1px 1px 5px" }} /> : null}
            </Grid>
          </Grid>
          <Grid item container>
            {props.formatFields
              ? props.formatFields.map((formatfield, index) => (
                <Grid item container direction="column" xs={6} justify="flex-start" style={{ padding: theme.spacing(2) }}>
                  <Grid item style={{ width: "80%" }}>
                    {(() => {
                      switch (formatfield.fieldDataType.dataTypeName) {
                        case "Integer":
                          return (
                            <TextField
                              label={formatfield.fieldName}
                              placeholder={formatfield.fieldName}
                              data-testid={formatfield.fieldName}
                              required={formatfield.isMandatory}
                              size="small"
                              fullWidth
                              type="number"
                              disabled={editMode && isEditable(formatfield.editMode)}
                              value={dfbBusinessObjectMetaDataList.length > 0 ? dfbBusinessObjectMetaDataList[index].v : ""}
                              onChange={(event) => {
                                handleNumberChange(event, index, formatfield.fieldName, formatfield.campaignFormatFieldId, formatfield.fieldDataType.dataTypeName, formatfield.fieldLength);
                              }}
                              onBlur={(event) => {
                                closeAllCalenders();
                                handleMetadataValidation(index);
                                setRefresh(!refresh);
                              }}
                            />
                          );
                        case "List":
                          return (
                            <Autocomplete
                              data-testid={formatfield.fieldName}
                              disableClearable
                              options={formatfield.campaignFormatFieldListValues}
                              getOptionLabel={(option: { campaignFormatFieldListvalue: string }) => option.campaignFormatFieldListvalue}
                              value={
                                dfbBusinessObjectMetaDataList.length > 0
                                  ? formatfield.campaignFormatFieldListValues.find((value: any) => {
                                    return value.campaignFormatFieldListvalue == dfbBusinessObjectMetaDataList[index].v;
                                  })
                                  : null
                              }
                              onChange={(event, value) => {
                                handleChangeOfDropDown(value, index, formatfield.fieldName, formatfield.campaignFormatFieldId, formatfield.fieldDataType.dataTypeName, formatfield.fieldLength);
                              }}
                              renderInput={(params) => <TextField {...params} label={formatfield.isMandatory ? formatfield.fieldName + "*" : formatfield.fieldName} />}
                              onBlur={(event) => {
                                closeAllCalenders();
                                handleMetadataValidation(index);
                                setRefresh(!refresh);
                              }}
                              disabled={editMode && isEditable(formatfield.editMode)}
                            />
                          );
                        case MULTI_SELECT_LIST:
                          return (
                            <Autocomplete
                              data-testid={formatfield.fieldName}
                              disableCloseOnSelect
                              multiple={true}
                              options={formatfield.campaignFormatFieldListValues}
                              getOptionLabel={(option: { campaignFormatFieldListvalue: string }) => option.campaignFormatFieldListvalue}
                              value={dfbBusinessObjectMetaDataList && dfbBusinessObjectMetaDataList.length > 0 ? getDropDownValues(dfbBusinessObjectMetaDataList[index], index, formatfield.campaignFormatFieldListValues) : []}
                              onChange={(event, value) => {
                                handleChangeOfMultiselectDropdown(value, index, formatfield.fieldName, formatfield.campaignFormatFieldId, formatfield.fieldDataType.dataTypeName, formatfield.fieldLength);
                              }}
                              renderInput={(params) => <TextField {...params} label={formatfield.isMandatory ? formatfield.fieldName + "*" : formatfield.fieldName} />}
                              onBlur={(event) => {
                                closeAllCalenders();
                                handleMetadataValidation(index);
                                setRefresh(!refresh);
                              }}
                              disabled={editMode && isEditable(formatfield.editMode)}
                            />
                          );
                        case "Date":
                          return (
                            <LocalizationProvider dateAdapter={DateFnsUtils}>
                              <DesktopDatePicker
                                inputFormat={datePickerConstants.UI_DATE_FORMAT}
                                allowSameDateSelection={true}
                                open={openCalendarDialog[index]}
                                onClose={() => {
                                  handleCalendarDialog(index, false);
                                }}
                                onOpen={() => {
                                  handleCalendarDialog(index, true);
                                }}
                                label={formatfield.isMandatory ? formatfield.fieldName + "*" : formatfield.fieldName}
                                value={dfbBusinessObjectMetaDataList.length > 0 ? getDate(index) : null}
                                disableMaskedInput={true}
                                onChange={(dateSelected, enteredDate) => handleDateChange(dateSelected, index, formatfield, enteredDate)}
                                renderInput={(props) => (
                                  <TextField
                                    id={`${index}date-picker`}
                                    data-testid={formatfield.fieldName}
                                    autoComplete="off"
                                    className={classes.warningStyles}
                                    onFocus={() => {
                                      handleCalendarDialog(index, false);
                                    }}
                                    style={{ width: "100%" }}
                                    FormHelperTextProps={{ style: { display: "none" } }}
                                    InputLabelProps={{
                                      style: { color: "none" },
                                    }}
                                    onBlur={(event) => {
                                      handleMetadataValidation(index);
                                      setRefresh(!refresh);
                                    }}
                                    {...props}
                                  />
                                )}
                                disabled={editMode && isEditable(formatfield.editMode)}
                              />
                            </LocalizationProvider>
                          );
                        default:
                          return (
                            <TextField
                              label={formatfield.fieldName}
                              placeholder={formatfield.fieldName}
                              data-testid={formatfield.fieldName}
                              required={formatfield.isMandatory}
                              size="small"
                              multiline
                              fullWidth
                              value={dfbBusinessObjectMetaDataList.length > 0 ? dfbBusinessObjectMetaDataList[index].v : ""}
                              onChange={(event) => {
                                handleChangeOfInput(event, index, formatfield.fieldName, formatfield.campaignFormatFieldId, formatfield.fieldDataType.dataTypeName, formatfield.fieldLength, formatfield.editMode);
                              }}
                              onBlur={(event) => {
                                closeAllCalenders();
                                handleMetadataValidation(index);
                                setRefresh(!refresh);
                              }}
                              disabled={editMode && isEditable(formatfield.editMode)}
                            />
                          );
                      }
                    })()}
                  </Grid>
                  {errorList.length > 0 ? (
                    <Grid container>
                      <Typography variant="caption" color="error">
                        {errorList[index].error}
                      </Typography>
                    </Grid>
                  ) : null}
                </Grid>
              ))
              : null}
            <DeleteOutputFileAlertDialogContainer open={isShowDialogue} handleClose={handleEditConfirmDialogueClose} onPrimaryButtonClick={handleSetUpdatedFieldAndComment} primaryButtonLoader={false} outPutFilesToBeDeleted={() => { }} isEditConfirmDialogue={true} />
            {selectedDFBBusinessObject.hasInputFile ? (
              <>
                <Grid item container direction="column" style={{ padding: theme.spacing(2, 0, 0), width: "93%" }}>
                  <Divider variant="fullWidth" />
                </Grid>
                <TargetListUploadComponent handleFileSelected={handleFileSelected} cancel={cancel} validate={validate} save={save} closeAllCalenders={closeAllCalenders} isFileValidated={isFileValidated} messages={props.messages} fileSelected={fileSelected} validationInitiated={validationInitiated} metaDataErrorPresent={metaDataErrorPresent} fileValidationInProgress={fileValidationInProgress} saveDFBBusinessObjectDataInProgress={props.saveDFBBusinessObjectDataInProgress} file={file} fileValidationErrors={fileValidationErrors} totalRecordCount={totalRecordCount} validationProgressDialogOpen={validationProgressDialogOpen} fileProcessedPercentage={props.fileProcessedPercentage} displayTitle={true} message={props.messages.UploadFileDFBBusinessObjectCreate} fieldValidationErrors={fileFieldValidationErrors} recordCount={totalRecordCount} editMode={editMode} updateInProgress={updateInProgress} customValidationErrors={customValidationErrors} data-testid={"hasInputFile"} />
              </>
            ) : null}
            <ValidateAndSaveActionButtonsComponent isFileValidated={isFileValidated} messages={props.messages} validationInitiated={validationInitiated} metaDataErrorPresent={metaDataErrorPresent} fileValidationInProgress={fileValidationInProgress} saveDFBBusinessObjectDataInProgress={props.saveDFBBusinessObjectDataInProgress} file={file} fileValidationErrors={fileValidationErrors} validationProgressDialogOpen={validationProgressDialogOpen} fileProcessedPercentage={props.fileProcessedPercentage} cancel={cancel} validate={validate} save={save} fieldValidationErrors={fileFieldValidationErrors} recordCount={totalRecordCount} editMode={editMode} hasInputFile={selectedDFBBusinessObject.hasInputFile} updateInProgress={updateInProgress} dfbBusinessObjectsData={dfbBusinessObjectsData} />
            {props.saveDFBBusinessObjectDataFailure ? (
              <Grid item container justify="flex-end" style={{ padding: theme.spacing(1) }}>
                <Grid item style={{ padding: theme.spacing(0, 0, 0, 5) }}>
                  <Typography variant="caption" color="error" align="right">
                    {props.saveDFBBusinessObjectDataFailureMessage}
                  </Typography>
                </Grid>
              </Grid>
            ) : null}
          </Grid>
          {errorDialogOpen ? <ErrorsDialogComponent open={errorDialogOpen} handleClose={handleDialogClose} errors={fileValidationErrors} /> : null}
        </div>
      </PageWrapper>
    </React.Fragment>
  );
};

const mapStateToProps = (state: RootState) => ({
  user: state.user.user,
  formatFields: state.format.formatFields,
  fileFormatFields: state.format.fileFormatFields,
  messages: state.messageBundle.messages,
  saveDFBBusinessObjectDataInProgress: state.dfbBusinessObject.saveDFBBusinessObjectDataInProgress,
  saveDFBBusinessObjectDataSuccess: state.dfbBusinessObject.saveDFBBusinessObjectDataSuccess,
  fileProcessedPercentage: state.dfbBusinessObject.fileProcessedPercentage,
  objectId: state.dfbBusinessObject.objectId, //not using any where, remove
  saveDFBBusinessObjectDataFailure: state.dfbBusinessObject.saveDFBBusinessObjectDataFailure,
  saveDFBBusinessObjectDataFailureMessage: state.dfbBusinessObject.saveDFBBusinessObjectDataFailureMessage,
  dfbBusinessObjectMetadata: state.dfbBusinessObject.dfbBusinessObjectMetadata ? state.dfbBusinessObject.dfbBusinessObjectMetadata : JSON.parse(sessionStorage.getItem("dfbBusinessObjectMetadata") || "[]"),
  dfbBusinessObject: state.dfbBusinessObject.dfbBusinessObject,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      saveDFBBusinessObjectDataRequests,
      setFileProcessedPercentage,
      deleteDFBBusinessObject,
      setSaveDFBBusinessObjectInitialState,
      setDefaultDFBBusinessObject,
      saveDFBBusinessObject,
    },
    dispatch
  );
};

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;
export const CampaignCreateContainer = withAppShell(connect(mapStateToProps, mapDispatchToProps)(UnconnectedCreateAndEditBusinessObjectComponent), {});
export const LicenseCreateContainer = withAppShell(connect(mapStateToProps, mapDispatchToProps)(UnconnectedCreateAndEditBusinessObjectComponent), {});
export const LicenseEditContainer = withAppShell(connect(mapStateToProps, mapDispatchToProps)(UnconnectedCreateAndEditBusinessObjectComponent), {});
export const CampaignEditContainer = withAppShell(connect(mapStateToProps, mapDispatchToProps)(UnconnectedCreateAndEditBusinessObjectComponent), {});
