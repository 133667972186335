import { GridSize } from "@material-ui/core";
import { configurationCombination, skipValidationsLabel } from "../dfbConstants";

interface validationsToSkipInterface {
    gridSize: GridSize,
    validationName: string,
    validation_code: string,
}

export const getValidationsToBeSkipped = (transferType: string, authType: string) => {
    const { AWS_S3_K, AZURE_BLOB_SAS } = configurationCombination;
    const { bucketExistsValidationName, bucketExistsValidationCode } = skipValidationsLabel.AWS_S3_K;
    const { containerExistsValidationName, containerExistValidationCode, listContainerValidationName, listContainerValidationCode } = skipValidationsLabel.AZURE_BLOB_SAS;
    const configCombination = transferType != null ? `${transferType.replace(" ", "_")}_${authType}` : '';
    switch (configCombination) {
        case AWS_S3_K:
            let awsS3KeyValidationObject: Array<validationsToSkipInterface> = [{
                gridSize: 5,
                validationName: bucketExistsValidationName,
                validation_code: bucketExistsValidationCode
            }]
            return awsS3KeyValidationObject;
        case AZURE_BLOB_SAS:
            let azureSasValidationObject: Array<validationsToSkipInterface> = [{
                gridSize: 3,
                validationName: containerExistsValidationName,
                validation_code: containerExistValidationCode
            }, {
                gridSize: 5,
                validationName: listContainerValidationName,
                validation_code: listContainerValidationCode
            }];
            return azureSasValidationObject;
        default:
            return [];

    }
}
export const convertSkipValidationObject = (dataFeedObj: any) => {
    let skippedValidation: Array<any> = [];
    let validationsToSkip = dataFeedObj.validationsToSkip != null ? dataFeedObj.validationsToSkip : []
    let validationsNeedsToSkip = getValidationsToBeSkipped(dataFeedObj.transferType, dataFeedObj.authType);
    for (let outerIndex = 0; outerIndex < validationsNeedsToSkip.length; outerIndex++) {
        for (let innerIndex = 0; innerIndex < validationsToSkip.length; innerIndex++) {
            if (validationsToSkip.length > 0 && validationsToSkip[innerIndex].validation_code === validationsNeedsToSkip[outerIndex].validation_code) {
                skippedValidation.push({ index: outerIndex, validation_code: validationsNeedsToSkip[outerIndex].validation_code, isBoxChecked: false });
            }
        }
    }
    return skippedValidation;

}

export const constructSkipValidationObject = (validationsToSkip: any) => {
    let skippedValidation: Array<any> = [];
    for (let index = 0; index < validationsToSkip.length; index++) {
        if (!validationsToSkip[index].isBoxChecked) {
            skippedValidation.push({ validation_code: validationsToSkip[index].validation_code })
        }
    }
    if (skippedValidation.length > 0) {
        return skippedValidation;
    } else {
        return null;
    }
}

export const skipValidationList = (validationsToSkip: String) => {
    const { bucketExistsValidationCode, bucketExistsDetail } = skipValidationsLabel.AWS_S3_K;
    const { containerExistValidationCode, containerExistDetail, listContainerValidationCode, listContainerDetail } = skipValidationsLabel.AZURE_BLOB_SAS;
    switch (validationsToSkip) {
        case listContainerValidationCode:
            return listContainerDetail;
        case containerExistValidationCode:
            return containerExistDetail;
        case bucketExistsValidationCode:
            return bucketExistsDetail;
        default:
            return '-';
    }
}