import { Grid, TextField, Typography } from '@material-ui/core';
import React from 'react';
import { RootState } from '../../../reducers/rootReducer';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import theme from '../../../assets/theme';
import { AZURE_BLOB, AWS_S3, ROLL_BASED, formatTypes, SFTP, authTypes } from '../../../utils/dfbConstants';
import { skipValidationList } from '../../../utils/dataFeedConfig/dataFeedConfigUtils';
interface DataFeedConfigurationsDetailsProps {
    dataFeedConfig: any
    isChildConfig: boolean
}

const getScheduleText = (scheduleString: string): string => {
    const weekdays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    if (scheduleString) {
        const scheduleDenomination = scheduleString.substring(0, 1).toUpperCase();
        const scheduleDayNumberString = scheduleString.substring(1, scheduleString.length);
        switch (scheduleDenomination) {
            case 'D': return `Daily`;
            case 'W': return `Weekly - ${weekdays[Number(scheduleDayNumberString) - 1]}`;
            case 'M': return `Monthly - Day ${scheduleDayNumberString}`;
            default: return '-';
        }
    }
    else {
        return '-'
    }
}
const getTragetFolderLabelName = (folderName: string, targetFolderLabel: string): string => {
    if (folderName !== undefined) {
        const labelArray: Array<string> = folderName.split("/");
        if (labelArray.length >= 2) {
            return `/${targetFolderLabel}`;
        }
        else {
            return '';
        }
    }
    return '';
}

const UnconnectedDataFeedConfigurationsDetails: React.FC<Props & DataFeedConfigurationsDetailsProps> = (props) => {
    return (
        <Grid container justify='space-evenly'>
            <Grid item container direction='row' xs={12} style={{ padding: theme.spacing(0, 0, 0, props.isChildConfig ? 2 : 0) }}>
                {props.selectedFormat.formatType.formatTypeAbbreviation === formatTypes.CAMPAIGN_TYPE && props.isChildConfig !== true ? <Grid item container xs={3} direction='column' style={{ padding: theme.spacing(1, 0, 0, 0) }}>
                    <Grid item container xs={4} justify='flex-start'>
                        <Typography variant='caption' style={{ opacity: 0.6, padding: theme.spacing(0, 3, 0, 0) }} data-testid='vendor-label'>
                            {props.messages.vendor}
                        </Typography>
                        <Grid container>
                            <Typography variant='body2' data-testid='vendor-name'>
                                {props.dataFeedConfig.vendorName}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid> : null}
                {props.selectedFormat.formatType.formatTypeAbbreviation === formatTypes.CAMPAIGN_TYPE && props.isChildConfig !== true ? <Grid item container xs={3} direction='column' style={{ padding: theme.spacing(1, 0, 0, 0) }}>
                    <Grid item container xs={8} justify='flex-start'>
                        <Typography variant='caption' style={{ opacity: 0.6 }} data-testid='vendor-display-label'>
                            {props.messages.displayName}
                        </Typography>
                        <Grid container>
                            <Typography variant='body2' data-testid='vendor-display-name' align='left' style={{ wordBreak: 'break-word' }}>
                                {props.dataFeedConfig.vendorDisplayName}
                            </Typography>
                        </Grid>

                    </Grid>
                </Grid> : null}
                {props.isChildConfig !== true ? <Grid item container xs={6} direction='column' style={{ padding: theme.spacing(1, 0, 1, 0) }}>
                    <Grid item container justify='flex-start'>
                        <Typography variant='caption' style={{ opacity: 0.6 }} data-testid='schedule-label'>
                            {props.messages.Schedule}
                        </Typography>
                    </Grid>
                    <Grid item container>
                        <Typography variant='body2' data-testid='schedule'>
                            {getScheduleText(props.dataFeedConfig.schedule)}
                        </Typography>
                    </Grid>
                </Grid> : null}
                <Grid item container xs={6} direction='column' style={{ padding: theme.spacing(1, 0, 1, 0) }}>
                    <Grid item container justify='flex-start'>
                        <Typography variant='caption' style={{ opacity: 0.6 }} data-testid='transfer-type-label'>
                            {props.messages.TransferType}
                        </Typography>
                    </Grid>
                    <Grid item container>
                        <Typography variant='body2' data-testid='transfer-type'>
                            {props.dataFeedConfig.transferType ? props.dataFeedConfig.transferType : '-'}
                        </Typography>
                    </Grid>
                </Grid>
                {props.dataFeedConfig.transferType === SFTP ?
                    <Grid item container xs={6} direction='column' style={{ padding: theme.spacing(1, 0, 1, 0) }}>
                        <Grid item container justify='flex-start'>
                            <Typography variant='caption' style={{ opacity: 0.6 }} data-testid='path-type-label'>
                                {props.messages.pathType}
                            </Typography>
                            <Grid item container>
                                <Typography variant='body2' data-testid='path-type-name' >
                                    {props.dataFeedConfig.pathType ? props.dataFeedConfig.pathType : '-'}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    : null}
                <Grid item container xs={6} direction='column' style={{ padding: theme.spacing(1, 0, 1, 0) }}>
                    <Grid item container justify='flex-start'>
                        <Typography variant='caption' style={{ opacity: 0.6 }} data-testid='auth-type-label'>
                            {props.messages.AuthType}
                        </Typography>
                    </Grid>
                    <Grid item container>
                        <Typography variant='body2' data-testid='auth-type'>
                            {props.dataFeedConfig.authType ? props.dataFeedConfig.authType : '-'}
                        </Typography>
                    </Grid>
                </Grid>
                {props.dataFeedConfig.transferType !== AZURE_BLOB && props.dataFeedConfig.transferType !== AWS_S3 ? <Grid item container xs={6} direction='column' style={{ padding: theme.spacing(1.5, 0, 1, 0) }}>
                    <Grid item container justify='flex-start'>
                        <Typography variant='caption' style={{ opacity: 0.6 }} data-testid='port-label'>
                            {props.messages.Port}
                        </Typography>
                    </Grid>
                    <Grid item container>
                        <Typography variant='body2' data-testid='port'>
                            {props.dataFeedConfig.port ? props.dataFeedConfig.port : '-'}
                        </Typography>
                    </Grid>
                </Grid> : null}
                {props.dataFeedConfig.authType !== ROLL_BASED ? <Grid item container xs={6} direction='column' style={{ padding: theme.spacing(1, 0, 1, 0) }}>
                    <Grid item container justify='flex-start'>
                        <Typography variant='caption' style={{ opacity: 0.6 }} data-testid='auth-user-name-label'>
                            {props.dataFeedConfig.transferType === AWS_S3 ? props.messages.accessKey : props.dataFeedConfig.transferType !== AZURE_BLOB ? props.messages.AuthUsername : props.messages.azureStorageAccountName}
                        </Typography>
                    </Grid>
                    <Grid item container>
                        <Typography variant='body2' data-testid='auth-user-name' align='left' style={{ wordBreak: 'break-word' }}>
                            {props.dataFeedConfig.authUserName ? props.dataFeedConfig.authUserName : '-'}
                        </Typography>
                    </Grid>
                </Grid> : null}
                {props.dataFeedConfig.authType !== ROLL_BASED && props.dataFeedConfig.transferType !== AZURE_BLOB ? <Grid item container xs={6} direction='column' style={{ padding: theme.spacing(1, 0, 1, 0) }}>
                    <Grid item container justify='flex-start'>
                        <Typography variant='caption' style={{ opacity: 0.6 }} data-testid='auth-password-label'>
                            {props.dataFeedConfig.transferType === AWS_S3 ? props.messages.secretKey : props.messages.AuthPassOrKey}
                        </Typography>
                    </Grid>
                    <Grid item container>
                        {props.dataFeedConfig.authPasswordOrKey ?
                            <TextField type='password' disabled InputProps={{ disableUnderline: true }} style={{ color: 'black', wordBreak: 'break-word' }} value={props.dataFeedConfig.authPasswordOrKey} data-testid='auth-password' />
                            :
                            <Typography variant='body2'>
                                {'-'}
                            </Typography>
                        }
                    </Grid>
                </Grid> : null}
                {props.dataFeedConfig.transferType !== AWS_S3 ? <Grid item container xs={6} direction='column' style={{ padding: theme.spacing(1, 0, 0, 0) }}>
                    <Grid item container justify='flex-start'>
                        <Typography variant='caption' style={{ opacity: 0.6 }} data-testid='target-folder-label'>
                            {props.dataFeedConfig.transferType !== AZURE_BLOB ? props.messages.TargetFolder : `${props.messages.azureContainerName}${getTragetFolderLabelName(props.dataFeedConfig.targetFolder, props.messages.azureSubFolderName)}`}
                        </Typography>
                    </Grid>
                    <Grid item container>
                        <Typography variant='body2' data-testid='target-folder' align='left' style={{ wordBreak: 'break-word', padding: theme.spacing(0, 2, 0, 0) }}>
                            {props.dataFeedConfig.targetFolder ? props.dataFeedConfig.targetFolder : '-'}
                        </Typography>
                    </Grid>
                </Grid> : <Grid item container xs={6} direction='column' style={{ padding: theme.spacing(1, 0, 0, 0) }}>
                    <Grid item container justify='flex-start'>
                        <Typography variant='caption' style={{ opacity: 0.6 }} data-testid='aws-bucket-name-label'>
                            {props.messages.bucketName}
                        </Typography>
                    </Grid>
                    <Grid item container>
                        <Typography variant='body2' data-testid='aws-bucket-name' align='left' style={{ wordBreak: 'break-word', padding: theme.spacing(0, 2, 0, 0) }}>
                            {props.dataFeedConfig.hostName ? props.dataFeedConfig.hostName : '-'}
                        </Typography>
                    </Grid>
                </Grid>}
                {props.dataFeedConfig.transferType !== AWS_S3 ? <Grid item container xs={6} direction='column' style={{ padding: theme.spacing(1, 0, 1, 0) }}>
                    <Grid item container justify='flex-start'>
                        <Typography variant='caption' style={{ opacity: 0.6 }} data-testid='host-name-label'>
                            {props.dataFeedConfig.transferType !== AZURE_BLOB ? props.messages.HostName : props.messages.azureSasUrl}
                        </Typography>
                    </Grid>
                    <Grid item container>
                        <Typography variant='body2' data-testid='host-name' align='left' style={{ wordBreak: 'break-word' }}>
                            {props.dataFeedConfig.hostName ? props.dataFeedConfig.hostName : '-'}
                        </Typography>
                    </Grid>
                </Grid> : null}
                {props.dataFeedConfig.transferType === AWS_S3 && props.dataFeedConfig.targetFolder != null && props.dataFeedConfig.targetFolder.trim().length > 1 ? <Grid item container xs={6} direction='column' style={{ padding: theme.spacing(1, 0, 1, 0) }}>
                    <Grid item container justify='flex-start'>
                        <Typography variant='caption' style={{ opacity: 0.6 }} data-testid='aws-subfolder-label'>
                            {props.messages.azureSubFolderName}
                        </Typography>
                    </Grid>
                    <Grid item container>
                        <Typography variant='body2' data-testid='aws-subfolder' align='left' style={{ wordBreak: 'break-word' }}>
                            {props.dataFeedConfig.targetFolder ? props.dataFeedConfig.targetFolder : '-'}
                        </Typography>
                    </Grid>
                </Grid> : null}
                <Grid item container xs={6} direction='column' style={{ padding: theme.spacing(1, 0, 1, 0) }}>
                    <Grid item container justify='flex-start'>
                        <Typography variant='caption' style={{ opacity: 0.6 }} data-testid='status-label'>
                            {props.messages.Status}
                        </Typography>
                    </Grid>
                    <Grid item container>
                        <Typography variant='body2' data-testid='status'>
                            {props.dataFeedConfig.isActive ? props.messages.Active : props.messages.Inactive}
                        </Typography>
                    </Grid>
                </Grid>
                {props.dataFeedConfig.transferType === AZURE_BLOB || (props.dataFeedConfig.transferType === AWS_S3 && props.dataFeedConfig.authType === authTypes.AUTH_KEY) ? 
               <Grid item container xs={6} direction='column' style={{ padding: theme.spacing(1.5, 0, 1, 0) }}>
                    <Grid item container justify='flex-start'>
                        <Typography variant='caption' style={{ opacity: 0.6 }} data-testid='validationSkip-label'>
                            {props.messages.skipValidation}
                        </Typography>
                    </Grid>
                    <Grid item container>
                        <Typography variant='body2' data-testid='validationSkip'>
                            {props.dataFeedConfig.validationsToSkip ? 
                            props.dataFeedConfig.validationsToSkip.map((element: any, index: any ) => {
                                return(<div key={index} style={{textAlign: "left"}}>
                                    {skipValidationList(element.validation_code)}
                                    </div>
                                ) 
                            })
                        : '-' }
                        </Typography>
                    </Grid>
                </Grid> : null }
            </Grid>
        </Grid>
    )
}

const mapStateToProps = (state: RootState) => ({
    messages: state.messageBundle.messages,
    selectedFormat: state.format.selectedFormat,

})
const mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators({
    }, dispatch)
};
type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;
export const DataFeedConfigurationsDetails = connect(mapStateToProps, mapDispatchToProps)(UnconnectedDataFeedConfigurationsDetails)