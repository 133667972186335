import messageBundle from '../../src/assets/resource_bundle/messages.json'
import { datePickerConstants, dfbFieldLengthConstraints, MULTI_SELECT_LIST } from './dfbConstants'
import * as validations from '../utils/customValidators/license'
import { formatMessage } from './messagesUtils';
interface ErrorObj {
    validated: boolean;
    error: string;
}

export const fieldValidator = (
    fieldName: string,
    fieldValue: any,
    fieldIndex: number,
    dfbBusinessObjectMetaDataList: Array<any>,
    editMode: boolean,
    dfbBusinessObject: any,
    formatFields: any): ErrorObj => {
    let errorObj: any = {
        validated: true,
        error: ''
    };
    try {
        let fieldTypeConstraint = formatFields ? formatFields[fieldIndex].fieldDataType.dataTypeName : '';
        let fieldLengthConstraint = formatFields ? formatFields[fieldIndex].fieldLength : 0;
        let fieldRequired = formatFields ? formatFields[fieldIndex].isMandatory : false;
        let validationRegex = formatFields ? formatFields[fieldIndex].validationRegex : '';
        let validationErrorMessage = formatFields ? formatFields[fieldIndex].validationErrorMessage : '';
        let additionalPropertyJson = formatFields && formatFields[fieldIndex].additionalPropertyJson ? formatFields[fieldIndex].additionalPropertyJson : null;

        if (fieldTypeConstraint.length > 1 || (fieldLengthConstraint ? fieldLengthConstraint : 0) < 1) {
            if (fieldRequired && (fieldValue.toString().length < 1)) {
                errorObj.validated = false;
                errorObj.error = `The field is required`
                return errorObj;
            }
            else if (fieldTypeConstraint === 'Date' && fieldValue === datePickerConstants.INVALID_DATE) {
                errorObj.validated = false;
                errorObj.error = messageBundle.invalidDateFormat.concat(datePickerConstants.UI_DATE_FORMAT.toUpperCase());
                return errorObj;
            }
            else if (validationRegex !== null && validationRegex.length > 0) {
                let re = new RegExp(validationRegex);
                if (fieldValue !== undefined && !re.test(fieldValue.toString())) {
                    errorObj.validated = false;
                    errorObj.error = validationErrorMessage;
                    return errorObj;
                }
            } else if (typeof fieldValue !== fieldTypeConstraint.toLowerCase() && fieldTypeConstraint.toLowerCase() !== 'list' && fieldTypeConstraint.toLowerCase() !== 'date' && fieldTypeConstraint.toLowerCase() !== 'integer' && fieldTypeConstraint.toLowerCase() !== MULTI_SELECT_LIST.toLowerCase()) {
                errorObj.validated = false;
                errorObj.error = `The field has to be a ${fieldTypeConstraint}`
                return errorObj;
            }
            else if (fieldLengthConstraint && fieldValue !== undefined && fieldValue.toString().length > (fieldLengthConstraint ? fieldLengthConstraint : 0)) {
                errorObj.validated = false;
                errorObj.error = messageBundle.exceededFieldLength.concat(fieldLengthConstraint.toString());
                return errorObj;
            } else if (fieldName === dfbFieldLengthConstraints.BILLCODE_FIELD_NAME && fieldLengthConstraint === null && fieldValue.length > 100) {
                errorObj.validated = false;
                errorObj.error = messageBundle.exceededFieldLength.concat(dfbFieldLengthConstraints.BILLCODE_MAX_LENGTH);
                return errorObj;
            }
            else if (fieldTypeConstraint.toLowerCase() === "integer" && typeof fieldValue != "number") {
                errorObj.validated = false;
                errorObj.error = formatMessage(messageBundle.incorrectInputFormat, [fieldTypeConstraint])
                return errorObj;
            }
            if (additionalPropertyJson && additionalPropertyJson.validationFunction) {
                type StatusKey = keyof typeof validations;
                let functionName: StatusKey = additionalPropertyJson.validationFunction as keyof typeof validations;
                let response = validations[functionName]({
                    fieldName: fieldName,
                    fieldValue: fieldValue,
                    error: validationErrorMessage,
                    dfbBusinessObjectMetaDataList: dfbBusinessObjectMetaDataList,
                    additionalPropertyJson: additionalPropertyJson,
                    editMode: editMode,
                    dfbBusinessObject: dfbBusinessObject,
                    formatFields: formatFields
                });
                if (response && !response.isValid) {
                    errorObj.error = response.message;
                    errorObj.validated = false;
                }
            }
            return errorObj;
        }
        return {
            validated: false,
            error: `Required type not specified`
        };
    } catch (error) {
        errorObj.validated = false;
        errorObj.error = `Invalid field value`;
        return errorObj;
    }
}
export const trimWhiteSpaces = (fieldValue: string): string => {
    return fieldValue.trim();
}