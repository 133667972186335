import { frcJsonAttributes, errorObjectAttributes } from '../../components/functional/fileTransferConfiguration/fileRetrievalConfigurationJson';

export function lastRetrievalDateValidation(frcDataNeedsToBeSend: frcJsonAttributes, errorObject: errorObjectAttributes, messages: any) {
    return (): boolean => {
        if (frcDataNeedsToBeSend.lastRetrievalDate !== null && typeof frcDataNeedsToBeSend.lastRetrievalDate === 'string') {
            frcDataNeedsToBeSend.lastRetrievalDate = new Date(frcDataNeedsToBeSend.lastRetrievalDate);
        }
        if (!frcDataNeedsToBeSend.lastRetrievalDate) {
            errorObject.lastRetrievalDate = messages.FieldRequired;
            return false;
        } else if (Number.isNaN(frcDataNeedsToBeSend.lastRetrievalDate.getDate())) {
            errorObject.lastRetrievalDate = messages.invalidDate;
            return false;
        } else {
            if (!isPastDate(frcDataNeedsToBeSend.lastRetrievalDate)) {
                errorObject.lastRetrievalDate = messages.pastDateValidation;
                return false;
            } else {
                errorObject.lastRetrievalDate = '';
                return true;
            }
        }
    }
}

const isPastDate = (givenDate: Date): any => {
    let todayDate = new Date();
    let currentYear = todayDate.getFullYear();
    let currentMonth = todayDate.getMonth() + 1;
    let currentDay = todayDate.getDate();

    let givenYear = givenDate.getFullYear();
    let givenMonth = givenDate.getMonth() + 1;
    let givenDateDay = givenDate.getDate();

    let todayFormattedDate = new Date(currentYear + '-' + currentMonth + '-' + currentDay).getTime();
    let givenFormattedDate = new Date(String(givenYear + '-' + givenMonth + '-' + givenDateDay)).getTime();

    return (todayFormattedDate - givenFormattedDate) >= 0;
}