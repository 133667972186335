import { AnyAction } from "redux"
import { actionType } from "../actions/actionTypes";

type licenseReportsReducerState = {
    licenseReportsFetchingInProgress: boolean,
    licenseReportsFetchingSuccess: boolean,
    licenseReportsFetchingFailure: boolean,
    licenseReports:any,
    
}

const initialState: licenseReportsReducerState = {
    licenseReportsFetchingInProgress: false,
    licenseReportsFetchingSuccess: false,
    licenseReportsFetchingFailure: false,
    licenseReports: [],
    
}

export function licenseReportReducer(state = initialState, action: AnyAction): licenseReportsReducerState {
    switch (action.type) {
        case actionType.FETCHING_LICENSE_REPORTS_IN_PROGRESS:
            return { ...state, licenseReportsFetchingInProgress: true, licenseReportsFetchingSuccess: false, licenseReportsFetchingFailure: false };

        case actionType.FETCHING_LICENSE_REPORTS_SUCCESS:
            return { ...state, licenseReportsFetchingInProgress: false, licenseReportsFetchingSuccess: true, licenseReportsFetchingFailure: false, licenseReports: action.payload };

        case actionType.FETCHING_LICENSE_REPORTS_FAILURE:
            return { ...state, licenseReportsFetchingInProgress: false, licenseReportsFetchingSuccess: false, licenseReportsFetchingFailure: true };
        default:
            return state;
    }
}