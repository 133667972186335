import {Grid, InputAdornment, makeStyles, TextField } from '@material-ui/core';
import {Search } from '@material-ui/icons';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import theme from '../../../assets/theme';
import { RootState } from '../../../reducers/rootReducer';
import PageWrapper from '../../ui/PageWrapper';
import { reportsJson } from './reportsJson'
import { ReportListItemComponent } from './ReportListItemComponent'
interface ReportListComponentProps {
    handleComponent(componentName: string): void
}

const ReportingListViewComponent: React.FC<Props & ReportListComponentProps> = (props) => {
    const [accordianIndex, setAccordianIndex] = React.useState(-1);
    const classes = useStyles();

    const setAccordianIndexValue = (accordian: number) => {
        setAccordianIndex(accordian);
    }

    return (
        <Grid container>
            <PageWrapper>
                <React.Fragment>
                    <Grid container xs={12}>
                        <Grid item container direction='row' style={{ padding: theme.spacing(2, 1, 2) }}>
                            <Grid item xs={12}>
                               <TextField
                                    data-testid='search-field'
                                    variant='outlined'
                                    size='small'
                                    fullWidth
                                    placeholder={props.messages.SearchReport}
                                    value={''}
                                    InputProps={{
                                        startAdornment: <InputAdornment position='start'><Search style={{ height: 16, width: 16 }} /></InputAdornment>,
                                        classes: {
                                            input: classes.input,
                                            notchedOutline: classes.notchedOutline
                                        }
                                    }}
                                    style={{ background: '#C8DAE2', borderRadius: 2 }}
                                />
                            </Grid>
                            <Grid container direction='column' justify={'flex-start'} alignContent='flex-start' xs={12} style={{ minHeight: 300 }}>
                                {reportsJson.map((data, index) => (
                                    <ReportListItemComponent report={data} handleComponent={props.handleComponent} accordianIndex={accordianIndex} setAccordianIndex={setAccordianIndexValue} ></ReportListItemComponent>
                                ))}
                            </Grid>
                        </Grid>
                    </Grid>
                </React.Fragment>
            </PageWrapper>
        </Grid>
    )
}

const useStyles = makeStyles(theme => ({
    input: {
        '&::placeholder': {
            color: '#000000',
            opacity: 1
        },
    },
    notchedOutline: {
        borderWidth: "0px",
        borderColor: "#ffffff",
    }
}));

const mapStateToProps = (state: RootState) => ({
    messages: state.messageBundle.messages
})
const mapDispatchToProps = (dispatch: Dispatch) => { return bindActionCreators({}, dispatch) };
type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;
export const ReportListComponent = connect(mapStateToProps, mapDispatchToProps)(ReportingListViewComponent)