import React from 'react';
import { Tooltip, withStyles } from '@material-ui/core';
import success from '../../src/assets/icons/Success.svg';
import creationInProgress from '../../src/assets/icons/CreationInProgress.svg';
import updateInProgress from '../../src/assets/icons/UpdateInProgress.svg';
import archived from '../../src/assets/icons/archive.svg'

const CustomTooltip = withStyles((theme: any) => ({
  tooltip: {
    textTransform: 'capitalize',
  }
}))(Tooltip);

export const getStatusIcon = (status: string, size?: string): any => {
  if (status.length > 0) {
    status = status.toLowerCase();
    let icon: any;
    let toolTip: any;
    if (status === 'c') {
      icon = success;
      toolTip = "Created";
    } else if (status === 'cip') {
      icon = creationInProgress;
      toolTip = "Creation in progress";
    } else if (status === 'uip') {
      icon = updateInProgress;
      toolTip = "Update in progress";
    } else if (status === 'a') {
      icon = archived;
      toolTip = "Archived";
    };

    if (size === "small") {
      return <CustomTooltip title={toolTip}>{<img src={icon} style={{ width: '12px', height: '12px' }} />}</CustomTooltip>;
    } else {
      return <CustomTooltip title={toolTip} style={{ color: 'red' }}>{<img src={icon} />}</CustomTooltip>;
    }
  }
};