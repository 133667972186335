import { Button, Dialog, DialogActions, DialogContent, DialogTitle, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { colorPalette } from '../../../assets/theme';

interface DialogProps {
    handleClose(): void;
    open: boolean;
    errors: any;
}

const ErrorDialogComponent: React.FC<DialogProps> = ({ errors, open, handleClose }) => {
    const classes = useStyles();
    return (
        <div >
            <Dialog
                open={open}
                onClose={handleClose}
                classes={{ paper: classes.dialogContainer }}
                disableBackdropClick
            >
                <div className={classes.topStripBlue} />
                <DialogTitle>
                    <Typography id="dialog-title" variant='h6' style={{ fontWeight: 'bold' }} data-testid='validation-error-label'>
                        Validation Errors
                </Typography>
                </DialogTitle>
                <DialogContent>
                    {errors.errors.map((error:String) => (
                        <Typography variant='body2' data-testid='errors'>{error}</Typography>
                    ))}
                </DialogContent>
                <DialogActions>
                    <React.Fragment>
                        <Button variant='contained' data-testid='cancel-button' onClick={handleClose}>
                            Close
                </Button>
                    </React.Fragment>
                </DialogActions>
            </Dialog>
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    dialogContainer: {
        minWidth: 'sm'
    },
    topStripBlue: {
        background: colorPalette.primaryShade,
        height: '10px'
    }
}));


export default ErrorDialogComponent;