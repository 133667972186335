import { HTTP } from "../rest";
import { actionType } from "./actionTypes"
import { configuration } from "../config/Configuration";
import { apiRoutes } from "../rest/APIRoutes"


export const fetchVendors = (queryString: string) => {
    return async (dispatch: (action: any) => void) => {
        try {
            dispatch(setVendorsToState({ type: actionType.FETCHING_VENDORS_IN_PROGRESS, payload: null }));
            let url = `${apiRoutes.vendorsURL}?${queryString}`;
            let result = await HTTP.get(url);
            dispatch(setVendorsToState({ type: actionType.FETCHING_VENDORS_SUCCESS, payload: result.data }));
            return result.data;
        } catch (error) {
            dispatch(setVendorsToState({ type: actionType.FETCHING_VENDORS_FAILURE, payload: null }));
            throw error;
        }
    }
}

const setVendorsToState = ({ type, payload }: { type: string, payload: Array<any> | null }) => {
    return { type: type, payload: payload };
}