import * as XLSX from 'xlsx';

export const downloadReport = (jsonData: any, sheetName: string, fileName: string, fileExtension: XLSX.BookType): void => {
    const worksheet = XLSX.utils.json_to_sheet(jsonData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
    XLSX.writeFile(workbook, `${fileName}.${fileExtension}`, { bookType: `${fileExtension}` })
}


export const downloadLicensedHcp = (licensedHcp: any) => {
    const { licensedHcpData, fileName } = licensedHcp
    const downloadurl = window.URL.createObjectURL(new Blob([licensedHcpData]));
    const link = document.createElement('a');
    link.href = downloadurl;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    link.addEventListener("click", function () {
        window.URL.revokeObjectURL(downloadurl);
    });
    document.body.removeChild(link);
}