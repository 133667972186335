import { ACTION_TYPE } from "./dfbConstants";

export const resetListViewPagination = (history: any) => {
    let pathname = history.location.pathname;
    if (sessionStorage.getItem('prevNavigationPath') == null) {
        sessionStorage.setItem('prevNavigationPath', pathname);
    } else {
        if (sessionStorage.getItem('prevNavigationPath') != pathname) {
            clearPaginationSessions();
            clearSortAndSearchSessions();
            sessionStorage.setItem('prevNavigationPath', pathname);
        }
    }
}

export const clearPaginationSessions = () => {
    sessionStorage.removeItem('listViewPagination');
    sessionStorage.removeItem('selectedBusinessObjectFromList');
}

export const setSearchDataFromSessionStorage = (setSearchCriteria: any) => {
    let searchText = ""
    if (sessionStorage.getItem('searchText') != null) {
        let textField: any = sessionStorage.getItem('searchText');
        searchText = textField;
    }
    setSearchCriteria(searchText);
    return searchText;
}

export const setSortDataFromSessionStorage = (setSortOptions: any) => {
    let tempSortOptions: any = [];
    if (sessionStorage.getItem('sortOptionDetails') != null) {
        let sortOptionDetails: any = sessionStorage.getItem('sortOptionDetails');
        tempSortOptions = JSON.parse(sortOptionDetails);
        setSortOptions(tempSortOptions);
    }
    return tempSortOptions;
}

export const clearSortAndSearchSessions = () => {
    sessionStorage.removeItem('sortOptionDetails');
    sessionStorage.removeItem('searchText');
    sessionStorage.removeItem('lastAction');
}

export const getLastAction = () => {
    let lastAction = "";
    if (sessionStorage.getItem('lastAction') != null) {
        let tempLast: any = sessionStorage.getItem('lastAction');
        lastAction = tempLast;
        if (lastAction === ACTION_TYPE.SEARCH && sessionStorage.getItem('listViewPagination') != null && sessionStorage.getItem('selectedBusinessObjectFromList') == null) {
            let pageDetails: any = sessionStorage.getItem('listViewPagination');
            pageDetails = JSON.parse(pageDetails);
            sessionStorage.setItem('listViewPagination', JSON.stringify({ page: 0, rowsPerPage: pageDetails.rowsPerPage }));
        }
    }
    return lastAction;
}
