import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import theme, { colorPalette } from '../../assets/theme';

interface DialogProps {
    heading: string;
    handleClose?(): void;
    open: boolean;
    body: any;
    primaryButtonHeading: string;
    onPrimaryButtonClick(): void;
    primaryButtonStyle?: any;
    primaryButtonLoader: boolean;
    secondaryButtonHeading?: string;
}

const DialogContainer: React.FC<DialogProps> = ({ body, open, handleClose, heading, primaryButtonHeading, onPrimaryButtonClick, primaryButtonStyle = {}, primaryButtonLoader, secondaryButtonHeading }) => {
    const classes = useStyles();
    return (
        <div >
            <Dialog
                open={open}
                onClose={handleClose}
                classes={{ paper: classes.dialogContainer }}
                disableBackdropClick
            >
                <div className={classes.topStripBlue} />
                <DialogTitle>
                    <Typography id="dialog-title" variant='h6' style={{ fontWeight: 'bold' }}>
                        {heading}
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    {body}
                </DialogContent>
                <DialogActions style={{ padding: theme.spacing(0, 2, 2, 2) }}>
                    {primaryButtonLoader ?
                        <CircularProgress color='secondary' size={24} /> :
                        <React.Fragment>
                            {secondaryButtonHeading != null ? <Button variant='contained' data-testid='cancel-button' onClick={handleClose}>
                                {secondaryButtonHeading}
                            </Button> : null}
                            <Button variant='contained' data-testid='confirm-button' color='secondary' style={primaryButtonStyle} onClick={onPrimaryButtonClick}>
                                {primaryButtonHeading}
                            </Button>
                        </React.Fragment>}
                </DialogActions>
            </Dialog>
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    dialogContainer: {
        minWidth: '300px'
    },
    topStripBlue: {
        background: colorPalette.primaryShade,
        height: '10px'
    }
}));


export default DialogContainer;