import { AnyAction } from "redux";
import { actionType } from "../actions/actionTypes";

type messageBundlerState = {
    messages: any
    appName: string,
    appNameExp: string
};

const initialState: messageBundlerState = {messages:{}, appName: 'DFB', appNameExp: 'Data Feed Builder'};

export function messageBundlerReducer(state= initialState, action: AnyAction): messageBundlerState{
    switch (action.type) {
        case actionType.SET_MESSAGE_BUNDLE:
            return{...state, messages:action.payload}
        default:
            return state;
    }
}