import React from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../../reducers/rootReducer';
import { bindActionCreators, Dispatch } from 'redux';
import StopIcon from '@material-ui/icons/Stop';
import { legends } from '../../functional/license/licenseReports/licenseReportLegendsJson';
import { fetchLicenseReports } from '../../../actions/licenseAuditReportActions';
import {
    LineChart,
    Line,
    Tooltip,
    XAxis,
    YAxis,
    CartesianGrid,
    TooltipProps,
} from "recharts";
import { Grid, Typography } from '@material-ui/core';
import theme from '../../../assets/theme';
import { reportsAdapter } from '../../../utils/chartUtils/reportsAdapter';
import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent';
interface LineChartReportComponentProps {
    noDataFoundText: string,
    yAxisText: string,
    xAxisText: string,
    numberOfFilteredLicense: number,
}

const UnconnectedLineChartReportComponent: React.FC<Props & LineChartReportComponentProps> = (props) => {

    const [reports, setReports] = React.useState<any | null>(props.reports.licenseAuditReport != null ? props.reports.licenseAuditReport : []);
    const legentKeys: any = Object.keys(legends);

    const getChartData = () => {
        if (props.numberOfFilteredLicense === 0) {
            return reportsAdapter(reports, legends, 1);
        }
        else {
            return reportsAdapter(reports.slice(-props.numberOfFilteredLicense), legends, reports.length - (props.numberOfFilteredLicense - 1))
        }
    }

    const CustomTooltip = ({
        active,
        payload,
    }: TooltipProps<ValueType, NameType>) => {
        let refereshs: any = payload
        let refreshData = refereshs[0]
        let refrestDataKeys: Array<string | null> = refreshData !== undefined ? Object.keys(refreshData.payload) : [];

        if (refreshData !== undefined) {
            refrestDataKeys = refrestDataKeys.filter(element => element != props.messages.refresh || props.messages.refreshDate)
        }

        if (active && refreshData.payload !== undefined) {
            return (
                <div>
                    <Grid container spacing={1} style={{ height: 500, width: 230, backgroundColor: '#FFFFFF', boxShadow: '0px 3px 6px #00000029' }} >
                        <Grid container justify="center" style={{ background: '#000000' }}>
                            <Typography variant='caption' style={{ alignContent: 'center', color: '#FFFFFF' }}>
                                {refreshData ? `${refreshData.payload[props.messages.refresh]} -  (${refreshData.payload[props.messages.refreshDate]})` : null}
                            </Typography>
                        </Grid>
                        {refrestDataKeys?.map((value: any) => (
                            <>
                                {checkRefresh(value) ? <Grid container wrap='nowrap' spacing={3} item  >
                                    <Grid item container xs={9} >
                                        <Grid>
                                            <StopIcon style={{ color: getColor(value), height: '17px', width: '17px', padding: theme.spacing(0.67, 0.5, 0, 0) }} />
                                        </Grid>
                                        <Grid item >
                                            <Typography style={{ display: 'inline-block', fontSize: '8px' }}>{value}</Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Typography style={{ display: 'inline-block', fontSize: '8px', color: getColor(value), alignContent: 'right' }}>{refreshData.payload[value]}</Typography>
                                    </Grid>
                                </Grid> : null}
                            </>
                        ))}
                    </Grid>
                </div>
            )
        }
        return null
    };
    const checkRefresh = (value: any) => {
        if (value == props.messages.refresh || value == props.messages.refreshDate) {
            return false
        }
        return true
    }

    const getColor = (legendValue: string) => {
        for (let i = 0; i < legentKeys.length; i++) {
            if (legendValue == legends[legentKeys[i]].legendName) {
                return legends[legentKeys[i]].legendColor
            }
        }
    }

    const getLegents = () => {
        return (
            <Grid container justify='center' style={{ padding: theme.spacing(0, 6, 0, 18) }}>
                {legentKeys.map((key: string) => (
                    <>
                        {legends[key].showLegend ? <Grid item style={{ padding: theme.spacing(0.1, 0.1, 0, 0) }}>
                            <StopIcon style={{ color: legends[key].legendColor, height: '18px', width: '18px' }} />
                        </Grid> : null}
                        {legends[key].showLegend ? <Grid item style={{ padding: theme.spacing(0, 2.5, 0, 0) }}>
                            <Typography style={{ fontSize: '14px' }}>{legends[key].legendName}</Typography>
                        </Grid> : null}
                    </>
                ))}
            </Grid>
        )
    }


    return (
        <div>
            {reports.length < 1 || props.licenseReportsFetchingFailure ? <Grid container direction='column' justify='center' alignContent='center' xs={12} style={{ height: '100%', paddingTop: 90 }}>
                <Typography variant='body2' style={{ opacity: 0.6 }}>
                    {props.noDataFoundText}
                </Typography>
            </Grid> :
                <div style={{ width: 1000, height: 590 }}>
                    {getLegents()}
                    <Grid container direction='row' md={12} alignContent='center' alignItems='center' justify='flex-start' style={{ padding: theme.spacing(3, 0, 0, 0) }}>
                        <Grid container item xs={1} justify='center' alignItems='flex-end' >
                            <span style={{ fontWeight: 'bold', padding: theme.spacing(6, 8, 0, 0), writingMode: 'vertical-rl', transform: 'rotate(180deg)' }}>
                                {props.yAxisText}
                            </span>
                        </Grid>
                        <Grid data-testId='line-chart' item md={11} style={{ padding: theme.spacing(0, 0, 0, 0) }}>
                            <LineChart
                                width={900}
                                height={350}
                                data={getChartData()}
                                margin={{
                                    top: 2,
                                    right: 2,
                                    left: 2,
                                    bottom: 2
                                }}
                            >
                                <CartesianGrid strokeDasharray="1 0" />
                                <XAxis style={{ fontWeight: 'lighter', fontSize: '12px', stroke: '#000000' }} dataKey={props.messages.refreshDate} onMouseEnter={() => {
                                }} onMouseLeave={() => {
                                }} />
                                <YAxis style={{ fontWeight: 'lighter', fontSize: '12px', stroke: '#000000' }} />
                                <Tooltip contentStyle={{ padding: theme.spacing(2, 5, 0, 0) }} isAnimationActive={false} content={<CustomTooltip />} />
                                <Line
                                    name="Record Cap"
                                    type="category"
                                    dataKey="Record Cap"
                                    stroke="#023E8A"
                                    activeDot={{ r: 8 }}
                                    onMouseEnter={() => {

                                    }}
                                />
                                <Line
                                    name="HCPs Licensed During Contract Term"
                                    type="category"
                                    dataKey="HCPs Licensed During Contract Term"
                                    stroke="#2B9348"
                                    activeDot={{ r: 8 }}

                                />
                                <Line
                                    name="Total Number Of Audience"
                                    type="category"
                                    dataKey="Total Number Of Audience"
                                    stroke="#40798C"
                                    activeDot={{ r: 8 }}

                                />
                                <Line
                                    name="Suppressed Via Record Cap"
                                    type="category"
                                    dataKey="Suppressed Via Record Cap"
                                    stroke="#EB6424"
                                    activeDot={{ r: 8 }}

                                />
                                <Line
                                    name="Emails Provided In Current Refresh"
                                    type="category"
                                    dataKey="Emails Provided In Current Refresh"
                                    stroke="#09BC8A"
                                    activeDot={{ r: 8 }}

                                />
                            </LineChart>
                            <Grid container item xs={12} justify='center' >
                                <span style={{ fontWeight: 'bold', alignContent: 'center', padding: theme.spacing(1, 0, 0, 0) }}>
                                    {props.xAxisText}
                                </span>
                            </Grid>
                        </Grid>
                    </Grid>

                </div>}
        </div>
    );
}
const mapStateToProps = (state: RootState) => ({
    user: state.user.user,
    messages: state.messageBundle.messages,
    licenseReportsFetchingFailure: state.licenseReport.licenseReportsFetchingFailure,
    reports: state.licenseReport.licenseReports
})
const mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators({
        fetchLicenseReports
    }, dispatch)
};

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;
export const LineChartReportComponent = connect(mapStateToProps, mapDispatchToProps)(UnconnectedLineChartReportComponent);