import React from 'react';
import { withAppShell } from '../hoc/app_shell/withAppShell';
import { Grid, Typography } from '@material-ui/core';
import theme from '../../assets/theme';

const UnAuthorizedErrorPageComp = (props:any) => {
    return (
        <Grid container justify='center' style={{margin: theme.spacing(10, 0, 0, 0)}}>
            <Grid item >
                <Typography variant='h6' style={{color:"#5e5e5e"}}>
                    {props.message}
                </Typography>
            </Grid>
        </Grid>
    )
}

export const UnAuthorizedErrorPage = withAppShell((UnAuthorizedErrorPageComp),{ enableNav: false });