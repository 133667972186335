import { frcJsonAttributes, errorObjectAttributes } from '../../components/functional/fileTransferConfiguration/fileRetrievalConfigurationJson';

export function authTypeValidation (frcDataNeedsToBeSend: frcJsonAttributes, errorObject: errorObjectAttributes, messages: any) { return ():boolean => {
    if (!frcDataNeedsToBeSend.authenticationType || frcDataNeedsToBeSend.authenticationType.length < 1) {
      errorObject.authenticationType = messages.FieldRequired;
      return false
    } else {
      errorObject.authenticationType = '';
      return true;
    }
  }
}