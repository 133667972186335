import { customValidatorForObjectTypeParameter } from "../../../../exports/interfaces/customValidatorForObjectTypeParameter";
import { customValidatorForObjectTypeReturnValue } from "../../../../exports/interfaces/customValidatorForObjectTypeReturnValue";
import { customValidatorForLicense } from "../customValidatorForLicense";

export const customValidatorForObjectType = (data: customValidatorForObjectTypeParameter): customValidatorForObjectTypeReturnValue => {
    switch (data.objectType) {
        case 'L':
            return customValidatorForLicense(data);
        default:
            return { isValidated: true };
    }
}