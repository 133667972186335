import { Grid, Typography } from '@material-ui/core'
import React from 'react'
import theme from '../../assets/theme';

interface StatusDisplayProps {
    statusColor: string,
    statusText: string,
    icon?: any
}

const StatusDisplay : React.FC<StatusDisplayProps> =({statusColor, statusText, icon})=>{
    return(
        <div>
            {statusText&&statusColor?
            <Grid container direction='row'>    
                {icon && <Grid item style={{alignSelf:'center'}}>{icon}</Grid>}
                <Typography  variant='overline' style={{color: statusColor, padding: theme.spacing(0.25,0,0,0.75), alignSelf:'center'}}>{statusText}</Typography>
            </Grid>:null}
        </div>
    )
}

export default StatusDisplay;