import { frcJsonAttributes, errorObjectAttributes } from '../../components/functional/fileTransferConfiguration/fileRetrievalConfigurationJson';
import { validateKeyPath } from '../dataConfigValidator';
import { authTypes, AWS_S3, AZURE_BLOB, SFTP } from '../dfbConstants';

export function authKeyFilevalidation(frcDataNeedsToBeSend: frcJsonAttributes,
    errorObject: errorObjectAttributes, messages: any) {
    return (): boolean => {
        if (frcDataNeedsToBeSend.transferType === SFTP && frcDataNeedsToBeSend.authenticationType === authTypes.AUTH_KEY && (!frcDataNeedsToBeSend.authKeyFileName || frcDataNeedsToBeSend.authKeyFileName.trim().length < 1)) {
            errorObject.authKeyFileName = messages.FieldRequired;
            return false;
        }
        else if (frcDataNeedsToBeSend.authenticationType && frcDataNeedsToBeSend.authenticationType.length > 0 && frcDataNeedsToBeSend.transferType === SFTP && frcDataNeedsToBeSend.authenticationType == authTypes.AUTH_KEY) {
            if (!validateKeyPath(String(frcDataNeedsToBeSend.authKeyFileName).trim()) || (frcDataNeedsToBeSend.authKeyFileName !=null  && frcDataNeedsToBeSend.authKeyFileName.trim().length > 1 && frcDataNeedsToBeSend.authKeyFileName.trim().indexOf(' ') !== -1)) {
                errorObject.authKeyFileName = messages.AuthKeyError;
                return false;
            }
            else {
                errorObject.authKeyFileName = '';
                return true;
            }
        }
        else {
            errorObject.authKeyFileName = '';
            return true;
        }
    };
}