export interface clientJsonAttributes {
    clientName: string | null,
    clientCode: string | null,
    vendorId: number,
    salesforceId: string | null,
    salesforceName: string | null
}

export const clientJson: clientJsonAttributes = {
    clientName: "",
    clientCode: "",
    vendorId: 1,
    salesforceId: "",
    salesforceName: ""
} 

export interface clientErrorObjectAttribute {
    clientName: string,
    clientCode: string,
    vendorId: string,
    salesforceId: string,
    salesforceName: string
}

export const defaultClientErrorObject: clientErrorObjectAttribute = {
    clientName: '',
    clientCode: '',
    vendorId: '',
    salesforceId: '',
    salesforceName: ''
}  