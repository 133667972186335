import React from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../../../reducers/rootReducer';
import { bindActionCreators, Dispatch } from 'redux';
import { Button, Grid, IconButton, Tooltip, Typography } from '@material-ui/core';
import theme from '../../../../assets/theme';
import GetAppIcon from '@material-ui/icons/GetApp';
import AutoCompleteComponent from '../../../ui/AutoCompleteComponent'
import { createFilterOptions } from '@material-ui/lab';
import { getChartFilterOptions } from './licenseReportLegendsJson';
import { LICENSE_DEFAULT_FILTER_OPTION, MATCH_FROM_START } from '../../../../utils/dfbConstants';

export interface ReportsOptionType {
    label: string;
    offset: number;
}
interface licenseHeaderProps {
    handleReportsDownload(): void,
    isReportDialogOpen: boolean,
    reportFilterCount(count: any): void
}

const UnconnectedLicenseLineChartHeader: React.FC<Props & licenseHeaderProps> = (props) => {

    const reportOptions: Array<ReportsOptionType> = [];
    const filterOptions = createFilterOptions({
        matchFrom: MATCH_FROM_START,
        stringify: (option: ReportsOptionType) => option.label
    });
    const filterHandler = async (selectedDropDownValue: any) => {
        props.reportFilterCount(selectedDropDownValue.offset)

    }

    return (
        <>
            <Grid data-testId='line-legends' item container md={7} style={{ padding: theme.spacing(0, 0, 0, 8) }}>
                <Typography variant='h6' style={{ fontWeight: 'bold' }}>
                    {props.messages.Reports}
                </Typography>
            </Grid>

            {props.licenseReports.licenseAuditReport != null && props.licenseReports.licenseAuditReport.length > 0 && !props.licenseReportFetchingInProgress ? <Grid item container md={5} direction='row'>
                <Grid style={{ padding: theme.spacing(0.5, 0, 0, 12.5) }} >
                    <Tooltip title={props.messages.downloadToolTip} placement='left'>
                        <IconButton data-testId="icon-button" onClick={() => { props.handleReportsDownload() }}> <GetAppIcon color='secondary' style={{ fontSize: 45 }} /></IconButton>
                    </Tooltip>
                </Grid>
                <Grid style={{ padding: theme.spacing(2, 0, 1, 0) }}>
                    <AutoCompleteComponent options={reportOptions} label={props.messages.filterLabel} filterOptions={filterOptions} optionLabel={props.messages.licenseFilterOptionLabel} testId='reports-filter' defaultValue={LICENSE_DEFAULT_FILTER_OPTION} boxWidth={200} isDialogOpen={props.isReportDialogOpen} onChange={filterHandler} getOptionsMethod={getChartFilterOptions} payload={props.licenseReports.licenseAuditReport} />
                </Grid>
            </Grid> : null}
        </>
    );
}
const mapStateToProps = (state: RootState) => ({
    messages: state.messageBundle.messages,
    licenseReports: state.licenseReport.licenseReports,
    licenseReportFetchingInProgress: state.licenseReport.licenseReportsFetchingInProgress,
})
const mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators({
    }, dispatch)
};

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;
export const LicenseLineChartHeader = connect(mapStateToProps, mapDispatchToProps)(UnconnectedLicenseLineChartHeader);