import { frcJsonAttributes, errorObjectAttributes } from '../../components/functional/fileTransferConfiguration/fileRetrievalConfigurationJson';
import { validateKeyPath } from '../dataConfigValidator';
import { authTypes, AWS_S3, AZURE_BLOB, FTP, SFTP } from '../dfbConstants';

export function validateAuthenticationPassword(frcDataNeedsToBeSend: frcJsonAttributes,
  errorObject: errorObjectAttributes, messages: any) {
  return (): boolean => {
    if (frcDataNeedsToBeSend.transferType !== AZURE_BLOB && (!frcDataNeedsToBeSend.authPassword || frcDataNeedsToBeSend.authPassword.trim().length < 1)) {
      if (frcDataNeedsToBeSend.transferType === SFTP && frcDataNeedsToBeSend.authenticationType === authTypes.AUTH_KEY) {
        errorObject.authPassword = '';
        return true;
      } else {
        errorObject.authPassword = messages.FieldRequired;
        return false;
      }
    }
    else if ((frcDataNeedsToBeSend.transferType === FTP || frcDataNeedsToBeSend.transferType === SFTP) && frcDataNeedsToBeSend.authenticationType === authTypes.AUTH_PASSWORD && frcDataNeedsToBeSend.authPassword !=null  && frcDataNeedsToBeSend.authPassword.trim().length > 1 && frcDataNeedsToBeSend.authPassword.trim().indexOf(' ') !== -1) {
      errorObject.authPassword = messages.invalidAuthPassword;
      return false;
    }
    else if (frcDataNeedsToBeSend.transferType === AWS_S3 && frcDataNeedsToBeSend.authenticationType === authTypes.AUTH_PASSWORD && frcDataNeedsToBeSend.authPassword != null && frcDataNeedsToBeSend.authPassword.trim().length > 1) {
      if (frcDataNeedsToBeSend.authPassword !== undefined) {
        const secretKeyRegExString = '^(?<![A-Za-z0-9/+=])[A-Za-z0-9/+=]{40}(?![A-Za-z0-9/+=]$)';
        const secretKeyRegEx = new RegExp(secretKeyRegExString);
        const match = secretKeyRegEx.exec(frcDataNeedsToBeSend.authPassword != null ? frcDataNeedsToBeSend.authPassword.trim() : '');
        if (!match || frcDataNeedsToBeSend.authPassword.trim().length !== 40) {
          errorObject.authPassword = messages.invalidSecretKeyFormat;
          return false;
        } else {
          errorObject.authPassword = '';
          return true;
        }
      }
      else {
        errorObject.authPassword = '';
        return true;
      }
    }
    else {
      errorObject.authPassword = "";
      return true;
    }
  };
}