import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { RootState } from '../../../reducers/rootReducer';
import PageWrapper from '../../ui/PageWrapper';

interface ReportDatailProps {
    componentName: string
}

const ReportingDetailsComponent: React.FC<Props & ReportDatailProps> = (props) => {
    return (
        <PageWrapper badgeTitle={`${('' + props.messages.Report).toUpperCase()}`}>
            <React.Fragment>
                <Grid container direction='column' justify='center' alignContent='center' xs={12} style={{ height: 370 }}>
                    <Typography variant='body2' style={{ opacity: 0.6 }}>
                        <h1>{`${props.componentName} work in progress`}</h1>
                    </Typography>
                </Grid>
            </React.Fragment>
        </PageWrapper>
    )
}

const mapStateToProps = (state: RootState) => ({
    messages: state.messageBundle.messages,
})
const mapDispatchToProps = (dispatch: Dispatch) => { return bindActionCreators({}, dispatch) };
type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;
export const ReportDetailsComponent = connect(mapStateToProps, mapDispatchToProps)(ReportingDetailsComponent)