import React ,{ useEffect }from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import CardHeader from '@material-ui/core/CardHeader';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Checkbox from '@material-ui/core/Checkbox';
import './FilterCheckBoxStyle.css';


const useStyles = makeStyles((theme) => ({
  list: {
    marginLeft:20,
    backgroundColor: theme.palette.background.paper,
    overflow: 'auto',
  }
}));

function not(a:number[], b:number[]) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a:number[], b:number[]) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a:number[], b:number[]) {
  return [...a, ...not(b, a)];
}

export default function FilterCheckBox(props:any) {
  const classes = useStyles();
  const [checked, setChecked] = React.useState<number[]>([]);

  useEffect(() => {
    setChecked(props.checked);
  });


  const handleToggle = (value: number) => () => {
    const currentIndex: number = checked.indexOf(value);
    const newChecked: number[] = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
    props.setSelectedFilters(newChecked);
  };

  const numberOfChecked = (items:number[]) => intersection(checked, items).length;

  const handleToggleAll = (items:number[]) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
      props.setSelectedFilters(not(checked, items));
    } else {
      setChecked(union(checked, items));
      props.setSelectedFilters(union(checked, items));
    }
  };

  const customList = (title: string, items: number[]) => (
    <Grid item style={{marginBottom:'10px'}}>
      <CardHeader
        className='filter-header'
        avatar={
          <Checkbox
          size='small'
          style={{
              padding:0
          }}
            onClick={handleToggleAll(items)}
            checked={numberOfChecked(items) === items.length && items.length !== 0}
            indeterminate={numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0}
            disabled={items.length === 0}
            inputProps={{ 'aria-label': 'all items selected' }}
          />
        }
        title={`${title}(${items.length})`}
      />
      <List className={classes.list} dense component="div" role="list">
        {items.map((value) => {
          const labelId = `transfer-list-all-item-${value}-label`;
          return (
            <ListItem key={value} role="listitem" button onClick={handleToggle(value)}>
              <ListItemIcon>
                <Checkbox
                    checked={checked.indexOf(value) !== -1}
                    tabIndex={-1}
                    disableRipple
                    size='small'
                    style={{
                        margin:0,
                        padding:0
                    }}
                    inputProps={{ 'aria-labelledby': labelId }}
                />

              </ListItemIcon>
              <ListItemText id={labelId} primary={value} />
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Grid>
  );
  return (
    <Grid item container className='checkBoxList'>
      {
        props.definedFilterClients.map((item: any) => {
                return (
                    <Grid item xs={12}>{customList(item.clientCode, item.formats)}</Grid>
                );
            }
        )
      } 
    </Grid>
  );
}