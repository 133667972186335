import { frcJsonAttributes, errorObjectAttributes } from '../../components/functional/fileTransferConfiguration/fileRetrievalConfigurationJson';
import { AZURE_BLOB } from '../dfbConstants';

export function sasUrlValidation(frcDataNeedsToBeSend: frcJsonAttributes, errorObject: errorObjectAttributes, messages: any) {
    return (): boolean => {
        var isValidUrl: boolean = false;
        const sasUrl = frcDataNeedsToBeSend.hostName != null ? frcDataNeedsToBeSend.hostName : '';
        const blobUriRegex = new RegExp(/^http[s]?:\/\/[a-z0-9]{3,24}\.blob.core.windows.net\/[a-z0-9](?!.*--)[a-z0-9-]{1,61}[a-z0-9]\?.{1,1024}$/gm);
        const match = blobUriRegex.exec(sasUrl);
        if (frcDataNeedsToBeSend.transferType === AZURE_BLOB && (!frcDataNeedsToBeSend.hostName || frcDataNeedsToBeSend.hostName.trim().length < 1)) {
            errorObject.hostName = messages.FieldRequired;
            handleAzureBlobProperties(frcDataNeedsToBeSend, errorObject);
        } else if (frcDataNeedsToBeSend.transferType === AZURE_BLOB && frcDataNeedsToBeSend.hostName != null && frcDataNeedsToBeSend.hostName.trim().length > 512) {
            errorObject.hostName = messages.maximumCharacterExceeded;
            handleAzureBlobProperties(frcDataNeedsToBeSend, errorObject);
        } else if (frcDataNeedsToBeSend.transferType === AZURE_BLOB && !match) {
            errorObject.hostName = messages.invalidSasUrlFormat;
            handleAzureBlobProperties(frcDataNeedsToBeSend, errorObject);
        }
        else {
            errorObject.hostName = '';
            errorObject.authUserName = '';
            errorObject.sourceFolder = '';
            handleAzureBlobProperties(frcDataNeedsToBeSend, errorObject);
            isValidUrl = true;
        }
        return isValidUrl;

    }
}

function handleAzureBlobProperties(frcDataNeedsToBeSend: frcJsonAttributes, errorObject: errorObjectAttributes): void {
    const accountRegex = new RegExp('//(.*).blob');
    const containerRegex = new RegExp('.net/(.*)\\?');
    const azureAccountName = frcDataNeedsToBeSend.hostName != null ? frcDataNeedsToBeSend.hostName : '';
    const azureContainerName = frcDataNeedsToBeSend.hostName != null ? frcDataNeedsToBeSend.hostName : '';
    let accountName = azureAccountName.match(accountRegex);
    let containerName = azureContainerName.match(containerRegex);
    if (accountName) {
        frcDataNeedsToBeSend.authUserName = accountName[1];
    } else {
        frcDataNeedsToBeSend.authUserName = '';
    }
    if (containerName) {
        frcDataNeedsToBeSend.sourceFolder = containerName[1];
    } else {
        frcDataNeedsToBeSend.sourceFolder = '';
    }
    if (errorObject.hostName != null && errorObject.hostName.length > 0) {
        frcDataNeedsToBeSend.authUserName = '';
        frcDataNeedsToBeSend.sourceFolder = '';
    }
}