import { AnyAction } from "redux"
import { actionType } from "../actions/actionTypes";

type vendorReducerState = {
    vendorsFetchingInProgress: boolean,
    vendorsFetchingSuccess: boolean,
    vendorsFetchingFailure: boolean,
    vendors: Array<any> | null,
    
}

const initialState: vendorReducerState = {
    vendorsFetchingInProgress: false,
    vendorsFetchingSuccess: false,
    vendorsFetchingFailure: false,
    vendors: null,
    
}

export function vendorReducer(state = initialState, action: AnyAction): vendorReducerState {
    switch (action.type) {
        case actionType.FETCHING_VENDORS_IN_PROGRESS:
            return { ...state, vendorsFetchingInProgress: true, vendorsFetchingSuccess: false, vendorsFetchingFailure: false };

        case actionType.FETCHING_VENDORS_SUCCESS:
            return { ...state, vendorsFetchingInProgress: false, vendorsFetchingSuccess: true, vendorsFetchingFailure: false, vendors: action.payload };

        case actionType.FETCHING_VENDORS_FAILURE:
            return { ...state, vendorsFetchingInProgress: true, vendorsFetchingSuccess: false, vendorsFetchingFailure: false };
        default:
            return state;
    }
}