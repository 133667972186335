import { datePickerConstants } from "../../dfbConstants";
import messageBundle from '../../../../src/assets/resource_bundle/messages.json'
import { startAndExpirationDatevalidator } from "./common/startAndExpirationDateValidator";
import dayjs from "dayjs";
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { customvalidationFunctionParameter } from "../../../exports/interfaces/customvalidationFunctionParameter";
import { extractFieldInformation } from "../../extractFieldInformationFromList";


export const l_valExpirationDate = (rowData: customvalidationFunctionParameter) => {
    try {
        console.log('dfbBusinessObjectdfbBusinessObjectRunLog',rowData.dfbBusinessObject)
        if (rowData.editMode) {
            let latestRunlogDate;
            if (rowData.dfbBusinessObject && rowData.dfbBusinessObject.dfbBusinessObjectRunLog.length > 0) {
                let lastIndex = rowData.dfbBusinessObject.dfbBusinessObjectRunLog.length - 1;
                latestRunlogDate = rowData.dfbBusinessObject.dfbBusinessObjectRunLog[lastIndex].logDate;
            }
            let ExpirationDate = rowData.fieldValue
            let startDate = extractFieldInformation(rowData.dfbBusinessObjectMetaDataList, 'f_id', 'Start Date').v;
            let startDateMasterDate = extractFieldInformation(rowData.formatFields, 'fieldName', 'Start Date');
            let ExpirationDateFormat = rowData.additionalPropertyJson.format;
            let startDateFormat = startDateMasterDate.additionalPropertyJson.format;
            return startAndExpirationDatevalidator({ ExpirationDate, startDate, latestRunlogDate, ExpirationDateFormat, startDateFormat, fieldName: 'Expiration Date' });
        } else {

            return createModeValidation(rowData);
        }
    } catch (error) {
        throw error;
    }
}

const createModeValidation = (data: customvalidationFunctionParameter) => {
    let startDate = extractFieldInformation(data.dfbBusinessObjectMetaDataList, 'f_id', 'Start Date').v;
    let startDateMasterDate = extractFieldInformation(data.formatFields, 'fieldName', 'Start Date');
    dayjs.extend(customParseFormat)
    if (!dayjs(data.fieldValue, data.additionalPropertyJson.format, true).isValid()) {
        return { "isValid": false, "message": messageBundle.invalidDateFormat.concat(datePickerConstants.UI_DATE_FORMAT.toUpperCase()) }
    } else if (!(dayjs(data.fieldValue, data.additionalPropertyJson.format, true) > dayjs().add(30, 'day'))) {
        return { "isValid": false, "message": messageBundle.invalidFutureExpirationDate }
    } else if (startDate !== "") {
        if (!dayjs(startDate, startDateMasterDate.additionalPropertyJson.format, true).isValid()) {
            return { "isValid": false, "message": messageBundle.invalidStartDate.concat(datePickerConstants.UI_DATE_FORMAT.toUpperCase()) }
        } else if (!(dayjs(data.fieldValue, data.additionalPropertyJson.format, true) > (dayjs(startDate, startDateMasterDate.additionalPropertyJson.format, true)))) {
            return { "isValid": false, "message": messageBundle.validateStartDateAndExpirationDate }
        }
    } else {
        return { "isValid": true }
    }
}

