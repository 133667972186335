import dayjs from "dayjs";
import customParseFormat from 'dayjs/plugin/customParseFormat';
import messageBundle from '../../../../../src/assets/resource_bundle/messages.json'
import { datePickerConstants } from "../../../dfbConstants";
import { formatMessage } from "../../../messagesUtils";
/**
 * Checks if the start date ie before the expiration date
 * @param startDate 
 * @param expriationDate 
 */

export const startAndExpirationDatevalidator = (data: any) => {
    dayjs.extend(customParseFormat)
    if (data.ExpirationDate !== "" && !dayjs(data.ExpirationDate, data.ExpirationDateFormat, true).isValid()) {
        return { "isValid": false, "message": messageBundle.invalidExpirationDate.concat(datePickerConstants.UI_DATE_FORMAT.toUpperCase()) }
    } else if (data.startDate !== "" && !dayjs(data.startDate, data.startDateFormat, true).isValid()) {
        return { "isValid": false, "message": messageBundle.invalidStartDate.concat(datePickerConstants.UI_DATE_FORMAT.toUpperCase()) }
    } else if (data.ExpirationDate && !(dayjs(data.ExpirationDate, data.ExpirationDateFormat, true) > (dayjs(data.startDate, data.startDateFormat, true)))) {
        if (data.fieldName === 'Start Date') {
            return { "isValid": false, "message": messageBundle.validateExpirationDateAndStartDate }
        } else if (data.fieldName === 'Expiration Date') {
            return { "isValid": false, "message": messageBundle.validateStartDateAndExpirationDate }
        }

    } else if (data.latestRunlogDate && !(dayjs(data.ExpirationDate, data.ExpirationDateFormat, true) > dayjs(data.latestRunlogDate))) {
        
        return { "isValid": false, "message":formatMessage( messageBundle.validateRunlogDateAndExpirationDate, dayjs(data.latestRunlogDate).format('MM/DD/YYYY')) }
    }
    else {
        return { "isValid": true };
    }
}