import { Grid, TextField, Typography } from '@material-ui/core';
import React from 'react';
import messageBundle from '../../../assets/resource_bundle/messages.json';
import { getLabelProperties } from './fileRetrievalConfigurationUtill';
import { authTypes, AWS_S3, AZURE_BLOB, FTP, passwordTypeKeys, scheduleTypes, SFTP, statuses } from '../../../utils/dfbConstants';

interface CampaignRunLogDisplayProps {
  fileRetrievalConfig: any
}

const getSchedule = (scheduleCode: string) => {
  return scheduleTypes.filter((item) => item.scheduleAbbr == scheduleCode)[0].schedule;
}

const getStatus = (statusCode: string) => {
  return statuses.filter((item) => item.statusCode == statusCode)[0].status;
}

const getAuthPasswordLabel = (configData: any): string => {
  if (configData.authenticationType === authTypes.AUTH_PASSWORD && (configData.transferType === FTP || configData.transferType === SFTP)) {
    return getLabelProperties(configData.transferType).passwordLabel;
  } else if (configData.transferType === AWS_S3 && configData.authenticationType === authTypes.AUTH_PASSWORD) {
    return getLabelProperties(configData.transferType).passwordLabel;
  } else {
    return messageBundle.SSHKey;
  }
}

const getSourceFolderLabel = (configData: any): string => {
  if (configData.transferType === AZURE_BLOB && configData.sourceFolder.includes("/")) {
    return getLabelProperties(configData.transferType).sourceFolderLabel.concat(`/${messageBundle.subFolderName}`)
  }
  else {
    return getLabelProperties(configData.transferType).sourceFolderLabel;
  }
}

const getConfigurationFields = (configData: any) => {
  let configurationFields: any = {};
  configData.clientName && (configurationFields[messageBundle.frcClientNameLabel] = configData.clientName);
  configData.groupName && (configurationFields[messageBundle.frcGroupNameLabel] = configData.groupName);
  configData.schedule && (configurationFields[messageBundle.Schedule] = getSchedule(configData.schedule));
  configData.transferType && (configurationFields[messageBundle.TransferType] = configData.transferType);
  configData.authenticationType && (configurationFields[messageBundle.frcAuthenticationTypeLabel] = configData.authenticationType)
  configData.authUserName && (configurationFields[getLabelProperties(configData.transferType).userNameLabel] = configData.authUserName);
  configData.sourceFolder && (configurationFields[getSourceFolderLabel(configData)] = configData.sourceFolder);
  configData.authPassword && (configurationFields[getAuthPasswordLabel(configData)] = configData.authPassword);
  configData.authKeyFileName && (configurationFields[messageBundle.SSHKey] = configData.authKeyFileName);
  configData.port && (configurationFields[messageBundle.Port] = configData.port);
  configData.hostName && (configurationFields[getLabelProperties(configData.transferType).hostLabel] = configData.hostName);
  configData.sourceFilePassword && (configurationFields[messageBundle.frcFilePasswordLabel] = configData.sourceFilePassword);
  configData.sourceFileNamePrefix && (configurationFields[messageBundle.frcSourceFilePrefixLabel] = configData.sourceFileNamePrefix);
  configData.sourceFileNameDatamask && (configurationFields[messageBundle.frcSourceFileDateMaskLabel] = configData.sourceFileNameDatamask);
  configData.sourceFileNameSuffix && (configurationFields[messageBundle.frcSourceFileSuffixLabel] = configData.sourceFileNameSuffix);
  configData.sourceFileNameExtension && (configurationFields[messageBundle.frcSourceFileExtensionLabel] = configData.sourceFileNameExtension);
  configData.nextRetrievalDate && (configurationFields[messageBundle.frcNextRetrievalLabel] = configData.nextRetrievalDate);
  configData.lastRetrievalDate && (configurationFields[messageBundle.frcLastRetrievalLabel] = configData.lastRetrievalDate);
  configData.retryLimit && (configurationFields[messageBundle.frcRetryLimitLabel] = configData.retryLimit);
  configData.retryInterval && (configurationFields[messageBundle.frcRetryInterval] = configData.retryInterval);
  configData.active && (configurationFields[messageBundle.Status] = getStatus(configData.active));
  return configurationFields;
}

const FileRetrievalDetailsComponent: React.FC<CampaignRunLogDisplayProps> = (props) => {
  const rowData = () => {
    let rows = [];
    const configurationFields = getConfigurationFields(props.fileRetrievalConfig);
    for (const key in configurationFields) {
      rows.push(
        <Grid item xs={6}>
          <Typography variant='subtitle1' style={{ fontSize: '12px', overflowWrap: 'break-word' }}>
            {key}
          </Typography>
          {!passwordTypeKeys.includes(key) ? <Typography variant='h5' style={{ fontSize: '16px', overflowWrap: 'break-word' }}>
            {configurationFields[key]}
          </Typography> : <Typography variant='h5' style={{ fontSize: '16px', overflowWrap: 'break-word' }}>
              <TextField type='password' disabled InputProps={{ disableUnderline: true }} style={{ color: 'black', wordBreak: 'break-word' }} value={configurationFields[key]} data-testid='password-field'/>
            </Typography>}
        </Grid>
      )
    }
    return rows;
  }

  return (
    <div>
      <Grid container spacing={2}>
        {rowData()}
      </Grid>
    </div>
  )
}

export default FileRetrievalDetailsComponent;