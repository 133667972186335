import { configuration } from "../config/Configuration";
const config: any = configuration();
export const apiRoutes = {
    "helthyURL": `${config.baseURL}/api/v1/health`,
    "loginURL": `${config.baseURL}/api/v1/login`,
    "campaignFormatsURL": `${config.baseURL}/api/v1/formats`,
    "formatfieldsURL": `${config.baseURL}/api/v1/formatfields`,
    "campaignsURL": `${config.baseURL}/api/v1/campaigns`,
    "vendorsURL": `${config.baseURL}/api/v1/vendors`,
    "fileRetrievalConfigURL": `${config.baseURL}/api/v1/fileRetrievalConfig`,
    "licenseAuditReportURL":`${config.baseURL}/api/v1/audits`
};