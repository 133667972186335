export const getOutPutFilesToBeDeleted = (outputFiles: any, selectedFile: any) => {
    let selectedFiles: any = [];
    outputFiles.map((item: any) => {
        if (compareDate(item.logDate, selectedFile.logDate) && item.dataFeedConfigId === selectedFile.dataFeedConfigId) {
          selectedFiles.push(item);
        }
    });
    return selectedFiles;
}

const compareDate = (date1: string, date2: string) => {
    return new Date(date1).toDateString() === new Date(date2).toDateString() ? true : false;
};

export const groupOutPutFiles = (outputFiles: any, sortOrder:any) => {
  let responseObject: any = [];
  outputFiles.sort(function(a:any,b:any):any{
    let date1:any = new Date(a.logDate);
    let date2:any = new Date(b.logDate);
    if(sortOrder === "desc"){
      return date2 - date1;
    }else{
      return date1 - date2;
    }
  });

  outputFiles.map((item: any, index:number)=>{
    if(index < outputFiles.length-1 && !compareDate(outputFiles[index].logDate,outputFiles[index+1].logDate) || index >= outputFiles.length-1){
      let arr = getRunLogs(outputFiles, item);
      responseObject.push(arr);
    }
  })
 
  const groupBy = (array:any, key:any) => {
    return array.reduce((result:any, currentValue:any) => {
      (result[currentValue[key]] = result[currentValue[key]] || []).push(currentValue);
      return result;
    }, {});
  };

  let response:any = [];
  responseObject.map((item: any) =>{
    let items = Object.values(groupBy(item,'dataFeedConfigId'));
    response = [...response,...items];
  })
  return response;
}

export const getRunLogs = (outputFiles: any, selectedFile: any) => {
  let runLogs: any = [];
  outputFiles.map((item: any) => {
      if (compareDate(item.logDate, selectedFile.logDate)) {
          runLogs.push(item);
      }
  });
  return runLogs;
}

export const getRunLogIds = (selectedFiles: any) => {
  let runLofIds: any = [];
  selectedFiles.map((item: any) => {
    runLofIds.push(item.runLogId);
  });
  return runLofIds;
}