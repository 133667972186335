import { frcJsonAttributes, errorObjectAttributes } from '../../components/functional/fileTransferConfiguration/fileRetrievalConfigurationJson';

export function retryLimitValidation(frcDataNeedsToBeSend: frcJsonAttributes, errorObject: errorObjectAttributes, messages: any) {
    return (): boolean => {
        const parsedRetryLimit = parseInt(String(frcDataNeedsToBeSend.retryLimit).trim());
        const RetryLimitString = String(parsedRetryLimit);
        if (!frcDataNeedsToBeSend.retryLimit || frcDataNeedsToBeSend.retryLimit === "" || String(frcDataNeedsToBeSend.retryLimit).trim().length < 1) {
            errorObject.retryLimit = messages.FieldRequired;
            return false;
        } else if (String(frcDataNeedsToBeSend.retryLimit).trim() !== '0' && (RetryLimitString === 'NaN' || !Number(frcDataNeedsToBeSend.retryLimit))) {
            errorObject.retryLimit = messages.numbersOnlyAllowed;
            return false;
        } else if (parsedRetryLimit > 5 || parsedRetryLimit < 1) {
            errorObject.retryLimit = messages.invalidRetryLimit;
            return false;
        } else {
            errorObject.retryLimit = '';
            return true;
        }
    }
}