import { AnyAction } from "redux";
import { actionType } from "../actions/actionTypes";

type formatReducerState = {
    formatListFetchingInProgress: boolean,
    formatListFetchingSuccess: boolean,
    formatListFetchingFailure: boolean,
    formatList: Array<any> | null;
    formatFieldsFetchingInProgress: boolean,
    formatFieldsFetchingSuccess: boolean,
    fileFormatFieldsFetchingSuccess: boolean
    formatFieldsFetchingFailure: boolean,
    formatFields: Array<any> | null,
    fileFormatFields: Array<any> | null,
    selectedFormat: any | null,
    fetchingFormatInProgress: boolean,
    fetchingFormatSuccess: boolean,
    fetchingFormatFailure: boolean,
    activeFormatList: Array<any> | null;
    activeCampaignFormatList: Array<any> | null;
    selectedFormatDataFeedConfig: Array<any> | null;
    selectedFormatFileRetrievalConfig: Array<any> | null,
    fetchingFileRetrievalConfigInProgress: boolean,
    fetchingFileRetrievalConfigSuccess: boolean,
    fetchingFileRetrievalConfigFailure: boolean,
    saveFileRetrievalConfigInProgress: boolean,
    saveFileRetrievalConfigSuccess: boolean,
    saveFileRetrievalConfigFailure: boolean,
    deleteFileRetrievalConfigInProgress: boolean,
    deleteFileRetrievalConfigSuccess: boolean,
    deleteFileRetrievalConfigFailure: boolean,
    updatingFileRetrievalConfigInProgress: boolean,
    updatingFileRetrievalConfigSuccess: boolean,
    updatingFileRetrievalConfigFailure: boolean,

};
const getInitialFormatFields = (): Array<any> | null => {
    let formatFields = sessionStorage.getItem('formatFields')
    return formatFields ? JSON.parse(formatFields) : null;
}
const getInitialFileFormatFields = (): Array<any> | null => {
    let fileFormatFields = sessionStorage.getItem('fileFormatFields')
    return fileFormatFields ? JSON.parse(fileFormatFields) : null
}
const initialState: formatReducerState = {
    formatList: null,
    formatListFetchingInProgress: false,
    formatListFetchingSuccess: false,
    formatListFetchingFailure: false,
    formatFieldsFetchingInProgress: false,
    formatFieldsFetchingSuccess: false,
    fileFormatFieldsFetchingSuccess: false,
    formatFieldsFetchingFailure: false,
    formatFields: getInitialFormatFields(),
    fileFormatFields: getInitialFileFormatFields(),
    selectedFormat: null,
    fetchingFormatInProgress: false,
    fetchingFormatSuccess: false,
    fetchingFormatFailure: false,
    activeFormatList: null,
    activeCampaignFormatList: null,
    selectedFormatDataFeedConfig: null,
    selectedFormatFileRetrievalConfig: null,
    fetchingFileRetrievalConfigInProgress: false,
    fetchingFileRetrievalConfigSuccess: false,
    fetchingFileRetrievalConfigFailure: false,
    saveFileRetrievalConfigInProgress: false,
    saveFileRetrievalConfigSuccess: false,
    saveFileRetrievalConfigFailure: false,
    deleteFileRetrievalConfigInProgress: false,
    deleteFileRetrievalConfigSuccess: false,
    deleteFileRetrievalConfigFailure: false,
    updatingFileRetrievalConfigInProgress: false,
    updatingFileRetrievalConfigSuccess: false,
    updatingFileRetrievalConfigFailure: false,

};

export function formatReducer(state = initialState, action: AnyAction): formatReducerState {
    switch (action.type) {
        case actionType.FETCHING_FORMAT_LIST_IN_PROGRESS:
            return { ...state, formatListFetchingInProgress: true, formatListFetchingSuccess: false, formatListFetchingFailure: false }
        case actionType.FETCHING_FORMAT_LIST_SUCCESS:
            return { ...state, formatListFetchingInProgress: false, formatListFetchingSuccess: true, formatListFetchingFailure: false, formatList: action.payload }
        case actionType.FETCHING_FORMAT_LIST_FAILURE:
            return { ...state, formatListFetchingInProgress: false, formatListFetchingSuccess: false, formatListFetchingFailure: true }
        case actionType.FETCHING_FORMAT_FIELDS_LIST_IN_PROGRESS:
            return { ...state, formatFieldsFetchingInProgress: true, formatFieldsFetchingSuccess: false, formatFieldsFetchingFailure: false }
        case actionType.FETCHING_FORMAT_FIELDS_LIST_SUCCESS:
            return { ...state, formatFieldsFetchingInProgress: false, formatFieldsFetchingSuccess: true, formatFieldsFetchingFailure: false, formatFields: action.payload }
        case actionType.FETCHING_FILE_FORMAT_FIELDS_LIST_SUCCESS:
            return { ...state, formatFieldsFetchingInProgress: false, fileFormatFieldsFetchingSuccess: true, formatFieldsFetchingFailure: false, fileFormatFields: action.payload }
        case actionType.FETCHING_FORMAT_FIELDS_LIST_FAILURE:
            return { ...state, formatFieldsFetchingInProgress: false, formatFieldsFetchingSuccess: false, formatFieldsFetchingFailure: true }
        case actionType.FETCHING_CAMPAIGN_FORMAT_IN_PROGRESS:
            return { ...state, fetchingFormatInProgress: true, fetchingFormatSuccess: false, fetchingFormatFailure: false }
        case actionType.FETCHING_CAMPAIGN_FORMAT_SUCCESS:
            return { ...state, fetchingFormatInProgress: false, fetchingFormatSuccess: true, fetchingFormatFailure: false, selectedFormat: action.payload, selectedFormatDataFeedConfig: action.payload.dataFeedConfigs, selectedFormatFileRetrievalConfig: action.payload.fileRetrievalConfigs }
        case actionType.FETCHING_CAMPAIGN_FORMAT_FAILURE:
            return { ...state, fetchingFormatInProgress: false, fetchingFormatSuccess: false, fetchingFormatFailure: true, selectedFormat: action.payload }
        case actionType.FETCHING_ACTIVE_FORMAT_LIST_SUCCESS:
            return { ...state, formatListFetchingInProgress: false, formatListFetchingSuccess: true, formatListFetchingFailure: false, activeFormatList: action.payload }
        case actionType.FETCHING_ACTIVE_CAMPAIGN_FORMAT_LIST_SUCCESS:
            return { ...state, formatListFetchingInProgress: false, formatListFetchingSuccess: true, formatListFetchingFailure: false, activeCampaignFormatList: action.payload }
        case actionType.FETCHING_FORMAT_FILE_RETRIEVAL_CONFIG_IN_PROGRESS:
            return { ...state, fetchingFileRetrievalConfigInProgress: true, fetchingFileRetrievalConfigSuccess: false, fetchingFileRetrievalConfigFailure: false }
        case actionType.FETCHING_FORMAT_FILE_RETRIEVAL_CONFIG_SUCCESS:
            return { ...state, fetchingFileRetrievalConfigInProgress: false, fetchingFileRetrievalConfigSuccess: true, fetchingFileRetrievalConfigFailure: false, selectedFormatFileRetrievalConfig: action.payload }
        case actionType.FETCHING_FORMAT_FILE_RETRIEVAL_CONFIG_FAILURE:
            return { ...state, fetchingFileRetrievalConfigInProgress: false, fetchingFileRetrievalConfigSuccess: false, fetchingFileRetrievalConfigFailure: true }
        case actionType.SAVE_FORMAT_FILE_RETRIEVAL_CONFIG_IN_PROGRESS:
            return { ...state, saveFileRetrievalConfigInProgress: true, fetchingFileRetrievalConfigSuccess: false, fetchingFileRetrievalConfigFailure: false }
        case actionType.SAVE_FORMAT_FILE_RETRIEVAL_CONFIG_SUCCESS:
            return { ...state, saveFileRetrievalConfigInProgress: false, fetchingFileRetrievalConfigSuccess: true, fetchingFileRetrievalConfigFailure: false, selectedFormatFileRetrievalConfig: action.payload }
        case actionType.SAVE_FORMAT_FILE_RETRIEVAL_CONFIG_FAILURE:
            return { ...state, saveFileRetrievalConfigInProgress: false, fetchingFileRetrievalConfigSuccess: false, fetchingFileRetrievalConfigFailure: true }
        case actionType.DELETE_FORMAT_FILE_RETRIEVAL_CONFIG_IN_PROGRESS:
            return { ...state, deleteFileRetrievalConfigInProgress: true, deleteFileRetrievalConfigSuccess: false, deleteFileRetrievalConfigFailure: false }
        case actionType.DELETE_FORMAT_FILE_RETRIEVAL_CONFIG_SUCCESS:
            return { ...state, deleteFileRetrievalConfigInProgress: false, deleteFileRetrievalConfigSuccess: true, deleteFileRetrievalConfigFailure: false, selectedFormatFileRetrievalConfig: action.payload }
        case actionType.DELETE_FORMAT_FILE_RETRIEVAL_CONFIG_FAILURE:
            return { ...state, deleteFileRetrievalConfigInProgress: false, deleteFileRetrievalConfigSuccess: false, deleteFileRetrievalConfigFailure: true }
        case actionType.UPDATING_FORMAT_FILE_RETRIEVAL_CONFIG_IN_PROGRESS:
            return { ...state, updatingFileRetrievalConfigInProgress: true, updatingFileRetrievalConfigSuccess: false, updatingFileRetrievalConfigFailure: false }
        case actionType.UPDATING_FORMAT_FILE_RETRIEVAL_CONFIG_SUCCESS:
            return { ...state, updatingFileRetrievalConfigInProgress: false, updatingFileRetrievalConfigSuccess: true, updatingFileRetrievalConfigFailure: false, selectedFormatFileRetrievalConfig: action.payload }
        case actionType.UPDATING_FORMAT_FILE_RETRIEVAL_CONFIG_FAILURE:
            return { ...state, updatingFileRetrievalConfigInProgress: false, updatingFileRetrievalConfigSuccess: false, updatingFileRetrievalConfigFailure: true }
        default:
            return state;
    }
}