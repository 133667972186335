export const configuration = () => {
    const APP_ENV = process.env.REACT_APP_ENV || 'local';
    const PRODUCT_NAME = process.env.REACT_APP_PROD || 'dfb';
    console.log("Inside Configuration Got APP_ENV", APP_ENV);
    console.log("Inside Configuration Got REACT_APP_PROD", process.env.REACT_APP_PROD);
    const localConfiguration = {
        ssoURL: 'https://dmdconnects.okta.com/app/dmdmarketingcorp_dfblocal_1/exkpn16lgb8hGOdWG0x7/sso/saml',
        baseURL: 'http://localhost:8080'
    }
    const devConfiguration = {
        ssoURL: 'https://dmdconnects.okta.com/app/dmdmarketingcorp_dfbdev_1/exkpn2ppbxN8uMhoc0x7/sso/saml',
        baseURL: 'https://dfb-api.dev.healthcarecommunicationsnetwork.org'
    }
    const qaConfiguration = {
        ssoURL: 'https://dmdconnects.okta.com/app/dmdmarketingcorp_dfbqa_1/exkppb5l5gU2e5usl0x7/sso/saml',
        baseURL: 'https://dfb-api.qa.healthcarecommunicationsnetwork.org'
    }
    const qadfb2Configuration = {
        ssoURL: 'https://dmdconnects.okta.com/app/dmdconnects_dfb2qa_1/exkxl5mf878quZAhU0x7/sso/saml',
        baseURL: 'https://dfb2-api.qa.healthcarecommunicationsnetwork.org'
    }
    const stgConfiguration = {
        ssoURL: 'https://dmdconnects.okta.com/app/dmdconnects_dfbstaging_1/exkpztjnqr3k3lphR0x7/sso/saml',
        baseURL: 'https://dfb-api.stg.healthcarecommunicationsnetwork.org'
    }
    const prodConfiguration = {
        ssoURL: 'https://dmdconnects.okta.com/app/dmdconnects_dfbprod_1/exkqgz20baklJxHJt0x7/sso/saml',
        baseURL: 'https://dfb-api.healthcarecommunicationsnetwork.org'
    }
    if (APP_ENV === 'local') {
        return localConfiguration;
    } else if (APP_ENV === 'dev') {
        return devConfiguration;
    } else if (APP_ENV === 'qa') {
        return PRODUCT_NAME === 'dfb2'? qadfb2Configuration:qaConfiguration;
    } else if (APP_ENV === 'stg') {
        return stgConfiguration;
    } else if (APP_ENV === 'prod') {
        return prodConfiguration;
    }
}
