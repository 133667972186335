import { clientErrorObjectAttribute, clientJsonAttributes } from "../../components/functional/client/ClientCreateJson";

export function clientNameValidation(clientDataNeedsToBeSend: clientJsonAttributes, errorObject: clientErrorObjectAttribute, messages: any) {
    return (): boolean => {
        if (!clientDataNeedsToBeSend.clientName || clientDataNeedsToBeSend.clientName.trim().length === 0) {
          errorObject.clientName = messages.clientNameIsMandatory;
          return false;
        } else if (clientDataNeedsToBeSend.clientName.length > 1) {
          errorObject.clientName = "";
          return true;
        } else {
          errorObject.clientName = "";
          return true;
        }
    }
}

export function clientCodeValidation(clientDataNeedsToBeSend: clientJsonAttributes, errorObject: clientErrorObjectAttribute, messages: any) {
    const clientCodeRegEx = /^[A-Za-z&]+$/g;
    return (): boolean => {
        if(clientDataNeedsToBeSend.clientCode && clientDataNeedsToBeSend.clientCode.trim().length > 0){
            if(clientDataNeedsToBeSend.clientCode.trim().length !== 3){
                if(clientCodeRegEx.test(clientDataNeedsToBeSend.clientCode.trim())){
                    errorObject.clientCode = messages.clientCodeShouldBeExactlyThreeCharacters;
                }else{
                    errorObject.clientCode = messages.clientCodeShouldBeExactlyThreeCharactersWithoutSpecialCharacters;
                }
                return false; 
            }else if(clientDataNeedsToBeSend.clientCode.trim().length === 3 && !clientCodeRegEx.test(clientDataNeedsToBeSend.clientCode.trim())){
                errorObject.clientCode = messages.noSpecialCharactersAllowed;
                return false; 
            }else{
                errorObject.clientCode = '';
                return true;
            }
        }
        errorObject.clientCode = '';
        return true;
    }
}

export function clientSalesforceIdValidation(clientDataNeedsToBeSend: clientJsonAttributes, errorObject: clientErrorObjectAttribute, messages: any) {
    // Future scope of implementation
    return (): boolean => {
        return true;
    }
}

export function clientSalesforceNameValidation(clientDataNeedsToBeSend: clientJsonAttributes, errorObject: clientErrorObjectAttribute, messages: any) {
    // Future scope of implementation
    return (): boolean => {
        return true;
    }
}