import { frcJsonAttributes, errorObjectAttributes } from '../../components/functional/fileTransferConfiguration/fileRetrievalConfigurationJson';

export function transferTypeValidation(frcDataNeedsToBeSend: frcJsonAttributes,
    errorObject: errorObjectAttributes, messages: any) {
    return (): boolean => {
        if (!frcDataNeedsToBeSend.transferType || frcDataNeedsToBeSend.transferType.length < 1) {
            errorObject.transferType = messages.FieldRequired;
            return false
        } else {
            errorObject.transferType = '';
            return true;
        }
    }
}