import { CircularProgress, Collapse, createStyles, Divider, Grid, makeStyles, Tab, TableCell, Tabs, Typography, Theme, IconButton, Tooltip, InputAdornment, TextField } from '@material-ui/core';
import { Edit, ExpandMore, Search, } from '@material-ui/icons';
import SubdirectoryArrowRightIcon from '@material-ui/icons/SubdirectoryArrowRight';
import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import theme, { colorPalette, getCampaignFormatStatusColorPalette } from '../../../assets/theme';
import { RootState } from '../../../reducers/rootReducer';
import { TOAST_TYPE, dfbEncoding, dfbLineEndings, formatTypes } from '../../../utils/dfbConstants';
import PageWrapper from '../../ui/PageWrapper';
import StatusDisplay from '../../ui/StatusDisplay';
import EnhancedTable from '../../ui/TableComponent';
import clsx from 'clsx';
import { DataFeedConfigurationsDetails } from '../datafeedconfiguration/DataFeedConfigurationDetails';
import { DataFeedEditDialogContainer } from '../datafeedconfiguration/DataFeedCreateAndEditDialogContainer';
import { toast } from 'react-toastify';
import { authorities, isAuthorityAvailable } from '../../../utils/permissions';
import { userGroups } from '../../../utils/dfbConstants';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import AddBoxIcon from '@material-ui/icons/AddBox';
import { FileRetrievalConfigDialogContainer } from '../fileTransferConfiguration/fileRetrievalConfigDialogContainer'
import DialogContainer from '../../ui/DialogContainer';
import messageBundle from '../../../assets/resource_bundle/messages.json';
import { deleteFRCConfig } from '../../../actions/formatAction';
import { getFrcConfigurationsGroupByClientName } from '../fileTransferConfiguration/fileRetrievalConfigurationUtill';
import VisibilityIcon from '@material-ui/icons/Visibility';
import toastMessage from '../../ui/ToastMessage';
interface CampaignFormatDetailsProps {
    campaignFormat: any;
    pageLoader: boolean;
}

const UnconnectedCampaignFormatDetails: React.FC<Props & CampaignFormatDetailsProps> = (props) => {

    const [selectedTab, setSelectedTab] = React.useState(0);
    const [expanded, setExpanded] = React.useState(0);
    const [dataFeedConfigEditDialogOpen, setDataFeedConfigEditDialogOpen] = React.useState(false);
    const [selectedDataFeedConfig, setSelectedDataFeedConfig] = React.useState(null);
    const [selectedDataFeedConfigIndex, setSelectedDataFeedConfigIndex] = React.useState(-1);
    const [refreshPage, setRefreshPage] = React.useState(false);
    const [expandedChild, setExpendedChild] = React.useState(0);
    const [isChildConfiguration, setIsChildConfiguration] = React.useState(false);
    const [addFileRetrievalConfigDialogOpen, setAddFileRetrievalConfigDialogOpen] = React.useState(false);
    const [selectedFileRetrievalConfig, setSelectedFileRetrievalConfig] = React.useState({});
    const [refreshFrchDialog, setRefreshFrcDialog] = React.useState(false);
    const [deleteConfigConfmDialogOpen, setDeleteConfigConfmDialogOpen] = React.useState(false);
    const [deleteConfigSelectedIndex, setDeleteConfigSelectedIndex] = React.useState(-1);
    const openId = useRef<number>(1);
    const [isDetailScreenOpen, setIsDetailScreenOpen] = React.useState(false);
    const [resetPagination, setResetPagination] = React.useState(false);
    const [isNewDataFeedConfig, setIsNewDataFeedConfig] = React.useState(false);
    const [frcExpanded, setFrcExpanded] = React.useState(0);
    const [selectedFrcClient, setSelectedFrcClient] = React.useState('');
    const [changePage, setChangePage] = React.useState(false);
    const [frcTablePage, setFrcTablePage] = React.useState(0);
    const scrollRef = useRef<null | HTMLDivElement>(null);

    useEffect(() => {
        setSelectedTab(0);
        setExpanded(0);
        setFrcExpanded(0);
        setFrcTablePage(0);
    }, [props.pageLoader])
    useEffect(() => {
        if (props.newClient?.length > 0) {
            onDataFeedCreateClick(false, true);
        }
    }, [props.newClient])

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setSelectedTab(newValue);
        setChangePage(true);
        setFrcExpanded(0);
        setFrcTablePage(0);
        setChangePage(false);
    };

    const getSelectedTabFileds = (selectedTabValue: number): number => {

        !props.campaignFormat.hasInputFile && selectedTabValue >= 1 && selectedTabValue++;

        return selectedTabValue;
    }

    const classes = useStyles();
    const handleExpandClick = (index: number) => {
        if (expanded === index) {
            setExpanded(-1);
        }
        else {
            setExpanded(index);
        }
    };
    const handleExpandedChildClick = (index: number) => {
        if (expandedChild === index) {
            setExpendedChild(-1);
        }
        else {
            setExpendedChild(index);
        }
    }
    const handleFrcExpandClick = (index: number) => {
        if (frcExpanded === index) {
            setFrcExpanded(-1);
        }
        else {
            setFrcExpanded(index);
        }
    }

    const handleFrcTablePage = (clientName: string, configId: number, fileRetrievalConfig: any) => {
        let frcActivePage: number = 0;
        let paginationCount: number = 4;
        let frcConfigurations: any = getFrcConfigurationsGroupByClientName(fileRetrievalConfig)[clientName];
        let frcIndexByClientConfigId: number = frcConfigurations.findIndex((frcConfig: { configId: number; }) => frcConfig.configId === configId);
        while (frcIndexByClientConfigId > paginationCount) {
            frcActivePage++;
            paginationCount = paginationCount + 5;

        }
        setChangePage(true);
        setFrcTablePage(frcActivePage);

    }

    const handleFrcScroller = () => {
        if (scrollRef.current != null) {
            let offSetTop = scrollRef.current.offsetTop;
            window.scrollTo({ top: offSetTop - 20, behavior: 'smooth' });
        }
    };

    const handleFrcAccordion = (clientName: string, configId: number, fileRetrievalConfig: any) => {
        if (props.campaignFormat.isGeneric) {
            let frcConfigurations: Array<String> = Object.keys(getFrcConfigurationsGroupByClientName(fileRetrievalConfig));
            let frcIndexByClient: number = frcConfigurations.findIndex(configClientName => configClientName === clientName);
            if (frcIndexByClient !== -1) {
                setFrcExpanded(frcIndexByClient);
                handleFrcScroller();
                handleFrcTablePage(clientName, configId, fileRetrievalConfig);
            }
            setChangePage(false);
        }
    }

    const isClientHasFrcConfigurations = (): boolean => {
        if (getFrcConfigurationsGroupByClientName(props?.selectedFormatFileRetrievalConfig)[selectedFrcClient] !== undefined && getFrcConfigurationsGroupByClientName(props?.selectedFormatFileRetrievalConfig)[selectedFrcClient].length > 0) {
            let frcConfigurations: Array<String> = Object.keys(getFrcConfigurationsGroupByClientName(props?.selectedFormatFileRetrievalConfig));
            let frcIndexByClient: number = frcConfigurations.findIndex(configClientName => configClientName === selectedFrcClient);
            if (frcIndexByClient !== -1) {
                setFrcExpanded(frcIndexByClient);
            }
            return true;
        } else {
            setFrcExpanded(0);
            return false;
        }
    }

    const metaDataFieldsHeaders = [
        {
            id: 'fieldName',
            label: 'Field Name',
            disableSort: true,
        },
        {
            id: 'fieldDataType.dataTypeName',
            label: 'Type',
            disableSort: true,
        },
        {
            id: 'isMandatory',
            label: 'Mandatory',
            disableSort: false,
        },
        {
            id: 'fieldLength',
            label: 'Size',
            disableSort: true,
        },
    ]

    const fileRetrievalConfigsHeaders = [
        {
            id: 'clientName',
            label: 'Client Name',
            disableSort: false,
        },
        {
            id: 'groupName',
            label: 'Group',
            disableSort: false,
        },
        {
            id: 'sourceFileNamePrefix,sourceFileNameDatamask,sourceFileNameSuffix,sourceFileNameExtension',
            label: 'File Name',
            disableSort: false,
        },
        {
            id: 'schedule',
            label: 'Schedule',
            disableSort: false,
        },
        {
            id: 'active',
            label: 'Active',
            disableSort: false,
        },
        {
            id: 'nextRetrievalDate',
            label: 'Next Retrieval',
            disableSort: false,
        },
        {
            id: 'action',
            label: 'Actions',
            disableSort: true,
        },
    ]

    const fileFieldsHeaders = [
        {
            id: 'fieldName',
            label: 'Field Name',
            disableSort: true,
        },
        {
            id: 'fieldDataType.dataTypeName',
            label: 'Type',
            disableSort: true,
        },
        {
            id: 'isMandatory',
            label: 'Mandatory',
            disableSort: false,
        },
        {
            id: 'fieldLength',
            label: 'Size',
            disableSort: true,
        },
    ];

    const getFilename = (fileRetrievalConfigData: any, unConcatedProperties: string): string => {

        const properties = unConcatedProperties.split(',');
        let filename = '';
        properties.forEach((property: string) => {
            if (fileRetrievalConfigData[property]) {
                filename = filename + fileRetrievalConfigData[property];
            }
        }
        )
        return filename;
    }

    const onDataFeedEditClick = (dataFeedConfig: any, index: number, childConfig: boolean) => {
        setSelectedDataFeedConfig(dataFeedConfig);
        setIsChildConfiguration(childConfig);
        setIsNewDataFeedConfig(false);
        setDataFeedConfigEditDialogOpen(true);
        setSelectedDataFeedConfigIndex(index);
    }
    const onDataFeedCreateClick = async (isChildConfig: boolean, isNewDataFeedConfig: boolean) => {
        setIsChildConfiguration(isChildConfig);
        setIsNewDataFeedConfig(isNewDataFeedConfig);
        setDataFeedConfigEditDialogOpen(true);
    }
    const setDefaultConfigObject = (dataFeedConfig: any) => {
        setSelectedDataFeedConfig(dataFeedConfig);
    }
    const onDataFeedEditCloseClick = () => {
        setDataFeedConfigEditDialogOpen(false);
        setIsChildConfiguration(false);
    }

    const rowRenderForMetaData = (metaData: any) => {
        let rowCells = [];
        let headers = metaDataFieldsHeaders;
        for (var i = 0; i < headers.length; i++) {
            let rowCell = null;
            if (i === 2) {
                rowCell = (
                    <TableCell align={'left'} style={{ background: '#ffffff' }}>
                        <Typography variant={'subtitle2'} >
                            {eval(`metaData.${headers[i].id}`) === true ? 'Yes' : 'No'}
                        </Typography>
                    </TableCell>
                );
            }
            else {
                rowCell = (
                    <TableCell align={'left'} style={{ background: '#ffffff' }}>
                        <Typography variant={'subtitle2'} >
                            {eval(`metaData.${headers[i].id}`) ? eval(`metaData.${headers[i].id}`) : '-'}
                        </Typography>
                    </TableCell>
                );
            }
            rowCells.push(rowCell);
        }
        return rowCells;
    };


    const openConfigConfmDialog = (configId: number, clientName: string) => {
        if (props.campaignFormat.isGeneric) {
            setSelectedFrcClient(clientName);
        }
        setDeleteConfigConfmDialogOpen(true);
        setDeleteConfigSelectedIndex(getConfigIndexByConfigId(configId));
    }

    const getConfigIndexByConfigId = (configId: number): number => {
        let configIndex: number = 0;
        if (props.selectedFormatFileRetrievalConfig != null) {
            configIndex = props.selectedFormatFileRetrievalConfig.findIndex(configObj => configObj.configId === configId);
        }
        return configIndex;
    }

    const DeleteDialogBody = (props: any) => {
        return (
            <div style={{ padding: theme.spacing(0, 0, 3, 0) }}>
                <Typography variant={'body1'}>
                    {props.message}
                </Typography>
            </div>
        )
    };

    const handleFRCDelete = async () => {
        const configId = props.selectedFormatFileRetrievalConfig ? props.selectedFormatFileRetrievalConfig[deleteConfigSelectedIndex].configId : -1;
        if (configId !== -1) {
            const result = await props.deleteFRCConfig(configId, deleteConfigSelectedIndex);
            if (result) {
                if (props.campaignFormat.isGeneric && !isClientHasFrcConfigurations()) {
                    handleResetPagination();
                    window.scrollTo({ top: 100, behavior: 'smooth' });
                } else {
                    handleFrcScroller();
                }
                toast(<Typography style={{ color: 'black', opacity: 0.7 }}>{props.messages.deleteFRCConfigSuccessfull}</Typography>);
            } else {
                toast.error(<Typography style={{ color: 'white', opacity: 0.7 }}>{props.messages.deleteFRCSomethingWentWrong}</Typography>);
            }

            // setDeleteConfigConfmDialogOpen(false);
        } else {
            toast.error(<Typography style={{ color: 'white' }}>{props.messages.deleteFRCConfigfailure}</Typography>);
        }
        setDeleteConfigConfmDialogOpen(false);
        setDeleteConfigSelectedIndex(-1);
    }

    const handleDeleteDialogClose = () => {
        setDeleteConfigConfmDialogOpen(false);
    };


    const rowRenderForFileRetrievalConfig = (fileRetrievalConfigData: any, index: number) => {
        let rowCells = [];
        let headers = fileRetrievalConfigsHeaders;
        for (var i = 0; i < headers.length; i++) {
            let rowCell = null;
            if (i === 6) {
                rowCell = (
                    <TableCell align={'left'} style={{ background: '#ffffff' }}>
                        <Grid container spacing={2} style={{ marginRight: theme.spacing(2) }}>
                            <Grid item xs={4}>
                                <Tooltip title="View" onClick={() => openDetailScreen(fileRetrievalConfigData.configId)}>
                                    <IconButton color='secondary' size="small">
                                        <VisibilityIcon />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                            <Grid item xs={4}>
                                <Tooltip title="Edit">
                                    <IconButton color='secondary' size="small" onClick={() => openEditFileRetrievalConfigDialog(fileRetrievalConfigData.configId)}>
                                        <EditIcon />
                                    </IconButton>
                                </Tooltip>
                            </Grid>

                            <Grid item xs={4}>
                                <Tooltip title="Delete">
                                    <IconButton color='secondary' size="small" onClick={() => openConfigConfmDialog(fileRetrievalConfigData.configId, fileRetrievalConfigData.clientName)}>
                                        <DeleteIcon />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        </Grid>
                    </TableCell>
                );
            } else if (i === 2) {
                rowCell = (
                    <TableCell align={'left'} style={{ background: '#ffffff' }}>
                        <Typography variant={'subtitle2'} >
                            {getFilename(fileRetrievalConfigData, headers[i].id)}
                            {/* {eval(`fileRetrievalConfigData.${headers[i].id}`) ? eval(`fileRetrievalConfigData.${headers[i].id}`) : '-'} */}
                        </Typography>
                    </TableCell>
                );
            } else {
                rowCell = (
                    <TableCell align={'left'} style={{ background: '#ffffff' }}>
                        <Typography variant={'subtitle2'} >
                            {eval(`fileRetrievalConfigData.${headers[i].id}`) ? eval(`fileRetrievalConfigData.${headers[i].id}`) : '-'}
                        </Typography>
                    </TableCell>
                );
            };
            rowCells.push(rowCell);
        }
        return rowCells;
    };

    const rowRenderForFileFields = (fileField: any) => {
        let rowCells = [];
        let headers = metaDataFieldsHeaders;
        for (var i = 0; i < headers.length; i++) {
            let rowCell = null;
            if (i === 2) {
                rowCell = (
                    <TableCell align={'left'} style={{ background: '#ffffff' }}>
                        <Typography variant={'subtitle2'} >
                            {eval(`fileField.${headers[i].id}`) === true ? 'Yes' : 'No'}
                        </Typography>
                    </TableCell>
                );
            }
            else {
                rowCell = (
                    <TableCell align={'left'} style={{ background: '#ffffff' }}>
                        <Typography variant={'subtitle2'} >
                            {eval(`fileField.${headers[i].id}`) ? eval(`fileField.${headers[i].id}`) : '-'}
                        </Typography>
                    </TableCell>
                );
            }
            rowCells.push(rowCell);
        }
        return rowCells;
    };

    const onEditComplete = (dataFeedConfig: any) => {
        let toastNotificationText = `${props.messages.ChangesSavedSuccess}`
        if (props.selectedFormatDataFeedConfig != null) {
            props.selectedFormatDataFeedConfig[selectedDataFeedConfigIndex] = dataFeedConfig;
        }
        setDataFeedConfigEditDialogOpen(false);
        setRefreshPage(!refreshPage);
        toastMessage(TOAST_TYPE.SUCCESS, toastNotificationText);


    }
    const onConfigEditComplete = (dataFeedConfig: any) => {
        let toastNotificationText = `${props.messages.ChangesSavedSuccess}`
        if (props.selectedFormatDataFeedConfig != null) {
            props.selectedFormatDataFeedConfig[expanded].additionalDeliveryConfiguration[expandedChild] = dataFeedConfig;
        }
        setDataFeedConfigEditDialogOpen(false);
        setRefreshPage(!refreshPage);
        toastMessage(TOAST_TYPE.SUCCESS, toastNotificationText);


    }

    const openAddFileRetrievalConfigDialog = () => {
        openId.current = openId.current + 1;
        setIsDetailScreenOpen(false);
        setRefreshFrcDialog(!refreshFrchDialog);
        setSelectedFileRetrievalConfig({})
        setAddFileRetrievalConfigDialogOpen(true);
    }
    const closeAddFileRetrievalConfigDialog = () => {
        // Increment id each time modal closes
        openId.current = openId.current + 1;
        setRefreshFrcDialog(!refreshFrchDialog);
        setSelectedFileRetrievalConfig({})
        setAddFileRetrievalConfigDialogOpen(false);
    }
    const openEditFileRetrievalConfigDialog = (configId: number) => {
        setRefreshFrcDialog(!refreshFrchDialog);
        setSelectedFileRetrievalConfig(props?.selectedFormatFileRetrievalConfig ? props.selectedFormatFileRetrievalConfig[getConfigIndexByConfigId(configId)] : [])
        setAddFileRetrievalConfigDialogOpen(true);
        setIsDetailScreenOpen(false);
    }
    const openDetailScreen = (configId: number) => {
        setSelectedFileRetrievalConfig(props?.selectedFormatFileRetrievalConfig ? props.selectedFormatFileRetrievalConfig[getConfigIndexByConfigId(configId)] : [])
        setAddFileRetrievalConfigDialogOpen(true);
        setIsDetailScreenOpen(true);
        setRefreshFrcDialog(!refreshFrchDialog);
    }

    const handleEdit = () => {
        setAddFileRetrievalConfigDialogOpen(true);
        setIsDetailScreenOpen(false);
    }

    const handleResetPagination = () => {
        setResetPagination(true);
        setChangePage(true);
        setFrcTablePage(0);
        setChangePage(false);
    }

    const dataFeedAuthorityCheck = () => {
        if (props.campaignFormat.isGeneric && isAuthorityAvailable(props.user ? props.user.auths : [],
            authorities.DATA_FEED_CONFIGURATION_CREATE, userGroups.DFB_ADMINISTRATOR,
            props.user ? props.user.groups : []))
            return true;
        else
            return false;
    }

    return (
        <PageWrapper
            pageTitle={props.pageLoader || !props.campaignFormat ? '' : `${props.campaignFormat.formatName} - V ${props.campaignFormat.campaignFormatMajorVersion}.${props.campaignFormat.minorVersion}`}
            badgeTitle={`${('' + props.messages.Format).toUpperCase()}`}
            additionalTitleInfo={<StatusDisplay statusColor={getCampaignFormatStatusColorPalette(props.campaignFormat ? props.campaignFormat.campaignFormatsStatus.statusCode : '')}
                statusText={props.pageLoader || !props.campaignFormat ? '' : props.campaignFormat.campaignFormatsStatus.statusDescription} />
            }
            formatType={props.pageLoader || !props.campaignFormat ? '' : props.campaignFormat.formatType}
            formatDescription={props.pageLoader || !props.campaignFormat ? '' : props.campaignFormat.formatDescription}>
            {props.pageLoader ?
                <Grid container direction='column' justify='center' alignContent='center' xs={12} style={{ height: '100%' }}>
                    <CircularProgress size={50} />
                </Grid> :
                <React.Fragment>
                    {!props.pageLoader && !props.campaignFormat ?
                        <Grid container direction='column' justify='center' alignContent='center' xs={12} style={{ height: '100%' }}>
                            <Typography variant='body2' style={{ opacity: 0.6 }}>
                                {'NO DATA FOUND'}
                            </Typography>
                        </Grid>
                        : <Grid container xs={12}>
                            <DataFeedEditDialogContainer
                                campaignFormat={props.campaignFormat}
                                open={dataFeedConfigEditDialogOpen}
                                isChildConfiguration={isChildConfiguration}
                                dataFeedConfig={selectedDataFeedConfig}
                                isGeneric={props.campaignFormat.isGeneric}
                                isNewDataFeedConfiguration={isNewDataFeedConfig}
                                setDefaultConfigObject={setDefaultConfigObject}
                                postSaveCallback={onEditComplete}
                                postChildConfigCallback={onConfigEditComplete}
                                handleClose={onDataFeedEditCloseClick}
                                onDataFeedCreateClick={onDataFeedCreateClick}
                                setExpanded={setExpanded}
                            />
                            <Grid xs={12} item container direction='row'>
                                <Grid item container xs={4} lg={2} direction='column' style={{ padding: theme.spacing(1, 3, 0) }}>
                                    <Typography data-testid='created-by-label' variant='caption' align='left' style={{ opacity: 1, color: colorPalette.primaryShade }}>
                                        {'Created By'}
                                    </Typography>
                                    <Typography data-testid='created-by-value' variant='body1' align='left' style={{ opacity: 0.87 }}>
                                        {props.campaignFormat.createdBy ? props.campaignFormat.createdBy : '-'}
                                    </Typography>
                                    <Typography data-testid='created-date' variant='body2' align='left' style={{ opacity: 0.6 }}>
                                        {props.campaignFormat.createdDate ? new Date(props.campaignFormat.createdDate).toDateString() : '-'}
                                    </Typography>
                                </Grid>
                                <Grid item container xs={4} lg={2} direction='column' style={{ padding: theme.spacing(1, 3, 0) }}>
                                    <Typography data-testid='approved-by-label' variant='caption' align='left' style={{ opacity: 1, color: colorPalette.primaryShade }}>
                                        {'Approved By'}
                                    </Typography>
                                    <Typography data-testid='approved-by-value' variant='body1' align='left' style={{ opacity: 0.87 }}>
                                        {props.campaignFormat.approvedBy ? props.campaignFormat.approvedBy : '-'}
                                    </Typography>
                                    <Typography data-testid='approved-date' variant='body2' align='left' style={{ opacity: 0.6 }}>
                                        {props.campaignFormat.approvedDate ? new Date(props.campaignFormat.approvedDate).toDateString() : '-'}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid data-testid='fields-table-grid' item container >
                                <Grid item container xs={12} direction='row' style={{ paddingLeft: theme.spacing(3) }}>
                                    <Tabs value={selectedTab} data-testid='tabs' onChange={handleChange} indicatorColor='primary'>
                                        <Tab label={<Typography variant='body1' style={{ textTransform: 'none', fontWeight: 'bold' }} data-testid='mete-data'>
                                            {'Meta Data'}</Typography>} />
                                        {props.campaignFormat.hasInputFile && <Tab label={<Typography variant='body1' style={{ textTransform: 'none', fontWeight: 'bold' }} data-testid='target-list'>
                                            {'Target List'}</Typography>} />}
                                        <Tab label={<Typography variant='body1' style={{ textTransform: 'none', fontWeight: 'bold' }} data-testid='data-feed-config'>
                                            {props.messages.DataFeedConfigurations}</Typography>} />
                                        {props.campaignFormat?.formatType?.formatTypeName === props.messages.License.toUpperCase() && <Tab data-testid='file-retrieval' label={<Typography variant='body1' style={{ textTransform: 'none', fontWeight: 'bold' }}>
                                            {props.messages.fileRetrievalConfiguration}</Typography>} />}
                                    </Tabs>
                                </Grid>
                                {getSelectedTabFileds(selectedTab) === 0 &&
                                    <Grid item container data-testid='metadata-fields-table' xs={12} style={{ padding: theme.spacing(3) }}>
                                        <EnhancedTable
                                            dataArray={props.campaignFormat.metadataFields}
                                            rowRenderer={rowRenderForMetaData}
                                            headers={metaDataFieldsHeaders}
                                            initialSortKey={''}
                                        />
                                    </Grid>}
                                {getSelectedTabFileds(selectedTab) === 1 &&
                                    <Grid item container data-testid='file-fields-table' xs={12} style={{ padding: theme.spacing(3) }}>
                                        <Grid item container direction='row' justify='space-between' data-testid='target-list-definitions' xs={12} style={{ background: 'white', margin: theme.spacing(1, 0), minHeight: '65px' }}>
                                            <Grid item container direction='column' xs={2} justify='center' style={{ padding: theme.spacing(2) }}>
                                                <Typography data-testid='target-list-delimiter-heading' variant='caption' align='left' style={{ opacity: 0.6 }}>
                                                    {props.messages.targetListDelimiter}
                                                </Typography>
                                                <Typography data-testid='target-list-delimiter-value' variant='subtitle2' align='left' >
                                                    {props.campaignFormat && props.campaignFormat.targetListDelim ? props.campaignFormat.targetListDelim : '-'}
                                                </Typography>
                                            </Grid>
                                            <Grid item container direction='column' xs={2} justify='center' style={{ padding: theme.spacing(2) }}>
                                                <Typography data-testid='target-list-endings-heading' variant='caption' align='left' style={{ opacity: 0.6 }}>
                                                    {props.messages.targetListLineEndings}
                                                </Typography>
                                                <Typography data-testid='target-list-endings-value' variant='subtitle2' align='left'>
                                                    {props.campaignFormat && props.campaignFormat.targetListLineEndings ? dfbLineEndings[props.campaignFormat.targetListLineEndings - 1] : '-'}
                                                </Typography>
                                            </Grid>
                                            <Grid item container direction='column' xs={2} justify='center' style={{ padding: theme.spacing(2) }}>
                                                <Typography data-testid='target-list-encoding-heading' variant='caption' align='left' style={{ opacity: 0.6 }}>
                                                    {props.messages.targetListEncoding}
                                                </Typography>
                                                <Typography data-testid='target-list-encoding-value' variant='subtitle2' align='left' >
                                                    {props.campaignFormat && props.campaignFormat.targetListEncoding ? dfbEncoding[props.campaignFormat.targetListEncoding - 1] : '-'}
                                                </Typography>
                                            </Grid>
                                            <Grid item container direction='column' xs={2} justify='center' style={{ padding: theme.spacing(2) }}>
                                                <Typography data-testid='target-list-encoding-heading' variant='caption' align='left' style={{ opacity: 0.6 }}>
                                                    {props.messages.targetListTextQualifiers}
                                                </Typography>
                                                <Typography data-testid='target-list-encoding-value' variant='subtitle2' align='left' >
                                                    {props.campaignFormat && props.campaignFormat.targetListTextQualifier ? props.campaignFormat.targetListTextQualifier : '-'}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <EnhancedTable
                                            dataArray={props.campaignFormat.fileFields}
                                            rowRenderer={rowRenderForFileFields}
                                            headers={fileFieldsHeaders}
                                            initialSortKey={''}
                                        />
                                    </Grid>
                                }
                                {getSelectedTabFileds(selectedTab) === 2 &&
                                    <Grid container direction='row' justify='space-between' xs={12} style={{ padding: theme.spacing(0, 3, 3, 3) }}>
                                        {dataFeedAuthorityCheck() ? <Grid item container xs={12} justify='flex-end' style={{ padding: theme.spacing(0, 1.2, 0, 0) }} >
                                            <Tooltip title='create'>
                                                <IconButton component="span" onClick={() => { onDataFeedCreateClick(false, true) }}>
                                                    <AddBoxIcon fontSize='large' color='secondary' />
                                                </IconButton>
                                            </Tooltip>
                                        </Grid> :
                                            null}
                                        <Grid item container xs={12} style={{ padding: theme.spacing(dataFeedAuthorityCheck() ? 0 : 3, 0, 0, 0) }}>
                                            {!props.selectedFormatDataFeedConfig || props.selectedFormatDataFeedConfig.length < 1 ?
                                                <Grid item container direction='column' justify='center' alignContent='center' xs={12} style={{ background: 'white', padding: theme.spacing(1, 2, 2, 3), minHeight: 250 }}>
                                                    <Typography variant='body2' style={{ opacity: 0.6 }}>
                                                        {props.messages.noDataFoundLabel}
                                                    </Typography>
                                                </Grid>
                                                :
                                                <Grid item container direction='column' xs={12} style={{ background: 'white', padding: theme.spacing(1, 1, 2, 3) }}>
                                                    {props.selectedFormatDataFeedConfig?.map((dataFeed, index) => (
                                                        <Grid item container style={{ padding: theme.spacing(2, 2, 2, 0) }}>
                                                            <Grid item container direction='column' xs={12} style={{ padding: theme.spacing(0, 0, 0.5, 0) }}>
                                                                <Grid item container xs={12} justify='space-between' direction='row' style={{ cursor: 'pointer' }} onClick={() => { handleExpandClick(index) }}>
                                                                    <Grid item container xs={8} justify='flex-start' style={{ wordBreak: 'break-word' }}>
                                                                        <Typography data-testid='metadata-label' variant='body1' style={{ color: colorPalette.primaryShade, fontWeight: 600, wordWrap: "break-word" }}>
                                                                            {('' + dataFeed.outputJobName).toUpperCase()} {props.campaignFormat.formatType.formatTypeAbbreviation === formatTypes.CAMPAIGN_TYPE ? ' (' + dataFeed.vendorDisplayName + ')' : (props.campaignFormat.formatType.formatTypeAbbreviation === formatTypes.LICENSE_TYPE && props.campaignFormat.isGeneric) ? ' (' + dataFeed?.client?.clientName + ')' : ''}
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item container xs={4} justify='flex-end' style={{ padding: theme.spacing(0, 0.75, 0, 0) }}>
                                                                        <ExpandMore
                                                                            className={clsx(classes.expand, {
                                                                                [classes.expandOpen]: expanded === index,
                                                                            })}
                                                                            aria-expanded={expanded === index}
                                                                            aria-label="show more"
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                            <Divider style={{ width: '100%' }} />
                                                            <Collapse in={(expanded !== -1 && expanded === index)} style={{ width: '100%' }}>
                                                                <Grid item container direction='row'>
                                                                    <Grid item container style={{ width: '95%', padding: theme.spacing(2, 0, 0, 0) }}>
                                                                        <DataFeedConfigurationsDetails dataFeedConfig={dataFeed} isChildConfig={false} />
                                                                    </Grid>
                                                                    <Grid item container direction='row' justify='flex-end' style={{ width: '5%', padding: theme.spacing(0, 0, 0, 0) }}>
                                                                        {isAuthorityAvailable(props.user ? props.user.auths : [], authorities.DATA_FEED_CONFIGURATION_EDIT, userGroups.DFB_ADMINISTRATOR, props.user ? props.user.groups : []) ?
                                                                            <Grid item>
                                                                                <IconButton data-testid='edit-data-feed' color="secondary" component="span" style={{}} onClick={() => { onDataFeedEditClick(dataFeed, index, false) }}>
                                                                                    <Edit color='secondary' />
                                                                                </IconButton>
                                                                            </Grid> : null}
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid>
                                                                    {dataFeed.additionalDeliveryConfiguration.length > 0 ? <Grid style={{ padding: theme.spacing(0, 2, 0, 1) }}>
                                                                        {dataFeed.additionalDeliveryConfiguration?.map((additionalDeliveryConfiguration: any, index: number) => (
                                                                            <Grid>
                                                                                <Grid item container justify='space-between' direction='row' style={{ cursor: 'pointer' }} onClick={() => { handleExpandedChildClick(index) }}>
                                                                                    <Grid item>
                                                                                        <Typography data-testid='metadata-label' variant='body1' style={{ color: colorPalette.primaryShade, fontWeight: 600, fontSize: '13.5px', padding: theme.spacing(0, 1, 0, 0), wordWrap: "break-word" }}>
                                                                                            <SubdirectoryArrowRightIcon color='primary' />{props.messages.additionalDeliveryConfiguration.toUpperCase()}
                                                                                        </Typography>
                                                                                    </Grid>
                                                                                    <Grid item style={{ padding: theme.spacing(1, 0, 0, 0) }}>
                                                                                        <ExpandMore
                                                                                            className={clsx(classes.expand, {
                                                                                                [classes.expandOpen]: expandedChild === index,
                                                                                            })}
                                                                                            aria-expanded={expandedChild === index}
                                                                                            aria-label="show more"
                                                                                        />
                                                                                    </Grid>
                                                                                </Grid>
                                                                                <div style={{ padding: theme.spacing(0, 2, 0, 2) }}>
                                                                                    <Divider />
                                                                                </div>

                                                                                <Collapse in={(expandedChild !== -1 && expandedChild === index)} style={{ width: '100%' }}>
                                                                                    <Grid item container direction='row'>
                                                                                        <Grid item container style={{ width: '95%', padding: theme.spacing(0, 0, 0, 0) }}>
                                                                                            <DataFeedConfigurationsDetails dataFeedConfig={additionalDeliveryConfiguration} isChildConfig={true} />
                                                                                        </Grid>
                                                                                        <Grid item container direction='row' justify='flex-end' style={{ width: '5%', padding: theme.spacing(0, 0, 0, 0) }}>
                                                                                            {isAuthorityAvailable(props.user ? props.user.auths : [], authorities.DATA_FEED_CONFIGURATION_EDIT, userGroups.DFB_ADMINISTRATOR, props.user ? props.user.groups : []) ?
                                                                                                <Grid item>
                                                                                                    <IconButton color="secondary" component="span" style={{}} onClick={() => { onDataFeedEditClick(additionalDeliveryConfiguration, index, true) }}>
                                                                                                        <Edit color='secondary' />
                                                                                                    </IconButton>
                                                                                                </Grid> : null}
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                </Collapse>
                                                                            </Grid>
                                                                        ))}
                                                                    </Grid> : null}
                                                                </Grid>
                                                            </Collapse>
                                                        </Grid>
                                                    ))}
                                                </Grid>
                                            }

                                        </Grid>
                                    </Grid>
                                }
                                {getSelectedTabFileds(selectedTab) === 3 &&
                                    <><Grid item container direction='row' justify='space-between' xs={12} style={{ padding: theme.spacing(0, 0, 0, 3) }}>
                                        <Grid item xs={8} style={{ padding: theme.spacing(1, 5.5, 1, 0.5) }}>
                                            {/* TOD0 - hidding search bar as of now it will be enable in future user story */}
                                            {false ? <TextField
                                                data-testid='search-field'
                                                variant='outlined'
                                                size='small'
                                                fullWidth
                                                placeholder={'search'}
                                                value={''}
                                                InputProps={{
                                                    startAdornment: <InputAdornment position='start'><Search style={{ height: 16, width: 16 }} /></InputAdornment>,
                                                    classes: {
                                                        input: classes.input,
                                                        notchedOutline: classes.notchedOutline
                                                    }
                                                }}
                                                style={{ background: 'white', borderRadius: 2 }}
                                            /> : null}
                                        </Grid>
                                        <Grid item xs={1} style={{ padding: theme.spacing(0) }} >
                                            <Tooltip title='create'>
                                                <IconButton component="span" onClick={openAddFileRetrievalConfigDialog}>
                                                    <AddBoxIcon fontSize='large' color='secondary' />
                                                </IconButton>
                                            </Tooltip>
                                        </Grid>
                                    </Grid>
                                        <Grid item container xs={12} style={{ padding: theme.spacing(0, 3, 0, 3) }}>
                                            {props?.selectedFormatFileRetrievalConfig && props.selectedFormatFileRetrievalConfig.length < 1 ?
                                                <Grid item container direction='column' justify='center' alignContent='center' xs={12} style={{ background: 'white', padding: theme.spacing(1, 2, 2, 3), minHeight: 250 }}>
                                                    <Typography variant='body2' style={{ opacity: 0.6 }}>
                                                        {'No Data Found'}
                                                    </Typography>
                                                </Grid>
                                                :
                                                <Grid item container direction='column' xs={12} >
                                                    {!props.campaignFormat.isGeneric ? <EnhancedTable
                                                        dataArray={props?.selectedFormatFileRetrievalConfig ? props.selectedFormatFileRetrievalConfig : []}
                                                        rowRenderer={rowRenderForFileRetrievalConfig}
                                                        headers={fileRetrievalConfigsHeaders}
                                                        initialSortKey={''}
                                                        resetPagination={resetPagination} /> : <Grid direction='column' xs={12} style={{ background: 'white', padding: theme.spacing(2, 0, 1, 0) }}>
                                                        {Object.keys(getFrcConfigurationsGroupByClientName(props?.selectedFormatFileRetrievalConfig ? props.selectedFormatFileRetrievalConfig : [])).map((frcConfiguration, index) => (
                                                            <Grid direction='row' ref={frcExpanded !== -1 && frcExpanded === index ? scrollRef : null} style={{ padding: theme.spacing(0, 2, frcExpanded === index ? 0 : 1.5, 2) }}>
                                                                <Grid item container xs={12} justify='space-between' direction='row' style={{ cursor: 'pointer' }} onClick={() => { handleFrcExpandClick(index) }}>
                                                                    <Grid item container xs={8} justify='flex-start' style={{ wordBreak: 'break-word' }}>
                                                                        <Typography data-testid='frc-client-name' variant='body1' style={{ color: colorPalette.primaryShade, fontWeight: 600, wordWrap: "break-word" }}>
                                                                            {frcConfiguration.toUpperCase()}
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item container xs={4} justify='flex-end' style={{ padding: theme.spacing(0, 0.75, 0, 0) }}>
                                                                        <ExpandMore
                                                                            className={clsx(classes.expand, {
                                                                                [classes.expandOpen]: frcExpanded === index,
                                                                            })}
                                                                            aria-expanded={frcExpanded === index}
                                                                            aria-label="show more"
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                                <div style={{ padding: theme.spacing(0.5, 1.5, 1, 0) }}>
                                                                    <Divider />
                                                                </div>
                                                                <Collapse in={(frcExpanded !== -1 && frcExpanded === index)} style={{ width: '100%' }}>
                                                                    <EnhancedTable
                                                                        dataArray={getFrcConfigurationsGroupByClientName(props?.selectedFormatFileRetrievalConfig ? props.selectedFormatFileRetrievalConfig : [])[frcConfiguration]}
                                                                        rowRenderer={rowRenderForFileRetrievalConfig}
                                                                        headers={fileRetrievalConfigsHeaders}
                                                                        initialSortKey={''}
                                                                        resetPagination={resetPagination}
                                                                        changePagination={changePage}
                                                                        tablePage={frcTablePage} />
                                                                </Collapse>
                                                            </Grid>
                                                        ))}
                                                    </Grid>}
                                                </Grid>}
                                            <FileRetrievalConfigDialogContainer
                                                refreshFrcDialog={refreshFrchDialog}
                                                campaignFormat={props.campaignFormat}
                                                open={addFileRetrievalConfigDialogOpen}
                                                fileRetrievalConfig={selectedFileRetrievalConfig}
                                                handleDialogClose={closeAddFileRetrievalConfigDialog}
                                                key={openId.current}
                                                isViewOpen={isDetailScreenOpen}
                                                handleEdit={handleEdit}
                                                handleResetPagination={handleResetPagination}
                                                setFrcExpand={setFrcExpanded}
                                                handleFrcAccordion={handleFrcAccordion}
                                            />
                                            <DialogContainer
                                                heading={`${messageBundle.deleteFileRetrievalConfiguration}`}
                                                open={deleteConfigConfmDialogOpen}
                                                body={<DeleteDialogBody message={`${messageBundle.deleteWarningMessage} configuration?`} />}
                                                primaryButtonHeading={'Delete'}
                                                primaryButtonLoader={props.deleteFileRetrievalConfigInProgress}
                                                onPrimaryButtonClick={handleFRCDelete}
                                                handleClose={handleDeleteDialogClose}
                                                secondaryButtonHeading='Cancel'
                                            />
                                        </Grid></>
                                }
                            </Grid>
                        </Grid>
                    }
                </React.Fragment>}
        </PageWrapper>
    )
}


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            maxWidth: 345,
        },
        input: {
            '&::placeholder': {
                color: '#000000',
                opacity: 1
            },
        },
        notchedOutline: {
            borderWidth: "0px",
            borderColor: "#ffffff",
        },
        expand: {
            transform: 'rotate(0deg)',
            marginLeft: 'auto',
            cursor: 'pointer',
            transition: theme.transitions.create('transform', {
                duration: theme.transitions.duration.shortest,
            }),
        },
        expandOpen: {
            transform: 'rotate(180deg)',
        },
    }),
);

const mapStateToProps = (state: RootState) => ({
    messages: state.messageBundle.messages,
    user: state.user.user,
    selectedFormatDataFeedConfig: state.format.selectedFormatDataFeedConfig,
    vendors: state.vendor.vendors,
    selectedFormatFileRetrievalConfig: state.format.selectedFormatFileRetrievalConfig,
    deleteFileRetrievalConfigInProgress: state.format.deleteFileRetrievalConfigInProgress,
    newClient: state.client.newClient
})
const mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators({
        deleteFRCConfig,
    }, dispatch)
};
type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;
export const CampaignFormatDetails = connect(mapStateToProps, mapDispatchToProps)(UnconnectedCampaignFormatDetails);
