export const actionType = {
  SAMPLE_ACTION_TYPE: "SAMPLE_ACTION_TYPE" as "SAMPLE_ACTION_TYPE",
  SET_MESSAGE_BUNDLE: "SET_MESSAGE_BUNDLE" as "SET_MESSAGE_BUNDLE",
  FETCHING_FORMAT_LIST_IN_PROGRESS: "FETCHING_FORMAT_LIST_IN_PROGRESS" as "FETCHING_FORMAT_LIST_IN_PROGRESS",
  FETCHING_FORMAT_LIST_SUCCESS: "FETCHING_FORMAT_LIST_SUCCESS" as "FETCHING_FORMAT_LIST_SUCCESS",
  FETCHING_FORMAT_LIST_FAILURE: "FETCHING_FORMAT_LIST_FAILURE" as "FETCHING_FORMAT_LIST_FAILURE",
  SETTING_USER: "SETTING_USER" as "SETTING_USER",
  FETCHING_FORMAT_FIELDS_LIST_IN_PROGRESS: "FETCHING_FORMAT_FIELDS_LIST_IN_PROGRESS" as "FETCHING_FORMAT_FIELDS_LIST_IN_PROGRESS",
  FETCHING_FORMAT_FIELDS_LIST_SUCCESS: "FETCHING_FORMAT_FIELDS_LIST_SUCCESS" as "FETCHING_FORMAT_FIELDS_LIST_SUCCESS",
  FETCHING_FORMAT_FIELDS_LIST_FAILURE: "FETCHING_FORMAT_FIELDS_LIST_FAILURE" as "FETCHING_FORMAT_FIELDS_LIST_FAILURE",
  FETCHING_FILE_FORMAT_FIELDS_LIST_SUCCESS: "FETCHING_FILE_FORMAT_FIELDS_LIST_SUCCESS" as "FETCHING_FILE_FORMAT_FIELDS_LIST_SUCCESS",
  FETCHING_DFB_BUSINESS_OBJECT_LIST_IN_PROGRESS: "FETCHING_DFB_BUSINESS_OBJECT_LIST_IN_PROGRESS" as "FETCHING_DFB_BUSINESS_OBJECT_LIST_IN_PROGRESS",
  FETCHING_DFB_BUSINESS_OBJECT_LIST_SUCCESS: "FETCHING_DFB_BUSINESS_OBJECT_LIST_SUCCESS" as "FETCHING_DFB_BUSINESS_OBJECT_LIST_SUCCESS",
  FETCHING_DFB_BUSINESS_OBJECT_LIST_FAILURE: "FETCHING_DFB_BUSINESS_OBJECT_LIST_FAILURE" as "FETCHING_DFB_BUSINESS_OBJECT_LIST_FAILURE",
  FETCHING_DFB_BUSINESS_OBJECT_IN_PROGRESS: "FETCHING_DFB_BUSINESS_OBJECT_IN_PROGRESS" as "FETCHING_DFB_BUSINESS_OBJECT_IN_PROGRESS",
  FETCHING_DFB_BUSINESS_OBJECT_SUCCESS: "FETCHING_DFB_BUSINESS_OBJECT_SUCCESS" as "FETCHING_DFB_BUSINESS_OBJECT_SUCCESS",
  FETCHING_DFB_BUSINESS_OBJECT_FAILURE: "FETCHING_DFB_BUSINESS_OBJECT_FAILURE" as "FETCHING_DFB_BUSINESS_OBJECT_FAILURE",
  SAVE_DFB_BUSINESS_OBJECT_DATA_IN_PROGRESS: "SAVE_DFB_BUSINESS_OBJECT_DATA_IN_PROGRESS" as "SAVE_DFB_BUSINESS_OBJECT_DATA_IN_PROGRESS",
  SAVE_DFB_BUSINESS_OBJECT_DATA_SUCCESS: "SAVE_DFB_BUSINESS_OBJECT_DATA_SUCCESS" as "SAVE_DFB_BUSINESS_OBJECT_DATA_SUCCESS",
  SAVE_DFB_BUSINESS_OBJECT_DATA_FAILURE: "SAVE_DFB_BUSINESS_OBJECT_DATA_FAILURE" as "SAVE_DFB_BUSINESS_OBJECT_DATA_FAILURE",
  DELETE_DFB_BUSINESS_OBJECT_IN_PROGRESS: "DELETE_DFB_BUSINESS_OBJECT_IN_PROGRESS" as "DELETE_DFB_BUSINESS_OBJECT_IN_PROGRESS",
  DELETE_DFB_BUSINESS_OBJECT_SUCCESS: "DELETE_DFB_BUSINESS_OBJECT_SUCCESS" as "DELETE_DFB_BUSINESS_OBJECT_SUCCESS",
  DELETE_DFB_BUSINESS_OBJECT_FAILURE: "DELETE_DFB_BUSINESS_OBJECT_FAILURE" as "DELETE_DFB_BUSINESS_OBJECT_FAILURE",
  SET_FILE_PROCESSED_PERCENTAGE: "SET_FILE_PROCESSED_PERCENTAGE" as "SET_FILE_PROCESSED_PERCENTAGE",
  SET_CAMPAIGN_ID: "SET_CAMPAIGN_ID" as "SET_CAMPAIGN_ID",
  SAVE_DFB_BUSINESS_OBJECT_DATA_INITIAL_STATE: "SAVE_DFB_BUSINESS_OBJECT_DATA_INITIAL_STATE" as "SAVE_DFB_BUSINESS_OBJECT_DATA_INITIAL_STATE",
  FETCHING_CAMPAIGN_FORMAT_IN_PROGRESS: "FETCHING_CAMPAIGN_FORMAT_IN_PROGRESS" as "FETCHING_CAMPAIGN_FORMAT_IN_PROGRESS",
  FETCHING_CAMPAIGN_FORMAT_SUCCESS: "FETCHING_CAMPAIGN_FORMAT_SUCCESS" as "FETCHING_CAMPAIGN_FORMAT_SUCCESS",
  FETCHING_CAMPAIGN_FORMAT_FAILURE: "FETCHING_CAMPAIGN_FORMAT_FAILURE" as "FETCHING_CAMPAIGN_FORMAT_FAILURE",
  FETCHING_ACTIVE_FORMAT_LIST_SUCCESS: "FETCHING_ACTIVE_FORMAT_LIST_SUCCESS" as "FETCHING_ACTIVE_FORMAT_LIST_SUCCESS",
  FETCHING_ACTIVE_CAMPAIGN_FORMAT_LIST_SUCCESS: "FETCHING_ACTIVE_CAMPAIGN_FORMAT_LIST_SUCCESS" as "FETCHING_ACTIVE_CAMPAIGN_FORMAT_LIST_SUCCESS",
  FETCHING_FORMAT_DATA_FEED_CONFIG_IN_PROGRESS: "FETCHING_FORMAT_DATA_FEED_CONFIG_IN_PROGRESS" as "FETCHING_FORMAT_DATA_FEED_CONFIG_IN_PROGRESS",
  FETCHING_FORMAT_DATA_FEED_CONFIG_SUCCESS: "FETCHING_FORMAT_DATA_FEED_CONFIG_SUCCESS" as "FETCHING_FORMAT_DATA_FEED_CONFIG_SUCCESS",
  FETCHING_FORMAT_DATA_FEED_CONFIG_FAILURE: "FETCHING_FORMAT_DATA_FEED_CONFIG_FAILURE" as "FETCHING_FORMAT_DATA_FEED_CONFIG_FAILURE",
  SAVE_FORMAT_DATA_FEED_CONFIG_IN_PROGRESS: "SAVE_FORMAT_DATA_FEED_CONFIG_IN_PROGRESS" as "SAVE_FORMAT_DATA_FEED_CONFIG_IN_PROGRESS",
  SAVE_FORMAT_DATA_FEED_CONFIG_SUCCESS: "SAVE_FORMAT_DATA_FEED_CONFIG_SUCCESS" as "SAVE_FORMAT_DATA_FEED_CONFIG_SUCCESS",
  SAVE_FORMAT_DATA_FEED_CONFIG_FAILURE: "SAVE_FORMAT_DATA_FEED_CONFIG_FAILURE" as "SAVE_FORMAT_DATA_FEED_CONFIG_FAILURE",
  UPDATING_FORMAT_DATA_FEED_CONFIG_IN_PROGRESS: "UPDATING_FORMAT_DATA_FEED_CONFIG_IN_PROGRESS" as "UPDATING_FORMAT_DATA_FEED_CONFIG_IN_PROGRESS",
  UPDATING_FORMAT_DATA_FEED_CONFIG_SUCCESS: "UPDATING_FORMAT_DATA_FEED_CONFIG_SUCCESS" as "UPDATING_FORMAT_DATA_FEED_CONFIG_SUCCESS",
  UPDATING_FORMAT_DATA_FEED_CONFIG_FAILURE: "UPDATING_FORMAT_DATA_FEED_CONFIG_FAILURE" as "UPDATING_FORMAT_DATA_FEED_CONFIG_FAILURE",
  FETCHING_VENDORS_IN_PROGRESS: "FETCHING_VENDORS_IN_PROGRESS" as "FETCHING_VENDORS_IN_PROGRESS",
  FETCHING_VENDORS_SUCCESS: "FETCHING_VENDORS_SUCCESS" as "FETCHING_VENDORS_SUCCESS",
  FETCHING_VENDORS_FAILURE: "FETCHING_VENDORS_FAILURE" as "FETCHING_VENDORS_FAILURE",
  FETCHING_FORMAT_FILE_RETRIEVAL_CONFIG_IN_PROGRESS: "FETCHING_FORMAT_FILE_RETRIEVAL_CONFIG_IN_PROGRESS" as "FETCHING_FORMAT_FILE_RETRIEVAL_CONFIG_IN_PROGRESS",
  FETCHING_FORMAT_FILE_RETRIEVAL_CONFIG_SUCCESS: "FETCHING_FORMAT_FILE_RETRIEVAL_CONFIG_SUCCESS" as "FETCHING_FORMAT_FILE_RETRIEVAL_CONFIG_SUCCESS",
  FETCHING_FORMAT_FILE_RETRIEVAL_CONFIG_FAILURE: "FETCHING_FORMAT_FILE_RETRIEVAL_CONFIG_FAILURE" as "FETCHING_FORMAT_FILE_RETRIEVAL_CONFIG_FAILURE",
  SAVE_FORMAT_FILE_RETRIEVAL_CONFIG_IN_PROGRESS: "SAVE_FORMAT_FILE_RETRIEVAL_CONFIG_IN_PROGRESS" as "SAVE_FORMAT_FILE_RETRIEVAL_CONFIG_IN_PROGRESS",
  SAVE_FORMAT_FILE_RETRIEVAL_CONFIG_SUCCESS: "SAVE_FORMAT_FILE_RETRIEVAL_CONFIG_SUCCESS" as "SAVE_FORMAT_FILE_RETRIEVAL_CONFIG_SUCCESS",
  SAVE_FORMAT_FILE_RETRIEVAL_CONFIG_FAILURE: "SAVE_FORMAT_FILE_RETRIEVAL_CONFIG_FAILURE" as "SAVE_FORMAT_FILE_RETRIEVAL_CONFIG_FAILURE",
  UPDATING_FORMAT_FILE_RETRIEVAL_CONFIG_IN_PROGRESS: "UPDATING_FORMAT_FILE_RETRIEVAL_CONFIG_IN_PROGRESS" as "UPDATING_FORMAT_FILE_RETRIEVAL_CONFIG_IN_PROGRESS",
  UPDATING_FORMAT_FILE_RETRIEVAL_CONFIG_SUCCESS: "UPDATING_FORMAT_FILE_RETRIEVAL_CONFIG_SUCCESS" as "UPDATING_FORMAT_FILE_RETRIEVAL_CONFIG_SUCCESS",
  UPDATING_FORMAT_FILE_RETRIEVAL_CONFIG_FAILURE: "UPDATING_FORMAT_FILE_RETRIEVAL_CONFIG_FAILURE" as "UPDATING_FORMAT_FILE_RETRIEVAL_CONFIG_FAILURE",
  DELETE_FORMAT_FILE_RETRIEVAL_CONFIG_SUCCESS: "DELETE_FORMAT_FILE_RETRIEVAL_CONFIG_SUCCESS" as "DELETE_FORMAT_FILE_RETRIEVAL_CONFIG_SUCCESS",
  DELETE_FORMAT_FILE_RETRIEVAL_CONFIG_IN_PROGRESS: "DELETE_FORMAT_FILE_RETRIEVAL_CONFIG_IN_PROGRESS" as "DELETE_FORMAT_FILE_RETRIEVAL_CONFIG_IN_PROGRESS",
  DELETE_FORMAT_FILE_RETRIEVAL_CONFIG_FAILURE: "DELETE_FORMAT_FILE_RETRIEVAL_CONFIG_FAILURE" as "DELETE_FORMAT_FILE_RETRIEVAL_CONFIG_FAILURE",
  FETCHING_LICENSE_REPORTS_IN_PROGRESS: "FETCHING_LICENSE_REPORTS_IN_PROGRESS" as "FETCHING_LICENSE_REPORTS_IN_PROGRESS",
  FETCHING_LICENSE_REPORTS_SUCCESS: "FETCHING_LICENSE_REPORTS_SUCCESS" as "FETCHING_LICENSE_REPORTS_SUCCESS",
  FETCHING_LICENSE_REPORTS_FAILURE: "FETCHING_LICENSE_REPORTS_FAILURE" as "FETCHING_LICENSE_REPORTS_FAILURE",
  FETCHING_CLIENTS_IN_PROGRESS: "FETCHING_CLIENTS_IN_PROGRESS" as "FETCHING_CLIENTS_IN_PROGRESS",
  FETCHING_CLIENTS_SUCCESS: "FETCHING_CLIENTS_SUCCESS" as "FETCHING_CLIENTS_SUCCESS",
  FETCHING_CLIENTS_FAILURE: "FETCHING_CLIENTS_FAILURE" as "FETCHING_CLIENTS_FAILURE",
  FETCHING_API_VERSION_IN_PROGRESS: "FETCHING_API_VERSION_IN_PROGRESS" as "FETCHING_API_VERSION_IN_PROGRESS",
  FETCHING_API_VERSION_SUCCESS: "FETCHING_API_VERSION_SUCCESS" as "FETCHING_API_VERSION_SUCCESS",
  FETCHING_API_VERSION_FAILURE: "FETCHING_API_VERSION_FAILURE" as "FETCHING_API_VERSION_FAILURE",
  SAVE_CLIENT_OBJECT_DATA_IN_PROGRESS: "SAVE_CLIENT_OBJECT_DATA_IN_PROGRESS" as "SAVE_CLIENT_OBJECT_DATA_IN_PROGRESS",
  SAVE_CLIENT_OBJECT_DATA_SUCCESS: "SAVE_CLIENT_OBJECT_DATA_SUCCESS" as "SAVE_CLIENT_OBJECT_DATA_SUCCESS",
  SAVE_CLIENT_OBJECT_DATA_FAILURE: "SAVE_CLIENT_OBJECT_DATA_FAILURE" as "SAVE_CLIENT_OBJECT_DATA_FAILURE",
  SAVE_CLIENT_OBJECT_DATA_ERROR: "SAVE_CLIENT_OBJECT_DATA_ERROR" as "SAVE_CLIENT_OBJECT_DATA_ERROR",
  RESET_CLIENT_ERROR_OBJECT: "RESET_CLIENT_ERROR_OBJECT" as "RESET_CLIENT_ERROR_OBJECT",
  SAVE_CURRENT_DATAFEED_CONFIGURATION_OBJECT: "SAVE_CURRENT_DATAFEED_CONFIGURATION_OBJECT" as "SAVE_CURRENT_DATAFEED_CONFIGURATION_OBJECT",
  CLEAR_CURRENT_DATAFEED_CONFIGURATION_OBJECT: "CLEAR_CURRENT_DATAFEED_CONFIGURATION_OBJECT" as "CLEAR_CURRENT_DATAFEED_CONFIGURATION_OBJECT",
  CLEAR_NEW_CLIENT: "CLEAR_NEW_CLIENT" as "CLEAR_NEW_CLIENT",
  CLIENT_NAME_CHANGED: "CLIENT_NAME_CHANGED" as "CLIENT_NAME_CHANGED",
  FETCHING_LICENSED_HCP_DETAILS_IN_PROGRESS: "FETCHING_LICENSED_HCP_DETAILS_IN_PROGRESS" as "FETCHING_LICENSED_HCP_DETAILS_IN_PROGRESS",
  FETCHING_LICENSED_HCP_DETAILS_SUCCESS: "FETCHING_LICENSED_HCP_DETAILS_SUCCESS" as "FETCHING_LICENSED_HCP_DETAILS_SUCCESS",
  FETCHING_LICENSED_HCP_DETAILS_FAILURE: "FETCHING_LICENSED_HCP_DETAILS_FAILUREE" as "FETCHING_LICENSED_HCP_DETAILS_FAILURE",
  RESET_LICENSED_HCP_STATE: "RESET_LICENSED_HCP_STATE" as "RESET_LICENSED_HCP_STATE"
};