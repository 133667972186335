import { Grid } from '@material-ui/core'
import React from 'react'
import theme from '../../assets/theme';

interface MasterSlaveProps {
    listComponent: React.ReactNode | React.ComponentType | Element,
    detailsComponent: React.ReactNode | React.ComponentType | Element,
}


const MasterSlaveContainer: React.FC<MasterSlaveProps | any> = ({ listComponent, detailsComponent }) => {
    return (
        <div style={{ height: '100%', width: '100%'}}>
            <Grid container xs={12} direction='row'>
                <Grid item container xs={4} md={4} lg={3} xl={3} style={{ width:408,  padding: theme.spacing(2,0,0) }}>
                    {listComponent}
                </Grid>
                <Grid item container xs={8} md={8} lg={9} xl={9} style={{padding: theme.spacing(2,0,0,4)}}>
                    {detailsComponent}
                </Grid>
            </Grid>
        </div>
    )
}

export default MasterSlaveContainer;