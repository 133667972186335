import { Button, Grid, Typography } from '@material-ui/core';
import React, { useEffect } from 'react';
import theme from '../../../assets/theme';
import ValidationProgressDialogComponent from './ValidationProgressDialogComponent';
import DialogContainer from '../../ui/DialogContainer';

/* Compoonent is used to validate data 
and input file uploaded*/

interface ValidateProps {
    isFileValidated?: boolean;
    messages?: any;
    validationInitiated?: boolean;
    metaDataErrorPresent?: boolean;
    fileValidationInProgress?: boolean;
    saveDFBBusinessObjectDataInProgress?: boolean;
    file?: any;
    fileValidationErrors?: any;
    validationProgressDialogOpen?: any;
    fileProcessedPercentage?: any;
    cancel(): void;
    validate(): void;
    save(): void;
    fieldValidationErrors: Array<any>,
    recordCount: Number | null,
    editMode?: boolean,
    hasInputFile: boolean,
    updateInProgress?: boolean,
    dfbBusinessObjectsData: string
}

const ValidateAndSaveActionButtonsComponent: React.FC<ValidateProps> = ({ isFileValidated,
    messages, validationInitiated,
    metaDataErrorPresent, fileValidationInProgress,
    saveDFBBusinessObjectDataInProgress, file, fileValidationErrors,
    validationProgressDialogOpen, fileProcessedPercentage,
    cancel, validate, save,
    fieldValidationErrors, recordCount, editMode, hasInputFile, updateInProgress, dfbBusinessObjectsData }) => {

    const [disableValidateButton, setDisableValidateButton] = React.useState(false);
    const isMetadataEditDisabled = Boolean(sessionStorage.getItem('isMetadataEditDisabled'));

    useEffect(() => {
        if (editMode && isMetadataEditDisabled) {
            setDisableValidateButton(true);
        } else if ((file != undefined) && (dfbBusinessObjectsData == undefined || dfbBusinessObjectsData === "")) {
            setDisableValidateButton(true);
        } else if (file != undefined && (fileValidationErrors.length > 0 || fieldValidationErrors.length > 0)) {
            setDisableValidateButton(true);
        }
        else {
            setDisableValidateButton(false);
        }
    }, [isMetadataEditDisabled, editMode, file, dfbBusinessObjectsData, fileValidationErrors]);

    const handleSave = () => {
        if (hasInputFile) {
            if (editMode || (recordCount && recordCount > 0)) {
                save();
            }
            else {
                handleWarningDialogOpen();
            }
        } else {
            save();
        }
    }

    const getTitle = (): string => {
        if (!hasInputFile) {
            return messages.dfbBusinessObjectCreationInprogressTitle.replace('{{formatTypeName}}', String(sessionStorage.getItem('formatTypeName')).toLowerCase());
        }
        return messages.fileUploadInprogressTitle;
    }

    const [zeroRecordWarningDialogOpen, setZeroRecordWarningDialogOpen] = React.useState(false);

    const handleWarningDialogOpen = () => {
        setZeroRecordWarningDialogOpen(true);
    }

    const handleWarningDialogConfirmation = () => {
        handleWarningDialogClose();
        save();
    }

    const handleWarningDialogClose = () => {
        setZeroRecordWarningDialogOpen(false);
    }

    const zeroRecordWarningDialogBody = (
        <div style={{ padding: theme.spacing(0, 0, 3, 0) }}>
            <Typography variant={'body1'}>
                {messages.ZeroRecordWarning}
            </Typography>
        </div>
    )

    return (<div style={{ width: '100%' }}>
        {editMode ? <ValidationProgressDialogComponent open={updateInProgress ? updateInProgress : false} fileProcessedPercentage={null} title={messages.savingYourChanges} description={messages.validationInProgressDescription} /> : null}
        <Grid item container direction='row' style={{ padding: theme.spacing(2) }}>
            <DialogContainer
                heading={messages.NoValidRecordsDetected}
                open={zeroRecordWarningDialogOpen}
                body={zeroRecordWarningDialogBody}
                primaryButtonHeading={'Save'}
                primaryButtonLoader={false}
                onPrimaryButtonClick={handleWarningDialogConfirmation}
                handleClose={handleWarningDialogClose}
                secondaryButtonHeading={'Cancel'} />
        </Grid>

        {(fileValidationInProgress || saveDFBBusinessObjectDataInProgress) ?
            <Grid item container justify='flex-end' style={{ padding: theme.spacing(2) }}>
                <Grid item style={{ padding: theme.spacing(0, 4, 0, 0) }}>
                    {fileValidationInProgress ? <ValidationProgressDialogComponent open={validationProgressDialogOpen} fileProcessedPercentage={hasInputFile ? fileProcessedPercentage : null} title={messages.validationInprogressTitle} description={messages.validationInProgressDescription} /> : null}
                    {saveDFBBusinessObjectDataInProgress ? <ValidationProgressDialogComponent open={validationProgressDialogOpen} fileProcessedPercentage={null} title={getTitle()} description={messages.fileUploadInProgressDescription} /> : null}
                </Grid>
            </Grid> : <Grid item container justify='flex-end' style={{ padding: theme.spacing(2) }}>

                <Grid item style={{ padding: theme.spacing(0, 4, 0, 0) }}>
                    <Button data-testid='cancel-button' variant='contained' size='large' disableElevation onClick={() => {
                        cancel()
                    }}>
                        <Typography style={{ color: 'black' }} data-testid='cancel-button-label'>
                            {'Cancel'}
                        </Typography>
                    </Button>
                </Grid>
                {(!isFileValidated || !validationInitiated) || metaDataErrorPresent ? <Grid item >
                    <Button variant='contained' size='large' disabled={disableValidateButton} color='secondary' disableElevation onClick={() => {
                        validate()
                    }} data-testid='validate-button'>
                        <Typography variant='button' data-testid='validate-button-label' style={{ color: '#ffffff' }}>
                            {messages.Validate}
                        </Typography>
                    </Button>
                </Grid> : <Grid item ><Button data-testid='save-button' variant='contained' size='large' color='secondary' disableElevation onClick={() => {
                    handleSave()
                }}>
                    <Typography variant='button' data-testid='save-button-label' style={{ color: '#ffffff' }}>
                        {messages.saveBtn}
                    </Typography>
                </Button>
                </Grid>}
            </Grid>
        }
    </div>)
}

export default ValidateAndSaveActionButtonsComponent;