import { Grid, Tooltip, Typography, withStyles } from '@material-ui/core';
import React from 'react';
import theme, { colorPalette } from '../../assets/theme';

interface ComponentProps {
    badgeTitle?: string,
    badgeSubtitle?: string,
    pageTitle?: string,
    titleStyle?: any,
    additionalTitleInfo?: any,
    formatType?: any,
    formatDescription?: string
}

export default class PageWrapper extends React.Component<ComponentProps | any> {
    render() {
        return (
            <div style={{ height: '100%', width: '100%', background: colorPalette.cardBackground, borderRadius: 14, padding: theme.spacing(0, 0, 2) }}>
                {this.props.pageTitle || this.props.badgeTitle ?
                    <Grid container direction='row' justify='space-between' style={{ width: '100%', minHeight: 48 }}>

                        <Grid item container direction='column' justify='flex-end' xs={9} style={this.props.titleStyle ? this.props.titleStyle : { padding: theme.spacing(0, 3, 0) }}>
                            {this.props.pageTitle ?
                                <Grid container direction='row' style={{ margin: theme.spacing(2, 0, 0, 0) }} data-testid='page-title-grid'>
                                    {this.props.formatType != undefined && <Grid item container xs={1} alignItems='center' justify='flex-start' style={{ margin: theme.spacing(0, 1, 0, 0) }} data-testid='type-icon'>
                                        {getTypeIcon(this.props.formatType, 48)}
                                    </Grid>}
                                    <Grid item style={{ padding: theme.spacing(0, 1, 0, 0) }}>
                                        <Typography align='left' variant='h6' style={{ color: '#000000', opacity: 1 }} data-testid='page-title'>
                                            {this.props.pageTitle}
                                        </Typography>
                                        <Typography variant='body2' align='left' style={{ opacity: 0.6 }} data-testid='format-description'>
                                            {this.props.formatDescription && this.props.formatDescription}
                                        </Typography>
                                    </Grid>
                                    {this.props.additionalTitleInfo ?
                                        <Grid item style={{ padding: theme.spacing(0, 0, 0, 1) }} data-testid='additional-title-info'>
                                            {this.props.additionalTitleInfo}
                                        </Grid> : null}
                                </Grid> : null}
                        </Grid>
                        {this.props.badgeTitle ?
                            <Grid item container xs={3} data-testid='badge-title-grid'>
                                <Grid item container direction='column' alignContent='center' justify='center' style={{ background: colorPalette.primaryShade, width: 190, height: 48, margin: theme.spacing(0, 7, 0, 0), borderRadius: '0px 0px 10px 10px' }} >
                                    <div style={{ width: '100%' }}>
                                        <Typography align='center' variant='subtitle2' style={{ color: colorPalette.dfbWhite, fontWeight: 600 }} data-testid='badge-title'>
                                            {this.props.badgeTitle}
                                        </Typography>
                                    </div>
                                    <div style={{ width: '100%' }}>
                                        <Typography align='center' variant='subtitle2' style={{ color: colorPalette.dfbWhite, fontWeight: 600 }} data-testid='badge-subtitle'>
                                            {this.props.badgeSubtitle}
                                        </Typography>
                                    </div>
                                </Grid>
                            </Grid> : null}
                    </Grid> : null}
                {this.props.children}
            </div>
        )
    }
}

export const getTypeIcon = (formatType: any, svgSize: number) => {
    if (formatType.formatTypeAbbreviation === 'C') {
        return (
            <CustomTooltip title={formatType.formatTypeName.toLowerCase()}>
                <img src={require("../../assets/icons/Campaign_" + svgSize + "px.svg")}></img>
            </CustomTooltip>
        )
    } else if (formatType.formatTypeAbbreviation === 'L') {
        return (
            <CustomTooltip title={formatType.formatTypeName.toLowerCase()}>
                <img src={require("../../assets/icons/License_" + svgSize + "px.svg")}></img>
            </CustomTooltip>
        )
    }
}

export const CustomTooltip = withStyles((theme) => ({
    tooltip: {
        textTransform: 'capitalize',
    }
}))(Tooltip);
