import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, makeStyles, TextField, Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import theme, { colorPalette } from "../../../assets/theme";
import { RootState } from "../../../reducers/rootReducer";
import { clientCodeValidation, clientNameValidation, clientSalesforceIdValidation, clientSalesforceNameValidation } from "../../../utils/client/clientFieldValidations";
import { CLIENT_CODE, CLIENT_CODE_GENERATION_FAILED, CLIENT_NAME, DUPLICATE_CLIENT_CODE, DUPLICATE_CLIENT_NAME, DUPLICATE_CLIENT_NAME_AND_DUPLICATE_CLIENT_CODE, SALESFORCE_ID, SALESFORCE_NAME } from "../../../utils/dfbConstants";
import { clientErrorObjectAttribute, clientJson, clientJsonAttributes, defaultClientErrorObject } from "./ClientCreateJson";
import { saveClient, resetClientErrorData, setClientNameChanged, clearNewClientDataFromState } from '../../../../src/actions/clientActions';
import { toast } from "react-toastify";

interface ClientCreateDialogProps {
    
    clientDialogOpen: boolean,
    handleClientCloseDialog: () => any,
    handleClientSave: () => any
}

const UnconnectedClientCreateDialogContainer: React.FC<Props & ClientCreateDialogProps> = (props) => {

    const classes = useStyles();

    const [actionKeyLoader, setActionKeyLoader] = React.useState(false)
    const [refresh, setRefresh] = React.useState(false);
    const [vendorName, setVendorName] = React.useState('');
    const [vendorId, setVendorId] = React.useState(1);
    const [isErrorPresent , setIsErrorPresent] = React.useState(false);
    const [isSaveSuccess , setIsSaveSuccess] = React.useState(false);
    const [clientDataNeedsToBeSend, setClientDataNeedsToBeSend] = React.useState({...clientJson });
    const [errorObject, setErrorObject] = React.useState({ ...defaultClientErrorObject });

    const validateClientName = clientNameValidation(clientDataNeedsToBeSend, errorObject, props.messages);

    const validateClientCode = clientCodeValidation(clientDataNeedsToBeSend, errorObject, props.messages);

    const validateSalesforceId = clientSalesforceIdValidation(clientDataNeedsToBeSend, errorObject, props.messages);

    const validateSalesforceName = clientSalesforceNameValidation(clientDataNeedsToBeSend, errorObject, props.messages);

    useEffect(() => {
        if(props.clientErrorCode && isErrorPresent){
            if(props.clientErrorCode === DUPLICATE_CLIENT_NAME){
                setErrorObject({...errorObject, clientName: props.messages.clientNameAlreadyExist});
            }
            if(props.clientErrorCode === DUPLICATE_CLIENT_NAME_AND_DUPLICATE_CLIENT_CODE){
                setErrorObject({...errorObject, clientName: props.messages.clientNameAlreadyExist, clientCode: props.messages.clientCodeAlreadyExist});
            }
            if(props.clientErrorCode === DUPLICATE_CLIENT_CODE){
                setErrorObject({...errorObject, clientCode: props.messages.clientCodeAlreadyExist});
            }
            if(props.clientErrorCode === CLIENT_CODE_GENERATION_FAILED){
                setErrorObject({...errorObject, clientCode: props.messages.clientCodeGenerationError});
            }
            setIsErrorPresent(false);
        }
    },[props.clientErrorCode, isErrorPresent]);

    useEffect(() => {
        if(props.clientSaveFailed && isErrorPresent){
            toast.error(<Typography style={{ color: 'black', opacity: 0.7 }}>{props.messages.genericFailureMessage}</Typography>);
            setIsErrorPresent(false);
        }
    },[props.clientSaveFailed, isErrorPresent]);

    useEffect(() => {
        if(props.newClient && props.clientSaveSuccess && isSaveSuccess){
            toast(<Typography style={{ color: 'black', opacity: 0.7 }}>{`${props.messages.clientSaveSuccessfull}`}</Typography>);
            props.setClientNameChanged(true);
            setClientDataNeedsToBeSend(clientJson);
            handleClientCloseDialog();
            setIsSaveSuccess(false);
        }
    },[props.newClient, props.clientSaveSuccess, isSaveSuccess]);
    
    const handleClientCloseDialog = () => {
        try{
            setClientDataNeedsToBeSend(clientJson);
            setErrorObject(defaultClientErrorObject);
            props.resetClientErrorData();
            props.handleClientCloseDialog()
            setIsSaveSuccess(false);
            setIsErrorPresent(false);
            props.clearNewClientDataFromState()
        }catch(error){
            console.log('Error in Client close'+error);
            throw error;
        }
    };

    const validateAllFields = (): boolean => {
        let errorCount: number = 0
        if (!validateClientName()) {
          errorCount++;
        }
        if(!validateClientCode()){
            errorCount++;
        }
        if(!validateSalesforceId()){
            errorCount++;
        }
        if(!validateSalesforceName()){
            errorCount++;
        }
        if (errorCount > 0) {
            return false
        }
        return true;
    };
  
  const getformattedData = (tempClientDataNeedsToBeSend:clientJsonAttributes) => {
    tempClientDataNeedsToBeSend.clientName = tempClientDataNeedsToBeSend.clientName ? tempClientDataNeedsToBeSend.clientName.trim() : "";
    tempClientDataNeedsToBeSend.clientCode = tempClientDataNeedsToBeSend.clientCode ? tempClientDataNeedsToBeSend.clientCode.trim() : "";
    tempClientDataNeedsToBeSend.salesforceId = tempClientDataNeedsToBeSend.salesforceId ? tempClientDataNeedsToBeSend.salesforceId.trim() : "";
    tempClientDataNeedsToBeSend.salesforceName = tempClientDataNeedsToBeSend.salesforceName ? tempClientDataNeedsToBeSend.salesforceName.trim() : "";

    setClientDataNeedsToBeSend(tempClientDataNeedsToBeSend);
  };

    const handleClientSave = async () => {
        try{
            refreshForm();
            if(validateAllFields()){
               getformattedData(clientDataNeedsToBeSend);
                let result: any;
                setActionKeyLoader(true);
                result = await props.saveClient(clientDataNeedsToBeSend);
                if(result){
                  if (result.status) {
                    // this will trigger useEffect for success client save
                    setIsSaveSuccess(true);
                  } else if(!result.status){
                    // this will trigger useEffect for toast messages
                    setIsErrorPresent(true);
                  } 
                }
                props.handleClientSave();
                setActionKeyLoader(false);
            }
        }catch(error){
            console.log('Error in Client Save' + error);
            toast.error(<Typography style={{ color: 'black', opacity: 0.7 }}>{props.messages.genericFailureMessage}</Typography>);
            handleClientCloseDialog();
        }
    };

    const handleVendorNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let tempObj = { ...clientDataNeedsToBeSend };
        tempObj.vendorId = Number(event.target.value);
        setClientDataNeedsToBeSend(tempObj);
        setVendorName(event.target.value);
    };

    const handleChangeOfInput = (key: keyof clientJsonAttributes, value: string) => {
        try{
            
            if(typeof clientDataNeedsToBeSend[key] === 'string' || typeof clientDataNeedsToBeSend[key] === 'number'){
                let tempObj = { ...clientDataNeedsToBeSend, [key]: value };
                setClientDataNeedsToBeSend(tempObj);
                if (key === CLIENT_NAME) {
                    clientNameValidation(tempObj, errorObject, props.messages)();
                }
                if(key === CLIENT_CODE){
                    clientCodeValidation(tempObj, errorObject, props.messages)();
                }
                if (key === SALESFORCE_ID) {
                    //cutsom logic if needed
                }
                if (key === SALESFORCE_NAME) {
                    //cutsom logic if needed
                }
            }
        }catch(error){
            console.log('Error in handleChangeOfInput',error);
            throw error;
        }
        
    }

    const displayErrorMessage = (key: keyof clientErrorObjectAttribute) => {
        return (
          errorObject[key] != null && errorObject[key].length > 1 ?
            <Typography variant='caption' color='error'>
              {errorObject[key]}
            </Typography> : null
        )
      }

    const refreshForm = () => {
        setRefresh(!refresh);
    }

    const getTextField = (padding: Array<number>, label: string, testId: string, isRequired: boolean, value: string | null, clientFieldKey: keyof clientJsonAttributes, handleChange: (Key: keyof clientJsonAttributes, fieldValue: string) => void, handleOnBlur?: () => void) => {
        return (
          <TextField
            style={{ padding: theme.spacing(padding[0], padding[1], padding[2], padding[3]) }}
            label={label}
            placeholder={label === props.messages.clientCode ? props.messages.clientCodePlaceholder : label}
            data-testid={testId}
            required={isRequired}
            size='small'
            fullWidth
            autoComplete={'off'}
            multiline
            value={value != null && value.length > 0 ? value: ''}
            onChange={(event) => {
              handleChange(clientFieldKey, event.target.value);
            }}
            onBlur={() => {
              if (handleOnBlur !== undefined) {
                handleOnBlur();
                refreshForm();
              }
            }}
          />
        )
      }

    return(
        <div>
            <Dialog
            open={props.clientDialogOpen}
            classes={{ paper: classes.dialogContainer }}
            onClose={handleClientCloseDialog}
            disableBackdropClick
            >
         <div className={classes.topStripBlue} />
         <DialogTitle>
          <Typography id="dialog-title" variant='h6' style={{ fontWeight: 'bold' }} data-testid='client-title'>
            {props.messages.client}
          </Typography>
        </DialogTitle>
        <DialogContent>
        <Grid container direction='column' xs={12} >
            <Grid item container direction='row' xs={12} style={{ padding: theme.spacing(0, 2, 2, 0) }}>
                <Grid item direction='column' xs={6} style={{ padding: theme.spacing(0, 1.5, 0, 0) }}>
                    {getTextField([0.5, 0, 0, 0], `${props.messages.frcClientNameLabel}`, "client-name", true, clientDataNeedsToBeSend.clientName ? String(clientDataNeedsToBeSend.clientName) : null, CLIENT_NAME, handleChangeOfInput,validateClientName)}
                    {displayErrorMessage("clientName")}
                </Grid>
                <Grid item direction='column' xs={6} style={{ padding: theme.spacing(0, 1.5, 0, 0) }}>
                    {getTextField([0.5, 0, 0, 0], `${props.messages.clientCode}`, "client-code", false, clientDataNeedsToBeSend.clientCode ? String(clientDataNeedsToBeSend.clientCode) : null, CLIENT_CODE, handleChangeOfInput,validateClientCode)}
                    {displayErrorMessage(CLIENT_CODE)}
                </Grid>
               </Grid>
               <Grid item container direction='row' xs={12} style={{ padding: theme.spacing(0, 2, 2, 0) }}>
                <Grid item direction='column' xs={6} style={{ padding: theme.spacing(0, 1.5, 0, 0) }}>
                    <TextField
                    fullWidth
                    data-testid='vendor-name'
                    select
                    SelectProps={{ native: true }}
                    label={props.messages.vendor}
                    value={clientDataNeedsToBeSend.vendorId}
                    onChange={handleVendorNameChange}
                    >
                    {props.vendors?.map((vendor) => (
                                        <option id={vendor.vendorName} key={vendor.vendorId} value={vendor.vendorId}>{vendor.vendorName}</option>
                                    ))}
                    </TextField>
                </Grid>
            </Grid>
            <Grid item container direction='row' xs={12} style={{ padding: theme.spacing(0, 2, 2, 0) }}>
            <Grid item direction='column' xs={6} style={{ padding: theme.spacing(0, 1.5, 0, 0) }}>
                    {getTextField([0.5, 0, 0, 0], `${props.messages.salesforceId}`, "salesforce-id", false, clientDataNeedsToBeSend.salesforceId ? String(clientDataNeedsToBeSend.salesforceId) : null, SALESFORCE_ID, handleChangeOfInput,validateSalesforceId)}
                    {displayErrorMessage(SALESFORCE_ID)}
                </Grid>
                <Grid item direction='column' xs={6} style={{ padding: theme.spacing(0, 1.5, 0, 0) }}>
                    {getTextField([0.5, 0, 0, 0], `${props.messages.salesforceName}`, "salesforce-name", false, clientDataNeedsToBeSend.salesforceName ? String(clientDataNeedsToBeSend.salesforceName) : null, SALESFORCE_NAME, handleChangeOfInput,validateSalesforceName)}
                    {displayErrorMessage(SALESFORCE_NAME)}
                </Grid>
            </Grid>
        </Grid>

        </DialogContent>
        <DialogActions style={{ margin: theme.spacing(0, 2, 2, 2) }}>
          {actionKeyLoader ? <CircularProgress color='secondary' size={24} /> :
            <React.Fragment>
              <Button data-testid='client-cancel-button' variant='contained' onClick={() => { handleClientCloseDialog() }}>
                {'Cancel'}
              </Button>
              <Button data-testid='client-save-button' variant='contained' color='secondary' onClick={() => { handleClientSave() }}>
                {'Save'}
              </Button>
            </React.Fragment>}
        </DialogActions>
        </Dialog>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    dialogContainer: {
      minWidth: '900px'
    },
    topStripBlue: {
      background: colorPalette.primaryShade,
      height: '10px'
    }
  }));

  const mapStateToProps = (state: RootState) => ({
    user: state.user.user,
    messages: state.messageBundle.messages,
    vendors: state.vendor.vendors,
    newClient: state.client.newClient,
    clientSaveSuccess: state.client.clientSaveSuccess,
    clientSaveInProgress: state.client.clientSaveInProgress,
    clientErrorCode: state.client.errorCode,
    clientSaveFailed: state.client.clientSaveFailure
  });

  const mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators({
        saveClient,
        resetClientErrorData,
        setClientNameChanged,
        clearNewClientDataFromState
    }, dispatch)
  };

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;
export const ClientCreateDialogContainer = connect(mapStateToProps, mapDispatchToProps)(UnconnectedClientCreateDialogContainer);