export const extractFieldInformation = (list: Array<any>, fieldName: string, fieldValue: string): any => {
    try {
        let fieldObject: any = {};
        for (let i = 0; i < list.length; i++) {
            for (const key in list[i]) {
                if (list[i][fieldName] === fieldValue) {
                    fieldObject = list[i];
                    break;
                }
            }
        }
        return fieldObject;
    } catch (error) {
        throw error;
    }
};