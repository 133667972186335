export interface frcJsonAttributes {
    configId: number | null;
    clientName: string;
    groupName: string;
    transferType: string;
    schedule: string;
    authenticationType: string;
    authKeyFileName: string | null;
    authKeyPassPhrase: string | null;
    authUserName: string ;
    authPassword: string | null;
    hostName: string | null;
    port: number | null;
    sourceFolder: string;
    sourceFileNamePrefix: string;
    sourceFileNameDatamask: string | null;
    sourceFileNameSuffix: string | null;
    sourceFileNameExtension: string;
    sourceFilePassword: string | null ;
    archiveStaticSuffix: string | null;
    active: string;
    nextRetrievalDate: Date | null;
    lastRetrievalDate: Date | null;
    retryLimit: number | string;
    retryInterval: number | string;
    updateDate: Date;
    updateBy: string;
}

export const frcJson: frcJsonAttributes = {
    configId:null,
    clientName: "",
    groupName: "",
    transferType: "",
    schedule: "O",
    authenticationType:"",
    authKeyFileName: null,
    authKeyPassPhrase: null,
    authUserName: "",
    authPassword: null,
    hostName: null,
    port: null,
    sourceFolder: "",
    sourceFileNamePrefix: "",
    sourceFileNameDatamask: "",
    sourceFileNameSuffix: "",
    sourceFileNameExtension: "",
    sourceFilePassword: "",
    archiveStaticSuffix: null,
    active: "N",
    nextRetrievalDate: null,
    lastRetrievalDate: null,
    retryLimit: "",
    retryInterval: "",
    updateDate: new Date(),
    updateBy: "",
}
export interface errorObjectAttributes{
    clientName: string,
    groupName: string,
    transferType: string,
    schedule: string,
    authenticationType: string,
    authKeyFileName: string,
    authKeyPassPhrase: string,
    authUserName: string,
    authPassword: string,
    hostName: string,
    port: string,
    sourceFolder: string,
    sourceFileNamePrefix: string,
    sourceFileNameDatamask: string,
    sourceFileNameSuffix: string,
    sourceFileNameExtension: string,
    sourceFilePassword: string,
    archiveStaticSuffix: string,
    active: string,
    nextRetrievalDate: string,
    lastRetrievalDate: string,
    retryLimit: string,
    retryInterval: string,
    updateDate: string,
    updateBy: string,
};
export const defaultErrorObject: errorObjectAttributes = {
    clientName: '',
    groupName: '',
    transferType: '',
    schedule: '',
    authenticationType: '',
    authKeyFileName: '',
    authKeyPassPhrase: '',
    authUserName: '',
    authPassword: '',
    hostName: '',
    port: '',
    sourceFolder: '',
    sourceFileNamePrefix: '',
    sourceFileNameDatamask: '',
    sourceFileNameSuffix: '',
    sourceFileNameExtension: '',
    sourceFilePassword: '',
    archiveStaticSuffix: '',
    active: '',
    nextRetrievalDate: '',
    lastRetrievalDate: '',
    retryLimit: '',
    retryInterval: '',
    updateDate: '',
    updateBy: '',
};