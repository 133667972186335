import { Button, Grid, Typography } from '@material-ui/core';
import React from 'react';
import theme, { colorPalette } from '../../../assets/theme';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { ValidationErrorDialogContainer } from './ValidationErrorDialogContainer';
interface TargetListUploadProps {
    isFileValidated?: boolean;
    messages?: any;
    fileSelected?: boolean;
    validationInitiated?: boolean;
    metaDataErrorPresent?: boolean;
    fileValidationInProgress?: boolean;
    saveDFBBusinessObjectDataInProgress?: boolean;
    file?: any;
    fileValidationErrors?: any;
    totalRecordCount?: any;
    validationProgressDialogOpen?: any;
    fileProcessedPercentage?: any;
    message?: string;
    displayTitle?: boolean;
    handleFileSelected(event: any): void;
    cancel(): void;
    validate(): void;
    save(): void;
    closeAllCalenders(): void;
    fieldValidationErrors: Array<any>,
    recordCount: Number | null,
    editMode?: boolean,
    updateInProgress?: boolean,
    customValidationErrors?: Array<string>
}

const TargetListUploadComponent: React.FC<TargetListUploadProps> = ({ isFileValidated,
    messages, fileSelected, validationInitiated,
    metaDataErrorPresent, fileValidationInProgress,
    saveDFBBusinessObjectDataInProgress, file, fileValidationErrors,
    totalRecordCount, message, displayTitle,
    handleFileSelected, save, closeAllCalenders,
    fieldValidationErrors, editMode, updateInProgress,
    customValidationErrors }) => {

    const [openErrorDisplayDialog, setOpenErrorDisplayDialog] = React.useState(false);

    const handleErrorDialogOpen = () => {
        setOpenErrorDisplayDialog(true);
    }

    const handleErrorDialogClose = () => {
        setOpenErrorDisplayDialog(false);
    }

    return (<div style={{ width: '100%' }}>
        {!editMode && <Grid item container direction='column' >
            {displayTitle ? <Grid item container style={{ padding: theme.spacing(2, 0, 0, 2) }}>
                <Typography variant='body1' style={{ color: colorPalette.primaryShade, fontWeight: 600 }} data-testid='upload-file-text'>
                    FILE UPLOAD
                </Typography>
                {isFileValidated ? <CheckCircleIcon style={{ color: colorPalette.success, width: 20, height: 20, padding: '2px 1px 1px 5px' }} /> : null}
            </Grid> : null}
            <Grid item container direction='row' style={{ padding: theme.spacing(2) }}>
                <Grid item container justify='flex-start' direction='column' xs>
                    {fileSelected ? <Typography variant="body1" align='left' data-testid='upload-new-file'>
                        {messages.UploadNewFile}
                    </Typography> : validationInitiated && !metaDataErrorPresent ? <Typography variant="body1" style={{ color: colorPalette.error }} align='left'>
                        {message}
                    </Typography> : <Typography variant="body1" align='left'>
                        {message}
                    </Typography>}
                </Grid>
            </Grid>
            {fileSelected ?
                <Grid item container direction='row' style={{ padding: theme.spacing(2) }}>
                    <ValidationErrorDialogContainer open={openErrorDisplayDialog} handleClose={handleErrorDialogClose} errors={fieldValidationErrors} />
                    <Grid item container justify='flex-start' direction='column' xs={6}>
                        <Typography align='left' variant='caption' style={{ opacity: 0.6 }} data-testid='file-name-lbl'>
                            File Name
                        </Typography>
                        <Grid item container direction='row' >
                            <Grid item style={{ maxWidth: '90%' }}>
                                <Typography align='left' variant='body2' style={{ wordBreak: 'break-word' }} data-testid='file-name'>
                                    {file !== undefined ? file.name : ''}
                                </Typography>
                            </Grid>
                            <Grid item style={{ padding: theme.spacing(0, 0, 0, 1), width: '10%' }}>
                                {!displayTitle && isFileValidated ? <CheckCircleIcon style={{ color: colorPalette.success, width: 20, height: 20, padding: theme.spacing(0, 0, 0, 0) }} /> : null}
                            </Grid>
                        </Grid>
                        {fileValidationErrors !== undefined && fileValidationErrors.length > 0 ?
                            <Typography align='left' variant='caption' color='error' data-testid='file-validation-error'>
                                {fileValidationErrors !== undefined && fileValidationErrors.length > 0 ? fileValidationErrors[0] + ' ' + messages.reSelectFile : ''}
                            </Typography> : null}
                        {fileValidationErrors.length == 0 && fieldValidationErrors.length > 0 ?
                            <Grid item container direction='row'>
                                <Typography align='left' variant='caption' color='error' data-testid='field-validation-error'>
                                    {fieldValidationErrors && fieldValidationErrors.length > 0 ? fieldValidationErrors.length + ' ' + messages.FileFieldErrorsFound + '. ' : ''}
                                </Typography>
                                <Typography align='left' variant='caption' color='primary' style={{ textDecoration: 'underline', padding: theme.spacing(0, 0, 0, 0.5), cursor: 'pointer' }}
                                    onClick={handleErrorDialogOpen} data-testid='click-to-view-text'>
                                    {messages.ClickToView}
                                </Typography>
                                <Typography align='left' variant='caption' color='error' style={{ marginLeft: '5px' }}
                                    data-testid='reselect-file'>
                                    {messages.reSelectFile}
                                </Typography>
                            </Grid> : null}
                        {customValidationErrors != undefined && customValidationErrors.length > 0 ?
                            <Typography align='left' variant='caption' color='error' data-testid='custom-validation-error'>
                                {customValidationErrors[0]}
                            </Typography> : null}
                    </Grid>
                    {totalRecordCount !== null ?
                        <Grid item container justify='flex-start' direction='column' xs={6} style={{ padding: theme.spacing(0, 0, 0, 4) }}>
                            <Typography align='left' variant='caption' style={{ opacity: 0.6 }} data-testid='total-record-count-label'>
                                Total Records
                            </Typography>
                            <Typography align='left' variant='body2' data-testid='total-record-count'>
                                {totalRecordCount}
                            </Typography>
                        </Grid> : null}
                </Grid> : null}
        </Grid>}
        {(editMode || (fileValidationInProgress || saveDFBBusinessObjectDataInProgress)) ? null : <Grid item container justify='flex-start' direction='column' xs alignContent='flex-start' style={{ padding: theme.spacing(0, 0, 0, 2) }}>
            <label htmlFor="upload-file">
                <input
                    style={{ display: 'none' }}
                    data-testid="upload-file"
                    id="upload-file"
                    name="upload-file"
                    type="file"
                    accept="text/plain,.csv"
                    onChange={(event: any) => handleFileSelected(event)}
                />

                <Button color="secondary" variant="contained" component="span" data-testid='file-upload-btn' onClick={() => { closeAllCalenders() }}>
                    <Typography variant='subtitle2' data-testid="upload-file-button-label">
                        {fileSelected ?
                            "UPLOAD NEW FILE" :
                            "UPLOAD FILE"}
                    </Typography>
                </Button>
            </label>
        </Grid>}
    </div>)
}


export default TargetListUploadComponent;