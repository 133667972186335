import { frcJsonAttributes, errorObjectAttributes } from '../../components/functional/fileTransferConfiguration/fileRetrievalConfigurationJson';

export function nextRetrievalDateValidation(frcDataNeedsToBeSend: frcJsonAttributes, errorObject: errorObjectAttributes, messages: any) {
    return (): boolean => {
        if (frcDataNeedsToBeSend.nextRetrievalDate !== null && typeof frcDataNeedsToBeSend.nextRetrievalDate === 'string') {
            frcDataNeedsToBeSend.nextRetrievalDate = new Date(frcDataNeedsToBeSend.nextRetrievalDate);
        }
        if (!frcDataNeedsToBeSend.nextRetrievalDate) {
            errorObject.nextRetrievalDate = messages.FieldRequired;
            return false;
        } else if (Number.isNaN(frcDataNeedsToBeSend.nextRetrievalDate.getDate())) {
            errorObject.nextRetrievalDate = messages.invalidDate;
            return false;
        } else {
            if (!isFutureDate(frcDataNeedsToBeSend.nextRetrievalDate)) {
                errorObject.nextRetrievalDate = messages.futureDateValidation;
                return false;
            } else {
                errorObject.nextRetrievalDate = '';
                return true;
            }
        }
    }
}

const isFutureDate = (givenDate: Date): any => {
    let todayDate = new Date();
    let currentYear = todayDate.getFullYear();
    let currentMonth = todayDate.getMonth() + 1;
    let currentDay = todayDate.getDate();

    let givenYear = givenDate.getFullYear();
    let givenMonth = givenDate.getMonth() + 1;
    let givenDateDay = givenDate.getDate();

    let todayFormattedDate = new Date(currentYear + '-' + currentMonth + '-' + currentDay).getTime();
    let givenFormattedDate = new Date(String(givenYear + '-' + givenMonth + '-' + givenDateDay)).getTime();

    return (todayFormattedDate - givenFormattedDate) <= 0;
}