import  React, {useEffect} from 'react';
import { connect } from 'react-redux';
import { Route, RouteComponentProps, RouteProps, useRouteMatch } from 'react-router';
import { bindActionCreators, Dispatch } from 'redux';
import { RootState } from '../reducers/rootReducer';
import * as auth from '../auth/AuthHelperMethods'
import { setUser } from '../actions/userActions';
import { configuration } from "../config/Configuration";
import { setMessageBundle } from '../actions/messageBundleActions';
import { UnAuthorizedErrorPage } from '../../src/components/ui/UnAuthorizedErrorPage'
import messageBundle from '../../src/assets/resource_bundle/messages.json';
import { fetchAPIVersion } from '../actions/apiVersionActions';

const config: any = configuration();
const getVersionQueryParam = 'get=version';

const ProtectedRoute: React.FC<RouteProps & Props> =
    ({ component: Component, location, exact, path, ...props }) => {
   
        useEffect(() => {
            props.setMessageBundle();
        }, [props.messages])

        let match = useRouteMatch("/auth/:jwttoken");
        if (match != null && match.path === '/auth/:jwttoken') {
            let params: any = match.params;
            if (params.jwttoken !== undefined) {
                auth.setToken(params.jwttoken)
            }
        }
        if(auth.getEnv() === (process.env.REACT_APP_ENV || 'local')){
            switch (auth.getSsoStatusCode()) {
                case 301:
                    const isAuthenticated = auth.loggedIn();
                    if (!Component) {
                        return null;
                    }
                    if (isAuthenticated) {
                        if (!props.user) {
                        /**
                         * user is fetched from token and then sent to setUser
                         */
                            const user = auth.getUser();
                            props.setUser(user);
                        }
                        return (
                        <Route
                            exact={exact}
                            path={path}
                            render={(props: RouteComponentProps<{}>) => <Component {...props} />}
                        />
                    );
                    }
                    else {
                        return (<Route render={() => (window.location.href = config.ssoURL)} />)
                    }
                break;
                case 415:
                    return (
                    <Route
                        exact={exact}
                        path={path}
                        render={() => <UnAuthorizedErrorPage message={messageBundle.somethingWentWrong}/>}
                    />
                    )
                break;
                case 416:
                    return (
                    <Route
                        exact={exact}
                        path={path}
                        render={() => <UnAuthorizedErrorPage message={messageBundle.unAuthorizedErrorMessage.replace("env",auth.getEnv())}/>}
                    />
                    )
                break
                default:
                    return (<Route render={() => (window.location.href = config.ssoURL)} />)
        }
        }else{
            return (
            <Route render={() => (window.location.href = config.ssoURL)} />
            )
        }
    };

const mapStateToProps = (state: RootState) => ({
    name: state.test.name,
    user: state.user.user,
    messages: state.messageBundle.messages
})

const mapDispatchToProps = (dispatch: Dispatch) => { return bindActionCreators({ setUser, setMessageBundle, fetchAPIVersion }, dispatch) };

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(ProtectedRoute);