import { frcJson, frcJsonAttributes } from '../../components/functional/fileTransferConfiguration/fileRetrievalConfigurationJson';
import { AZURE_BLOB } from '../dfbConstants';
export function subFolderValidation(frcDataNeedsToBeSend: frcJsonAttributes,
    subFolder: string, setSubfolderError: (value: string) => void, messages: any) {
    return (): boolean => {
        if (frcDataNeedsToBeSend.transferType === AZURE_BLOB && subFolder != null && subFolder.trim().length > 1 && subFolder.charAt(0) !== "/") {
            setSubfolderError(messages.subfolderFormatError);
            return false;
        } else {
            setSubfolderError('');
            return true;
        }
    }
}