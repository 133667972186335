import { HTTP } from "../rest"
import { actionType } from "./actionTypes"
import { apiRoutes } from "../rest/APIRoutes"

export interface LicenseFetchReportParams {
    clientId: number,
    licenseId: number
}


export const fetchLicenseReports = (queryStringParams: LicenseFetchReportParams) => {
    return async (dispatch: (action: any) => void) => {
        const queryString = `clientId=${queryStringParams.clientId}&licenseId=${queryStringParams.licenseId}`
        try {
            dispatch(setLicenseReportsToState({ type: actionType.FETCHING_LICENSE_REPORTS_IN_PROGRESS, payload: null }));
            let url = `${apiRoutes.licenseAuditReportURL}?${queryString}`;
            let result = await HTTP.get(url);
            dispatch(setLicenseReportsToState({ type: actionType.FETCHING_LICENSE_REPORTS_SUCCESS, payload: result.data }));
            return result.data.licenseAuditReport;
        } catch (error) {
            dispatch(setLicenseReportsToState({ type: actionType.FETCHING_LICENSE_REPORTS_FAILURE, payload: null }));
            throw error;
        }
    }
}

const setLicenseReportsToState = ({ type, payload }: { type: string, payload: Array<any> | null }) => {
    return { type: type, payload: payload };
}