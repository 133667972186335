import { actionType } from "./actionTypes";
import messageBundle from '../assets/resource_bundle/messages.json'

export const setMessageBundle = () => {
    return (dispatch: (action: any) => void) => {
       dispatch(setMessageBundleToState(messageBundle))
    };
  };

const setMessageBundleToState = (messageBundle:any) => {
    return {type: actionType.SET_MESSAGE_BUNDLE, payload: messageBundle };
};