import { customvalidationFunctionParameter } from "../../../exports/interfaces/customvalidationFunctionParameter";
import { extractFieldInformation } from "../../extractFieldInformationFromList";
import { startAndExpirationDatevalidator } from "./common/startAndExpirationDateValidator";


export const l_valStartDate = (rowData: customvalidationFunctionParameter) => {
    let ExpirationDate = extractFieldInformation(rowData.dfbBusinessObjectMetaDataList, 'f_id', 'Expiration Date').v;
    let startDate = rowData.fieldValue;
    let startDateMasterDate = extractFieldInformation(rowData.formatFields, 'fieldName', 'Start Date');
    let ExpirationDateFormat = rowData.additionalPropertyJson.format;
    let startDateFormat = startDateMasterDate.additionalPropertyJson.format;
    return startAndExpirationDatevalidator({ ExpirationDate, startDate, latestRunlogDate: null, ExpirationDateFormat, startDateFormat, fieldName: 'Start Date'});
}

