import { errorObjectAttributes, frcJsonAttributes } from "../../components/functional/fileTransferConfiguration/fileRetrievalConfigurationJson";

export function clientNameValidation(frcDataNeedsToBeSend: frcJsonAttributes, errorObject: errorObjectAttributes, messages: any, isGeneric: boolean) {
    
    return():boolean =>{
        if (isGeneric && (!frcDataNeedsToBeSend.clientName|| frcDataNeedsToBeSend.clientName.length < 1)) {
            errorObject.clientName = messages.FieldRequired
        }
        else {
            errorObject.clientName = '';
            return true;
        }
        return false;
    
    }
}