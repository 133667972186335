import { frcJsonAttributes, errorObjectAttributes } from '../../components/functional/fileTransferConfiguration/fileRetrievalConfigurationJson';

export function sourceFileSuffixValidation(frcDataNeedsToBeSend: frcJsonAttributes,
    errorObject: errorObjectAttributes, messages: any) {
    return (): boolean => {
        if (frcDataNeedsToBeSend.sourceFileNameSuffix != null && frcDataNeedsToBeSend.sourceFileNameSuffix.trim().length > 1 && frcDataNeedsToBeSend.sourceFileNameSuffix.trim().length < 50) {
            const sourceFileNameSuffixRegExString = '(^[a-zA-Z0-9\-\_\*]+$)';
            const sourceFileNameSuffixRegEx = new RegExp(sourceFileNameSuffixRegExString);
            const matchRegEx = sourceFileNameSuffixRegEx.exec(frcDataNeedsToBeSend.sourceFileNameSuffix.trim());
            if (!matchRegEx) {
                errorObject.sourceFileNameSuffix = messages.invalidSourceFile.concat('suffix');
                return false;;
            } else {
                errorObject.sourceFileNameSuffix = '';
                return true
            }
        }
        else if (frcDataNeedsToBeSend.sourceFileNameSuffix != null && frcDataNeedsToBeSend.sourceFileNameSuffix.trim().length > 50) {
            errorObject.sourceFileNameSuffix = messages.exceededFieldLength.concat('50');
            return false;
        } else {
            errorObject.sourceFileNameSuffix = '';
            return true;
        }
    }
}