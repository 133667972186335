import axios, { AxiosResponse } from 'axios';
export const get = async (url: string, headers: any = {}, params = {}): Promise<AxiosResponse<any>> => {
    try {
        let requestHeaders = setRequestHeaders(headers);
        /**
         * temporary, url will come as a parameter
         */
        // const url = 'http://localhost:8080/api/v1/health'
        console.log("headers ", requestHeaders);
        let response = await axios.get(url, { headers: requestHeaders, params: { key: params } }).then((response: AxiosResponse<any>) => {
            console.log("resp ", response);
            return response
        });
        return response;

    } catch (error) {
        console.log("error  ", error)
        throw error;

    }
};

export const post = async (url: string, data: any, headers: any) => {
    try {
        console.log("This is POST ", url, data);
        let requestHeaders = postRequestHeaders(headers);
        let response = await axios.post(url, data, { headers: requestHeaders }).then((response: AxiosResponse<any>) => {
            return response
        });
        return response;
    } catch (error) {
        throw error;
    }
};

export const put = async (url: string, data: any, headers: any) => {
    try {
        let requestHeaders = postRequestHeaders(headers);
        let response = await axios.put(url, data, { headers: requestHeaders }).then((response: AxiosResponse<any>) => {
            return response;
        });
        return response;
    } catch (error) {
        console.log(error);
        throw error;
    }
};

export const setRequestHeaders = (additionalHeaders: any) => {

    const { contentType = 'application/json' } = additionalHeaders;
    const token = localStorage.getItem('id_token');
    const headers = {
        Authorization: token,
        ...additionalHeaders,
    };
    return headers;
};

export const postRequestHeaders = (additionalHeaders: any) => {
    const { contentType = 'application/json' } = additionalHeaders;
    const token = localStorage.getItem('id_token');
    const headers = {
        'Content-Type': contentType,
        Authorization: token,
        ...additionalHeaders,
    };
    return headers;
};


export const postAll = async (requests: any) => {
    try {
        let response = await axios.all(requests)
        console.log(response)
        return response;
    } catch (error) {
        throw error;
    }
};

export const deleteAPI = async (url: string, headers: any = {}) => {
    try {
        let requestHeaders = setRequestHeaders(headers);
        let response = await axios.delete(url, { headers: requestHeaders }).then((response: AxiosResponse<any>) => {
            return response
        });
        return response;
    } catch (error) {
        throw error;
    }
};

export const getAll = async (requests: any) => {
    try {
        let response = await axios.all(requests)
        return response;
    } catch (error) {
        console.log("Error in getting all the promise: ",error);
        throw error;
    }
};