import { HTTP } from "../rest";
import { actionType } from "./actionTypes"
import { apiRoutes } from "../rest/APIRoutes"


export const fetchAPIVersion = (queryString: string) => {
    return async (dispatch: (action: any) => void) => {
        try {

                dispatch(setAPIVersionToState({ type: actionType.FETCHING_API_VERSION_IN_PROGRESS, payload: null }));

                let url = `${apiRoutes.helthyURL}?${queryString}`;

                let result = await HTTP.get(url);

                dispatch(setAPIVersionToState({ type: actionType.FETCHING_API_VERSION_SUCCESS, payload: result.data }));

                return result.data;

        } catch (error) {

                dispatch(setAPIVersionToState({ type: actionType.FETCHING_API_VERSION_FAILURE, payload: null }));
                throw error;
                
        }
    }
};

const setAPIVersionToState = ({ type, payload }: { type: string, payload: Array<any> | null }) => {
    return { type: type, payload: payload };
}