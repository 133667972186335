import { makeStyles, TextField } from '@material-ui/core';
import React, { useEffect } from 'react';
import { Autocomplete, createFilterOptions } from '@material-ui/lab';
import { ReportsOptionType } from '../functional/license/licenseReports/licenseLineChartHeader';
import { Height } from '@material-ui/icons';

interface AutoCompleteComponentProps {
  options: any,
  label: string,
  testId: string,
  defaultValue: any,
  filterOptions: any,
  optionLabel: string,
  boxWidth: number,
  onChange(value: any): void,
  isDialogOpen?: boolean,
  payload?: any,
  getOptionsMethod(payload: any): any
}


const AutoCompleteComponent: React.FC<AutoCompleteComponentProps> = ({ options, label, testId, defaultValue, boxWidth, onChange, filterOptions, optionLabel, isDialogOpen, payload, getOptionsMethod }) => {

  const [initialValue, setInitialValue] = React.useState(defaultValue);
  const [optionsValue, setOptionsValue] = React.useState(options);

  useEffect(() => {
    if (payload != undefined) {
      setOptionsValue(getOptionsMethod(payload));
    }
  }, [isDialogOpen])
  const handleDropDown = (selectedValue: any) => {
    setInitialValue(selectedValue);
    onChange(selectedValue);
  }
  return (
    <Autocomplete
      disablePortal
      disableClearable={true}
      data-testid={testId}
      value={initialValue}
      onChange={(event, value) => {
        handleDropDown(value)
      }}
      filterOptions={filterOptions}
      options={optionsValue}
      getOptionLabel={(option: any) => option[optionLabel]}
      style={{ width: boxWidth }}
      renderInput={(params) => <TextField   {...params} size='small' label={label} variant='outlined' />}
    />
  )
}

export default AutoCompleteComponent;