import { AnyAction } from "redux";
import { actionType } from "../actions/actionTypes";
type clientReducerState = {
  clientsFetchingInProgress: boolean;
  clientsFetchingSuccess: boolean;
  clientsFetchingFailure: boolean;
  clients: Array<any>;
  clientSaveInProgress: boolean;
  clientSaveSuccess: boolean;
  clientSaveFailure: boolean;
  newClient: Array<any>;
  errorCode: string | null;
  hasClientNameChanged: boolean
};
const initialState: clientReducerState = {
  clientsFetchingInProgress: false,
  clientsFetchingSuccess: false,
  clientsFetchingFailure: false,
  clients: [],
  clientSaveInProgress: false,
  clientSaveSuccess: false,
  clientSaveFailure: false,
  newClient: [],
  errorCode: null,
  hasClientNameChanged: false
  
};
export function clientReducer(state = initialState, action: AnyAction) {
  switch (action.type) {
    case actionType.FETCHING_CLIENTS_IN_PROGRESS:
      return {
        ...state,
        clientsFetchingInProgress: true,
        clientsFetchingSuccess: false,
        clientsFetchingFailure: false,
      };

    case actionType.FETCHING_CLIENTS_SUCCESS:
      return {
        ...state,
        clientsFetchingInProgress: false,
        clientsFetchingSuccess: true,
        clientsFetchingFailure: false,
        clients: action.payload,
      };
    case actionType.FETCHING_CLIENTS_FAILURE:
      return {
        ...state,
        clientsFetchingInProgress: false,
        clientsFetchingSuccess: false,
        clientsFetchingFailure: true,
      };
    case actionType.SAVE_CLIENT_OBJECT_DATA_IN_PROGRESS:
      return {
        ...state,
        clientSaveInProgress: true,
        clientSaveSuccess: false,
        clientSaveFailure: false,
      };
    case actionType.SAVE_CLIENT_OBJECT_DATA_SUCCESS:
      let tempClients: any[] = [...state.clients];
      tempClients.push(action.payload[0]);
      return {
        ...state,
        clientSaveInProgress: false,
        clientSaveSuccess: true,
        clientSaveFailure: false,
        newClient: action.payload,
        clients: tempClients,
      };
    case actionType.SAVE_CLIENT_OBJECT_DATA_FAILURE:
      return {
        ...state,
        clientSaveInProgress: false,
        clientSaveSuccess: false,
        clientSaveFailure: true,
      };
    case actionType.SAVE_CLIENT_OBJECT_DATA_ERROR:
      return {
        ...state,
        clientSaveInProgress: false,
        clientSaveSuccess: false,
        clientSaveFailure: false,
        errorCode: action.payload?.errorCode ? action.payload.errorCode : null,
      };
    case actionType.RESET_CLIENT_ERROR_OBJECT:
      return {
        ...state,
        errorCode: null,
      };
    case actionType.CLEAR_NEW_CLIENT:
      return {
        ...state,
        newClient:[]
      };
    case actionType.CLIENT_NAME_CHANGED:
        return {
            ...state,
            hasClientNameChanged: action.payload[0]
        }
    default:
      return state;
  }
}
