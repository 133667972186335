import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { setMessageBundle } from '../../actions/messageBundleActions';
import { RootState } from '../../reducers/rootReducer';
import { userGroups, formatTypes } from '../../utils/dfbConstants';
import { CampaignContainer } from './campaign/CampaignContainer';
import { CampaignFormatListContainer } from './formats/CampaignFormatListContainer';
import { checkUserGroup } from '../../utils/permissions';

const MainComponent: React.FC<Props> = (props) => {

    /**Sets the PO/Dev decided messages from messages.json. 
     * Function call is subject to change and will occur only once post login */
    sessionStorage.setItem('navIndex', '0');
    sessionStorage.setItem('activeFormatType', formatTypes.CAMPAIGN_TYPE);
    props.setMessageBundle();
    if (checkUserGroup(props.user?.groups, userGroups.DFB_ACCOUNT_MANAGER)) {
        return (
            <CampaignContainer />
        )
    }
    else {
        sessionStorage.setItem('navIndex', '1');
        return (
            <CampaignFormatListContainer />
        )
    }

}
const componentStyle = {
    mainContainer: {
        display: 'flex',
        flex: 1,
        flexGrow: 1,
    }
}

const mapStateToProps = (state: RootState) => ({
    user: state.user.user,
})
const mapDispatchToProps = (dispatch: Dispatch) => { return bindActionCreators({ setMessageBundle }, dispatch) };
type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;
export const Main = connect(mapStateToProps, mapDispatchToProps)(MainComponent);