import jwt from 'jsonwebtoken';

export const loggedIn = () => {
    // Checks if there is a saved token and it's still valid
    const token = getToken(); // Getting token from localstorage
    return !!token && !isTokenExpired(token); // handwaiving here
};

export const isTokenExpired = (token: string) => {
    try {
        const decoded: any = jwt.decode(token);
        if (decoded !== null) {
            if (decoded.exp < Date.now() / 1000) {
                // Checking if token is expired.
                return true;
            } else {
                return false;
            }
        } else {
            return true
        }
    } catch (err) {
        return true;
    }
};

export const setToken = (idToken: string) => {
    // Saves user token to localStorage
    localStorage.setItem("id_token", idToken);
};

export const getToken = (): string => {
    // Retrieves the user token from localStorage
    return localStorage.getItem("id_token") || '';
};

export const logout = () => {
    // Clear user token and profile data from localStorage
    localStorage.removeItem("id_token");
};

export const getUser = () => {
    // Using jwt-decode npm package to decode the token
    let user = jwt.decode(getToken());
    return user;
};

export const getSsoStatusCode = () => {
    const token = getToken();
    if (!isTokenExpired(token)) {
        let user: any = jwt.decode(token);
        return user ? user.statusCode : ''
    } else {
        return ''
    }

}


export const getEnv = () => {
    let user: any = jwt.decode(getToken());
    return user ? user.env : '';
}