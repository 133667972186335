import { Tab, Tabs, Typography } from '@material-ui/core';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Dispatch } from 'redux';
import theme, { colorPalette } from '../../../assets/theme';
import { RootState } from '../../../reducers/rootReducer';
import { formatTypes } from '../../../utils/dfbConstants';
import { resetListViewPagination } from '../../../utils/paginationUtils';

const UnconnectedNavBar: React.FC<Props> = (props) => {

    const [selectedTab, setSelectedTab] = React.useState(sessionStorage.getItem('navIndex') ? Number(sessionStorage.getItem('navIndex')) : 0);
    const history = useHistory();

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        sessionStorage.setItem('navIndex', JSON.stringify(newValue));
        console.log(JSON.stringify(newValue));
        if (JSON.stringify(newValue) === '0') {
            sessionStorage.setItem('activeFormatType', formatTypes.CAMPAIGN_TYPE);
        } else if (JSON.stringify(newValue) === '2') {
            sessionStorage.setItem('activeFormatType', formatTypes.LICENSE_TYPE);
        }
        setSelectedTab(newValue);
        history.push(props.navigationRoutes[newValue].route);
    };
    useEffect(() => {
        //Updating navindex for other scenarios
        resetListViewPagination(history);
        setSelectedTab(Number(sessionStorage.getItem('navIndex')));
    }, []);


    return (
        <React.Fragment>
            <Tabs
                value={selectedTab}
                indicatorColor={'primary'}
                onChange={handleChange}
                style={{ paddingLeft: theme.spacing(3) }}>
                {props.navigationRoutes.map((navRoute, index) => (
                    <Tab data-testid={navRoute.label}
                        label={<Typography
                            variant='body2'
                            style={{ textTransform: 'none', fontWeight: 'bold', color: selectedTab === index ? colorPalette.primary : '#000000' }}>
                            {navRoute.label}
                        </Typography>}
                    />
                ))}
            </Tabs>
        </React.Fragment>
    );
}

const mapStateToProps = (state: RootState) => ({
    navigationRoutes: state.navigation.navigationRoutes,
})
const mapDispatchToProps = (dispatch: Dispatch) => ({});
type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;
export const NavBar = connect(mapStateToProps, mapDispatchToProps)(UnconnectedNavBar);