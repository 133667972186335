import { HTTP } from "../rest";
import { actionType } from "./actionTypes"
import { configuration } from "../config/Configuration";
import { apiRoutes } from "../rest/APIRoutes"
import { frcJsonAttributes } from '../components/functional/fileTransferConfiguration/fileRetrievalConfigurationJson';
import { store } from '../reducers';

const config: any = configuration();
export const fetchFormatList = () => {
    return async (dispatch: (action: any) => void) => {
        dispatch(setFormatListToState({ type: actionType.FETCHING_FORMAT_LIST_IN_PROGRESS, payload: null }));
        let response = await HTTP.get(apiRoutes.campaignFormatsURL);
        dispatch(setFormatListToState({ type: actionType.FETCHING_FORMAT_LIST_SUCCESS, payload: response.data }));
        return response.data;
    };
};

export const fetchFormatFieldsByFormat = (campaignFormatId: number, campaignFormatMajorVersion: number, fieldType: string) => {
    return async (dispatch: (action: any) => void) => {
        dispatch(setFormatFieldsToState({ type: actionType.FETCHING_FORMAT_FIELDS_LIST_IN_PROGRESS, payload: null }));
        let url = `${apiRoutes.formatfieldsURL}?campaignFormatId=${campaignFormatId}&campaignFormatMajorVersion=${campaignFormatMajorVersion}&fieldType=${fieldType}`
        let response = await HTTP.get(url, {}, {});
        if (fieldType === 'metadata') {
            sessionStorage.setItem('formatFields', JSON.stringify(response.data));
            dispatch(setFormatFieldsToState({ type: actionType.FETCHING_FORMAT_FIELDS_LIST_SUCCESS, payload: response.data }));

        } else if (fieldType === 'file') {
            sessionStorage.setItem('fileFormatFields', JSON.stringify(response.data))
            dispatch(setFormatFieldsToState({ type: actionType.FETCHING_FILE_FORMAT_FIELDS_LIST_SUCCESS, payload: response.data }));
        }
        return response.data;
    };
};

const setFormatListToState = ({ type, payload }: { type: string, payload: Array<any> | null }) => {
    return { type: type, payload: payload }
}

const setFormatFieldsToState = ({ type, payload }: { type: string, payload: Array<any> | null }) => {
    return { type: type, payload: payload }
}


export const fetchFormatByIdandMajorVersion = (formatId: number, campaignFormatMajorVersion: number) => {
    return async (dispatch: (action: any) => void) => {
        try {
            let url = `${apiRoutes.campaignFormatsURL}?filterBy=idAndMajorVersion&id=${formatId}&campaignFormatMajorVersion=${campaignFormatMajorVersion}`
            dispatch(setSelectedFormatToState({ type: actionType.FETCHING_CAMPAIGN_FORMAT_IN_PROGRESS, payload: null }));
            let response = await HTTP.get(url);
            const format = { ...response.data.format, metadataFields: response.data.metadataFields, fileFields: response.data.fileFields, fileRetrievalConfigs:response.data.fileRetrievalConfigs ,dataFeedConfigs : response.data.dataFeedConfigs}
            
            dispatch(setSelectedFormatToState({ type: actionType.FETCHING_CAMPAIGN_FORMAT_SUCCESS, payload: format }));
            return response.data;
        } catch (error) {
            dispatch(setSelectedFormatToState({ type: actionType.FETCHING_CAMPAIGN_FORMAT_FAILURE, payload: null }));
            return null;
        }
    };
};


const setSelectedFormatToState = ({ type, payload }: { type: string, payload: any | null }) => {
    return { type: type, payload: payload }
}

export const fetchFormatByStatus = (statusCode: string) => {
    return async (dispatch: (action: any) => void) => {

        let url = `${apiRoutes.campaignFormatsURL}?filterBy=status&status=${statusCode}`
        dispatch(setSelectedFormatToState({ type: actionType.FETCHING_CAMPAIGN_FORMAT_IN_PROGRESS, payload: null }));
        let response = await HTTP.get(url);
        console.log("170", response);
        dispatch(setSelectedFormatToState({ type: actionType.FETCHING_ACTIVE_FORMAT_LIST_SUCCESS, payload: response.data }));
        return response.data;

    };
};

export const fetchFormatByType = (type: string, statusCode: string) => {
    return async (dispatch: (action: any) => void) => {
        try {
            let url = `${apiRoutes.campaignFormatsURL}?filterBy=type&status=${statusCode}&objectType=${type}`
            console.log(url);
            dispatch(setSelectedFormatToState({ type: actionType.FETCHING_CAMPAIGN_FORMAT_IN_PROGRESS, payload: null }));
            let response = await HTTP.get(url);
            console.log("170", response);
            if (response.data.length > 0 && response.data[0].formatType.formatTypeAbbreviation === sessionStorage.getItem('activeFormatType')) {
                dispatch(setSelectedFormatToState({ type: actionType.FETCHING_ACTIVE_CAMPAIGN_FORMAT_LIST_SUCCESS, payload: response.data }));
            }
            return response.data;
        } catch (error) {
            dispatch(setSelectedFormatToState({ type: actionType.FETCHING_ACTIVE_CAMPAIGN_FORMAT_LIST_SUCCESS, payload: null }));
            throw error;
        }

    };
};

export const setCampaignFormatDataFeedConfigs = (datafeedConfigurations: any) => {
    return async (dispatch: (action: any) => void) => {
        try {
            dispatch(setSelectedFormatDataFeedConfigToState({ type: actionType.FETCHING_FORMAT_DATA_FEED_CONFIG_SUCCESS, payload: datafeedConfigurations }))
        } catch (error) {
            dispatch(setSelectedFormatDataFeedConfigToState({ type: actionType.FETCHING_FORMAT_DATA_FEED_CONFIG_FAILURE, payload: null }))
            return null
        }
    }
}

export const updateCampaignFormatDataFeedConfig = (dataFeedConfigObject: any, queryParameter: string) => {
    return async (dispatch: (action: any) => void) => {
        try {
            let url = `${apiRoutes.campaignFormatsURL}?subPath=config`;
            if (queryParameter) {
                url = `${url}&${queryParameter}`
            }
            dispatch(setSelectedFormatDataFeedConfigToState({ type: actionType.UPDATING_FORMAT_DATA_FEED_CONFIG_IN_PROGRESS, payload: null }))
            let response = await HTTP.put(url, dataFeedConfigObject, {});
            dispatch(setSelectedFormatDataFeedConfigToState({ type: actionType.UPDATING_FORMAT_DATA_FEED_CONFIG_SUCCESS, payload: null }))
            if (response.status === 200) {
                return { success: true, data: response.data };
            }
            else {
                return { success: false, data: null };
            }
        } catch (error) {
            dispatch(setSelectedFormatDataFeedConfigToState({ type: actionType.UPDATING_FORMAT_DATA_FEED_CONFIG_FAILURE, payload: null }))
            return { success: false, data: null };
        }
    }
}

export const saveCampaignFormatDataFeedConfig = (dataFeedConfigObject: any, queryParameter: string) => {
    return async (dispatch: (action: any) => void) => {
        try {
            let url = `${apiRoutes.campaignFormatsURL}?subPath=config`;
            if (queryParameter.length > 0) {
                url = `${url}&${queryParameter}`
            }
            dispatch(setSelectedFormatDataFeedConfigToState({ type: actionType.SAVE_FORMAT_DATA_FEED_CONFIG_IN_PROGRESS, payload: null }))
            let response = await HTTP.post(url, dataFeedConfigObject, {});
            console.log('the response is ', response);
            dispatch(setSelectedFormatDataFeedConfigToState({ type: actionType.SAVE_FORMAT_DATA_FEED_CONFIG_SUCCESS, payload: response.data }))
            if (response.status === 200) {
                return { success: true, data: response.data };
            }
            else {
                return { success: false, data: null };
            }
        } catch (error) {
            dispatch(setSelectedFormatDataFeedConfigToState({ type: actionType.SAVE_FORMAT_DATA_FEED_CONFIG_FAILURE, payload: null }))
            return { success: false, data: null };
        }
    }
}

const setSelectedFormatDataFeedConfigToState = ({ type, payload }: { type: string, payload: any | null }) => {
    return { type: type, payload: payload }
}

export const fileStub = {
    name: "Test_file",
};

export const errorStub: any[] = ["Error1"];


export const setFormatFieldsFromStorageToState = () => {
    return async (dispatch: (action: any) => void) => {
        dispatch(setFormatFieldsToState({ type: actionType.FETCHING_FORMAT_FIELDS_LIST_IN_PROGRESS, payload: null }));
        let formatFields = sessionStorage.getItem('formatFields');
        dispatch(setFormatFieldsToState({ type: actionType.FETCHING_FORMAT_FIELDS_LIST_SUCCESS, payload: JSON.parse(formatFields || '{}') }))
        let fileFormatFields = sessionStorage.getItem('fileFormatFields')
        dispatch(setFormatFieldsToState({ type: actionType.FETCHING_FILE_FORMAT_FIELDS_LIST_SUCCESS, payload: JSON.parse(fileFormatFields || '{}') }));
    };
};

const setSelectedFileRetrievalConfigToState = ({ type, payload }: { type: string, payload: any | null }) => {
    return { type: type, payload: payload }
}

export const saveFileRetrievalConfig = (campaignFormatId: number, campaignFormatMajorVersion: number, transferConfigType: string, frcNeedsToBeSend: frcJsonAttributes) => {
    return async (dispatch: (action: any) => void) => {
        try {
            let url = `${apiRoutes.fileRetrievalConfigURL}?formatId=${campaignFormatId}&formatMajorVersion=${campaignFormatMajorVersion}&transferConfigType=${transferConfigType}`;
            dispatch(setSelectedFileRetrievalConfigToState({ type: actionType.SAVE_FORMAT_FILE_RETRIEVAL_CONFIG_IN_PROGRESS, payload: null }))
            let response = await HTTP.post(url, frcNeedsToBeSend, {});
            dispatch(setSelectedFileRetrievalConfigToState({ type: actionType.SAVE_FORMAT_FILE_RETRIEVAL_CONFIG_SUCCESS, payload: response.data }))
            if (response.status === 200) {
                return { success: true, data: response.data };
            } else {
                return { success: false, data: null };
            }
        } catch (error) {
            dispatch(setSelectedFileRetrievalConfigToState({ type: actionType.SAVE_FORMAT_FILE_RETRIEVAL_CONFIG_FAILURE, payload: null }))
            return { success: false, data: null };
        }
    }
}

export const updateFileRetrievalConfig = (configId: number, campaignFormatId: number, campaignFormatMajorVersion: number, frcNeedsToBeSend: frcJsonAttributes) => {
    return async (dispatch: (action: any) => void) => {
        try {
            let url = `${apiRoutes.fileRetrievalConfigURL}?configId=${configId}&formatId=${campaignFormatId}&formatMajorVersion=${campaignFormatMajorVersion}`;
            dispatch(setSelectedFileRetrievalConfigToState({ type: actionType.UPDATING_FORMAT_FILE_RETRIEVAL_CONFIG_IN_PROGRESS, payload: null }))
            let response = await HTTP.put(url, frcNeedsToBeSend, {});
            dispatch(setSelectedFileRetrievalConfigToState({ type: actionType.UPDATING_FORMAT_FILE_RETRIEVAL_CONFIG_SUCCESS, payload: response.data }))
            if (response.status === 200) {
                return { success: true, data: response.data };
            } else {
                return { success: false, data: null };;
            }
        } catch (error) {
            dispatch(setSelectedFileRetrievalConfigToState({ type: actionType.UPDATING_FORMAT_FILE_RETRIEVAL_CONFIG_FAILURE, payload: null }))
            return { success: false, data: null };
        }
    }
}

export const deleteFRCConfig = (configId: number, index: number): any => {
    return async (dispatch: (action: any) => void) => {
        try {
            dispatch(setSelectedFileRetrievalConfigToState({ type: actionType.DELETE_FORMAT_FILE_RETRIEVAL_CONFIG_IN_PROGRESS, payload: null }));
            let url = `${apiRoutes.fileRetrievalConfigURL}?configId=${configId}`;
            const result = await HTTP.deleteAPI(url);
            if (result.status === 200) {
                let configs: any = store.getState().format.selectedFormatFileRetrievalConfig;
                configs?.splice(index, 1);
                dispatch(setSelectedFileRetrievalConfigToState({ type: actionType.DELETE_FORMAT_FILE_RETRIEVAL_CONFIG_SUCCESS, payload: configs }));
                return true;
            } else {
                dispatch(setSelectedFileRetrievalConfigToState({ type: actionType.DELETE_FORMAT_FILE_RETRIEVAL_CONFIG_FAILURE, payload: null }));
                return false;
            }
        } catch (error) {
            console.log("error from config delete : ", error);
            dispatch(setSelectedFileRetrievalConfigToState({ type: actionType.DELETE_FORMAT_FILE_RETRIEVAL_CONFIG_FAILURE, payload: null }));
            return false;
        }
    }
};

export const setFormatMetaData = (metaData: any): any => {
    return async (dispatch: (action: any) => void) => {
        try {
            sessionStorage.setItem('formatFields', JSON.stringify(metaData));
            dispatch(setFormatFieldsToState({ type: actionType.FETCHING_FORMAT_FIELDS_LIST_SUCCESS, payload: metaData }));
        } catch (error) {
            console.log("error from config delete : ", error);
        }
    }
};

