import { AnyAction } from "redux";
import { actionType } from "../actions/actionTypes";

type UserState = {
    user:{
    firstname: string,
    lastname: string,
    email: string,
    username: string,
    groups: Array<string>|string,
    auths: Array<string>,
    iat: Number,
    exp: Number
    }|undefined,
};

const initialState: UserState = {user: undefined};

export function userReducer(state= initialState, action: AnyAction): UserState{
    switch (action.type) {
        case actionType.SETTING_USER:
            return {...state, user:action.payload};
        default:
            return state; 
    }
};

