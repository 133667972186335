import { frcJsonAttributes, errorObjectAttributes } from '../../components/functional/fileTransferConfiguration/fileRetrievalConfigurationJson';
import { validateFolder } from '../dataConfigValidator';
import { AWS_S3, FTP, SFTP } from '../dfbConstants';

export function validateSourceFolder(frcDataNeedsToBeSend: frcJsonAttributes,
  errorObject: errorObjectAttributes, messages: any) {
  return (): boolean => {
    if ((frcDataNeedsToBeSend.transferType !== AWS_S3) && (!frcDataNeedsToBeSend.sourceFolder || frcDataNeedsToBeSend.sourceFolder.trim().length < 1)) {
      errorObject.sourceFolder = messages.FieldRequired;
      return false;
    }
    if (frcDataNeedsToBeSend.transferType === AWS_S3) {
      if (frcDataNeedsToBeSend.sourceFolder === null || frcDataNeedsToBeSend.sourceFolder.trim().length < 1) {
        errorObject.sourceFolder = messages.FieldRequired;
        return false;
      }
      else if (frcDataNeedsToBeSend.sourceFolder != null && frcDataNeedsToBeSend.sourceFolder.trim().length > 1 && frcDataNeedsToBeSend.sourceFolder.charAt(0) !== '/') {
        errorObject.sourceFolder = messages.subfolderFormatError;
        return false;
      }
      else if (frcDataNeedsToBeSend.sourceFolder.trim().length < 3) {
        errorObject.sourceFolder = messages.invalidSubFolder;
        return false;
      }
      else if (frcDataNeedsToBeSend.sourceFolder.trim() !== "" && frcDataNeedsToBeSend.sourceFolder.trim().length > 1) {
        const bucketFolderNameRegExString = '(^[a-zA-Z0-9\-\/\!\_\.\*\'\(\)]{3,63}$)';
        const bucketFolderNameRegEx = new RegExp(bucketFolderNameRegExString);
        const matchRegEx = bucketFolderNameRegEx.exec(frcDataNeedsToBeSend.sourceFolder);
        if (!matchRegEx && frcDataNeedsToBeSend.sourceFolder.trim() !== '/') {
          errorObject.sourceFolder = messages.invalidSubFolder;
          return false;
        }

      } else {
        errorObject.sourceFolder = "";
        return true;
      }
    }
    if (!validateFolder(frcDataNeedsToBeSend.sourceFolder) && (frcDataNeedsToBeSend.transferType === FTP)) {
      errorObject.sourceFolder = messages.TargetFolderError;
      return false;
    } else {
      errorObject.sourceFolder = "";
      return true;
    }

  }
}
