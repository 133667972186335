import { HashRouter, Switch } from 'react-router-dom';
import React from 'react';
import { routes } from './Routes';
import ProtectedRoute from './ProtectedRoute'
import { Main } from '../components/functional';
import { CampaignCreateContainer, LicenseCreateContainer, LicenseEditContainer, CampaignEditContainer } from '../components/functional/dfbBusinessObject/CreateAndEditBusinessObjectComponent';
import { CampaignContainer } from '../components/functional/campaign/CampaignContainer';
import { CampaignFormatListContainer } from '../components/functional/formats/CampaignFormatListContainer';
import { ReportComponent } from '../components/functional/reports/ReportComponent';
import { LicenseContainer } from '../components/functional/license/LicenseContainer';
const AppRouter = () => {
    return (
        <HashRouter>
            <Switch>
                <ProtectedRoute
                    exact={true}
                    path={routes.unProtectedMain}
                    component={Main}
                />
                <ProtectedRoute
                    exact={true}
                    path={routes.main}
                    component={Main}
                />
                <ProtectedRoute
                    exact={true}
                    path={routes.metadata}
                    component={CampaignCreateContainer}
                />
                <ProtectedRoute
                    exact={true}
                    path={routes.campaigns}
                    component={CampaignContainer}
                />
                <ProtectedRoute
                    exact={true}
                    path={routes.licenses}
                    component={LicenseContainer}
                />
                <ProtectedRoute
                    exact={true}
                    path={routes.formats}
                    component={CampaignFormatListContainer}
                />
                <ProtectedRoute
                    exact={true}
                    path={routes.createLicenses}
                    component={LicenseCreateContainer}
                />
                <ProtectedRoute
                    exact={true}
                    path={routes.reports}
                    component={ReportComponent}
                />
                <ProtectedRoute
                    exact={true}
                    path={routes.editLicenses}
                    component={LicenseEditContainer}
                />
                <ProtectedRoute
                    exact={true}
                    path={routes.editCampaigns}
                    component={CampaignEditContainer}
                />
            </Switch>
        </HashRouter>
    );
}

export default AppRouter