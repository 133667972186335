import {combineReducers} from 'redux';
import { dfbBusinessObjectReducer } from './dfbBusinessObjectReducer';
import { formatReducer } from './formatReducer';
import { messageBundlerReducer } from './messageBundleReducer';
import { navigationReducer } from './navigationReducer';
import { testReducer } from './testReducer';
import { userReducer } from './userReducer';
import {vendorReducer} from './vendorReducer';
import { licenseReportReducer } from './licenseAuditReportReducer';
import { clientReducer } from './clientReducer';
import { apiVersionReducer } from './apiVersionReducer';
import { licensedHcpsReducer } from './licensedHcpsReducer';

export const rootReducer= combineReducers({
    test: testReducer,
    messageBundle: messageBundlerReducer,
    format: formatReducer,
    user: userReducer,
    dfbBusinessObject: dfbBusinessObjectReducer,
    navigation: navigationReducer,
    vendor: vendorReducer,
    licenseReport:licenseReportReducer,
    client: clientReducer,
    apiVersion: apiVersionReducer,
    licensedHcps: licensedHcpsReducer

});

export type RootState = ReturnType<typeof rootReducer>;