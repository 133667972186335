import { AppBar, Grid, makeStyles, Toolbar, Typography } from '@material-ui/core'
import React, { useEffect } from 'react'
import theme, { segoeUIFont, colorPalette } from '../../assets/theme';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { RootState } from '../../reducers/rootReducer';
import { AccountCircle } from '@material-ui/icons';
import generatedGitInfoAPP from '../../generatedGitInfoAPP.json';
import { fetchAPIVersion } from '../../actions/apiVersionActions';


const CustomAppBar: React.FC<Props> = (props) => {

  const [apiVersion, setApiVersion] = React.useState('');
  const [dbVersion, setDVersion] = React.useState('');
  const [dbTagVersion, setDBTagVersion] = React.useState('');

  const classes = useStyles();
  const getVersionQueryParam = 'get=version';

  useEffect(() => {
    if (props.apiVersion == null) {
      props.fetchAPIVersion(getVersionQueryParam);
    } else {
      setApiVersion(validateGitTag(props.apiVersion?.apiVersion));
      setDVersion(props.apiVersion?.dbVersion);
      setDBTagVersion(validateGitTag(props.apiVersion?.dbTagVersion));
    }
  }, [props.apiVersion]);

  const validateGitTag = (gitTag: string): string => {
    let splittedText = gitTag.split('-');
    let validatedTag = splittedText.length > 1 ? gitTag.substring(gitTag.indexOf('-') + 1) : splittedText[0];
    return validatedTag;
  }

  return (
    <AppBar position={'static'} color='primary' className={classes.appBar}>
      <Toolbar style={{ height: 80 }}>
        <Grid item container justify={'flex-start'} direction='column' style={{ width: 250 }}>
          <Typography data-testid='appName' variant='h6' className={classes.textStyle}>
            {props.appName}
          </Typography>
          <Typography data-testid='appNameExp' variant='body1' className={classes.textStyle}>
            {props.appNameExp}
          </Typography>
        </Grid>
        <Grid container justify={'flex-start'} direction='column' alignItems="flex-start" style={{ width: 300 }}>
          <Typography data-testid='version-display' variant='body2' className={classes.apiTextStyle}>{`App ver - ${validateGitTag(generatedGitInfoAPP.gitAppTag)}`}</Typography>
          <Typography data-testid='version-display' variant='body2' className={classes.apiTextStyle}>{`Api ver - ${apiVersion}`}</Typography>
          <Typography data-testid='version-display' variant='body2' className={classes.apiTextStyle}>{`DB ver - ${dbVersion}`}</Typography>
          <Typography data-testid='version-display' variant='body2' className={classes.apiTextStyle}>{`DB Tag ver - ${dbTagVersion}`}</Typography>
        </Grid>
        <Grid container direction='row' style={{}}>
          {props.user?.firstname && <Grid item container justify='flex-end' alignContent='center' style={{ padding: theme.spacing(0, 2, 0) }}>
            <AccountCircle />
            <Typography data-testid='userName' variant='subtitle1' style={{ padding: theme.spacing(0, 1, 0) }}>{props.user?.firstname + ' ' + props.user?.lastname}</Typography>
          </Grid>}
        </Grid>
      </Toolbar>
    </AppBar>
  )
}

const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    height: 80,
  },
  textStyle: {
    fontFamily: segoeUIFont
  },
  apiTextStyle: {
    padding: theme.spacing(0, 0, 0, 2),
    fontSize: '10px',
    color: colorPalette.primary,
    fontFamily: segoeUIFont,
  }
}));

const mapStateToProps = (state: RootState) => ({
  messages: state.messageBundle.messages,
  appName: state.messageBundle.appName,
  appNameExp: state.messageBundle.appNameExp,
  user: state.user.user,
  apiVersion: state.apiVersion.apiVersion,
  apiVersionFetchingSuccess: state.apiVersion.apiVersionFetchingSuccess
})

const mapDispatchToProps = (dispatch: Dispatch) => { return bindActionCreators({ fetchAPIVersion }, dispatch) };
type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;
export default connect(mapStateToProps, mapDispatchToProps)(CustomAppBar);