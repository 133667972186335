import { Button, Dialog, DialogActions, DialogContent, DialogTitle, makeStyles, Typography} from '@material-ui/core';
import React from 'react';
import theme, { colorPalette } from '../../../assets/theme';

interface DFBBusinessObjectRunLogDisplayProps{
    heading:string;
    handleClose():void;
    open:boolean;
    body: any;
    primaryButtonStyle?:any;
}

const DFBBusinessObjectRunLogDisplayComponent: React.FC<DFBBusinessObjectRunLogDisplayProps>=({body,open,handleClose, primaryButtonStyle={}})=>{
    const classes = useStyles();
return(
    <div style={{width:500}}>
        <Dialog
        open={open}
        onClose={handleClose}
        classes={{paper:classes.dialogContainer}}
        disableBackdropClick
        >
            <div className={classes.topStripBlue}/>
            <DialogTitle>
                <Typography  id="dialog-title" variant='h6' style={{fontWeight:'bold'}} data-testid='output-files-label'>
                    {"Output Files"}
                </Typography> 
            </DialogTitle>
            <DialogContent data-testid='body'>
                {body}
            </DialogContent>
            <DialogActions style={{padding:theme.spacing(0,2,2,2)}}>
            <React.Fragment>
                <Button variant='contained' data-testid='close-button' color='secondary' style={primaryButtonStyle} onClick={handleClose}>
                    {'Close'}
                </Button>
                </React.Fragment>
            </DialogActions>
        </Dialog>
    </div>
)
}

const useStyles = makeStyles((theme) => ({
    dialogContainer: {
      minWidth:'850px',
    },
    topStripBlue:{
        background: colorPalette.primaryShade,
        height:'10px'
    }
  }));


export default DFBBusinessObjectRunLogDisplayComponent;