import { createMuiTheme } from "@material-ui/core"

export const colorPalette = {
    primary: '#00497B',
    primaryShade: '#0087E0',
    secondary: '#F85E00',
    cardBackground: '#F0F6FA',
    success: '#008000',
    alert: '#FF1D15',
    dfbBlue: '#2699FB',
    dfbWhite: '#FAFAFA',
    error: '#B00020',
    listItemSelectedShade: '#D2E3EF',
    dfbBlack:'#000000'
}

const theme = createMuiTheme({
    palette: {
        primary: {
            main: colorPalette.primary
        },
        secondary: {
            main: colorPalette.secondary,
        }
    },
    typography: {
        h5: {
            fontFamily: [
                '-apple-system',
                'BlinkMacSystemFont',
                '"Segoe UI"',
                'Roboto',
                '"Helvetica Neue"',
                'Arial',
                'sans-serif',
                '"Apple Color Emoji"',
                '"Segoe UI Emoji"',
                '"Segoe UI Symbol"',
            ].join(','),
        },
        subtitle1: {
            fontFamily: [
                '-apple-system',
                'BlinkMacSystemFont',
                '"Segoe UI"',
                'Roboto',
                '"Helvetica Neue"',
                'Arial',
                'sans-serif',
                '"Apple Color Emoji"',
                '"Segoe UI Emoji"',
                '"Segoe UI Symbol"',
            ].join(','),
        },
        button: {
            fontFamily: [
                '-apple-system',
                'BlinkMacSystemFont',
                '"Segoe UI"',
                'Roboto',
                '"Helvetica Neue"',
                'Arial',
                'sans-serif',
                '"Apple Color Emoji"',
                '"Segoe UI Emoji"',
                '"Segoe UI Symbol"',
            ].join(','),
        },
    }
})

export const segoeUIFont = [
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
].join(',')

export const icons = {
    preview: require('./icons/preview-24px.svg')
}
export const getCampaignFormatStatusColorPalette = (status: string): string => {
    if (status.toLowerCase() === 'app') {
        return '#008000'
    } else if (status.toLowerCase() === 'rej') {
        return '#F50800'
    } else if (status.toLowerCase() === 'act') {
        return '#00C853'
    } else if (status.toLowerCase() === 'ina') {
        return '#757575'
    } else if (status.toLowerCase() === 'dra') {
        return '#0087E0'
    } else {
        return ''
    }
}

export const getDFBBusinessObjectStatusColorPalette = (status: string): string => {
    if (status.toLowerCase() === 'c' || status.toLowerCase() === 'cip') {
        return '#008000';
    } else if (status.toLowerCase() === 'uip') {
        return '#FFB700';
    } else if (status.toLowerCase() === 'a') {
        return '#B34404';
    }
    else {
        return '';
    }
}

export default theme;