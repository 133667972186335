import { PATH_TYPE_ABSOLUTE } from "./dfbConstants";

const hostnameregex = new RegExp("^((?!-)[A-Za-z0-9-]" +
    "{1,63}(?<!-)\\.)" +
    "+[A-Za-z]{2,6}");

const pathnameregex = new RegExp("^[\/].*[^\/]$");

const folderregex = new RegExp("^[^\/].*\/$");

const absolutePathRegex = new RegExp("^[\/].*");

const scheduleRegex = new RegExp("(^[dD]$)|(^[wW][1-7]$)|" +
    "(^[mM]([1-9]{1})$)|(^[mM]([1-2][0-9]{0,1})$)" +
    "|(^[mM]([3][0-1]{0,1})$)");

const portRegex = new RegExp("^[0-9]*$");

const azureContainerRegex = new RegExp("^(?!.*--)[a-z0-9-]+(-[a-z0-9-]+)*$");

export const validateHostName = (hostname: string): boolean => {
    return hostnameregex.test(hostname);
}

export const validateFolder = (folder: string, pathType?: string): boolean => {
    if (pathType === PATH_TYPE_ABSOLUTE)
        return absolutePathRegex.test(folder);
    else
        return folderregex.test(folder);

}

export const validateAzureContainer = (container: string): boolean => {
    return azureContainerRegex.test(container);
}

export const validateKeyPath = (path: string): boolean => {

    return pathnameregex.test(path);
}

export const validateSchedule = (sc: string): boolean => {
    return scheduleRegex.test(sc);
}

export const validatePort = (port: string): boolean => {
    return portRegex.test(port);
}

export const validateSchedule1 = (schedule: string): boolean => {
    const denominaton = schedule.substring(0, 1).toLowerCase();
    if (denominaton === 'd') {
        if (schedule.length === 1) {
            return true;
        }
        else {
            return false
        }
    }
    if (denominaton === 'w') {
        const dayNumber = schedule.substring(1, schedule.length);
        let isnum = new RegExp("/^\d+$/").test(dayNumber);
        if (isnum && Number(dayNumber) > 0 && Number(dayNumber) < 8) {
            return true;
        }
        else {
            return false;
        }
    }
    return false;
}