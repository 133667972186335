import { HTTP } from "../rest"
import { actionType } from "./actionTypes"
import { apiRoutes } from "../rest/APIRoutes"
import axios from 'axios';
import { HTTP_RESPONSE_STATUS_CODE } from "../utils/dfbConstants";
const path = require('path');

export interface LicensedHcpsFetchParams {
    clientId: number,
    clientName: string
    dfbBusinessObjectId: number,
    formatId: number,
    objectType: String
}

export const fetchLicensedHcpDetails = (queryStringParams: LicensedHcpsFetchParams) => {
    return async (dispatch: (action: any) => void) => {
        const { clientId, clientName, dfbBusinessObjectId, formatId, objectType } = queryStringParams
        const queryString = `objectType=${objectType}&clientId=${clientId}&clientName=${clientName}&dfbBusinessObjectId=${dfbBusinessObjectId}&formatId=${formatId}`
        try {
            dispatch(setLicensedHcpDetailsToState({ type: actionType.FETCHING_LICENSED_HCP_DETAILS_IN_PROGRESS, payload: null }));
            let url = `${apiRoutes.campaignsURL}?getBy=licensedHcp&${queryString}`;
            let result = await HTTP.get(url);
            let signedUrl = result.data.signedUrl
            const licensedHcpData = await axios.get(signedUrl, {
                responseType: 'blob'
            });
            if (licensedHcpData.status === HTTP_RESPONSE_STATUS_CODE.OK) {
                const fileName = path.basename(signedUrl).split("?")[0];
                const data = [{ licensedHcpData: licensedHcpData.data, fileName: fileName }]
                dispatch(setLicensedHcpDetailsToState({ type: actionType.FETCHING_LICENSED_HCP_DETAILS_SUCCESS, payload: data }));
            } else {
                dispatch(setLicensedHcpDetailsToState({ type: actionType.FETCHING_LICENSED_HCP_DETAILS_FAILURE, payload: null }));
            }
        } catch (error) {
            dispatch(setLicensedHcpDetailsToState({ type: actionType.FETCHING_LICENSED_HCP_DETAILS_FAILURE, payload: null }));
            throw error;
        }
    }
}

export const resetLicensedHcpState = () => {
    return async (dispatch: (action: any) => void) => {
        try {
            dispatch(setLicensedHcpDetailsToState({ type: actionType.RESET_LICENSED_HCP_STATE, payload: null }));
        } catch (error) {
            dispatch(setLicensedHcpDetailsToState({ type: actionType.RESET_LICENSED_HCP_STATE, payload: null }));
            throw error;
        }
    }
}

const setLicensedHcpDetailsToState = ({ type, payload }: { type: string, payload: Array<any> | null }) => {
    return { type: type, payload: payload };
}