import { Collapse, Divider, Grid, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import theme from '../../../assets/theme';
import clsx from 'clsx';
import { RootState } from '../../../reducers/rootReducer';
import { CustomTooltip } from '../../ui/PageWrapper';
import SubdirectoryArrowRightIcon from '@material-ui/icons/SubdirectoryArrowRight';
import { ExpandMore } from '@material-ui/icons';

interface ReportListItemComponentViewProps {
    report: any;
    handleComponent(componentName: string): void;
    accordianIndex: number;
    setAccordianIndex(accordian: number): void;
}

const ReportListItemComponentView: React.FC<Props & ReportListItemComponentViewProps> = (props) => {
    const classes = useStyles();

    const setExpandedValue = (index: number) => {
        props.setAccordianIndex(index)
        if (props.accordianIndex !== index) {
            props.setAccordianIndex(index)
        }
        else {
            props.setAccordianIndex(-1)
        }
    }
    return (
        <React.Fragment>
            <Grid item container style={{ padding: theme.spacing(2, 0, 1, 1) }}>
                <Grid item container direction='column' xs={12} >
                    <Grid item container justify='space-between' alignItems='center' direction='row' style={{ cursor: 'pointer' }} onClick={() => { setExpandedValue(props.report.reportId) }}>
                        <Grid item container xs={8} alignItems='center'>
                            <Grid item style={{ padding: theme.spacing(0, 1, 0, 0) }}>
                                <CustomTooltip title={props.report.name}>
                                    <img height='30px' width='30px' src={require("../../../assets/icons/" + props.report.icon)}></img>
                                </CustomTooltip>
                            </Grid>
                            <Grid item>
                                <Typography data-testid={props.report.name} variant='body1' style={{ fontWeight: 550, fontSize: 16 }}>
                                    {props.report.name}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item style={{ padding: theme.spacing(0) }}>
                            <ExpandMore
                                className={clsx(classes.expand, {
                                    [classes.expandOpen]: props.accordianIndex === props.report.reportId,
                                })}
                                aria-expanded={props.accordianIndex === props.report.reportId}
                                aria-label="show more"
                            />
                        </Grid>
                    </Grid>
                    {props.accordianIndex !== props.report.reportId ? <div style={{ padding: theme.spacing(1.5, 0, 0, 0) }}>
                        <Divider />
                    </div> : null}
                </Grid>
                <Collapse in={(props.accordianIndex !== -1 && props.accordianIndex === props.report.reportId)} style={{ width: '100%' }}>
                    <Grid item container direction='row'>
                        {props.report.reportOptions.map((option: string, index: number) => (<Grid item container style={{ width: '95%', padding: theme.spacing(0.5, 0, 0, 2) }}>
                            <Grid item>
                                <SubdirectoryArrowRightIcon style={{ paddingRight: '2px', opacity: 0.6 }}></SubdirectoryArrowRightIcon>
                            </Grid>
                            <Grid onClick={() => { props.handleComponent(option) }} style={{ cursor: 'pointer' }}>
                                <Typography variant='subtitle1'>{option}</Typography>
                            </Grid>
                        </Grid>))}
                    </Grid>
                    <Grid>
                    </Grid>
                    <div style={{ padding: theme.spacing(1.5, 0, 0, 0) }}>
                        <Divider />
                    </div>
                </Collapse>
            </Grid>
        </React.Fragment>)
}
const useStyles = makeStyles(theme => ({
    root: {
        maxWidth: 345,
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        cursor: 'pointer',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    }
}));
const mapStateToProps = (state: RootState) => ({

})
const mapDispatchToProps = (dispatch: Dispatch) => { return bindActionCreators({}, dispatch) };
type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;
export const ReportListItemComponent = connect(mapStateToProps, mapDispatchToProps)(ReportListItemComponentView)