import { frcJsonAttributes, errorObjectAttributes } from '../../components/functional/fileTransferConfiguration/fileRetrievalConfigurationJson';

export function groupNameValidation(frcDataNeedsToBeSend: frcJsonAttributes, errorObject: errorObjectAttributes, messages: any) {
    return (): boolean => {
        if (!frcDataNeedsToBeSend.groupName || frcDataNeedsToBeSend.groupName.trim().length < 1) {
            errorObject.groupName = messages.FieldRequired;
            return false
        } else if(frcDataNeedsToBeSend.groupName.trim().length > 100){
            errorObject.groupName = messages.exceededFieldLength.concat('100');
            return false;
        }
         else {
            errorObject.groupName = '';
            return true;
        }
    }
}