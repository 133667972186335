import { Button, Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, FormLabel, Grid, IconButton, InputAdornment, makeStyles, TextField, Tooltip, Typography } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import AddBoxIcon from '@material-ui/icons/AddBox';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { saveCampaignFormatDataFeedConfig, setCampaignFormatDataFeedConfigs, updateCampaignFormatDataFeedConfig } from "../../../actions/formatAction";
import theme, { colorPalette } from '../../../assets/theme';
import { RootState } from '../../../reducers/rootReducer';
import { validateAzureContainer, validateFolder, validateHostName, validateKeyPath, validatePort, validateSchedule } from '../../../utils/dataConfigValidator';
import { AWS_S3, AZURE_BLOB, formatTypes, TOAST_TYPE } from '../../../utils/dfbConstants';
import { trimWhiteSpaces } from '../../../utils/fieldValidator';
import DialogContainer from '../../ui/DialogContainer';
import { ClientCreateDialogContainer } from '../client/ClientCreateDialogContainer';
import { clearNewClientDataFromState, setClientNameChanged } from "../../../actions/clientActions";
import { constructSkipValidationObject, convertSkipValidationObject, getValidationsToBeSkipped } from '../../../utils/dataFeedConfig/dataFeedConfigUtils';
import CustomCheckBoxComponent from '../../ui/CustomCheckBox';
import toastMessage from '../../ui/ToastMessage';

interface DataFeedEditDialogProps {
    campaignFormat: any;
    open: boolean;
    isChildConfiguration: boolean;
    dataFeedConfig: any;
    isGeneric: boolean;
    isNewDataFeedConfiguration: boolean;
    setExpanded: any;
    setDefaultConfigObject: (dataFeedConfig: any) => void;
    postSaveCallback: (dataFeedConfigObject: any) => any;
    postChildConfigCallback: (childdataFeedConfigObject: any) => any;
    handleClose: () => any;
    onDataFeedCreateClick?: (isChildConfig: boolean, isNewDataFeedConfig: boolean) => any
}

const UnconnectedDataFeedEditDialogContainer: React.FC<Props & DataFeedEditDialogProps> = (props) => {
    const defaultErrorObject = {
        outputJobName: '',
        client_id: '',
        schedule: '',
        authType: '',
        hostName: '',
        port: '',
        authUserName: '',
        authPasswordOrKey: '',
        targetFolder: '',
        vendorDisplayName: '',
        transferType: '',
        subFolder: ''
    }
    const azureBlobAuthType = 'SAS'
    const rollBasedAuthType = 'R'
    const transferTypeValues = ['', 'FTP', 'SFTP', 'Azure Blob', 'AWS S3']
    const scheduleTypes = ['Daily', 'Weekly', 'Monthly']
    const dataFeedConfigStatuses = ['Active', 'Inactive']
    const pathType = ['Absolute', 'Relative'];
    const defaultOption = '';

    const scheduleDayValues = {
        W: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
        M: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10',
            '11', '12', '13', '14', '15', '16', '17', '18', '19', '20',
            '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31']
    }
    const [dataFeedConfigObject, setDataFeedConfigObject] = React.useState({ ...props.dataFeedConfig });
    const [authPassMask, setAuthPassMask] = React.useState(true);
    const [errorObject, setErrorObject] = React.useState({ ...defaultErrorObject });
    const [refresh, setRefresh] = React.useState(false);
    const [authTypes, setAuthTypes] = React.useState(['']);
    const [actionKeyLoader, setActionKeyLoader] = React.useState(false);
    const [pageLoader, setPageLoader] = React.useState(false);
    const [scheduleType, setScheduleType] = React.useState('Daily');
    const [scheduleDaysArray, setScheduleDaysArray] = React.useState([''])
    const [disableScheduleDaySelect, setDisableScheduleDaySelect] = React.useState(true);
    const [scheduleDay, setScheduleDay] = React.useState('');
    const [scheduleDayNumber, setScheduleDayNumber] = React.useState('');
    const [selectedStatus, setSelectedStatus] = React.useState(dataFeedConfigStatuses[0]);
    const [venorName, setVendorName] = React.useState(0);
    const [vendorDisplayName, setvendorDisplayName] = React.useState('');
    const [openDialog, setOpenDialog] = React.useState(false);
    const [initialVendorName, setInitialVendorName] = React.useState(0);
    const [initialVendorDisplayName, setInitialVendorDisplayName] = React.useState('');
    const [initialSasUrl, setInitialSasUrl] = React.useState('');
    const [isAzureBlobSelected, setIsAzureBlobSelected] = React.useState(false);
    const [azureAuthType, setAzureAuthType] = React.useState(azureBlobAuthType);
    const [subFolder, setSubFolder] = React.useState("");
    const [isAwsSelected, setIsAwsSelected] = React.useState(false);
    const [isRollBasedAuthType, setIsRollBasedAuthType] = React.useState(false);
    const [checked, setChecked] = React.useState(true);
    const [isNewClient, setIsNewClient] = React.useState(false);
    const [openClientDialog, setOpenClientDialog] = React.useState(false);
    const [hasClientChanged, setHasClientNameChanged] = React.useState(false);
    const [validationsNeedsToSkip, setValidationsNeedsToSkip] = React.useState<Array<any> | null>(null);
    const [skippedValidations, setSkippedValidations] = React.useState<Array<any>>([]);

    const generateSubFolder = (dataFeed: any) => {
        let index = dataFeed.targetFolder != null ? dataFeed.targetFolder.indexOf('/') : 0;
        if (dataFeed.transferType === AWS_S3 && dataFeed.targetFolder != null && dataFeed.targetFolder.length > 1) {
            setSubFolder(dataFeed.targetFolder);
        } else {
            if (index > 0) {
                setSubFolder(dataFeed.targetFolder.substring(index, dataFeed.targetFolder.length));
            }
            else {
                setSubFolder('');
            }
        }
    }
    const checkSelect = () => {
        if (props.dataFeedConfig.pathType === pathType[0]) {
            setChecked(false);
        }
        else {
            setChecked(true);
        }
    }
    interface DataFeedConfigObjectToBeSet {
        clientId: any
    }
    useEffect(() => {
        if (props.open && props.isGeneric && props.isNewDataFeedConfiguration) {
            let clientId: Number = props?.newClient?.[0] ? props?.newClient?.[0]?.clientId : dataFeedConfigObject.client_id;
            setDefaultDataFeedConfig({ clientId });
            props.setClientNameChanged(true);
            setHasClientNameChanged(true);
        } else {
            if (props.dataFeedConfig && props.open) {
                setPageLoader(true);
                setDataFeedConfigObject({ ...props.dataFeedConfig });
                setAuthTypes(getAuthTypeValues(props.dataFeedConfig.transferType || ""));
                setErrorObject({ ...defaultErrorObject });
                setAuthPassMask(true);
                setInitialSchedule(props.dataFeedConfig.schedule);
                setVendorName(props.dataFeedConfig.vendorId);
                setInitialVendorName(props.dataFeedConfig.vendorId);
                setvendorDisplayName(props.dataFeedConfig.vendorDisplayName);
                setInitialVendorDisplayName(props.dataFeedConfig.vendorDisplayName);
                setIsAzureBlobSelected(props.dataFeedConfig.transferType === transferTypeValues[3] ? true : false);
                setInitialSasUrl(props.dataFeedConfig.hostName);
                setAzureAuthType(props.dataFeedConfig.transferType === transferTypeValues[3] ? azureBlobAuthType : props.dataFeedConfig.transferType);
                setSelectedStatus(props.dataFeedConfig.isActive ? dataFeedConfigStatuses[0] : dataFeedConfigStatuses[1]);
                setIsAwsSelected(props.dataFeedConfig.transferType === transferTypeValues[4] ? true : false);
                setIsRollBasedAuthType(props.dataFeedConfig.authType === rollBasedAuthType ? true : false);
                setPageLoader(false);
                checkSelect();
                // we need to set the clientnamechanged to false as we are not changing clientname while opening the edit screen for first time
                setHasClientNameChanged(false);
                if (props.dataFeedConfig.transferType === transferTypeValues[3] || props.dataFeedConfig.transferType === transferTypeValues[4]) {
                    generateSubFolder(props.dataFeedConfig);
                }
            }
        }
    }, [props.open, props.newClient])

    useEffect(() => {
        setValidationsNeedsToSkip(getValidationsToBeSkipped(dataFeedConfigObject.transferType, dataFeedConfigObject.authType));
    }, [dataFeedConfigObject.transferType, dataFeedConfigObject.authType])

    useEffect(() => {
        if (props.dataFeedConfig != null && props.dataFeedConfig.validationsToSkip == dataFeedConfigObject.validationsToSkip) {
            setSkippedValidations(convertSkipValidationObject(props.dataFeedConfig));
        }
    }, [props.open, dataFeedConfigObject.authType])


    const setDefaultDataFeedConfig = (newData: DataFeedConfigObjectToBeSet) => {
        let tempObj = { ...dataFeedConfigObject };
        tempObj.outputJobName = '';
        tempObj.schedule = '';
        tempObj.transferType = '';
        tempObj.hostName = '';
        tempObj.port = '';
        tempObj.authType = '';
        tempObj.authUserName = '';
        tempObj.authPasswordOrKey = '';
        tempObj.targetFolder = '';
        tempObj.vendorId = dataFeedConfigObject?.vendorId;
        tempObj.pathType = '';
        tempObj.campaignFormatId = '';
        tempObj.campaignFormatMajorVersion = '';
        tempObj.isActive = ''
        tempObj.vendorDisplayName = getVendorNameById(dataFeedConfigObject?.vendorId, props.vendors ? props.vendors : []);
        tempObj.client_id = newData?.clientId;
        setAuthPassMask(true);
        setAuthTypes(['']);
        setScheduleType('Daily');
        setScheduleDaysArray(['']);
        setDisableScheduleDaySelect(true);
        setScheduleDay('');
        setScheduleDayNumber('');
        setChecked(true);
        setIsAzureBlobSelected(false);
        setIsAwsSelected(false);
        setIsRollBasedAuthType(false);
        setSelectedStatus(dataFeedConfigStatuses[0]);
        setErrorObject({ ...defaultErrorObject });
        props.setDefaultConfigObject(tempObj);
        setDataFeedConfigObject(tempObj);
    }

    const setInitialSchedule = (scheduleString: string) => {
        if (scheduleString) {
            const scheduleDenomination = scheduleString.substring(0, 1).toUpperCase();
            const scheduleDayNumberString = scheduleString.substring(1, scheduleString.length);
            switch (scheduleDenomination) {
                case 'D': setScheduleType('Daily');
                    setDisableScheduleDaySelect(true);
                    setScheduleDayNumber('');
                    break;
                case 'W': setScheduleType('Weekly');
                    setDisableScheduleDaySelect(false);
                    setScheduleDaysArray(scheduleDayValues['W']);
                    setScheduleDay(scheduleDayValues.W[Number(scheduleDayNumberString) - 1]);
                    setScheduleDayNumber(scheduleDayNumberString.length > 0 ? scheduleDayNumberString : '1');
                    break;
                case 'M': setScheduleType('Monthly');
                    setDisableScheduleDaySelect(false);
                    setScheduleDaysArray(scheduleDayValues['M']);
                    setScheduleDay(scheduleDayNumberString);
                    setScheduleDayNumber(scheduleDayNumberString.length > 0 ? scheduleDayNumberString : '1');
                    break;
                default:
                    break;
            }

        }
    }

    const handleIsActiveChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedStatus(event.target.value);
    };

    const handleTransferTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let tempObj = { ...dataFeedConfigObject };
        setSkippedValidations([]);
        if (props.dataFeedConfig.transferType === event.target.value && props.dataFeedConfig.vendorName === tempObj.vendorName) {
            tempObj.authUserName = props.dataFeedConfig.authUserName;
            tempObj.targetFolder = props.dataFeedConfig.targetFolder;
            tempObj.hostName = props.dataFeedConfig.hostName;
            tempObj.authType = props.dataFeedConfig.authType;
            tempObj.port = props.dataFeedConfig.port;
            tempObj.authPasswordOrKey = props.dataFeedConfig.authPasswordOrKey
            tempObj.validationsToSkip = props.dataFeedConfig.validationsToSkip
            generateSubFolder(props.dataFeedConfig);
            setSkippedValidations(convertSkipValidationObject(props.dataFeedConfig));
        }
        else {
            tempObj.authUserName = '';
            tempObj.targetFolder = '';
            tempObj.hostName = '';
            tempObj.authType = null;
            tempObj.port = '';
            tempObj.authPasswordOrKey = '';
            tempObj.validationsToSkip = null;
            setSubFolder("");
            errorObject.subFolder = '';
        }
        if (event.target.value === transferTypeValues[3]) {
            setIsAzureBlobSelected(true);
            setIsAwsSelected(false);
            setAzureAuthType(azureBlobAuthType);
            if ((props.dataFeedConfig && props.dataFeedConfig.transferType === transferTypeValues[3]) && props.dataFeedConfig.targetFolder && props.dataFeedConfig.targetFolder.indexOf("/") > 0 && props.dataFeedConfig.transferType === event.target.value && props.dataFeedConfig.vendorName === tempObj.vendorName) {
                generateSubFolder(props.dataFeedConfig);
            }
        }
        else if (event.target.value === transferTypeValues[4]) {
            if (tempObj.authType !== rollBasedAuthType) {
                setIsRollBasedAuthType(false);
            }
            else {
                setIsRollBasedAuthType(true);
            }
            setIsAwsSelected(true);
            setIsAzureBlobSelected(false);
        }
        else {
            setSubFolder("");
            setIsAzureBlobSelected(false);
            setIsAwsSelected(false)
            setIsRollBasedAuthType(false);
        }
        tempObj.transferType = event.target.value;
        setDefaultErrorObject();
        if (event.target.value === transferTypeValues[3]) {
            tempObj.authType = azureBlobAuthType;
        } else {
            setAuthTypes(getAuthTypeValues(event.target.value));
        }
        setDataFeedConfigObject(tempObj);
    };
    const handleOutputJobName = (event: React.ChangeEvent<HTMLInputElement>) => {
        let tempObj = { ...dataFeedConfigObject }
        tempObj.outputJobName = event.target.value;
        setDataFeedConfigObject(tempObj);
    }
    const handleClientName = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDefaultDataFeedConfig({ clientId: Number(event.target.value) });
        errorObject.client_id = '';
        props.setClientNameChanged(true);
        setHasClientNameChanged(true);
    }
    const handleAuthTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let tempObj = { ...dataFeedConfigObject }
        if (isAwsSelected && event.target.value === rollBasedAuthType) {
            tempObj.authUserName = '';
            tempObj.authPasswordOrKey = '';
            tempObj.validationsToSkip = null;
            errorObject.authUserName = '';
            setSkippedValidations([]);
            setIsRollBasedAuthType(true);
        }
        else if (isAwsSelected && event.target.value !== rollBasedAuthType && props.dataFeedConfig.transferType === tempObj.transferType) {
            setIsRollBasedAuthType(false);
            tempObj.authUserName = props.dataFeedConfig.authUserName;
            tempObj.authPasswordOrKey = props.dataFeedConfig.authPasswordOrKey;
        }
        else if (props.dataFeedConfig.transferType === tempObj.transferType) {
            tempObj.authUserName = props.dataFeedConfig.authUserName;
            tempObj.authPasswordOrKey = props.dataFeedConfig.authPasswordOrKey;
        }
        else {
            tempObj.authUserName = '';
            tempObj.authPasswordOrKey = '';
            setIsRollBasedAuthType(false);
        }
        tempObj.authType = event.target.value;
        errorObject.authPasswordOrKey = '';
        setDataFeedConfigObject(tempObj);
    };
    const handleAuthUserNameChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        let tempObj = { ...dataFeedConfigObject }
        tempObj.authUserName = event.target.value;
        setDataFeedConfigObject(tempObj);
    }
    const getAuthPassOrKeyLabel = (): string => {
        if (dataFeedConfigObject && dataFeedConfigObject.authType) {
            if (dataFeedConfigObject.authType.toLowerCase() == 'k') {
                return props.messages.SSHKey;
            }
            else if (dataFeedConfigObject.authType.toLowerCase() == 'p') {
                return props.messages.Password;
            }
            else {
                return props.messages.AuthPassOrKey;
            }
        }
        else {
            return props.messages.AuthPassOrKey;
        }
    }

    const handleAuthPassOrKeyChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        let tempObj = { ...dataFeedConfigObject }
        tempObj.authPasswordOrKey = event.target.value;
        setDataFeedConfigObject(tempObj);
    }
    const handleTargetFolderChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        let tempObj = { ...dataFeedConfigObject }
        tempObj.targetFolder = event.target.value;
        setDataFeedConfigObject(tempObj);
    }
    const handleHostNameChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        let tempObj = { ...dataFeedConfigObject }
        tempObj.hostName = event.target.value;
        setDataFeedConfigObject(tempObj);
    }
    const handlePortChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        let tempObj = { ...dataFeedConfigObject }
        tempObj.port = event.target.value;
        setDataFeedConfigObject(tempObj);
    }
    const handleClickShowAuthPass = () => {
        setAuthPassMask(!authPassMask);
    };

    const handleScheduleTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setScheduleType(event.target.value);
        if (event.target.value == scheduleTypes[0]) {
            setDisableScheduleDaySelect(true);
            setScheduleDay('');
            setScheduleDayNumber('');
        }
        else if (event.target.value == scheduleTypes[1]) {
            setDisableScheduleDaySelect(false);
            setScheduleDaysArray(scheduleDayValues['W']);
            setScheduleDay(scheduleDayValues.W[0]);
            setScheduleDayNumber('1');
        }
        else if (event.target.value == scheduleTypes[2]) {
            setDisableScheduleDaySelect(false);
            setScheduleDaysArray(scheduleDayValues['M']);
            setScheduleDay(scheduleDayValues.M[0]);
            setScheduleDayNumber(scheduleDayValues.M[0]);
        }
    }

    const handleScheduleDayChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setScheduleDay(event.target.value);
        if (scheduleType == scheduleTypes[1]) {
            setScheduleDayNumber('');
        }
        if (scheduleType == scheduleTypes[1]) {
            setScheduleDayNumber(`${scheduleDayValues.W.indexOf(event.target.value) + 1}`);
        }
        else if (scheduleType == scheduleTypes[2]) {
            setScheduleDayNumber(`${scheduleDayValues.M.indexOf(event.target.value) + 1}`);
        }
    }
    const handleVendorNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let vendorName: string = '';
        let vendorArray: Array<any> = props.vendors ? props.vendors : [];
        let tempObj = { ...dataFeedConfigObject }
        tempObj.vendorId = event.target.value
        tempObj.vendorId = Number(tempObj.vendorId);
        vendorName = getVendorNameById(tempObj.vendorId, vendorArray);
        tempObj.vendorName = vendorName;
        tempObj.vendorDisplayName = tempObj.vendorName;
        setDefaultErrorObject();
        setvendorDisplayName(tempObj.vendorName);
        setVendorName(tempObj.vendorId);
        setDataFeedConfigObject(tempObj);
        setAzureAuthType('')
        setIsAzureBlobSelected(false);
        setOpenDialog(true);
    }

    const handleVendorDisplayNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let tempObj = { ...dataFeedConfigObject }
        tempObj.vendorDisplayName = event.target.value;
        setvendorDisplayName(event.target.value);
        setDataFeedConfigObject(tempObj);
    }
    const handleCloseDialog = () => {
        let tempObj = { ...dataFeedConfigObject }
        if (tempObj.transferType === transferTypeValues[3]) {
            setIsAzureBlobSelected(true);
            setAzureAuthType(azureBlobAuthType);
        }
        tempObj.vendorId = initialVendorName
        setOpenDialog(false);
        setVendorName(initialVendorName);
        setvendorDisplayName(initialVendorDisplayName);
        setDataFeedConfigObject(tempObj);

    }

    const handleSasUrlChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        let tempObj = { ...dataFeedConfigObject }
        tempObj.hostName = event.target.value;
        const accountRegex = new RegExp('//(.*).blob');
        const containerRegex = new RegExp('.net/(.*)\\?');
        let accountName = tempObj.hostName.match(accountRegex);
        let containerName = tempObj.hostName.match(containerRegex);
        if (accountName) {
            tempObj.authUserName = accountName[1];
        } else {
            tempObj.authUserName = "";
        }
        if (containerName) {
            tempObj.targetFolder = containerName[1];
        } else {
            tempObj.targetFolder = "";
        }
        setDataFeedConfigObject(tempObj);
    }

    const handleSubFolderNameChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setSubFolder(event.target.value)
    }

    const handleSkipValidations = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
        let validationsArray: any = skippedValidations.find((skippedObj: any) => skippedObj.validation_code === event.target.value);
        if (validationsArray === undefined) {
            let valObj = skippedValidations;
            valObj.push({ index: index, validation_code: event.target.value, isBoxChecked: event.target.checked });
            setSkippedValidations(valObj);
        } else {
            let valObjIndex = skippedValidations.findIndex((valObj) => valObj.validation_code === event.target.value);
            skippedValidations[valObjIndex].isBoxChecked = event.target.checked;
            setSkippedValidations(skippedValidations);

        }
        setValidationsNeedsToSkip(getValidationsToBeSkipped(dataFeedConfigObject.transferType, dataFeedConfigObject.authType));
    }

    const closeDialogForUpdate = () => {
        setOpenDialog(false)
        dataFeedConfigObject.transferType = transferTypeValues[0];
        dataFeedConfigObject.authType = '';
        dataFeedConfigObject.hostName = '';
        dataFeedConfigObject.port = '';
        dataFeedConfigObject.authUserName = '';
        dataFeedConfigObject.authPasswordOrKey = '';
        dataFeedConfigObject.targetFolder = '';
        setSelectedStatus(dataFeedConfigStatuses[0]);
        setScheduleType(scheduleTypes[0]);
        setDisableScheduleDaySelect(true);
        setAzureAuthType('');

    }
    const getVendorNameById = (vendorId: number, vendorObject: Array<any>): string => {
        var selectedVendorName: string = '';
        for (let vendors = 0; vendors < vendorObject.length; vendors++) {
            if (vendorId === vendorObject[vendors].vendorId) {
                selectedVendorName = vendorObject[vendors].vendorName;
            }
        }
        return selectedVendorName;
    }
    const setDefaultErrorObject = () => {
        errorObject.outputJobName = '';
        errorObject.client_id = '';
        errorObject.schedule = '';
        errorObject.authType = '';
        errorObject.hostName = '';
        errorObject.port = '';
        errorObject.authUserName = '';
        errorObject.targetFolder = '';
        errorObject.vendorDisplayName = '';
        errorObject.transferType = '';
        errorObject.authPasswordOrKey = '';
    }
    const warningDialog = (
        <div style={{ padding: theme.spacing(0, 0, 3, 0), width: '350px', height: '30px' }}>
            <Typography variant={'body1'} >
                {props.messages.dateFeedConfigurationUpdateDialog}
            </Typography>
        </div>
    )
    const getAuthTypeValues = (transferType: string) => {
        switch (transferType.toLowerCase()) {
            case 'sftp': return ['', 'K', 'P'];
            case 'ftp': return ['', 'P'];
            case 'aws s3': return ['', 'K', 'R']
            default: return [''];
        }
    }

    const classes = useStyles();

    const scheduleValidator = () => {
        if (!dataFeedConfigObject.schedule || dataFeedConfigObject.schedule.length < 1) {
            errorObject.schedule = props.messages.FieldRequired
        }
        else if (!validateSchedule(dataFeedConfigObject.schedule)) {
            errorObject.schedule = props.messages.ScheduleError;
        }
        else {
            errorObject.schedule = '';
            return true;
        }
        return false;
    }

    const hostNameValidator = () => {
        if (!dataFeedConfigObject.hostName || dataFeedConfigObject.hostName.length < 1) {
            errorObject.hostName = props.messages.FieldRequired
        }
        else if (dataFeedConfigObject.transferType === AWS_S3) {
            let isValidBucketName: boolean = true;
            const awsBucketName = dataFeedConfigObject.hostName.split("/")[0].trim();

            if (awsBucketName.length < 3 || awsBucketName.length > 63 || !(/^[\a-z\d\.\-]*$/.test(awsBucketName))
                || !(/^[\a-z\d]/.test(awsBucketName)) || !(!/\-$/.test(awsBucketName)) || !(!/\.+\./.test(awsBucketName))
                || !(!/\-+\.$/.test(awsBucketName)) || !(!/^(?:(?:^|\.)(?:2(?:5[0-5]|[0-4]\d)|1?\d?\d)){4}$/.test(awsBucketName))
                || awsBucketName.length >= 4 && awsBucketName.substring(0, 4) === 'xn--' || awsBucketName.charAt(awsBucketName.length - 1) === '.'
                || awsBucketName.length >= 8 && awsBucketName.substring(awsBucketName.length - 8, awsBucketName.length) === '-s3alias') {
                isValidBucketName = false;
            } else {
                errorObject.hostName = '';
                isValidBucketName = true;
            }
            if (isValidBucketName) {
                errorObject.hostName = '';
                return true;
            } else {
                errorObject.hostName = props.messages.invalidS3BucketName;
                return false;
            }
        }
        else if (!validateHostName(dataFeedConfigObject.hostName) && !isAzureBlobSelected) {
            errorObject.hostName = props.messages.HostNameError;
        }
        else {
            errorObject.hostName = '';
            return true;
        }
        return false;
    }

    const portValidator = () => {
        if ((!dataFeedConfigObject.port || dataFeedConfigObject.port.length < 1) && !isAzureBlobSelected) {
            errorObject.port = props.messages.FieldRequired
        }
        else if (!validatePort(dataFeedConfigObject.port)) {
            errorObject.port = props.messages.PortError;
        }
        else {
            errorObject.port = '';
            dataFeedConfigObject.port = Number(dataFeedConfigObject.port);
            return true;
        }
        return false;
    }

    const authTypeValidator = () => {
        if ((!dataFeedConfigObject.authType || dataFeedConfigObject.authType.length < 1) && !isAzureBlobSelected) {
            errorObject.authType = props.messages.FieldRequired
        }
        else {
            errorObject.authType = '';
            return true;
        }
        return false;
    }
    const transferTypeValidator = () => {
        if (!dataFeedConfigObject.transferType || dataFeedConfigObject.transferType.length < 1) {
            errorObject.transferType = props.messages.FieldRequired
        }
        else {
            errorObject.transferType = '';
            return true;
        }
        return false;

    }
    const authPassOrKeyValidator = () => {
        if ((!dataFeedConfigObject.authPasswordOrKey || dataFeedConfigObject.authPasswordOrKey.length < 1) && !isAzureBlobSelected && !isRollBasedAuthType) {
            errorObject.authPasswordOrKey = props.messages.FieldRequired
        } else if (dataFeedConfigObject.transferType === AWS_S3 && dataFeedConfigObject.authType === 'K') {
            if (dataFeedConfigObject.authPasswordOrKey !== undefined) {
                const secretKeyRegExString = '^(?<![A-Za-z0-9/+=])[A-Za-z0-9/+=]{40}(?![A-Za-z0-9/+=]$)';
                const secretKeyRegEx = new RegExp(secretKeyRegExString);
                const match = secretKeyRegEx.exec(dataFeedConfigObject.authPasswordOrKey.trim());
                if (!match || dataFeedConfigObject.authPasswordOrKey.trim().length !== 40) {
                    errorObject.authPasswordOrKey = props.messages.invalidSecretKeyFormat;
                    return false;
                } else {
                    errorObject.authPasswordOrKey = '';
                    return true;
                }
            }
        }
        else {
            if (dataFeedConfigObject.authType && dataFeedConfigObject.authType.length > 0 && dataFeedConfigObject.authType.toLowerCase() == 'k') {
                if (!validateKeyPath(dataFeedConfigObject.authPasswordOrKey)) {
                    errorObject.authPasswordOrKey = props.messages.AuthKeyError;
                }
                else {
                    errorObject.authPasswordOrKey = '';
                    return true;
                }
            }
            else {
                errorObject.authPasswordOrKey = '';
                return true;
            }

        }
        return false;
    }

    const targetFolderValidator = () => {
        if ((!dataFeedConfigObject.targetFolder || dataFeedConfigObject.targetFolder.trim().length < 1) && dataFeedConfigObject.transferType !== AWS_S3) {
            errorObject.targetFolder = props.messages.FieldRequired;
        }
        else if (!validateFolder(dataFeedConfigObject.targetFolder, dataFeedConfigObject.pathType) && !isAzureBlobSelected && dataFeedConfigObject.transferType !== transferTypeValues[4]) {
            errorObject.targetFolder = props.messages.TargetFolderError;
        }
        else if ((dataFeedConfigObject.targetFolder.trim().length < 3 || dataFeedConfigObject.targetFolder.trim().length > 63) && isAzureBlobSelected && dataFeedConfigObject.transferType !== transferTypeValues[4]) {
            errorObject.targetFolder = props.messages.invalidContainerName;
        }
        else if (!validateAzureContainer(dataFeedConfigObject.targetFolder.trim()) && isAzureBlobSelected && dataFeedConfigObject.transferType !== transferTypeValues[4]) {
            errorObject.targetFolder = props.messages.invalidContainerName;
        }
        else if ((dataFeedConfigObject.targetFolder.trim().charAt(0) === '-' && dataFeedConfigObject.transferType !== transferTypeValues[4]) || dataFeedConfigObject.targetFolder.trim().charAt(dataFeedConfigObject.targetFolder.trim().length - 1) === '-' && isAzureBlobSelected) {
            errorObject.targetFolder = props.messages.invalidContainerName;
        }
        else {
            errorObject.targetFolder = '';
            return true;
        }
        return false;

    }
    const authUsernameValidator = () => {
        if ((!dataFeedConfigObject.authUserName || dataFeedConfigObject.authUserName.trim().length < 1) && !isRollBasedAuthType) {
            errorObject.authUserName = props.messages.FieldRequired;
        }
        else if ((dataFeedConfigObject.authUserName.trim().length < 3 || dataFeedConfigObject.authUserName.trim().length > 24) && isAzureBlobSelected) {
            errorObject.authUserName = props.messages.invalidAccountName;
        } else if (dataFeedConfigObject.transferType === AWS_S3 && dataFeedConfigObject.authType === 'K') {
            if (dataFeedConfigObject.authUserName !== undefined) {
                const accessKeyRegExString = '^(?<![A-Z0-9])[A-Z0-9]{20}(?![A-Z0-9])$';
                const accessKeyRegEx = new RegExp(accessKeyRegExString);
                const match = accessKeyRegEx.exec(dataFeedConfigObject.authUserName.trim());
                if (!match) {
                    errorObject.authUserName = props.messages.invalidAccessKeyFormat
                    return false;
                } else {
                    errorObject.authUserName = '';
                    return true;
                }
            }
        }
        else {
            errorObject.authUserName = '';
            return true;
        }
        return false;

    }
    const vendorDisplayNameValidator = () => {
        if ((!dataFeedConfigObject.vendorDisplayName || dataFeedConfigObject.vendorDisplayName.trim().length < 1) && !props.isChildConfiguration) {
            errorObject.vendorDisplayName = props.messages.FieldRequired;
        }
        else {
            errorObject.vendorDisplayName = '';
            return true;
        }
        return false;
    }
    const outputJobNameValidator = () => {
        if (props.isGeneric && (!dataFeedConfigObject.outputJobName || dataFeedConfigObject.outputJobName.trim().length < 1)) {
            errorObject.outputJobName = props.messages.FieldRequired;
        }
        else {
            errorObject.outputJobName = '';
            return true;
        }
        return false;
    }
    const clientNameValidator = () => {
        if (props.isGeneric
            && (dataFeedConfigObject.client_id === undefined || dataFeedConfigObject.client_id === 0)) {
            errorObject.client_id = props.messages.FieldRequired
        }
        else {
            errorObject.client_id = '';
            return true;
        }
        return false;

    }
    const sasUrlValidator = () => {
        let tempObj = { ...dataFeedConfigObject };
        if (tempObj.hostName.trim().length === 0) {
            errorObject.hostName = props.messages.FieldRequired
            return false;
        }
        if (tempObj.hostName.trim().length > 512) {
            errorObject.hostName = props.messages.maximumCharacterExceeded
            return false;
        }
        const blobUriRegex = new RegExp(/^http[s]?:\/\/[a-z0-9]{3,24}\.blob.core.windows.net\/[a-z0-9](?!.*--)[a-z0-9-]{1,61}[a-z0-9]\?.{1,1024}$/gm);
        const match = blobUriRegex.exec(tempObj.hostName);
        if (!match) {
            errorObject.hostName = props.messages.invalidSasUrlFormat
            return false;
        }
        errorObject.hostName = ""
        return true;
    }

    const subFolderValidator = () => {
        const slashRegex = new RegExp(/^\//gm);
        const slashMatch = slashRegex.exec(subFolder);
        const subFolderRegex = new RegExp(/^\/[.(\S*)]{1,255}$/gm);
        const match = subFolderRegex.exec(subFolder);
        if (subFolder.length === 0 || (match && slashMatch && dataFeedConfigObject.transferType !== transferTypeValues[4])) {
            errorObject.subFolder = '';
            return true;
        }
        if (dataFeedConfigObject.transferType === transferTypeValues[4] && slashMatch && subFolder.trim().length !== 0) {
            if (subFolder !== undefined) {
                const bucketFolderNameRegExString = '(^[a-zA-Z0-9\-\/\!\_\.\*\'\(\)]{3,63}$)';
                const bucketFolderNameRegEx = new RegExp(bucketFolderNameRegExString);
                const matchRegEx = bucketFolderNameRegEx.exec(subFolder);
                if (!matchRegEx) {
                    errorObject.subFolder = props.messages.invalidSubFolder;
                    return false;
                }
                else {
                    errorObject.subFolder = '';
                    return true;
                }
            }
        }
        if (!slashMatch) {
            errorObject.subFolder = props.messages.subfolderFormatError;
            return false;
        }
        if (!match) {
            errorObject.subFolder = props.messages.invalidSubFolder;
            return false;
        }
        errorObject.subFolder = '';
    }

    const refreshForm = () => {
        setRefresh(!refresh);
    }

    const validateAllFields = () => {
        let errorCount = 0;
        if (props.isGeneric && !outputJobNameValidator()) {
            errorCount++;
        }
        if (props.isGeneric && !clientNameValidator()) {
            errorCount++;
        }
        if (!hostNameValidator() && isAwsSelected) {
            errorCount++;
        }
        if (!hostNameValidator() && !isAwsSelected) {
            errorCount++;
        }
        if (!portValidator() && !isAwsSelected) {
            errorCount++;
        }
        if (!transferTypeValidator()) {
            errorCount++;
        }
        if (!authTypeValidator()) {
            errorCount++;
        }
        if (!authPassOrKeyValidator() && dataFeedConfigObject.authType !== rollBasedAuthType) {
            errorCount++;
        }
        if ((!isAzureBlobSelected) && dataFeedConfigObject.transferType !== AWS_S3 && !targetFolderValidator()) {
            errorCount++;
        }
        if (!isAzureBlobSelected && dataFeedConfigObject.authType !== rollBasedAuthType && !authUsernameValidator()) {
            errorCount++;
        }
        if (!props.isGeneric && !props.isNewDataFeedConfiguration && !vendorDisplayNameValidator()) {
            errorCount++;
        }
        if (isAzureBlobSelected && !sasUrlValidator()) {
            errorCount++;
        }
        if ((isAzureBlobSelected || dataFeedConfigObject.transferType === AWS_S3) && !subFolderValidator()) {
            errorCount++;
        }
        if (errorCount > 0) {
            return false;
        }
        return true;
    }
    const evaluateSuFolder = (tempDataFeedConfigObject: any) => {
        const data = tempDataFeedConfigObject.targetFolder.split('/');
        if (subFolder.length > 1) {
            tempDataFeedConfigObject.targetFolder = data[0] + subFolder;
        } else if (data[1] !== undefined) {
            tempDataFeedConfigObject.targetFolder = data[0];
        }
    }
    const trimAllWhiteSpaces = (tempDataFeedConfigObject: any) => {
        if (dataFeedConfigObject.transferType === AWS_S3) {
            tempDataFeedConfigObject.targetFolder = subFolder.trim().length > 1 ? subFolder : null;
        }
        if (isAzureBlobSelected) {
            setAzureBlogConfigurations(tempDataFeedConfigObject);
        }
        tempDataFeedConfigObject.isActive = selectedStatus === dataFeedConfigStatuses[0] ? true : false;
        tempDataFeedConfigObject.authUserName = trimWhiteSpaces(tempDataFeedConfigObject.authUserName);
        tempDataFeedConfigObject.authPasswordOrKey = trimWhiteSpaces(tempDataFeedConfigObject.authPasswordOrKey);
        tempDataFeedConfigObject.targetFolder = tempDataFeedConfigObject.targetFolder != null ? trimWhiteSpaces(tempDataFeedConfigObject.targetFolder) : null;
        tempDataFeedConfigObject.hostName = trimWhiteSpaces(tempDataFeedConfigObject.hostName);
        if (props.isGeneric) {
            tempDataFeedConfigObject.outputJobName = trimWhiteSpaces(tempDataFeedConfigObject.outputJobName);
        }
    }
    const setAzureBlogConfigurations = (dataFeedConfiguration: any) => {
        dataFeedConfiguration.authType = azureBlobAuthType;
        dataFeedConfiguration.port = '';
        dataFeedConfiguration.authPasswordOrKey = '';
        evaluateSuFolder(dataFeedConfiguration);
    }
    const pathTypeCheck = () => {
        if (dataFeedConfigObject.transferType === transferTypeValues[2]) {
            if (checked) {
                dataFeedConfigObject.pathType = pathType[1];
            }
            else {
                dataFeedConfigObject.pathType = pathType[0];
            }
        } else {
            dataFeedConfigObject.pathType = null;
        }
    }
    const shrinkTextField = (fieldValue: any): boolean => {
        if (fieldValue != null && fieldValue.length > 0) {
            return true;
        } else {
            return false;
        }
    }

    const isValidationBoxChecked = (validationCode: string, objectIndex: number) => {

        let skippedValidation = skippedValidations.find((valObj) => valObj.validation_code === validationCode && valObj.index === objectIndex);
        if (skippedValidation !== undefined) {
            return skippedValidation.isBoxChecked;
        } else {
            return true;
        }
    }

    const showSkipValidationsNote = () => {
        if (dataFeedConfigObject.transferType === AZURE_BLOB && (constructSkipValidationObject(skippedValidations)?.length == validationsNeedsToSkip?.length))
            return true;
        else
            return false;
    }
    const saveDataFeedConfigurations = async (dataFeedConfiguration: any) => {
        let result = {};
        dataFeedConfiguration.vendorId = props.vendors != null && props.vendors.length > 0 ? props.vendors[0].vendorId : null;
        dataFeedConfiguration.vendorDisplayName = getVendorNameById(dataFeedConfiguration.vendorId, props.vendors ? props.vendors : []);
        result = await props.saveCampaignFormatDataFeedConfig(dataFeedConfiguration, '');
        return result;
    }
    const saveChildDataFeedConfig = async () => {
        try {
            let toastNotificationText;
            setActionKeyLoader(true);
            if (validateAllFields()) {
                pathTypeCheck();
                let tempDataFeedConfigObject = { ...dataFeedConfigObject };
                tempDataFeedConfigObject.validationsToSkip = constructSkipValidationObject(skippedValidations);
                trimAllWhiteSpaces(tempDataFeedConfigObject);
                let result: any = await props.updateCampaignFormatDataFeedConfig(tempDataFeedConfigObject, 'update=additionalDeliveryConfig');
                if (result.success) {
                    setActionKeyLoader(false);
                    setIsAzureBlobSelected(false);
                    props.postChildConfigCallback(tempDataFeedConfigObject);
                    setSubFolder('');
                    setSkippedValidations(convertSkipValidationObject(tempDataFeedConfigObject));
                }
                else {
                    setActionKeyLoader(false);
                    refreshForm();
                    props.handleClose();
                    toastNotificationText = `${props.messages.genericFailureMessage}`
                    toastMessage(TOAST_TYPE.ERROR, toastNotificationText)
                }
            }
            else {
                setActionKeyLoader(false);
                refreshForm();
            }
        } catch (error) {
            let toastNotificationText = `${props.messages.SaveAPIError}`
            setActionKeyLoader(false);
            toastMessage(TOAST_TYPE.ERROR, toastNotificationText);
        }
    }

    const saveClick = async () => {
        try {
            let result: any = '';
            let toastNotificationText;
            setActionKeyLoader(true);
            if (validateAllFields()) {
                pathTypeCheck();
                let tempDataFeedConfigObject = { ...dataFeedConfigObject };
                trimAllWhiteSpaces(tempDataFeedConfigObject);
                tempDataFeedConfigObject.schedule = scheduleType.substring(0, 1).toUpperCase() + scheduleDayNumber;
                tempDataFeedConfigObject.campaignFormatId = props.campaignFormat.campaignFormatId;
                tempDataFeedConfigObject.campaignFormatMajorVersion = props.campaignFormat.campaignFormatMajorVersion;
                tempDataFeedConfigObject.vendorDisplayName = tempDataFeedConfigObject.vendorDisplayName != null ? trimWhiteSpaces(tempDataFeedConfigObject.vendorDisplayName) : '';
                tempDataFeedConfigObject.validationsToSkip = constructSkipValidationObject(skippedValidations);
                if (props.isGeneric && props.isNewDataFeedConfiguration) {
                    tempDataFeedConfigObject.outputJobName = trimWhiteSpaces(tempDataFeedConfigObject.outputJobName);
                    result = await saveDataFeedConfigurations(tempDataFeedConfigObject);
                    props.setExpanded(0);
                } else {
                    result = await props.updateCampaignFormatDataFeedConfig(tempDataFeedConfigObject, '');
                }
                if (result.success && result.data != null) {
                    await props.setCampaignFormatDataFeedConfigs(result.data);
                    setActionKeyLoader(false);
                    setIsAzureBlobSelected(false);
                    props.postSaveCallback(tempDataFeedConfigObject);
                    setSubFolder('');
                    props.clearNewClientDataFromState();
                    setDefaultDataFeedConfig({ clientId: 0 });
                    setSkippedValidations(convertSkipValidationObject(tempDataFeedConfigObject));
                }
                else {
                    setActionKeyLoader(false);
                    refreshForm();
                    props.handleClose();
                    toastNotificationText = `${props.messages.genericFailureMessage}`
                    toastMessage(TOAST_TYPE.ERROR, toastNotificationText)
                }
            }
            else {
                setActionKeyLoader(false);
                refreshForm();
            }
        } catch (error) {
            let toastNotificationText = `${props.messages.SaveAPIError}`
            setActionKeyLoader(false);
            toastMessage(TOAST_TYPE.ERROR, toastNotificationText);
        }
    }

    const handleClose = () => {
        setDefaultErrorObject();
        props.handleClose();
        setSubFolder('');
        props.clearNewClientDataFromState();
        setSkippedValidations([])
        setDefaultDataFeedConfig({ clientId: 0 });
        props.setClientNameChanged(false);
    }

    const handleOnClickOfAddNewClient = async () => {
        setDefaultErrorObject();
        props.handleClose();
        setSubFolder('');
        setIsNewClient(true);
        setOpenClientDialog(true);
    }

    const handleClientCloseDialog = async () => {
        if (props.onDataFeedCreateClick) {
            props.onDataFeedCreateClick(false, props.isNewDataFeedConfiguration);
        }
        setIsNewClient(false);
        setOpenClientDialog(false);
    };

    const handleClientSave = () => {
        // For future implementation
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked(event.target.checked);
        if (checked) {
            dataFeedConfigObject.pathType = pathType[0];
        }
        else {
            dataFeedConfigObject.pathType = pathType[1];
        }
    }

    //Dialog title for Data Feed Configuration
    const getDialogtitle = () => {
        let message = '';
        if (!props.isNewDataFeedConfiguration && !hasClientChanged) {
            if (!props.isChildConfiguration) {
                if (props.dataFeedConfig && props.dataFeedConfig.outputJobName) {
                    message = props.dataFeedConfig.outputJobName;
                }
                if (props.campaignFormat.formatType.formatTypeAbbreviation === formatTypes.CAMPAIGN_TYPE) {
                    if (props.dataFeedConfig && props.dataFeedConfig.vendorDisplayName) {
                        message += ' (' + props.dataFeedConfig.vendorDisplayName + ')';
                    }
                }
                if (props.isGeneric && props.dataFeedConfig) {
                    if (props.dataFeedConfig.outputJobName) {
                        message += ' (' + props.dataFeedConfig.client.clientName + ')';
                    }
                    else {
                        message = props.messages.DataFeedConfigurations;
                    }
                }
            } else {
                message = props.messages.additionalDeliveryConfiguration.toUpperCase();
            }
        } else {
            message = props.messages.DataFeedConfigurations;
        }
        return message;
    }
    return (
        <div>
            {!isNewClient ?
                <Dialog
                    open={props.open}
                    onClose={props.handleClose}
                    classes={{ paper: classes.dialogContainer }}
                    disableBackdropClick
                >
                    <div className={classes.topStripBlue} />
                    <DialogTitle>
                        <Typography data-testid='data-feed-configurations' variant='h6' style={{ fontWeight: 'bold' }} >{getDialogtitle()}</Typography>
                    </DialogTitle>
                    <DialogContent>
                        <Grid container direction='column' xs={12} >
                            {props.isGeneric ? <Grid item container direction='row' xs={12} style={{ padding: theme.spacing(0, 2, 0, 0) }}>
                                <Grid item direction='column' xs={3} style={{ padding: theme.spacing(0, 1.5, 0, 0) }}>
                                    <TextField
                                        data-testid='client-name'
                                        select
                                        fullWidth
                                        SelectProps={{ native: true }}
                                        value={dataFeedConfigObject.client_id}
                                        label={props.messages.clientName}
                                        onChange={handleClientName}
                                        onBlur={() => {
                                            clientNameValidator();
                                            refreshForm();
                                        }}
                                    >
                                        <option value={defaultOption}>{defaultOption}</option>
                                        {props.clients?.map((client: any) => (
                                            <option id={client.clientId} key={client.clientId} value={client.clientId}>{client.clientName}</option>
                                        ))}
                                    </TextField>
                                    {errorObject.client_id != null && errorObject.client_id.length > 1 &&
                                        <Typography variant='caption' color='error'>
                                            {errorObject.client_id}
                                        </Typography>}
                                </Grid>
                                <Grid item direction='column' xs={3} style={{ padding: theme.spacing(0, 1.5, 0, 0) }}>
                                    <Tooltip title='Add New Client'>
                                        <IconButton component="span" onClick={handleOnClickOfAddNewClient}>
                                            <AddBoxIcon fontSize='large' color='secondary' />
                                        </IconButton>
                                    </Tooltip>

                                </Grid>
                                <Grid item direction='column' xs={6} style={{ padding: theme.spacing(0, 0, 0, 1) }}>
                                    <TextField
                                        data-testid='vendor-name'
                                        select
                                        fullWidth
                                        SelectProps={{ native: true }}
                                        label={props.messages.vendor}
                                        value={dataFeedConfigObject.vendorId}
                                        onChange={handleVendorNameChange}
                                    >
                                        {props.vendors?.map((vendor) => (
                                            <option id={vendor.vendorName} key={vendor.vendorId} value={vendor.vendorId}>{vendor.vendorName}</option>
                                        ))}
                                    </TextField>
                                </Grid>
                                {props.isGeneric ? <Grid item container xs={12} style={{ padding: theme.spacing(0.5, 0, 3, 0) }}>
                                    <TextField
                                        data-testid='output-job-name'
                                        label={props.messages.outputJobName}
                                        value={dataFeedConfigObject.outputJobName}
                                        fullWidth
                                        multiline
                                        onChange={handleOutputJobName}
                                        onBlur={() => {
                                            outputJobNameValidator();
                                            refreshForm();
                                        }}
                                    />
                                    {errorObject.outputJobName != null && errorObject.outputJobName.length > 1 &&
                                        <Typography variant='caption' color='error'>
                                            {errorObject.outputJobName}
                                        </Typography>}
                                </Grid> : null}
                            </Grid> : null}
                            <Grid item container direction='row' xs={12}>
                                {props.campaignFormat.formatType.formatTypeAbbreviation === formatTypes.CAMPAIGN_TYPE && !props.isChildConfiguration ? <Grid item container direction='row' justify='flex-start' xs={6} style={{ padding: theme.spacing(0, 2, 0, 0) }}>
                                    <Grid item container direction='column' xs={6} style={{ padding: theme.spacing(0, 1.5, 0, 0) }}>
                                        <TextField
                                            data-testid='vendor-name'
                                            select
                                            SelectProps={{ native: true }}
                                            label={props.messages.vendor}
                                            value={venorName}
                                            onChange={handleVendorNameChange}
                                        >
                                            {props.vendors?.map((vendor) => (
                                                <option id={vendor.vendorName} key={vendor.vendorId} value={vendor.vendorId}>{vendor.vendorName}</option>
                                            ))}
                                        </TextField>
                                    </Grid>
                                    <Grid item container direction='column' xs={6} style={{ padding: theme.spacing(0, 0, 0, 2) }}>
                                        <TextField
                                            data-testid='vendor-display-name'
                                            label={props.messages.displayName}
                                            value={vendorDisplayName}
                                            fullWidth
                                            multiline
                                            onChange={handleVendorDisplayNameChange}
                                            onBlur={() => {
                                                vendorDisplayNameValidator();
                                                refreshForm()
                                            }}
                                        />
                                        {errorObject.vendorDisplayName != null && errorObject.vendorDisplayName.length > 1 &&
                                            <Typography variant='caption' color='error'>
                                                {errorObject.vendorDisplayName}
                                            </Typography>}
                                    </Grid>
                                </Grid> : null}
                                {!props.isChildConfiguration ? <Grid item container direction='row' justify='flex-start' xs={6} style={{ padding: theme.spacing(0, 2, 0, 0) }}>
                                    <Grid item container direction='column' xs={6} style={{ padding: theme.spacing(0, 1.5, 0, 0) }} >
                                        <TextField
                                            data-testid='schedule-type-select'
                                            select
                                            SelectProps={{ native: true }}
                                            label={props.messages.Schedule}
                                            value={scheduleType}
                                            onChange={handleScheduleTypeChange}
                                        >
                                            {scheduleTypes.map((schedule) => (
                                                <option key={schedule} value={schedule}>{schedule}</option>
                                            ))}
                                        </TextField>
                                    </Grid>
                                    {!disableScheduleDaySelect &&
                                        <Grid item container direction='column' xs={6} style={{ padding: theme.spacing(0, 0.5, 0, 2) }}>
                                            <TextField
                                                data-testid='schedule-day-select'
                                                select
                                                SelectProps={{ native: true }}
                                                label={props.messages.ScheduleDay}
                                                value={scheduleDay}
                                                fullWidth
                                                onChange={handleScheduleDayChange}
                                            >
                                                {scheduleDaysArray.map((scheduleDayOption) => (
                                                    <option key={scheduleDayOption} value={scheduleDayOption}>{scheduleDayOption}</option>
                                                ))}
                                            </TextField>
                                        </Grid>}
                                </Grid> : null}

                                <Grid item container direction='column' xs={6} style={{ padding: theme.spacing(props.campaignFormat.formatType.formatTypeAbbreviation === formatTypes.CAMPAIGN_TYPE && !props.isChildConfiguration ? 2 : 0, 2, 0, 0) }}>
                                    <TextField
                                        data-testid='transfer-type-select'
                                        select
                                        SelectProps={{ native: true }}
                                        label={props.messages.TransferType}
                                        value={dataFeedConfigObject.transferType}
                                        onChange={handleTransferTypeChange}
                                        onBlur={() => {
                                            transferTypeValidator();
                                            refreshForm()
                                        }}>
                                        {transferTypeValues.map((transferType) => (
                                            <option key={transferType} value={transferType}>{transferType}</option>
                                        ))}
                                    </TextField>
                                    {errorObject.transferType != null && errorObject.transferType.length > 1 &&
                                        <Typography variant='caption' color='error'>
                                            {errorObject.transferType}
                                        </Typography>}
                                </Grid>

                                {dataFeedConfigObject.transferType === transferTypeValues[2] ?
                                    <Grid item container direction='column' xs={6} style={{ padding: theme.spacing(!props.isChildConfiguration ? 2 : 0, 2, 0, 0) }}>
                                        <FormLabel><Typography variant='caption'>{props.messages.pathType}</Typography></FormLabel>
                                        <FormControlLabel
                                            data-testId='path-type'
                                            label={props.messages.PathTypeLabel}
                                            control={
                                                <Checkbox
                                                    checked={checked}
                                                    onChange={handleChange}
                                                />
                                            }
                                        />
                                    </Grid> : null}
                                <Grid item container direction='column' xs={6} style={{ padding: theme.spacing(!props.isChildConfiguration ? 2 : 0, 2, 0, 0) }}>
                                    {!isAzureBlobSelected ? <TextField
                                        data-testid='auth-type-select'
                                        select
                                        SelectProps={{ native: true }}
                                        label={props.messages.AuthType}
                                        value={dataFeedConfigObject.authType ? dataFeedConfigObject.authType : ''}
                                        onChange={handleAuthTypeChange}
                                        onBlur={() => {
                                            authTypeValidator();
                                            refreshForm()
                                        }}
                                    >
                                        {authTypes.map((authType) => (
                                            <option key={authType} value={authType}>{authType}</option>
                                        ))}
                                    </TextField> : <TextField
                                        data-testid='sas-auth-type-select'
                                        select
                                        SelectProps={{ native: true }}
                                        label={props.messages.AuthType}
                                        value={azureAuthType}
                                        onChange={() => { setIsAzureBlobSelected(true) }}
                                        onBlur={() => {
                                            authTypeValidator();
                                            refreshForm()
                                        }}
                                    >
                                        <option key={azureAuthType} value={azureAuthType}>{azureAuthType}</option>
                                    </TextField>}
                                    {!isAzureBlobSelected && errorObject.authType != null && errorObject.authType.length > 1 &&
                                        <Typography variant='caption' color='error'>
                                            {errorObject.authType}
                                        </Typography>}
                                </Grid>
                                {isAzureBlobSelected && <Grid item container direction='column' justify='flex-start' xs={12} style={{ padding: theme.spacing(2, 2, 0, 0) }}>
                                    <TextField
                                        data-testid='sas-url-input'
                                        label={props.messages.azureSasUrl}
                                        value={dataFeedConfigObject.hostName && dataFeedConfigObject.hostName}
                                        fullWidth
                                        multiline
                                        onChange={handleSasUrlChange}
                                        onBlur={() => {
                                            sasUrlValidator();
                                            refreshForm()
                                        }}
                                    />
                                    {errorObject.hostName != null && errorObject.hostName.length > 1 &&
                                        <Typography variant='caption' color='error' data-testid='sas-url-error'>
                                            {errorObject.hostName}
                                        </Typography>}
                                </Grid>}
                                {!isAzureBlobSelected && !isAwsSelected ? <Grid item container direction='column' justify='flex-start' xs={6} style={{ padding: theme.spacing((props.isChildConfiguration && dataFeedConfigObject.transferType === transferTypeValues[2]) ? 0 : 2, 2, 0, 0) }}>
                                    <TextField
                                        disabled={isAzureBlobSelected}
                                        data-testid='port-input'
                                        label={props.messages.Port}
                                        value={!isAzureBlobSelected ? dataFeedConfigObject.port : ''}
                                        fullWidth
                                        multiline
                                        onChange={handlePortChange}
                                        onBlur={() => {
                                            portValidator();
                                            refreshForm()
                                        }}
                                    />
                                    {!isAzureBlobSelected && errorObject.port != null && errorObject.port.length > 1 &&
                                        <Typography variant='caption' color='error'>
                                            {errorObject.port}
                                        </Typography>}
                                </Grid> : null}
                                <Grid item container direction='column' justify='flex-start' xs={6} style={{ padding: theme.spacing(2, 2, 0, 0) }}>
                                    <TextField
                                        data-testid='auth-username-input'
                                        label={isAwsSelected ? props.messages.accessKey : isAzureBlobSelected ? props.messages.azureStorageAccountName : props.messages.AuthUsername}
                                        value={dataFeedConfigObject.authUserName}
                                        fullWidth
                                        multiline
                                        disabled={isAzureBlobSelected || (isAwsSelected && isRollBasedAuthType)}
                                        InputLabelProps={{ shrink: shrinkTextField(dataFeedConfigObject.authUserName) }}
                                        onChange={handleAuthUserNameChange}
                                        onBlur={() => {
                                            authUsernameValidator();
                                            refreshForm()
                                        }}
                                    />
                                    {errorObject.authUserName != null && errorObject.authUserName.length > 1 &&
                                        <Typography variant='caption' color='error'>
                                            {errorObject.authUserName}
                                        </Typography>}
                                </Grid>
                                {!isAzureBlobSelected && <Grid item container direction='column' justify='flex-start' xs={6} style={{ padding: theme.spacing(2, 2, 0, 0) }}>
                                    <TextField
                                        data-testid='auth-pass-key-input'
                                        label={isAwsSelected ? props.messages.secretKey : getAuthPassOrKeyLabel()}
                                        type={!authPassMask ? 'text' : 'password'}
                                        value={dataFeedConfigObject.authPasswordOrKey}
                                        disabled={isAwsSelected && isRollBasedAuthType}
                                        InputProps={{
                                            endAdornment:
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowAuthPass}
                                                    >
                                                        {!authPassMask ? <Visibility /> : <VisibilityOff />}
                                                    </IconButton>
                                                </InputAdornment>
                                        }}
                                        multiline={!authPassMask}
                                        fullWidth
                                        onChange={handleAuthPassOrKeyChange}
                                        onBlur={() => {
                                            authPassOrKeyValidator();
                                            refreshForm()
                                        }}
                                    />
                                    {errorObject.authPasswordOrKey != null && errorObject.authPasswordOrKey.length > 1 &&
                                        <Typography variant='caption' color='error'>
                                            {errorObject.authPasswordOrKey}
                                        </Typography>}
                                </Grid>}
                                {isAwsSelected ? <Grid item container direction='column' justify='flex-start' xs={6} style={{ padding: theme.spacing(2, 2, 0, 0) }}>
                                    <TextField
                                        data-testid='hostname'
                                        label={props.messages.bucketName}
                                        value={dataFeedConfigObject.hostName.split('/')[0]}
                                        fullWidth
                                        multiline
                                        onChange={handleHostNameChange}
                                        onBlur={() => {
                                            hostNameValidator();
                                            refreshForm()
                                        }}
                                    />
                                    {errorObject.hostName != null && errorObject.hostName.length > 1 &&
                                        <Typography variant='caption' color='error'>
                                            {errorObject.hostName}
                                        </Typography>}
                                </Grid> : <Grid item container direction='column' justify='flex-start' xs={6} style={{ padding: theme.spacing(2, 2, 0, 0) }}>
                                    <TextField
                                        data-testid='target-folder-input'
                                        label={isAzureBlobSelected ? props.messages.azureContainerName : props.messages.TargetFolder}
                                        value={isAzureBlobSelected ? dataFeedConfigObject.targetFolder && dataFeedConfigObject.targetFolder.split('/')[0] : dataFeedConfigObject.targetFolder}
                                        fullWidth
                                        multiline
                                        disabled={isAzureBlobSelected}
                                        InputLabelProps={{ shrink: shrinkTextField(dataFeedConfigObject.targetFolder) }}
                                        onChange={handleTargetFolderChange}
                                        onBlur={() => {
                                            targetFolderValidator();
                                            refreshForm()
                                        }}
                                    />
                                    {errorObject.targetFolder != null && errorObject.targetFolder.length > 1 &&
                                        <Typography variant='caption' color='error'>
                                            {errorObject.targetFolder}
                                        </Typography>}
                                </Grid>}
                                {isAzureBlobSelected || isAwsSelected ? <Grid item container direction='column' justify='flex-start' xs={6} style={{ padding: theme.spacing(2, 2, 0, isAwsSelected ? 0 : isAzureBlobSelected ? 0 : 2) }}>
                                    <TextField
                                        data-testid='sub-folder'
                                        label={props.messages.subFolderName}
                                        value={subFolder}
                                        fullWidth
                                        multiline
                                        onChange={handleSubFolderNameChange}
                                        onBlur={() => {
                                            subFolderValidator();
                                            refreshForm()
                                        }}
                                    />
                                    {errorObject.subFolder != null && errorObject.subFolder.length > 1 &&
                                        <Typography variant='caption' color='error' data-testid='sub-folder-error'>
                                            {errorObject.subFolder}
                                        </Typography>}
                                </Grid> : null}
                                <Grid item container direction='column' xs={6} style={{ padding: theme.spacing(2, 2, 0, 0) }}>
                                    <TextField
                                        data-testid='is-active-select'
                                        select
                                        SelectProps={{ native: true }}
                                        label={props.messages.Status}
                                        value={selectedStatus}
                                        onChange={handleIsActiveChange}
                                    >
                                        {dataFeedConfigStatuses.map((status) => (
                                            <option key={status} value={status}>{status}</option>
                                        ))}
                                    </TextField>
                                </Grid>
                                {!isAzureBlobSelected && !isAwsSelected ? <Grid item container direction='column' justify='flex-start' xs={6} style={{ padding: theme.spacing(2, 2, 0, 0) }}>
                                    <TextField
                                        data-testid='host-name-input'
                                        label={props.messages.HostName}
                                        value={dataFeedConfigObject.hostName ? dataFeedConfigObject.hostName : ''}
                                        fullWidth
                                        multiline
                                        onChange={handleHostNameChange}
                                        onBlur={() => {
                                            hostNameValidator();
                                            refreshForm()
                                        }}
                                    />
                                    {errorObject.hostName != null && errorObject.hostName.length > 1 &&
                                        <Typography variant='caption' color='error'>
                                            {errorObject.hostName}
                                        </Typography>}
                                </Grid> : null}
                                <Grid></Grid>
                            </Grid>
                            {!props.isNewDataFeedConfiguration ? <Grid container lg={12} direction='column'>
                                {validationsNeedsToSkip != null && validationsNeedsToSkip.length > 0 ? <Grid container direction='row' sm={12} style={{ padding: theme.spacing(2.5, 0, 0, 0) }}>
                                    <Typography style={{ fontSize: '17px', fontWeight: 'bold' }}>{props.messages.performConnectionValidations}</Typography>
                                </Grid> : null}
                                <Grid container justify='flex-start' direction='row' xs={10} style={{ padding: theme.spacing(1.5, 0, 0, 0.5) }}>
                                    {validationsNeedsToSkip?.map((skipValidationObject: any, index: number) => (
                                        <CustomCheckBoxComponent gridSize={skipValidationObject.gridSize} testId={skipValidationObject.validation_code} label={skipValidationObject.validationName} value={skipValidationObject.validation_code} name={skipValidationObject} isBoxChecked={isValidationBoxChecked(skipValidationObject.validation_code, index)} index={index} onChange={handleSkipValidations} />
                                    ))}
                                </Grid>
                                {showSkipValidationsNote() ? <Grid direction='row' style={{ padding: theme.spacing(0.7, 0, 0, 0) }}>
                                    <Typography style={{ fontSize: '14px' }}>{`${props.messages.note}${props.messages.skipValidationsWarningMessage}`}</Typography>
                                </Grid> : null}
                            </Grid> : null}
                        </Grid>
                    </DialogContent>
                    <DialogContainer
                        heading={props.messages.updateDataFeedConfiguration}
                        open={openDialog}
                        body={warningDialog}
                        primaryButtonHeading={'Edit'}
                        primaryButtonLoader={false
                        }
                        onPrimaryButtonClick={closeDialogForUpdate}
                        handleClose={handleCloseDialog}
                        secondaryButtonHeading={'Cancel'}
                    />
                    <DialogActions style={{ padding: theme.spacing(0, 2, 2, 2) }}>
                        {actionKeyLoader ?
                            <CircularProgress color='secondary' size={24} /> :
                            <React.Fragment>
                                <Button data-testid='edit-cancel-button' variant='contained' onClick={handleClose}>
                                    {props.messages.cancelButtonLabel}
                                </Button>
                                {!props.isChildConfiguration ? <Button data-testid='edit-save-button' variant='contained' color='secondary' onClick={saveClick}>
                                    {props.messages.saveButtonLabel}
                                </Button> : <Button data-testid='edit-save-button' variant='contained' color='secondary' onClick={saveChildDataFeedConfig}>
                                    {props.messages.saveButtonLabel}
                                </Button>}
                            </React.Fragment>}
                    </DialogActions>
                </Dialog> : <ClientCreateDialogContainer
                    clientDialogOpen={openClientDialog}
                    handleClientCloseDialog={handleClientCloseDialog}
                    handleClientSave={handleClientSave}
                />}
        </div>

    )
}

const useStyles = makeStyles((theme) => ({
    dialogContainer: {
        minWidth: '900px'
    },
    topStripBlue: {
        background: colorPalette.primaryShade,
        height: '10px'
    }
}));

const mapStateToProps = (state: RootState) => ({
    messages: state.messageBundle.messages,
    user: state.user.user,
    selectedFormatDataFeedConfig: state.format.selectedFormatDataFeedConfig,
    vendors: state.vendor.vendors,
    clients: state.client.clients,
    clientFetchiningInProgress: state.client.clientsFetchingInProgress,
    newClient: state.client.newClient,
    hasClientChanged: state.client.hasClientNameChanged
});
const mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators(
        {
            updateCampaignFormatDataFeedConfig,
            saveCampaignFormatDataFeedConfig,
            setCampaignFormatDataFeedConfigs,
            clearNewClientDataFromState,
            setClientNameChanged
        },
        dispatch
    );
};
type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export const DataFeedEditDialogContainer = connect(mapStateToProps, mapDispatchToProps)(UnconnectedDataFeedEditDialogContainer);