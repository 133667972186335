import { REFRESH, REFRESH_DATE } from "../dfbConstants";

export function padTo2Digits(num: number) {
    return num.toString().padStart(2, '0');
}

function formatDate(date: Date) {
    let monthNames = ["Jan", "Feb", "Mar", "Apr",
        "May", "Jun", "Jul", "Aug",
        "Sep", "Oct", "Nov", "Dec"];
    return [
        padTo2Digits(date.getDate()),
        monthNames[date.getMonth()],
        date.getFullYear(),
    ].join(' ');
}

export const reportsAdapter = (reports: any, legends: any,filterCount:number) => {
    let reportData = []
    for (let j = 0; j < reports.length; j++) {
        let reportItem: any = {};
        reportItem[REFRESH] = `Refresh ${j + filterCount}`
        reportItem[REFRESH_DATE] = formatDate(new Date(reports[j]['runDate']));
        Object.keys(reports[j]).forEach(function (key: string) {
            if (legends[key] !== undefined) {
                reportItem[legends[key].legendName] = Number(reports[j][key]);
            }
        });
        reportData.push(reportItem);
    }
    return reportData;
}
