/**
 * 
 * @param userAuthorities 
 * @param requestedAuthority
 * This util consists of  isAuthorityAvailable which takes in the logged in user's auths
 * and the required access constant and verifies if permission has to be given
 */


export const isAuthorityAvailable = (userAuthorities: Array<any>, requestedAuthority: string, targetGroup: string, groups: any) => {
    if (checkUserGroup(groups, targetGroup)) {
        if (userAuthorities && userAuthorities.length > 0) {
            const result = userAuthorities.find(auth => auth === requestedAuthority)
            if (result) {
                return true;
            }
            else {
                return false;
            }
        }
        else {
            return false;
        }
    } else {
        return false;
    }
}

export const adminUserAuthoritiesStub: Array<any> = [
    {
        authority_id: 1,
        authority: 'CAMPAIGN_VIEW'
    }
]

export const accountManagerUserAuthoritiesStub: Array<any> = [
    {
        authority_id: 1,
        authority: 'CAMPAIGN_VIEW'
    },
    {
        authority_id: 2,
        authority: 'CAMPAIGN_CREATE'
    },
    {
        authority_id: 3,
        authority: 'CAMPAIGN_DELETE'
    }
]

export const authorities = {
    'CAMPAIGN_VIEW': 'CAMPAIGN_VIEW',
    'CAMPAIGN_CREATE': 'CAMPAIGN_CREATE',
    'CAMPAIGN_DELETE': 'CAMPAIGN_DELETE',
    'DATA_FEED_CONFIGURATION_CREATE': 'DATA_FEED_CONFIGURATION_CREATE',
    'DATA_FEED_CONFIGURATION_EDIT': 'DATA_FEED_CONFIGURATION_EDIT',
    'DELETE_OUTPUT_FILE': 'DELETE_OUTPUT_FILE',
    'FILE_RETRIEVAL_CONFIG_CREATE': 'FILE_RETRIEVAL_CONFIG_CREATE',
    'FILE_RETRIEVAL_CONFIG_DELETE': 'FILE_RETRIEVAL_CONFIG_DELETE'
}
export const checkUserGroup = (loggedInUserGroups: any, targetGroup: string): boolean => {
    if (loggedInUserGroups) {
        if (Array.isArray(loggedInUserGroups)) {
            return loggedInUserGroups.includes(targetGroup);
        }
        else {
            return loggedInUserGroups === targetGroup;
        }
    }
    else {
        return false;
    }
};